<template>
  <span>
    <a class="link-btn" href="javascript:;" @click="buyNow" v-if="isNormal">
      <slot>Buy Now</slot>
    </a>
    <v-btn
      v-else
      @click="buyNow"
      block
      tile
      depressed
      class="primary white--text text-none"
    >
      <slot>Buy Now</slot>
    </v-btn>
  </span>
</template>
<script>
export default {
  data: () => ({
    obj: {
      CartType: "BuyNow",
      CartQuantity: 1,
    },
  }),
  props: {
    product: Object,
    isNormal: Boolean,
    selectedAttributes: Array,
    isEmi: Boolean,
  },
  methods: {
    buyNow() {
      if (this.product.StockQuantity <= 0) {
        alert("Stock not available.");
        return;
      }

      if (this.obj.CartQuantity < 1) {
        alert("Cart quantity must be 1 or higher");
        return;
      }

      if (
        this.product.StockType == "Lot" &&
        this.product.SaleType == "Auction"
      ) {
        this.obj.CartQuantity = this.product.StockQuantity;
      }

      if (this.obj.CartQuantity > this.product.StockQuantity) {
        alert("Stock not available. Please reduce stock.");
        return;
      }

      // emi block
      if (this.isEmi) {
        this.obj.CartQuantity = this.product.StockQuantity = 1;
        this.product.AvailEmi = true;
      }

      let { Documents, ImageUrl, Id, ...propObj } = this.product;

      if (!ImageUrl && Documents) {
        ImageUrl = Documents[0]?.FilePath;
      }
      this.obj = {
        ...propObj,
        ItemId: Id,
        ImageUrl,
        ...this.obj,
        Attributes: this.selectedAttributes,
      };

      // pixel event for addToCard Start
      window.fbq("track", "InitiateCheckout", {
        value: this.product.SalesPrice,
        currency: "BDT",
      });
      // pixel event for addToCard End

      this.$store.dispatch("updateCards", this.obj).then(() => {
        let resolved = this.$router.resolve({
          name: "Checkout",
          params: this.$route.params,
        });
        let check = this.$root.checkAuth(resolved.href);
        if (check) return;

        this.$router.push({ name: "Checkout" }).catch(() => {});
      });
    },
  },
  created() {
    this.Fire.$on("qtyUpdate", (val) => {
      if (this.product.Id == val.id) {
        this.obj.CartQuantity = val.qty;
      }
    });
  },
  beforeDestroy() {
    this.Fire.$off("qtyUpdate");
  },
};
</script>

<style lang="scss">
@import "@/sass/app/index.scss";
.link-btn {
  padding: rem-calc(0 12px);
  display: inline-block;
  text-decoration: underline;
  transition: all 300ms;
  @include font(false, 16px, 14px, bold);
}
</style>
