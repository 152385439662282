export function loadFbSdk(appId, version) {
  return new Promise((resolve) => {
    /* eslint-disable */
    window.fbAsyncInit = function () {
      FB.init({
        appId,
        version,
        xfbml: true,
        cookie: true
      });
      FB.AppEvents.logPageView();
      resolve('SDK Loaded!');
    };
    /* eslint-enable */
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
export function loadGoogle(client_id, callback) {
  return new Promise((resolve) => {
    let googleScript = document.createElement('script');
    googleScript.onload = () => {
      window.google.accounts.id.initialize({ 
        client_id: client_id,
        callback: callback
      });
      window.google.accounts.id.renderButton(
        document.getElementById("signin_button"),
        { theme: "outline", size: "large" }  // customization attributes data
      );
      window.google.accounts.id.prompt();
    };
    googleScript.setAttribute(
      "src",
      "https://accounts.google.com/gsi/client"
    );
    document.head.appendChild(googleScript);
    resolve('google Loaded!');
  });
}
export function getFbLoginStatus() {
  return new Promise((resolve) => {
    window.FB.getLoginStatus((responseStatus) => {
      resolve(responseStatus);
    });
  });
}

export function fbLogin(options) {
  return new Promise((resolve) => {
    window.FB.login((response) => resolve(response), options);
  });
}
export function fbme(token) {
  return new Promise((resolve) => {
    window.FB.api('/me?fields=id,name,email,picture,first_name,last_name&access_token=' + token, (response) => resolve(response));
  });
}
export function fbLogout() {
  return new Promise((resolve) => {
    window.FB.logout((response) => resolve(response));
  });
}
