<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-layout
                v-if="dashboardCounts && Object.keys(dashboardCounts).length"
                wrap
                class="account-panel"
              >
                <!-- summary sections -->
                <v-flex
                  class="summary-wrapper"
                  v-for="(Obj, index) in dashboardCounts"
                  :key="index"
                >
                  <h2 v-text="Obj.who"></h2>
                  <v-layout
                    wrap
                    class="summary-wrapper-container"
                    v-for="(segmentObj, index) in Obj.data"
                    :key="index"
                  >
                    <h3 v-text="segmentObj.title + ' : '"></h3>
                    <v-flex
                      class="d-flex flex-wrap flex-grow-0 summary-wrapper-in"
                    >
                      <a
                        href="javascript:void(0)"
                        class="flex-grow-0 summary-box"
                        v-for="(countVal, index) in segmentObj.countsArr"
                        :key="index"
                      >
                        <span v-text="countVal.itemName"> </span>
                        <span
                          v-text="'(' + productSummary[countVal.varName] + ')'"
                        ></span>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-flex v-else>
                <h3 class="pa-3">Data not found!</h3>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import DashboardCounts from "@/dashboardCountsConfig";

export default {
  name: "MyAccount",
  components: {
    AccountSidebar,
  },
  data() {
    return {
      productSummary: Object,
    };
  },
  created() {
    this.getMyProductSummary();
    this.dashboardCounts = DashboardCounts;
  },
  methods: {
    getMyProductSummary() {
      this.$http
        .post(this.$store.state.Configs.getMyProductSummary)
        .then((result) => {
          this.productSummary = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_profile.scss";
</style>
