<template>
  <div>
    <v-flex class="cart-summary mb-5">
      <template v-if="login">
        <v-flex>
          <h2
            class="d-flex justify-space-between align-center"
            @click="isOpenShipping()"
          >
            <span v-text="$t('Shipping Address')"></span>
            <v-icon>{{
              isShippingOpen ? "mdi-chevron-down" : "mdi-chevron-up"
            }}</v-icon>
          </h2>
        </v-flex>

        <v-flex
          class="ship-info"
          v-bind:class="{ 'close-shipping-adderss': isShippingOpen }"
        >
          <v-flex
            class="address"
            v-if="shippingAddressList.length && !pickupAddressLoading"
          >
            <v-flex class="d-flex shiping-title justify-space-between">
              <span
                v-if="pickupAddressId"
                v-text="authUser.UserFullName"
              ></span>
              <span
                v-else-if="Object.keys(selectedAddress).length"
                v-text="selectedAddress.Title"
              ></span>

              <v-flex class="d-flex flex-grow-0">
                <v-btn
                  v-if="!pickupAddressId"
                  class="add-address text-nones mr-1"
                  outlined
                  tile
                  small
                  color="indigo"
                  @click="$refs.addressdialog.modalOpen(selectedAddress)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-flex class="change-address flex-grow-0">
                  <v-menu
                    transition="slide-x-transition"
                    bottom
                    left
                    offset-y
                    rounded="0"
                    v-if="shippingAddress.length"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-flex
                        v-bind="attrs"
                        v-on="on"
                        class="pointer change-address-text"
                        >Change</v-flex
                      >
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="shipping in shippingAddress"
                        :key="shipping.Id"
                      >
                        <v-list-item-title>
                          <a
                            @click="changeAddress(shipping.Id, true)"
                            href="javascript:void(0)"
                            v-text="shipping.Title"
                          ></a>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <a
                            class="add-address text-none"
                            small
                            color="teal"
                            @click="$refs.addressdialog.modalOpen()"
                          >
                            <v-icon>mdi-plus</v-icon>Add New
                          </a>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-btn
                  v-if="!shippingAddress.length"
                  class="add-address text-none ml-1"
                  outlined
                  tile
                  small
                  color="teal"
                  @click="$refs.addressdialog.modalOpen()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-flex>

            <address
              v-if="pickupAddressId && Object.keys(selectedAddress).length"
            >
              <!-- for pickup address head -->
              <h3 class="collect-point">
                <i class="d-inline-block" v-text="selectedAddress.Title"></i>
              </h3>

              <span
                :class="{
                  'address-error':
                    alertObj.addressError && !selectedAddress.Address1,
                }"
              >
                Address:
                <strong v-text="selectedAddress.Address1"></strong>
              </span>
              <span>
                <strong
                  class="success--text"
                  v-text="selectedAddress.DistrictName"
                ></strong>
              </span>
              <div class="change-pickup">
                <small
                  class="pointer"
                  @click="
                    $refs.pickupaddressdialog.modalOpen(pickupAddressList)
                  "
                  >Change Pickup Point</small
                >
                <v-icon size="16" color="brand">mdi-chevron-right</v-icon>
              </div>
            </address>

            <address v-else-if="Object.keys(selectedAddress).length">
              <span>
                Name:
                <strong v-text="selectedAddress.Name"></strong>
              </span>
              <span>
                Phone:
                <strong v-text="selectedAddress.Mobile"></strong>
              </span>
              <span class="text-truncate">
                Email:
                <strong v-text="selectedAddress.Email"></strong>
              </span>
              <span
                :class="{
                  'address-error':
                    alertObj.addressError && !selectedAddress.Address1,
                }"
              >
                Address:
                <strong v-text="selectedAddress.Address1"></strong>
              </span>
              <span
                ><strong
                  class="success--text"
                  v-text="selectedAddress.DistrictName"
                ></strong
              ></span>
              <span
                v-if="selectedAddress.IsDefault"
                class="brand--text"
                v-text="'Default Address'"
              ></span>
            </address>
            <address v-else>
              <strong>Address not found</strong>
            </address>

            <v-flex
              class="pick-up transition-ease-in-out"
              v-if="!pickupAddressId && pickupAddressList.length"
              @click="$refs.pickupaddressdialog.modalOpen(pickupAddressList)"
            >
              <a class="d-inline-block">
                <span class="d-block">Collect from Nilam Pick-up point ?</span>
                Get Free Delivery
                <v-icon size="18" color="brand">mdi-chevron-right</v-icon>
              </a>
            </v-flex>
          </v-flex>
        </v-flex>
        <address-form ref="addressdialog"></address-form>
        <pickup-address-list ref="pickupaddressdialog"></pickup-address-list>

        <billing-address
          v-if="shippingAddressList.length"
          :address-list="shippingAddressList"
        ></billing-address>
      </template>

      <v-flex class="delivery-option d-none">
        <v-radio-group v-model="ShippedBy" hide-details>
          <v-radio
            v-for="element in shippedByArr"
            :key="element.Key"
            :value="element.Key"
            :disabled="cartType != 'BuyNow'"
            color="primary"
          >
            <template v-slot:label>
              <v-layout class="option-item justify-space-between">
                <span v-text="element.Title"></span>
                <strong v-text="$root.showAmount(element.Cost)"></strong>
              </v-layout>
            </template>
          </v-radio>
        </v-radio-group>
      </v-flex>
      <h2 class="d-flex align-center">Order Summary</h2>
      <v-flex class="cart-summary-box">
        <v-flex class="cart-info">
          <strong>Sub Total:</strong>
          <span v-text="$root.showAmount(subTotal)"></span>
        </v-flex>

        <v-flex class="cart-info" v-if="couponObj && couponObj.Amount">
          <strong v-text="'Coupon: ' + couponObj.Code"></strong>
          <span
            class="coupon-amount"
            v-text="'(-) ' + $root.showAmount(couponObj.Amount)"
          ></span>
          <span
            class="danger--text darken-4 text-decoration-underline pointer"
            @click="removeCoupon()"
            >Remove</span
          >
        </v-flex>

        <v-flex class="cart-info">
          <strong>Shipping Fee:</strong>
          <span v-text="$root.showAmount(ShippingFee)"></span>
        </v-flex>
        <v-flex class="cart-info">
          <strong>Total:</strong>
          <span
            class="grand-total brand--text"
            v-text="$root.showAmount(grandTotal)"
          ></span>
        </v-flex>

        <v-layout class="btn-holder pt-3 justify-space-between">
          <v-flex class="input-wrap coupon-code flex-grow-0">
            <v-text-field
              v-model="Coupon.Code"
              value="4557"
              solo
              flat
              hide-details
              outlined
              autocomplete="off"
              type="text"
              label="Enter Your Coupon code"
            ></v-text-field>
          </v-flex>

          <v-flex class="coupon-code-ntn flex-grow-0">
            <v-btn
              @click="applyCoupon()"
              tile
              depressed
              class="primary white--text text-none"
              >Apply Coupon</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-flex>

    <v-flex class="d-flex flex-wrap justify-end continue-btn-holder">
      <v-flex class="w-100">
        <!-- Agree -->
        <ValidationProvider
          v-if="
            siteSettings &&
            Object.keys(siteSettings).length &&
            siteSettings.TermsAndConditionURL &&
            page == 'payment'
          "
          class="agree"
          tag="div"
          :bails="false"
          v-slot="{ errors, classes }"
        >
          <div :class="classes" class="pb-3">
            <v-checkbox hide-details v-model="formData.agree">
              <template v-slot:label>
                <div>
                  I agree with
                  <a
                    target="_blank"
                    :href="siteSettings.TermsAndConditionURL"
                    @click.stop
                    >Order Terms And Conditions</a
                  >
                </div>
              </template>
            </v-checkbox>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </v-flex>
      <v-btn
        block
        v-if="page == 'checkout'"
        @click="goForPayment"
        tile
        depressed
        class="continue-payment text-none"
        >Place Order</v-btn
      >
      <v-btn
        v-if="page == 'payment'"
        @click="placeOrder"
        tile
        depressed
        :loading.sync="loading"
        :disabled="!formData.agree"
        class="confirm-order brand white--text text-none order-1"
      >
        Confirm Order
      </v-btn>
    </v-flex>

    <v-flex class="mt-2 d-none">
      <!-- only for go to payment gatewaty -->
      <pay-now-btn
        v-if="Object.keys(orderObj).length"
        ref="payNowBtn"
        referance-type="Order"
        :cart-type="cartType"
        :referance-id="orderObj.Id"
        :amount="orderObj.TotalAmount"
        :avail-emi.sync="AvailEmi"
        :payment-gateway.sync="PaymentMode"
      ></pay-now-btn>
    </v-flex>

    <v-snackbar
      v-model="alertObj.modal"
      color="purple"
      :centered="true"
      :multi-line="true"
    >
      <span v-text="alertObj.message"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="alertObj.modal = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <ipdc-modal
      v-if="page == 'payment'"
      ref="ipdc"
      :gateway.sync="selectedGatewayInfo"
    >
    </ipdc-modal>
  </div>
</template>

<script>
import AddressForm from "./AddressForm.vue";
import IpdcModal from "./IpdcModal.vue";
import PayNowBtn from "./PayNowBtn.vue";
import PickupAddressList from "./PickupAddressList.vue";
import BillingAddress from "./BillingAddress.vue";
import { AddressMixins } from "@/mixins/AddressMixins";

export default {
  name: "OrderSummay",
  mixins: [AddressMixins],
  components: {
    AddressForm,
    PayNowBtn,
    PickupAddressList,
    IpdcModal,
    BillingAddress,
  },
  props: {
    login: Boolean,
    page: String,
    PaymentMode: String,
    OrderNote: String,
    GatewayList: Array,
  },

  data() {
    return {
      alertObj: {},
      loading: false,
      pickupAddressLoading: false,
      cartType: "BuyNow",
      formData: {
        agree: true,
      },
      addressObj: {},
      Coupon: {
        Type: "BuyNow",
      },
      shippedByArr: [
        {
          Key: "Seller",
          IsDisabled: false,
          Title: "Shipped By Seller",
          Cost: 0,
        },
        {
          Key: "Nilam",
          IsDisabled: false,
          Title: "Shipped By Seller",
          Cost: 50,
        },
      ],
      ShippedBy: "Nilam",
      shippingAddressList: [],
      shippingAddress: [],
      selectedAddress: {},
      pickupAddressList: [],
      ShippingFee: 0,
      orderObj: {},
      AvailEmi: false,
      isShippingOpen: false,
    };
  },
  computed: {
    carts() {
      return this.$store.state.carts;
    },
    couponArr() {
      return this.carts.Coupon;
    },
    items() {
      return this.carts[this.cartType];
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    couponObj() {
      var discountAmount = 0;
      var Code = null;

      if (this.couponArr.length) {
        var index = this._.findIndex(this.couponArr, {
          Type: this.cartType,
        });

        if (index !== -1) {
          if (!this.couponArr[index].Amount) {
            return { Amount: 0, Code: null };
          }

          Code = this.couponArr[index].Code;

          // Percentage mandatory in backoffice
          if (this.couponArr[index].IsPercentage) {
            // Calculate percentage amount of this coupon
            discountAmount =
              (this.subTotal * this.couponArr[index].Amount) / 100;

            if (this.couponArr[index].MaxDiscountAmount) {
              // set discout value for this coupon
              if (discountAmount > this.couponArr[index].MaxDiscountAmount) {
                discountAmount = this.couponArr[index].MaxDiscountAmount;
              }
            }
          }
          // currently this option turned off
          // else {
          //   return this.carts.Coupon.Amount;
          // }
        }
      }
      return { Amount: discountAmount, Code: Code };
    },
    subTotal() {
      let subTotalHolder = 0;
      this.items.forEach((element) => {
        if (!element.CartType && !element.IsSelected) {
          subTotalHolder += 0;
        } else {
          subTotalHolder += element.SalesPrice * element.CartQuantity;
        }
      });
      if (this.page == "payment" && this.selectedGatewayInfo.Percentage) {
        subTotalHolder +=
          (subTotalHolder * this.selectedGatewayInfo.Percentage) / 100;
      }
      return subTotalHolder;
    },
    grandTotal() {
      return (this.subTotal - this.couponObj.Amount + this.ShippingFee).toFixed(
        2
      );
    },
    pickupAddressId() {
      return this.$store.state.selectedPickupAddressId;
    },
    selectedBillingAddressId() {
      return this.$store.state.selectedBillingAddressId;
    },
    sourceList() {
      if (this.pickupAddressId) {
        return [...this.pickupAddressList];
      }
      return [...this.shippingAddressList];
    },
    authUser() {
      if (this.$auth) {
        return this.$auth.user;
      }
      return null;
    },
    profileObj() {
      return this.$store.state.profileObj;
    },
    selectedGatewayInfo() {
      return (
        this._.find(this.GatewayList, {
          Code: this.PaymentMode,
        }) ?? {}
      );
    },
  },

  created() {
    document.addEventListener("visibilitychange", this.visibilityChange, false);
    this.cartType =
      this.$root.capitalizeFirstLetter(
        this._.camelCase(this.$route.params.cartType)
      ) || "BuyNow";

    this.getPickupAddress();
    this.ShippedBy = this.carts.ShippedBy || "Nilam";
  },
  methods: {
    isOpenShipping() {
      this.isShippingOpen = !this.isShippingOpen;
    },

    visibilityChange() {
      if (!document.hidden) {
        this.$store.state.carts = JSON.parse(localStorage.getItem("app-carts"));
      }
    },
    removeCoupon() {
      var index = this._.findIndex(this.carts.Coupon, {
        Type: this.cartType,
      });
      if (index != -1) {
        this.$store.dispatch("removeCoupon", this.carts.Coupon[index]);
      }
    },
    async changeAddress(Id, pickuptAddressToBeEmpty) {
      if (Id == this.selectedAddress.Id) return;

      if (
        pickuptAddressToBeEmpty &&
        this.$store.state.selectedPickupAddressId
      ) {
        this.$store.commit("removePickupAddressId");
      }

      this.selectedAddress = await this.getSelectedAddress(this.sourceList, Id);

      this.shippingAddress = this.getAddressListsWithoutSelected(
        this.shippingAddressList,
        Id
      );

      this.updateCartsForSelectedShippingAddress(Id);

      this.getShippingCost(Id);
    },
    getPickupAddress() {
      if (this.pickupAddressLoading) return;
      this.pickupAddressLoading = true;

      return this.$http({
        method: "post",
        url: this.$store.state.Configs.getPickupPoint,
      })
        .then((resp) => {
          this.pickupAddressList = resp.data;
        })
        .then(() => {
          // this.pickupAddressLoading = false;//this line is added in getShippingAddress()
          this.getShippingAddress();
        })
        .catch((err) => {
          this.pickupAddressLoading = false;
          console.log(err);
        });
    },
    getShippingAddress(ShippingAddressId) {
      if (this.loading) return;

      this.loading = true;

      this.$http({
        method: "post",
        url: this.$store.state.Configs.getShippingAddress,
      })
        .then((resp) => {
          this.pickupAddressLoading = false;
          this.shippingAddressList = resp.data;
        })
        .then(() => {
          let selectedShippingAddressId =
            ShippingAddressId ||
            this.carts?.ShippingAddressId ||
            this.pickupAddressId ||
            0;

          this.shippingAddress = this.getAddressListsWithoutSelected(
            this.shippingAddressList,
            selectedShippingAddressId
          );

          return Number(selectedShippingAddressId);
        })
        .then((selectedShippingAddressId) => {
          this.selectedAddress = this.getSelectedAddress(
            this.sourceList,
            selectedShippingAddressId
          );
          return this.selectedAddress;
        })
        .then((selectedAddress) => {
          this.updateCartsForSelectedShippingAddress(selectedAddress?.Id || 0);
          return selectedAddress;
        })
        .then((selectedAddress) => {
          this.getShippingCost(selectedAddress?.Id || 0);
        })
        .catch((err) => {
          this.pickupAddressLoading = false;
          this.loading = false;
          console.log(err);
        });
    },
    getShippingCost(selectedShippingAddressId) {
      //firt time get shipping cost when geting shipping address
      if (!this.subTotal || !selectedShippingAddressId) {
        this.ShippingFee = 0;
        this.loading = false;
        return;
      }

      this.$http({
        method: "post",
        url: this.$store.state.Configs.getShippingCost,
        data: {
          CartType: this.cartType,
          AddressId: Number(selectedShippingAddressId),
          ItemId: this.items[0]?.ItemId,
          Quantity: this.items[0]?.CartQuantity,
        },
      })
        .then((res) => {
          this.ShippingFee = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    deleteIfSameCouponExistsInOtherCart(CartType, CouponCode) {
      return new Promise((resolve, reject) => {
        var replaceAbleObj = {
          BuyNow: "Ecommerce",
          Ecommerce: "BuyNow",
        };

        var index = this._.findIndex(this.carts.Coupon, {
          Type: replaceAbleObj[CartType],
          Code: CouponCode,
        });

        if (index != -1) {
          this.$store
            .dispatch("removeCoupon", {
              Type: replaceAbleObj[CartType],
              Code: CouponCode,
            })
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve("Fresh coupon is applied");
        }
      });
    },
    applyCoupon() {
      if (
        this.cartType != "BuyNow" &&
        this.carts &&
        Object.keys(this.carts).length &&
        !this.carts.Ecommerce.some((cart) => cart.IsSelected == true)
      ) {
        this.alertObj = {
          modal: true,
          addressError: true,
          message: "Please select at least one item.",
        };
        return;
      }

      if (!this.Coupon.Code) {
        this.alertObj = {
          modal: true,
          addressError: true,
          message: "Please input a coupon code first.",
        };
        return;
      }

      this.deleteIfSameCouponExistsInOtherCart(
        this.cartType,
        this.Coupon.Code.toUpperCase()
      ).then(() => {
        let couponObj = {
          Type: this.cartType,
          Code: this.Coupon.Code.toUpperCase(),
          ItemId: 0,
          Quantity: 0,
          BargainId: 0,
        };

        if (this.cartType == "BuyNow") {
          let { ItemId, CartQuantity, BargainId } = this._.first(this.items);
          couponObj = {
            ...couponObj,
            ItemId: ItemId,
            Quantity: CartQuantity,
            BargainId: BargainId,
          };
        }
        this.$http({
          method: "post",
          url: this.$store.state.Configs.addPromoCodeToCart,
          data: couponObj,
        })
          .then((res) => {
            //console.log(res);
            this.$store.dispatch("updateCoupon", res.data.ResponseData);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.alertObj = {
              modal: true,
              addressError: true,
              message: err.response.data,
            };
          });
      });
    },
    updateCartsForSelectedShippingAddress(changedSelectedAddressId) {
      let obj = {
        ShippedBy: this.ShippedBy,
        ShippingAddressId: Number(changedSelectedAddressId),
      };
      let newCarts = { ...this.carts, ...obj };
      this.$store.state.carts = newCarts; //update store object
      localStorage.setItem("app-carts", JSON.stringify(newCarts)); //update local storage
      return newCarts;
    },
    goForPayment() {
      if (!this.selectedAddress || !this.selectedAddress.Address1) {
        this.alertObj = {
          modal: true,
          addressError: true,
          message: "Please provide your shipping address properly.",
        };
        return;
      }

      let newCarts = this.updateCartsForSelectedShippingAddress(
        this.selectedAddress.Id
      );

      if (this.cartType == "BuyNow") {
        this.$router.push({
          name: "Payment",
        });
      } else {
        if (
          newCarts &&
          !newCarts.Ecommerce.some((cart) => cart.IsSelected == true)
        ) {
          this.$store.commit("set_flash", {
            type: "error",
            message: "Please select at least one item.",
          });
          return;
        }
        this.$router.push({
          name: "Payment",
          params: { cartType: this.cartType.toLowerCase() },
        });
      }
    },
    async placeOrder(agreeWith = false, emiObj = {}) {
      if (this.loading) return;

      if (this.selectedGatewayInfo.IsApiUrl && !this.profileObj.Email) {
        this.Fire.$emit("openUpdateEmailWindow", true);
        return;
      }

      var yes = agreeWith;

      if (!agreeWith) {
        if (this.PaymentMode == "IPDC") {
          this.$refs.ipdc.modalOpen();
        } else {
          yes = await this.$confirm("Are you sure?", {
            title: "Confirmation",
          });
        }
      }

      if (!yes) return;

      let ItemIdsArr = [];

      let obj = {
        OrderType: this.cartType,
        ShippedBy: this.ShippedBy,
        ShippingAddressId: this.selectedAddress.Id,
        BillingAddressId:
          this.selectedBillingAddressId || this.selectedAddress.Id,
        PaymentMode: this.PaymentMode,
        OrderNote: this.OrderNote,
      };

      if (this.cartType == "BuyNow") {
        let { ItemId, CartQuantity, SalesPrice, BargainId, AvailEmi } =
          this._.first(this.items);

        this.AvailEmi = AvailEmi || false;

        ItemIdsArr.push(ItemId);

        obj = {
          ...obj,
          ItemId: ItemId,
          Quantity: CartQuantity,
          SalesPrice: SalesPrice,
          BargainId: BargainId,
          IsAvailEmi: this.AvailEmi,
          OrderEmi: emiObj,
        };
      } else {
        let filterdArr = this._.filter(this.items, { IsSelected: true });
        ItemIdsArr = this._.map(filterdArr, "Id");
      }

      this.loading = true;

      // If order is already created but error in gateway
      if (
        Object.keys(this.orderObj).length &&
        this.orderObj.Id &&
        this.orderObj.PaymentStatus == "UnPaid" &&
        this.orderObj.IsApiUrl
      ) {
        this.$refs.payNowBtn.payNow();
        return;
      }

      this.$http
        .post(this.$store.state.Configs.placeOrder, { ...obj })
        .then((res) => {
          this.orderObj = res.data.ResponseData;

          // pixel event for addToCard Start
          window.fbq("track", "Purchase", {
            content_ids: ItemIdsArr,
            content_type: "product",
            value: this.orderObj.TotalAmount,
            currency: "BDT",
          });
          // pixel event for addToCard End
        })
        .then(() => {
          if (
            this.orderObj.PaymentStatus == "UnPaid" &&
            this.orderObj.IsApiUrl
          ) {
            this.$refs.payNowBtn.payNow();
          } else {
            this.$store
              .dispatch("setCartItemsToEmpty", this.cartType)
              .then(() => {
                this.loading = false;

                this.$store.commit("set_success_order_id", this.orderObj.Id);
                this.$router.push({ name: "OrderSuccess" });
              })
              .then(() => {
                this.$store.dispatch("getEcommerceItems");
              });
          }
        })
        .catch((error) => {
          this.loading = false;

          if (
            typeof error.response.data == "object" &&
            error.response.data.Stock == "Unavailable"
          ) {
            this.$store.commit("set_flash", {
              type: "error",
              message: error.response.data.Message,
            });
            if (this.cartType == "BuyNow") {
              return this.$router.push({
                name: "ProductDetails",
                params: {
                  id: this.carts?.BuyNow[0]?.ItemId,
                  slug: this.carts?.BuyNow[0]?.Slug,
                },
              });
            } else {
              return this.$router.push({
                name: "Checkout",
                params: { cartType: "Ecommerce" },
              });
            }
          } else {
            this.$store.commit("set_flash", {
              type: "error",
              message: error.response.data,
            });
            this.$vuetify.goTo(0);
          }
          //console.log(error.response);
        });
    },
  },
  mounted() {
    // this.$on("couponDelted");

    this.Fire.$on("address-updated", (obj) => {
      if (obj) {
        this.getShippingAddress(obj.Id);
      } else {
        console.log("updated object does not fetch properly");
      }
    });

    this.Fire.$on("pickupAddressConfirmed", (Id) => {
      if (Id) {
        this.$store.commit("setPickupAddressId", Id);
        this.changeAddress(Id);
      } else {
        console.log("updated object does not fetch properly");
      }
    });

    // place order btn loading for payment gateway response
    this.Fire.$on("onlineGatewayResponse", (obj) => {
      this.loading = obj.loading;
      if (!this.loading && Object.keys(this.orderObj).length) {
        this.$store.state.profileObj.CartQuantityCount = 0;

        this.$router.push({
          name: "MyOrderDetails",
          params: { id: this.orderObj.Id },
        });
        return;
      }
    });

    this.Fire.$on("cardLessResponse", (obj) => {
      if (obj && Object.keys(obj).length) {
        this.placeOrder(true, obj);
      }
    });

    if (this.carts) {
      var index = this._.findIndex(this.carts.Coupon, {
        Type: this.cartType,
      });
      if (
        index != -1 &&
        this.carts.Coupon[index].MinPurchaseAmount > this.subTotal
      ) {
        this.$store.dispatch("removeCoupon", this.carts.Coupon[index]);
      }
    }

    if (this.$route.name == "Payment") {
      // pixel event for AddPaymentInfo Start
      window.fbq("track", "AddPaymentInfo", {
        value: this.grandTotal,
        currency: "BDT",
      });
      // pixel event for AddPaymentInfo End
    }
  },
  beforeDestroy() {
    this.Fire.$off("address-updated");
    this.Fire.$off("pickupAddressConfirmed");
    this.Fire.$off("onlineGatewayResponse");
    this.Fire.$off("cardLessResponse");
  },
  watch: {
    subTotal() {
      this.getShippingCost(this.selectedAddress.Id);
    },
    $route() {
      //when BuyNow and Ecommerce items both are not empty and switch each other
      this.cartType =
        this.$root.capitalizeFirstLetter(
          this._.camelCase(this.$route.params.cartType)
        ) || "BuyNow";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/order_summery.scss";
</style>
