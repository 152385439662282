<template>
  <v-flex id="more-info" class="product-details-mid">
    <v-flex class="product-detail-title">
      <a class="active" @click="gotTo('summary')" href="javascript:void(0)"
        >Product Summary</a
      >
      <a @click="gotTo('attributes')" href="javascript:void(0)"
        >Product Attributes</a
      >
      <a @click="gotTo('description')" href="javascript:void(0)"
        >Product Description</a
      >
      <a @click="gotTo('review')" href="javascript:void(0)">Product Review</a>
    </v-flex>

    <v-flex id="summary" class="product-Summary">
      <h3>Product Summary</h3>
      <div v-html="product.Summary"></div>
    </v-flex>

    <v-flex
      id="attributes"
      v-if="attributsArr && Object.keys(attributsArr).length"
      class="product-attributes"
    >
      <h3>Product Attributes</h3>
      <ul>
        <li v-for="(attributes, index) in attributsArr" :key="index">
          <span class="attribute-category" v-text="index"></span>
          <div class="attribute-values">
            <span v-for="(attribute, index) in attributes" :key="index">
              <i v-text="attribute.Name"></i>
              <template
                v-if="attributes.length > 1 && index + 1 != attributes.length"
                >&nbsp;,</template
              >
            </span>
          </div>
        </li>
      </ul>
    </v-flex>

    <v-flex id="description" class="product-description">
      <h3>Product Description</h3>
      <div v-html="product.Description"></div>
    </v-flex>
  </v-flex>
</template>

<script>
export default {
  name: "ProductInformation",
  props: {
    product: {},
  },
  computed: {
    attributsArr() {
      return this._.groupBy(this.product.Attributes, "CategoryName");
    },
  },
  methods: {
    gotTo(selector) {
      this.$vuetify.goTo("#" + selector, {
        duration: 1000,
        offset: this.$root.$refs.stickyheader.$el.clientHeight,
        easing: "easeInOutCubic",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app/index.scss";

.product-details-mid {
  margin: rem-calc(85px 0 0);
  .product-detail-title {
    margin-bottom: rem-calc(15px);
    background-color: lighten(map-get($colors, grey), 40);

    a {
      padding: rem-calc(15px 30px);
      display: inline-block;
      position: relative;
      @include font(primary, 16px, 24px, false);

      &.active:before {
        @include sudo(100%, 3px, left, 0, bottom, 0, brand);
      }
      &:hover:before {
        @include sudo(100%, 3px, left, 0, bottom, 0, brand);
      }
    }
  }
  .product-Summary {
    padding: rem-calc(20px 30px);
    @include font(false, 15px, 25px, false);
    border-bottom: 20px solid lighten(map-get($colors, grey), 40);

    h3 {
      padding-bottom: rem-calc(15px);
      @include font(false, 22px, 25px, medium);
    }
  }
  .product-attributes {
    padding: rem-calc(20px 30px);
    @include font(false, 15px, 25px, false);
    border-bottom: 20px solid lighten(map-get($colors, grey), 40);

    h3 {
      padding-bottom: rem-calc(15px);
      @include font(false, 22px, 25px, medium);
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: rem-calc(12px 0);
        margin: 0;
        display: flex;
        border-top: 1px solid #ddd;
        .attribute-category {
          position: relative;
          width: 200px;
          @include font(false, 15px, 22px, false);
          &:after {
            content: ":";
            position: absolute;
            right: 20px;
            top: 0;
          }
        }
        .attribute-values {
          span {
            @include font(false, 14px, 19px, medium);
            display: block;
            i {
              font-style: normal;
            }
          }
        }
      }
    }
  }
  .product-description {
    padding: rem-calc(20px 30px);
    @include font(false, 15px, 25px, false);
    border-bottom: 20px solid lighten(map-get($colors, grey), 40);

    &::v-deep h3 {
      padding-bottom: rem-calc(15px);
      @include font(false, 22px, 25px, medium);
    }
    &::v-deep ul {
      li {
        list-style: disc;
      }
    }
  }

  .v-card__subtitle {
    padding: rem-calc(0 0 13px);
    @include font(false, 15px, 24px, medium);
  }
  .items {
    padding: 0;
    margin: 0;
    column-count: 2;
    column-gap: 40px;

    li {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: rem-calc(10px);
      @include font(grey, 14px, 22px, false);

      .item-content {
        width: calc(100% - 160px);
        color: map-get($colors, primary);
      }
    }
  }
  &::v-deep img {
    max-width: 100%;
  }
}

@include media(1639px) {
  .product-details-mid {
    margin: rem-calc(60px 0 0);
  }
}
@include media(lg) {
  .product-details-mid {
    .product-Summary {
      padding: rem-calc(20px 15px);
    }
    .product-description {
      padding: rem-calc(20px 15px);
    }

    .items {
      column-count: auto;
      column-gap: normal;
    }
  }
}
@include media(479px) {
  .product-details-mid {
    .items {
      li {
        .item-content {
          width: 100%;
        }
      }
    }
  }
}
</style>
