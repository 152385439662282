var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{staticClass:"site-content-wrap site_bg"},[_c('v-flex',{staticClass:"mx-auto"},[_c('v-container',{staticClass:"py-0"},[_c('v-layout',{attrs:{"wrap":""}},[_c('account-sidebar'),_c('v-flex',{staticClass:"account-wrap"},[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-flex',{staticClass:"account-panel"},[(_vm.brandsObj.length)?_c('v-layout',{staticClass:"account-panel-top align-center justify-space-between",attrs:{"wrap":""}},[_c('h2',[_vm._v("My brands")])]):_vm._e(),_c('v-flex',{staticClass:"my-brands"},[(_vm.brandsObj.length)?_c('v-row',_vm._l((_vm.brandsObj),function(brand,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-flex',{staticClass:"brand-box transition-swing"},[_c('v-flex',{staticClass:"brand-banner"},[_c('img',{staticClass:"d-block",attrs:{"title":brand.BrandName,"src":_vm.$root.generateMediaImageUrl(brand.BannerUrl),"alt":"brand.BrandName"}})]),_c('v-flex',{staticClass:"brand-infos pa-2"},[_c('v-layout',{staticClass:"align-center"},[_c('v-layout',{staticClass:"align-center pr-1"},[_c('img',{staticClass:"d-block",attrs:{"title":brand.BrandName,"src":_vm.$root.generateMediaImageUrl(brand.IconUrl),"lazy-src":require('@/assets/images/no-img.jpg'),"alt":"brand.BrandName"}}),_c('h4',{staticClass:"pl-2",domProps:{"textContent":_vm._s(brand.BrandName)}})]),(brand.IsFeatured)?_c('span',{staticClass:"own-brand"},[_vm._v("Own Brand")]):_vm._e()],1),_c('v-flex',{staticClass:"featured pt-2"},[_c('span',{staticClass:"pr-2"},[_vm._v("Products "),_c('span',{staticClass:"brand--text",domProps:{"textContent":_vm._s('(' + brand.ItemCount + ')')}})]),(brand.IsFeatured)?_c('span',{staticClass:"pr-2"},[_vm._v(" | Resticted for others")]):_vm._e()]),_c('v-flex',{staticClass:"btn-wrap text-right pt-2"},[_c('v-btn',{staticClass:"b-hover primary mx-1 white--text text-none transition-swing",attrs:{"to":{
                              name: 'ProductSearch',
                              query: {
                                OwnerId: _vm.getAuthUserId(),
                                BrandId: brand.Id,
                              },
                            },"height":"28","tile":"","depressed":""}},[_vm._v("Details")])],1)],1)],1)],1)}),1):_c('data-not-found',[_vm._v("No Brand tagged yet")])],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-flex',{staticClass:"pa-10"},[_c('h3',[_vm._v("Edit mode Coming soon")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }