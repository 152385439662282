<template>
  <v-flex
    class="login-register-bg"
    :style="{
      background:
        'url(' + require('@/assets/images/login-bg.png') + ') left top',
    }"
  >
    <h3>{{ $t("Welcome To Nilam") }}</h3>
    <v-card
      tile
      elevation="3"
      class="signin-signup-wrap mx-auto"
      :style="{
        background:
          '#fff url(' +
          require('@/assets/images/login-bar.png') +
          ') left top repeat-x',
      }"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        height="3"
        color="light"
        aria-label="loading status"
      ></v-progress-linear>
      <v-card-title class="justify-center">{{ $t("Sign In") }}</v-card-title>
      <v-card-subtitle v-if="false"
        >Sign In with your social network</v-card-subtitle
      >
      <v-flex class="or-section" v-if="false">
        <v-layout wrap class="sign-with-btns justify-space-between">
          <v-btn depressed class="facebook text-none" @click="login">
            <v-icon class="mr-1">mdi-facebook</v-icon>Facebook
          </v-btn>

          <v-btn depressed class="gmail text-none">
            <v-icon class="mr-1">mdi-gmail</v-icon>Google
          </v-btn>
        </v-layout>
        <v-flex class="or-text">OR</v-flex>
        <!-- <v-flex class="sign-with grey--text text-center pt-3">Sign in With</v-flex> -->
      </v-flex>

      <ValidationObserver
        class="signin-signup-form login-form"
        tag="div"
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            tag="div"
            class="place-bid-field"
            name="email or phone"
            rules="required"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                solo
                type="text"
                flat
                hide-details
                outlined
                :label="$t('Email or Phone')"
                prepend-inner-icon="mdi-account"
                v-model="formData.username"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="place-bid-field"
            name="password"
            rules="required"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                solo
                type="password"
                step="any"
                flat
                hide-details
                outlined
                :label="$t('Password')"
                prepend-inner-icon="mdi-lock"
                v-model="formData.password"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <v-layout wrap class="remember justify-space-between">
            <ValidationProvider
              tag="div"
              class="place-bid-field"
              name="remember"
              :bails="false"
              v-slot="{ errors, classes }"
            >
              <div :class="classes">
                <v-checkbox
                  color="grey"
                  hide-details
                  v-model="formData.remember"
                  :label="$t('Remember')"
                ></v-checkbox>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <router-link :to="{ name: 'ForgotPassword' }">{{
              $t("Forgot Password ?")
            }}</router-link>
          </v-layout>
          <v-btn
            width="100%"
            class="signin-signup-btn brand white--text text-none d-block"
            depressed
            type="submit"
            :loading="loading"
            :disabled="loading"
            color="info"
            >{{ $t("Sign in to your account") }}</v-btn
          >
        </form>
      </ValidationObserver>
      <v-flex class="account-text grey--text text-center">
        {{ $t("New here?") }}
        <router-link :to="{ name: 'Register' }">{{
          $t("Create an account")
        }}</router-link>
      </v-flex>
    </v-card>
  </v-flex>
</template>

<script>
import {
  loadFbSdk,
  getFbLoginStatus,
  fbLogout,
  fbLogin,
} from "@/plugins/social-login.js";

export default {
  name: "Login",

  data() {
    return {
      isWorking: false,
      isConnected: false,
      formData: {
        username: "",
        password: "",
        remember: true,
      },
      loginOptions: {
        type: Object,
        default: function () {
          return {
            scope: "email",
          };
        },
      },
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    loading: function () {
      return this.$store.getters.isLoading;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.$store
          .dispatch("login", this.formData)
          .then((res) => {
            var loginRedirect = localStorage.getItem("loginRedirect");
            if (loginRedirect) {
              localStorage.removeItem("loginRedirect");
              this.$router.push(loginRedirect).catch((err) => console.log(err));
            } else {
              this.$router
                .push({ name: "MyAccount" })
                .catch((err) => console.log(err));
            }
            this.$store.commit("set_flash", {
              type: res.data.ResponseType,
              message: res.data.ResponseMessage,
            });
          })
          .catch((err) => {
            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
          });

        // Resetting Values
        this.formData.password = "";

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
    },
    login() {
      this.isWorking = true;
      fbLogin(this.loginOptions).then((response) => {
        if (response.status === "connected") {
          this.isConnected = true;
          let data = {
            grant_type: "facebook_token",
            assertion: response.authResponse.accessToken,
          };

          this.$store
            .dispatch("login", data)
            .then((res) => {
              this.$router
                .push({ name: "MyAccount" })
                .catch((err) => console.log(err));
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
            })
            .catch((err) => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
        } else {
          this.isConnected = false;
        }
        this.isWorking = false;
        this.$emit("login", {
          response,
          FB: window.FB,
        });
      });
    },
    logout() {
      this.isWorking = true;
      fbLogout().then((response) => {
        this.isWorking = false;
        this.isConnected = false;
        this.$emit("logout", response);
      });
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ name: "MyAccount" });
    }

    this.isWorking = true;
    loadFbSdk(this.siteSettings.FacebookAppId, "v10.0")
      .then(getFbLoginStatus)
      .then((response) => {
        if (response.status === "connected") {
          this.isConnected = true;
        }
        this.isWorking = false;
        /** Event `get-initial-status` to be deprecated in next major version! */
        this.$emit("get-initial-status", response);
        this.$emit("sdk-loaded", {
          isConnected: this.isConnected,
          FB: window.FB,
        });
      });
  },
  beforeDestroy() {
    if (!this.isLoggedIn) {
      localStorage.removeItem("local-cartable-obj"); // remove after adding cart if he not interest to login
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/login_register.scss";
</style>
