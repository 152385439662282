<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-layout wrap class="account-panel">
                <!-- summary sections -->

                <v-flex class="campaing">
                  <v-layout wrap class="campaing-title justify-space-between">
                    <v-flex class="flex-grow-0">
                      <h2>Campaign</h2>
                    </v-flex>
                    <v-flex class="flex-grow-0">
                      <router-link class="view-all" :to="{name:'AllCampaign'}">View All Campaign</router-link>
                    </v-flex>
                  </v-layout>

                  <v-flex class="campaing-container">
                    <v-row>
                      <v-col
                        v-for="(campaign,index) in campaigns"
                        :key="index"
                        cols="12"
                        md="4"
                        sm="6"
                      >
                        <v-flex class="campaing-box">
                          <router-link :to="{name:'CampaignDetails'}">
                            <v-flex class="remain-time">
                              <strong>
                                <span>03</span>
                                <small>Days</small>
                              </strong>:
                              <strong>
                                <span>09</span>
                                <small>HOURS</small>
                              </strong>:
                              <strong>
                                <span>55</span>
                                <small>Minutes</small>
                              </strong>:
                              <strong>
                                <span>36</span>
                                <small>Seconds</small>
                              </strong>
                            </v-flex>

                            <v-flex class="campaing-banner">
                              <span class="type" v-text="campaign.Type"></span>
                              <img :src="require('@/assets/temp/'+campaign.Image)" />
                            </v-flex>

                            <v-flex class="campaing-content">
                              <span class="start-and-end">
                                Star & End:
                                <span v-text="campaign.CampaignStartDate"></span> -
                                <span v-text="campaign.CampaignEndDate"></span>
                              </span>
                              <h2 class="title" v-text="campaign.Title"></h2>
                              <span class="content-item" v-text="campaign.MinDiscount"></span>
                              <span
                                class="content-item"
                                v-text="'Add your products until '+campaign.JoiningEndDate"
                              ></span>
                              <v-flex class="total">
                                <span v-text="'Sellers: '+campaign.SellersCount+' |'"></span>
                                <span v-text="'Products: '+campaign.ProductsCounts"></span>
                              </v-flex>
                            </v-flex>
                          </router-link>

                          <v-flex class="btn-container">
                            <v-btn
                              :to="{name:'JoinCampaign'}"
                              tile
                              depressed
                              block
                              height="40"
                              class="brand white--text text-none"
                            >Join Now</v-btn>
                          </v-flex>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";

export default {
  name: "Campaign",
  components: {
    AccountSidebar,
  },
  data() {
    return {
      campaigns: [
        {
          Id: "1",
          Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
          Image: "campaign_1.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "Nov 27, 2021",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Flash Sale",
          Status: "Publish",
          SellersCount: "320",
          ProductsCounts: "320",
        },
        {
          Id: "1",
          Title: "W49 Home Appliances with brand warrant",
          Image: "campaign_2.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "Nov 27, 2021",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Weekly Campaign",
          Status: "Publish",
          SellersCount: "420",
          ProductsCounts: "156",
        },
        {
          Id: "1",
          Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
          Image: "campaign_3.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "Nov 27, 2021",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "New on Nilam",
          Status: "Publish",
          SellersCount: "425",
          ProductsCounts: "65",
        },
        {
          Id: "1",
          Title: "W49 Home Appliances with brand warrant",
          Image: "campaign_2.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "Nov 27, 2021",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Weekly Campaign",
          Status: "Publish",
          SellersCount: "420",
          ProductsCounts: "156",
        },
        {
          Id: "1",
          Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
          Image: "campaign_3.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "Nov 27, 2021",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "New on Nilam",
          Status: "Publish",
          SellersCount: "425",
          ProductsCounts: "65",
        },
        {
          Id: "1",
          Title: "W49 Home Appliances with brand warrant",
          Image: "campaign_2.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "Nov 27, 2021",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Weekly Campaign",
          Status: "Publish",
          SellersCount: "420",
          ProductsCounts: "156",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/campaign.scss";
</style>
