<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar tile dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Close</v-toolbar-title>
        </v-toolbar>
        <inner-banner :options="bannerContent"></inner-banner>
        <v-flex class="pt-5 modal-wrap">
          <v-flex class="container">
            <div class="modal-bg">
              <div class="modal-head text-center">
                <div class="py-4 org-info mb-7">
                  <h2>IPDC (আইপিডিসি)</h2>
                  <v-flex class="">
                    (<small v-text="gateway?.Description"></small>)</v-flex
                  >
                </div>
                <h2>
                  এখন প্রয়োজনীয় সব ইলেকট্রনিক্স পণ্য কিনুন কার্ড ছাড়া সহজ
                  কিস্তিতে
                </h2>
                <h3>
                  সর্বনিম্ন ১৩,০০০/= ( তেরো হাজার) টাকার যেকোনো ইলেকট্রনিক্স
                  পণ্য বা মোবাইল কিনে ৩ থেকে ২৪ মাসের কিস্তি সুবিধা এখন আপনিও
                  পাবেন ।
                </h3>
                <span
                  >ইএমআই সুবিধা নেওয়ার জন্য আপনার পেশা অনুযায়ী নিচের যে কোনো
                  একটি অপশন নির্বাচন করে আবেদনটি জমা দিন</span
                >
              </div>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <v-radio-group
                    hide-details
                    class="customers"
                    row
                    v-model="customer_type"
                  >
                    <v-radio class="customer-type" value="Jobholder">
                      <template v-slot:label>
                        <div class="customer-box">
                          <div class="customer-boxIn">
                            <div class="customer-img">
                              <img
                                :src="
                                  require('@/assets/images/ipdc/employee.png')
                                "
                                alt="employee"
                              />
                            </div>
                            <span class="subtitle d-block"
                              >কিস্তিতে পণ্য কিনতে চাকুরীজীবিদের যেই তথ্য
                              প্রয়োজনঃ
                            </span>
                            <h3>চাকুরীজীবিদের জন্য</h3>
                            <ul class="top-list">
                              <li>
                                <v-icon>mdi-check-circle-outline</v-icon> বেতন
                                ২০,০০০/- ( বিশ হাজার ) টাকা বা তার বেশি
                              </li>
                              <li>
                                <v-icon>mdi-check-circle-outline</v-icon>
                                স্যালারি একাউন্ট থাকা আবশ্যক
                              </li>
                            </ul>

                            <ul class="btm-list">
                              <li>
                                <span>১ </span>
                                জাতীয় পরিচয়পত্র
                              </li>
                              <li>
                                <span>২ </span>
                                গত ৩ মাসের ব্যাংক স্টেটমেন্ট কপি
                              </li>
                              <li>
                                <span>৩ </span>
                                বেতনের সনদপত্র
                              </li>
                              <li>
                                <span>৪ </span>
                                চেকের পাতার ছবি
                              </li>
                              <li>
                                <span>৫ </span>
                                অফিস পরিচয়পত্র
                              </li>
                              <li>
                                <span>৬ </span>
                                রেফারেন্সের নাম্বার ও জাতীয় পরিচয়পত্র
                              </li>
                              <li>
                                <span>৭ </span>
                                পাসপোর্ট সাইজের ছবি
                              </li>
                              <li>
                                <span>৮ </span>
                                ইউটিলিটি বিলের কপি বা ছবি
                              </li>
                            </ul>
                          </div>

                          <a class="customer-btn" href="#"
                            >আমি একজন চাকুরীজীবি
                          </a>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio class="customer-type" value="Businessman">
                      <template v-slot:label>
                        <div class="customer-box">
                          <div class="customer-boxIn">
                            <div class="customer-img">
                              <img
                                :src="
                                  require('@/assets/images/ipdc/business.png')
                                "
                                alt="business"
                              />
                            </div>
                            <span class="subtitle d-block"
                              >কিস্তিতে পণ্য কিনতে ব্যবসায়ীদের যেই তথ্য প্রয়োজনঃ
                            </span>
                            <h3>ব্যবসায়ীদের জন্য</h3>
                            <ul class="top-list">
                              <li>
                                <v-icon>mdi-check-circle-outline</v-icon>বাৎসরিক
                                লেনদেন ৩৫ লক্ষ টাকা বা তার বেশি
                              </li>
                              <li>
                                <v-icon>mdi-check-circle-outline</v-icon> ট্রেড
                                লাইসেন্স ( হালনাগাদ)
                              </li>
                            </ul>

                            <ul class="btm-list">
                              <li>
                                <span>১ </span>
                                জাতীয় পরিচয়পত্র
                              </li>
                              <li>
                                <span>২ </span>
                                ব্যবসায়ীক একাউন্ট ব্যাংক স্টেটমেন্ট – ১ বছরের
                              </li>
                              <li>
                                <span>৩ </span>
                                ট্রেড লাইসেন্স
                              </li>
                              <li>
                                <span>৪ </span>
                                ব্যক্তিগত একাউন্টের ব্যাংক স্টেটমেন্ট – ১ বছরের
                              </li>
                              <li>
                                <span>৫ </span>
                                পাসপোর্ট সাইজের ছবি
                              </li>
                              <li>
                                <span>৬ </span>
                                ইউটিলিটি বিলের কপি বা ছবি
                              </li>
                              <li>
                                <span>৭ </span>
                                চেকের পাতার ছবি
                              </li>
                              <li>
                                <span>৮ </span>
                                টিন সার্টিফিকেট / ট্যাক্স টোকেন
                              </li>
                            </ul>
                          </div>
                          <a class="customer-btn" href="#"
                            >আমি একজন ব্যবসায়ী
                          </a>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio class="customer-type" value="Other">
                      <template v-slot:label>
                        <div class="customer-box">
                          <div class="customer-boxIn">
                            <div class="customer-img">
                              <img
                                :src="
                                  require('@/assets/images/ipdc/others.png')
                                "
                                alt="Other"
                              />
                            </div>
                            <span class="subtitle d-block"
                              >আপনি যদি অন্য পেশাজীবী হয়ে থাকেন তাহলে নিচের
                              ফরমটি পূরণ করুন
                            </span>
                            <h3>অন্যান্য পেশাজীবীদের জন্য</h3>
                            <ValidationProvider
                              tag="div"
                              name="occupation"
                              rules="required"
                              :bails="false"
                              v-slot="{ errors, classes }"
                            >
                              <div class="input-wrap" :class="classes">
                                <v-text-field
                                  solo
                                  flat
                                  hide-details
                                  outlined
                                  label="পেশার নাম * (যেমনঃ ফ্রিল্যান্সার, ডাক্তার)"
                                  v-model="formData.occupation"
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>

                            <ValidationProvider
                              tag="div"
                              name="Monthly Income"
                              rules="required"
                              :bails="false"
                              v-slot="{ errors, classes }"
                            >
                              <div class="input-wrap" :class="classes">
                                <v-text-field
                                  solo
                                  flat
                                  hide-details
                                  outlined
                                  label="মাসিক আয় * (যেমনঃ ৫৫০০০ ৳)"
                                  v-model="formData.monthly_income"
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              name="Bank Name"
                              rules="required"
                              :bails="false"
                              v-slot="{ errors, classes }"
                            >
                              <div class="input-wrap" :class="classes">
                                <v-text-field
                                  solo
                                  flat
                                  hide-details
                                  outlined
                                  label="ব্যাংকের নাম * (যেমনঃ ব্র্যাক ব্যাংক, ডাচ বাংলা ব্যাংক)"
                                  v-model="formData.bank_name"
                                ></v-text-field>
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                            <div class="input-wrap">
                              <v-textarea
                                solo
                                height="100"
                                flat
                                hide-details
                                outlined
                                type="number"
                                label="মতামত"
                                v-model="formData.opinion"
                              ></v-textarea>
                            </div>
                            <v-flex class="pt-3 pb-9"
                              >* ষ্টার মার্ক করা ঘর গুলি অবশ্যই পূরণ করুন
                            </v-flex>
                          </div>

                          <a class="customer-btn" href="#"
                            >আমি অন্য পেশায় নিয়োজিত আছি
                          </a>
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>

                  <v-layout
                    class="bottom-section flex-wrap justify-space-between"
                  >
                    <v-flex class="order-lg-2 applyinfo-right flex-grow-0">
                      <v-btn
                        height="45"
                        width="200"
                        tile
                        color="brand"
                        depressed
                        @click="aggree()"
                        :disabled="!btnDisabled"
                        v-if="customer_type != 'Other'"
                        class="continue-payment text-none light--text"
                      >
                        <span>নিশ্চিত করুন</span>
                      </v-btn>
                      <v-btn
                        height="45"
                        width="200"
                        tile
                        color="brand"
                        depressed
                        @click="validate()"
                        :disabled="!btnDisabled"
                        v-else
                        class="continue-payment text-none light--text"
                      >
                        <span>আবেদনটি জমা দিন</span>
                      </v-btn>
                    </v-flex>
                    <v-flex class="applyinfo order-lg-1 flex-grow-0">
                      <v-flex class="pb-5"
                        >আপনার আবেদনটি জমা দেওয়ার পর নিলাম প্রতিনিধি দল
                        প্রয়োজনীয় কাগজপত্রের জন্য আপনার সাথে দ্রুত যোগাযোগ
                        করবে । জমাকৃত কাগজপত্র যাচাই-বাছাইয়ের পর মনোনীত হলে তিন
                        দিনের মধ্যে আপনার সাথে যোগাযোগ করা হবে।</v-flex
                      >
                      <v-flex>বিস্তারিত জানতে এখনি যোগাযোগ করুনঃ </v-flex>
                      <ul class="d-flex flex-wrap">
                        <li>* 01844-224191</li>
                        <li>* 01633-273197</li>
                        <li>* 01799-588223</li>
                        <li>* 01932-212119</li>
                        <li>* 01690-241593</li>
                      </ul>
                    </v-flex>
                  </v-layout>
                </form>
              </ValidationObserver>
            </div>
          </v-flex>
        </v-flex>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import InnerBanner from "@/components/sites/common/InnerBanner";
export default {
  name: "IpdcModal",
  components: {
    InnerBanner,
  },
  props: {
    gateway: Object,
  },
  data() {
    return {
      dialog: false,
      customer_type: "",
      defaultOrg: "IPDC",
      bannerContent: {
        title: "",
        image: "emi.jpg",
      },
      formData: {
        occupation: "",
        monthly_income: "",
        bank_name: "",
        opinion: "",
      },
    };
  },
  computed: {
    btnDisabled() {
      return !!this.customer_type;
    },
  },
  methods: {
    modalOpen() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    async validate() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.aggree(this.formData);
      }
    },
    resetValidation() {
      this.$refs.observer.reset();
    },
    aggree(formInfo) {
      let obj = {
        OrgName: "IPDC",
        CustomerType: this.customer_type,
        FormInfo: null,
      };

      if (formInfo) {
        obj.FormInfo = JSON.stringify(formInfo);
      } else {
        obj.FormInfo = null;
      }

      this.Fire.$emit("cardLessResponse", obj);
      this.dialog = false;
    },
  },
  watch: {
    customer_type(newVal) {
      if (newVal != "Other") {
        this.resetValidation();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/ipdc_modal.scss";
</style>
