<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-layout wrap class="account-panel">
                <!-- summary sections -->

                <v-flex class="campaing-btn">
                  <router-link class="active" to="/">Flash Sale</router-link>
                  <router-link to="/">Daily Deal</router-link>
                  <router-link to="/">Special Promotions</router-link>
                </v-flex>
                <v-flex class="all-campaing">
                  <!-- Headers -->
                  <v-flex class="campaing-item campaing-item-header">
                    <v-flex class="flex-grow-0 banner">Campaign Banner</v-flex>
                    <v-flex class="flex-grow-0 name">Campaign Name</v-flex>
                    <v-flex class="flex-grow-0 sellers">Sellers</v-flex>
                    <v-flex class="flex-grow-0 close-in">Registration Close In</v-flex>
                    <v-flex class="flex-grow-0 live">Campaign Live Period</v-flex>
                    <v-flex class="flex-grow-0 status">Status</v-flex>
                    <v-flex class="flex-grow-0 actions">Actions</v-flex>
                  </v-flex>
                  <!--End Headers -->

                  <v-flex v-for="(campaign, index) in campaigns" :key="index" class="campaing-item">
                    <v-flex class="flex-grow-0 banner">
                      <img class="image-flu" :src="require('@/assets/temp/'+campaign.Image)" />
                    </v-flex>
                    <v-flex class="flex-grow-0 name">
                      <h3 v-text="campaign.Title"></h3>
                    </v-flex>
                    <v-flex class="flex-grow-0 sellers" v-text="campaign.SellersCount"></v-flex>
                    <v-flex class="flex-grow-0 close-in">
                      <v-flex class="close-in-box">
                        <span class="d-block" v-text="campaign.JoiningEndDate"></span>
                        <span class="time">
                          <span>3d</span>
                          <span>5h</span>
                          <span>31m</span>
                          <span>3s</span>
                        </span>
                      </v-flex>
                    </v-flex>
                    <v-flex class="flex-grow-0 live">
                      Start at 07 Jun 2020 00:00 GMT+06:00
                      <br />End at 13 Jun 2020 23:59 GMT+06:00
                    </v-flex>
                    <v-flex class="flex-grow-0 status silver_tree--text">Live</v-flex>
                    <v-flex class="flex-grow-0 actions">
                      <router-link :to="{name:'CampaignDetails'}" class="primary--text">View Details</router-link>
                    </v-flex>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";

export default {
  name: "AllCampaign",
  components: {
    AccountSidebar,
  },
  data() {
    return {
      campaigns: [
        {
          Id: "1",
          Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
          Image: "campaign_1.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "03 Jun 2020 23:59 GMT+06:00",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Flash Sale",
          Status: "Live",
          SellersCount: "320",
          ProductsCounts: "320",
        },
        {
          Id: "1",
          Title: "W49 Home Appliances with brand warrant",
          Image: "campaign_2.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "27 Jun 2020 23:59 GMT+06:00",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Weekly Campaign",
          Status: "Joined",
          SellersCount: "420",
          ProductsCounts: "156",
        },
        {
          Id: "1",
          Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
          Image: "campaign_3.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "15 Jun 2020 23:59 GMT+06:00",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "New on Nilam",
          Status: "Closed",
          SellersCount: "425",
          ProductsCounts: "65",
        },
        {
          Id: "1",
          Title: "W49 Home Appliances with brand warrant",
          Image: "campaign_2.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "03 Jun 2020 23:59 GMT+06:00",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "03 Dec 2020 23:59 GMT+06:00",
          Type: "Weekly Campaign",
          Status: "Closed",
          SellersCount: "420",
          ProductsCounts: "156",
        },
        {
          Id: "1",
          Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
          Image: "campaign_3.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "03 Jun 2020 23:59 GMT+06:00",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "03 Jun 2020 23:59 GMT+06:00",
          Type: "New on Nilam",
          Status: "Closed",
          SellersCount: "425",
          ProductsCounts: "65",
        },
        {
          Id: "1",
          Title: "W49 Home Appliances with brand warrant",
          Image: "campaign_2.png",
          MinNumOfDays: "",
          MinNumOfOrders: "",
          MinDiscount: "Minimum price discount",
          MinNumOfQty: "",
          Note: "",
          JoiningStartDate: "Nov 27, 2021",
          JoiningEndDate: "03 Jun 2020 23:59 GMT+06:00",
          CampaignStartDate: "Dec 5, 2021",
          CampaignEndDate: "Dec 23, 2021",
          Type: "Weekly Campaign",
          Status: "Closed",
          SellersCount: "420",
          ProductsCounts: "156",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/all-campaign.scss";
</style>
