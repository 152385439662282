<template>
  <v-flex class="quantity-holder d-flex flex-grow-0">
    <v-icon @click="decrease()">mdi-minus</v-icon>
    <v-text-field
      v-model.number="quantity"
      step="1"
      @blur="stockCheking()"
      solo
      flat
      hide-details
      type="number"
    ></v-text-field>
    <v-icon @click="increase()">mdi-plus</v-icon>
  </v-flex>
</template>
<script>
export default {
  name: "QuantityHolder",
  data() {
    return {
      quantity: 1,
    };
  },
  props: {
    qty: Number,
    cartQuantity: Number,
    productId: Number,
    notChangeable: Boolean,
    pageName: String,
  },
  created() {
    if (this.qty > 0) {
      if (this.pageName && this.pageName == "checkout") {
        if (this.cartQuantity > this.qty) {
          this.quantity = this.qty;
          this.eventFire(true);
        } else {
          this.quantity = this.cartQuantity;
        }
      } else {
        this.quantity = 1;
        this.eventFire(true);
      }
      this.Fire.$on("addedInCart", (val) => {
        if (!this.pageName && val) {
          this.quantity = 1;
          this.eventFire(true);
        }
      });
    } else {
      this.quantity = 0;
    }
  },
  methods: {
    eventFire(isAuto) {
      this.Fire.$emit("qtyUpdate", {
        id: this.productId,
        qty: this.quantity,
        isAuto: !!isAuto,
      });
    },
    increase() {
      if (this.quantity < this.qty) {
        if (this.notChangeable) {
          alert("Lot size can not be changed");
        } else {
          this.quantity++;
          this.eventFire();
        }
      } else {
        alert("Stock Not Available");
      }
    },
    decrease() {
      if (this.quantity > 1) {
        if (this.notChangeable) {
          alert("Lot size can not be changed");
        } else {
          this.quantity--;
          this.eventFire();
        }
      }
    },
    stockCheking() {
      if (this.qty && Number(this.quantity) === this.quantity) {
        let qty = Math.floor(this.quantity);

        if (qty <= 1) {
          this.quantity = 1;
        } else if (qty > this.qty) {
          this.quantity = this.qty;
        } else {
          this.quantity = qty;
        }

        this.eventFire();
      } else {
        this.quantity = 0;
      }
    },
  },
  beforeDestroy() {
    this.Fire.$off("addedInCart");
  },
};
</script>

<style lang="scss">
.quantity-holder {
  width: 115px;
  text-align: center;
  border: 1px solid #dbdbdb;
  .v-icon {
    padding-right: 3px;
    padding-left: 3px;

    &:focus::after {
      display: none;
    }
  }

  .mdi-minus {
    border-right: 1px solid #dbdbdb;
  }
  .mdi-plus {
    border-left: 1px solid #dbdbdb;
  }
  input {
    text-align: center;
  }
}
</style>
