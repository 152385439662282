<template>
  <v-btn
    text
    :loading="loader"
    :disabled="loader || disableOtpButton"
    @click="sendOtp()"
  >
    <v-icon> {{ icon || "mdi-refresh" }} </v-icon>{{ btnText || "Resend OTP" }}
    <template v-slot:loader>
      <span v-text="'Wait for ' + resedOtpTimerObj.second + ' sec.'"></span>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: "SendOtpBtn",
  data() {
    return {
      loader: false,
      disableOtpButton: false,
      nextResendOtpTime: {
        Mobile: 0,
        Email: 0,
      },
      currentTime: null,
      resedOtpTimerObj: {
        minute: 0,
        second: 0,
      },
    };
  },
  props: {
    changeUrl: Boolean,
    username: String,
    type: String,
    btnText: String,
    icon: String,
  },
  mounted() {
    this.setResendOtpTimer();
  },
  methods: {
    getUrl() {
      if (this.changeUrl) {
        return this.$store.state.Configs.sendOtpForChange;
      }
      return this.$store.state.Configs.sendOtp;
    },
    sendOtp() {
      if (this.loader) return;

      this.nextResendOtpTime =
        JSON.parse(sessionStorage.getItem("otpResendObj")) || {};

      this.loader = true;
      this.$http({
        method: "post",
        url: this.getUrl(),
        data: { username: this.username },
      })
        .then((res) => {
          // resend otp time in seconds
          var otp_resend_at =
            Math.round(Date.now() / 1000) +
            this.$store.state.Configs.resendOtpTime;

          this.nextResendOtpTime[this.type] = otp_resend_at;

          // Save resende Otp time to local sessionStorage
          sessionStorage.setItem(
            "otpResendObj",
            JSON.stringify(this.nextResendOtpTime)
          );
          // call Resend otp timer counter
          this.setResendOtpTimer();

          if (this.type == "Mobile" || this.type == "Email") {
            this.Fire.$emit("otpUpdate", {
              type: res.data.ResponseType,
              message: res.data.ResponseMessage,
            });
          } else {
            this.$store.commit("set_flash", {
              type: res.data.ResponseType,
              message: res.data.ResponseMessage,
            });
          }
        })
        .catch((err) => {
          this.loader = false;

          if (this.type == "Mobile" || this.type == "Email") {
            this.Fire.$emit("otpUpdate", {
              type: "error",
              message: err.response.data,
            });
          } else {
            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
          }
        });
    },
    setResendOtpTimer() {
      this.nextResendOtpTime =
        JSON.parse(sessionStorage.getItem("otpResendObj")) || {};

      if (this.nextResendOtpTime[this.type]) {
        this.currentTime = Math.round(Date.now() / 1000);

        if (this.nextResendOtpTime[this.type] > this.currentTime) {
          this.loader = true;
          let remainingResendOtpTime =
            this.nextResendOtpTime[this.type] - this.currentTime;
          this.disableOtpButton = true;

          var minute = Math.floor(remainingResendOtpTime / 60);
          var second = remainingResendOtpTime % 60;

          minute = minute < 10 ? "0" + minute : minute;
          second = second < 10 ? "0" + second : second;

          this.resedOtpTimerObj.minute = minute;
          this.resedOtpTimerObj.second = second;

          remainingResendOtpTime -= 1;

          if (remainingResendOtpTime >= 0) {
            setTimeout(() => {
              this.setResendOtpTimer(remainingResendOtpTime);
            }, 1000);
            return;
          }
        } else {
          this.disableOtpButton = false;
          // sessionStorage.removeItem("otpResendObj");
          this.resedOtpTimerObj.minute = 0;
          this.resedOtpTimerObj.second = 0;
          this.loader = false;
        }

        this.disableOtpButton = false;
      }
    },
  },
};
</script>
