<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          class="price-edit pointer d-block pl-2"
          v-if="product.SaleType != 'Auction'"
          @click="(dialog = true), setProductAttributeUpdateFormData()"
        >
          <v-icon class="mr-1" size="18" color="silver_tree">mdi-lead-pencil</v-icon>
        </span>
      </template>
      <small v-text="'Update ' + type"></small>
    </v-tooltip>

    <!-- Edit product single property modal -->
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title class="text-h5">
          {{
          productAttributeUpdateType
          }}
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="div" ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <v-flex v-if="type != 'ItemDiscount'">
                <ValidationProvider
                  tag="div"
                  class="place-bid-field"
                  :name="productAttributeUpdateType"
                  rules="required|max_value:99999999"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="input-wrap" :class="classes">
                    <v-text-field
                      solo
                      type="number"
                      step="any"
                      flat
                      hide-details
                      outlined
                      :label="productAttributeUpdateType"
                      v-model="formData[productAttributeUpdateType]"
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-flex>

              <v-flex v-else>
                <!-- Discount amount -->
                <ValidationProvider
                  tag="div"
                  class="col-md-12"
                  name="Discount"
                  :rules="''"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="input-wrap" :class="classes">
                    <v-flex class="input-label" v-text="$t('Discount (TK)')"></v-flex>
                    <v-flex class="tooltip-wrap">
                      <v-flex>
                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          @wheel="$event.target.blur()"
                          type="number"
                          label="Example 200 TK"
                          v-model="discountObj.Discount"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </v-flex>
                    </v-flex>
                  </div>
                </ValidationProvider>

                <!-- Discount Start At -->
                <ValidationProvider
                  tag="div"
                  class="col-md-12"
                  name="Discount Start At"
                  :rules="''"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <v-flex class="input-label" v-text="$t('Discount Start At')"></v-flex>
                  <div class="input-wrap" :class="classes">
                    <v-dialog
                      ref="dialog1"
                      v-model="discountStartAtModal"
                      :return-value.sync="discountObj.StartAt"
                      persistent
                      width="300px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="discountObj.StartAt"
                          solo
                          flat
                          hide-details
                          outlined
                          label="Date Start"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </template>

                      <v-date-picker
                        v-model="discountObj.StartAt"
                        scrollable
                        :allowed-dates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="discountStartAtModal = false">Cancel</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog1.save(discountObj.StartAt)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </ValidationProvider>

                <!-- Discount End At -->
                <ValidationProvider
                  tag="div"
                  class="col-md-12"
                  name="Discount End At"
                  :rules="''"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <v-flex class="input-label" v-text="$t('Discount End At')"></v-flex>
                  <div class="input-wrap" :class="classes">
                    <v-dialog
                      ref="dialog2"
                      v-model="discountEndAtModal"
                      :return-value.sync="discountObj.EndAt"
                      persistent
                      width="300px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="discountObj.EndAt"
                          solo
                          flat
                          hide-details
                          outlined
                          label="Date End"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </template>

                      <v-date-picker
                        v-model="discountObj.EndAt"
                        scrollable
                        :allowed-dates="allowedDates"
                        :min="discountObj.StartAt"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="discountEndAtModal = false">Cancel</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog2.save(discountObj.EndAt)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                </ValidationProvider>

                <!-- Discount isParcentage -->
                <ValidationProvider
                  tag="div"
                  class="col-md-12"
                  name="Discount Percentage"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="input-wrap renilam" :class="classes">
                    <v-checkbox
                      v-model="discountObj.IsPercentage"
                      :label="$t('Percentage (Item discount will be calculated by percentage of discount amount.)')+ '?'"
                      color="primary"
                      :value="false"
                      hide-details
                    ></v-checkbox>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-flex>

              <v-flex class="text-right pb-3">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    class="modal-close text-none white--text transition-swing mr-2"
                    tile
                    depressed
                    :class="hover ? 'primary' : 'primary lighten-2'"
                    @click.stop="
                      (dialog = !dialog), resetUpdateProductAttributeForm()
                    "
                  >{{$t('Close')}}</v-btn>
                </v-hover>

                <v-hover v-slot="{ hover }">
                  <v-btn
                    type="submit"
                    tile
                    depressed
                    class="place-bid-btn brand white--text text-none transition-swing"
                    :class="hover ? 'brand darken-1' : 'brand'"
                  >{{$t('Update')}}</v-btn>
                </v-hover>
              </v-flex>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "ProductAttributeUpdate",
  data() {
    return {
      dialog: false,
      formData: {},
      discountObj: {
        ItemId: this.product.Id,
        Discount: 0,
        IsPercentage: false,
        StartAt: null,
        EndAt: null,
      },
      updateProductSalesPriceUrl:
        this.$store.state.Configs.updateItemSalesPrice,
      updateProductQuantityUrl: this.$store.state.Configs.updateItemQuantity,
      updateProductDiscountUrl: this.$store.state.Configs.updateItemDiscount,
      getItemDiscountByItemIdUrl:
        this.$store.state.Configs.getItemDiscountByItemId,
      productAttributeUpdateUrl: "",
      productAttributeUpdateType: "",
      discountStartAtModal: false,
      discountEndAtModal: false,
    };
  },
  props: {
    product: Object,
    type: String,
  },
  created() {},
  methods: {
    allowedDates: (val) => Date.parse(val) > Date.now() - 8.64e7,
    setProductAttributeUpdateFormData() {
      this.formData.Id = this.product.Id;
      this.productAttributeUpdateType = this.type;

      if (this.type == "SalesPrice") {
        this.productAttributeUpdateUrl = this.updateProductSalesPriceUrl;
        this.formData[this.productAttributeUpdateType] =
          this.product.SalesPrice;
      } else if (this.type == "StockQuantity") {
        this.productAttributeUpdateUrl = this.updateProductQuantityUrl;
        this.formData[this.productAttributeUpdateType] =
          this.product.StockQuantity;
      } else if (this.type == "ItemDiscount") {
        this.productAttributeUpdateUrl = this.updateProductDiscountUrl;

        this.$http
          .post(this.getItemDiscountByItemIdUrl.replace(":id", this.product.Id))
          .then((res) => {
            // for edit item discount start and end time formate start
            if (res.data.StartAt) {
              res.data.StartAt = format(
                new Date(res.data.StartAt),
                "yyyy-MM-dd"
              );
            }
            if (res.data.EndAt) {
              res.data.EndAt = format(new Date(res.data.EndAt), "yyyy-MM-dd");
            }
            //end
            this.discountObj.Discount = res.data.Discount;
            this.discountObj.IsPercentage = res.data.IsPercentage;
            this.discountObj.StartAt = res.data.StartAt;
            this.discountObj.EndAt = res.data.EndAt;
          })
          .catch(() => {});
      }
    },
    resetUpdateProductAttributeForm() {
      this.formData = {};
      this.productAttributeUpdateType = "";
      this.productAttributeUpdateUrl = "";
    },
    updateProductAttribute() {
      return new Promise((resolve, reject) => {
        if (this.type == "ItemDiscount") {
          this.formData = this.discountObj;
        }
        this.$http
          .post(this.productAttributeUpdateUrl, this.formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      let check = this.$root.checkAuth();
      if (check) return;

      this.$refs.form.validate().then(() => {
        this.updateProductAttribute()
          .then((res) => {
            var discountedData = res.data.ResponseData;

            this.$nextTick(() => {
              if (this.type == "ItemDiscount") {
                this.Fire.$emit("product-attribute-updated", {
                  discountedData,
                  productAttributeUpdateType: this.productAttributeUpdateType,
                  ItemId: this.product.Id,
                });
              } else {
                this.Fire.$emit("product-attribute-updated", {
                  ...this.formData,
                  productAttributeUpdateType: this.productAttributeUpdateType,
                });
              }

              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });

              this.$refs.form.reset();
              this.resetUpdateProductAttributeForm();
              this.dialog = false;
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
              this.$vuetify.goTo(0);
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app/index.scss";
</style>
