var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multi-image-uploader"},[(_vm.loading)?_c('div',[_vm._v("Loading......")]):_vm._e(),_c('h3',{domProps:{"innerHTML":_vm._s(_vm.title || 'Pictures')}}),_c('vue-upload-multiple-image',{staticClass:"multi-image-uploader-main",attrs:{"data-images":_vm.dataImages,"id-upload":_vm.id || 'myIdUpload',"edit-upload":_vm.editId || 'myIdEdit',"primary-text":_vm.options.primaryText || 'Default',"show-edit":_vm.options.showEdit,"show-primary":_vm.options.showPrimary,"show-delete":_vm.options.showDelete,"mark-is-primary-text":_vm.options.markIsPrimaryText || 'Set as default',"drag-text":_vm.options.dragText || 'Drag images (many)',"browse-text":_vm.options.browseText || '(or) Select',"drop-text":_vm.options.dropText || 'Drop your file here ...',"popup-text":_vm.options.popupText || 'This image will be displayed as default',"multiple":_vm.options.multiple,"max-image":_vm.options.maxImage || 10},on:{"upload-success":_vm.uploadImageSuccess,"before-remove":_vm.beforeRemove,"edit-image":_vm.editImage,"mark-is-primary":_vm.markIsPrimary}}),(_vm.imgConfig && Object.keys(_vm.imgConfig).length)?_c('small',{domProps:{"innerHTML":_vm._s(
      'NB: Allowed image extensions: (<strong>' +
      _vm.imgConfig.Extension.join(', ') +
      '</strong>) , max size: <strong>' +
      _vm.imgConfig.Size +
      '</strong> KB & with resolution: <strong>' +
      _vm.imgConfig.Width +
      ' x ' +
      _vm.imgConfig.Height +
      '</strong> pixel'
    )}}):_vm._e(),(Object.keys(_vm.snackbarObj).length)?_c('v-snackbar',{attrs:{"centered":"","color":_vm.snackbarObj.type?_vm.snackbarObj.type.toLowerCase():'',"timeout":"4000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbarObj = {}}}},'v-btn',attrs,false),[_vm._v("Close")])]}}],null,false,3998922245),model:{value:(_vm.snackbarObj.type),callback:function ($$v) {_vm.$set(_vm.snackbarObj, "type", $$v)},expression:"snackbarObj.type"}},[_c('span',{domProps:{"textContent":_vm._s(_vm.snackbarObj.message)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }