<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <v-card tile>
        <v-skeleton-loader
          class="mx-auto pl-5 pr-5"
          type="list-item-avatar-three-line, image, article"
          v-if="loading"
        ></v-skeleton-loader>
        <v-sheet class="bid-history-wrap" v-else>
          <h2>Bid History</h2>
          <v-row v-if="product && Object.keys(product).length">
            <v-col md="4" cols="12" order-md="2" class="py-0">
              <v-flex class="item-info-wrap">
                <v-flex class="item-info d-flex align-center">
                  <v-flex class="item-image">
                    <v-img
                      v-if="product.Documents"
                      :src="
                        $root.generateMediaImageUrl(
                          product.Documents[0].FilePath
                        )
                      "
                    ></v-img>
                  </v-flex>

                  <v-flex class="item-title">
                    <h3 v-text="product.ItemName"></h3>
                    <v-sheet class="item-id">
                      Item ID:
                      <b v-text="product.DisplayId"></b>
                    </v-sheet>
                    <router-link
                      :to="{
                        name: 'ProductDetails',
                        params: { id: product.Id, slug: product.Slug },
                      }"
                      >View Product Details</router-link
                    >
                  </v-flex>
                </v-flex>

                <v-flex class="item-info">
                  <ul>
                    <li>
                      Current Bid:
                      <span class="info-text">
                        <strong
                          v-if="product.CurrentBid"
                          class="brand--text"
                          v-text="$root.showAmount(product.CurrentBid)"
                        ></strong>
                        <strong v-else>N/A</strong>
                        (Reserve Price
                        {{ $root.showAmount(product.ApproximatePrice) }})
                        <v-tooltip right color="success" max-width="400" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                              >mdi-information-outline</v-icon
                            >
                          </template>
                          <span
                            class="tooltip-info"
                            v-text="note.reservePrice.text"
                          ></span>
                        </v-tooltip>
                      </span>
                    </li>

                    <li v-if="product.UserLastBid">
                      Your Bid:
                      <span class="info-text">
                        <strong
                          class="brand--text"
                          v-text="$root.showAmount(product.UserLastBid)"
                        ></strong>
                      </span>
                    </li>
                    <li>
                      Starting bid:
                      <span class="info-text">
                        <b v-text="$root.showAmount(product.BasePrice)"></b>
                      </span>
                    </li>
                    <li>
                      Shipping:
                      <span class="info-text">
                        Check item description and payment instructions or
                        contact seller for details.
                      </span>
                    </li>
                  </ul>
                </v-flex>

                <v-flex class="place-bid-wrap" v-if="isBiddingEnable">
                  <v-flex class="bid-label">Enter your bid:</v-flex>
                  <!-- bidding-form -->
                  <bidding-form v-bind:id="product.AuctionId"></bidding-form>

                  <v-flex class="automatic-bid">
                    <a
                      @click="$refs.automaticBidDialog.modalOpen()"
                      href="javascript:void(0);"
                      >Place an automatic bid</a
                    >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" size="20" class="ml-1"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span class="tooltip-info">Place your automatic bid</span>
                    </v-tooltip>
                    <br />
                    <!-- automatic bid popup -->
                    <auto-matic-bid
                      :auction-id="product.AuctionId"
                      :auto-matic-bid-obj="product.AutomaticBid"
                      ref="automaticBidDialog"
                    ></auto-matic-bid>
                  </v-flex>
                </v-flex>
                <v-flex class="item-info" v-if="isBuyNowEnable">
                  <ul>
                    <li>
                      Buy Now Price:
                      <span class="info-text">
                        <strong>
                          {{ $root.showAmount(product.BuyNowPrice) }}
                          <buy-now-btn
                            :product="product"
                            :is-normal="true"
                          ></buy-now-btn>
                        </strong>
                        You don’t need to attend on bidding, just buy this
                        product
                      </span>
                    </li>
                  </ul>
                </v-flex>

                <v-flex class="item-info">
                  <v-card-title class="pa-0 pb-3 text-decoration-underline"
                    >Seller information</v-card-title
                  >

                  <v-card-text class="pa-0 pb-2">
                    <a
                      href="javascript:void(0)"
                      class="brand--text pr-6"
                      v-text="product.SellerName"
                    ></a>
                    <span class="grey--text text--darken-3">Review</span>

                    <span class="brand--text">
                      ( {{ product.SellerPositiveRating }}
                      <v-icon class="brand--text star">mdi-star</v-icon>)
                    </span>
                  </v-card-text>
                  <v-card-text class="pa-0 pb-2">
                    <span
                      class="grey--text text--darken-3"
                      v-text="
                        product.SellerResponseRating > 0
                          ? product.SellerResponseRating + ' Positive feedback'
                          : 'No feedback yet'
                      "
                    ></span>
                    <br />
                    <span>Shipping & Handling (Customer Pick-up)</span>
                  </v-card-text>
                </v-flex>

                <v-flex class="item-info">
                  <span class="d-block pb-3">Payments Method</span>

                  <v-img
                    max-width="258"
                    :src="require('@/assets/images/payment-methods.png')"
                  ></v-img>
                </v-flex>
              </v-flex>
            </v-col>

            <v-col md="8" cols="12" order-md="1" class="py-0">
              <v-flex class="bid-history-box">
                <v-flex
                  class="
                    bid-history-time
                    d-flex
                    flex-wrap
                    align-center
                    justify-space-between
                  "
                >
                  <v-flex class="bidding-info flex-grow-0">
                    <span>
                      Bidder:
                      <strong v-text="product.BidderCount"></strong>
                    </span>
                    <span>
                      Total Bid:
                      <strong v-text="product.BidCount"></strong>
                    </span>
                  </v-flex>

                  <v-flex class="time-holder flex-grow-0">
                    <template v-if="product.AuctionStatus == 'Pending'">
                      Your item is waiting for admin approval.
                    </template>
                    <countdown
                      v-else-if="
                        product.AuctionStatus == 'Bidding' ||
                        product.AuctionStatus == 'Published'
                      "
                      class="d-flex"
                      :time="$root.getTime(product)"
                      :transform="$root.transform"
                      :emit-events="true"
                      @end="countdownEnded"
                    >
                      <template slot-scope="props">
                        <v-flex
                          class="time-left flex-grow-0"
                          :class="$root.countDown.Color[product.AuctionStatus]"
                        >
                          <div class="time-left-in">
                            {{ $root.countDown.Text[product.AuctionStatus] }}:
                            &nbsp;
                          </div>
                        </v-flex>
                        <v-flex class="flex-grow-0">
                          <span v-html="props.days"></span>
                          <span v-html="props.hours"></span>
                          <span v-html="props.minutes"></span>
                          <span v-html="props.seconds"></span>
                        </v-flex>
                      </template>
                    </countdown>
                    <template class="time-holder" v-else>
                      Nilam is closed
                    </template>
                  </v-flex>
                </v-flex>

                <v-flex class="table-top d-flex justify-space-between">
                  <a href="javascript:;">Learn more about bidding</a>
                </v-flex>

                <v-simple-table class="bid-table" fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Bidder</th>
                        <th class="text-center">Bid Amount</th>
                        <th class="text-right">Bid Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="bid_item in bid_items" :key="bid_item.Id">
                        <td
                          class="bidder-dtls"
                          data-label="Bidder Name"
                          :class="checkBidOwner(bid_item)"
                        >
                          <v-layout class="align-center">
                            <v-avatar
                              size="30"
                              color="blue"
                              class="mr-2 lighten-5"
                            >
                              <v-icon size="19" class="blue--text"
                                >mdi-account-settings</v-icon
                              >
                            </v-avatar>
                            <v-flex>
                              <span
                                class="bidder-name"
                                v-html="getBidderName(bid_item)"
                              ></span>
                              <span
                                class="bid-time text-truncate"
                                v-text="bid_item.CreatedAtFormated"
                              ></span>
                            </v-flex>
                          </v-layout>
                        </td>
                        <td data-label="Bid Amount" class="text-center">
                          <span
                            class="bid-amount"
                            v-text="$root.showAmount(bid_item.BidAmount)"
                          ></span>
                        </td>
                        <td data-label="Bid Time" class="text-right">
                          <span
                            class="bid-time"
                            v-text="bid_item.CreatedAtFormated"
                          ></span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>

              <!-- <v-flex class="table-bottom-text"
                >If two people bid the same amount, the first bid has
              priority</v-flex>-->
            </v-col>
          </v-row>
          <data-not-found v-else class="mt-10">
            <h1>Data not found</h1>
          </data-not-found>
        </v-sheet>
      </v-card>

      <v-card tile elevation="2" class="mt-4 py-4">
        <v-card-text class="py-0 black--text text-center please-contact">
          If you want to Buy or Sell then create you post? please
          <router-link :to="{ name: 'Contact' }">Contact</router-link>
        </v-card-text>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import BiddingForm from "@/components/sites/pages/BiddingForm";
import AutoMaticBid from "@/components/sites/common/AutoMaticBid";
import BuyNowBtn from "@/components/sites/common/BuyNowBtn";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "biddingDetails",

  components: {
    BiddingForm,
    AutoMaticBid,
    BuyNowBtn,
    DataNotFound,
  },

  data() {
    return {
      loading: false,
      product: Object,
      bid_items: [],
      dialog: false,
      formData: {
        maximum_price: "",
        auto_price: "",
      },
    };
  },
  created() {
    this.getProductDetails();
    // Listen to score changes coming from SignalR events
    this.$notificationHub.$on("broadcast", this.getBroadcast);
  },
  computed: {
    isOwner() {
      if (
        this.product.Documents[0] &&
        this.product.Documents[0].UserId == this.$auth?.user?.UserId
      ) {
        return true;
      }
      return false;
    },
    isBiddingEnable() {
      if (this.product.AuctionStatus != "Bidding") {
        return false;
      }

      if (this.isOwner) {
        return false;
      }

      return true;
    },
    isBuyNowEnable() {
      if (this.product.CurrentBid >= this.product.BuyNowPrice) {
        return false;
      }

      if (
        this.product.AuctionStatus != "Published" &&
        this.product.AuctionStatus != "Bidding"
      ) {
        return false;
      }

      if (this.isOwner) {
        return false;
      }

      return true;
    },
    note() {
      return this.$store.state.Configs.priceNote;
    },
  },
  mounted() {
    this.Fire.$on("auto-matic-bid-deleted", () => {
      this.product.AutomaticBid = {};
    });

    this.Fire.$on("auto-matic-bid-updated", (obj) => {
      this.product.AutomaticBid = obj;
    });
    document.body.classList.add("bidding-details-page");
  },

  destroyed() {
    document.body.classList.remove("bidding-details-page");
  },

  methods: {
    countdownEnded() {
      if (this.product.AuctionStatus == "Published") {
        this.product.AuctionStatus = "Bidding";
      } else {
        this.product.AuctionStatus = "Closed";
      }
    },
    checkBidOwner(bid_item) {
      if (this.$auth?.user?.UserId == bid_item.UserId) return "own_bid";
    },
    getBidderName(bid_item) {
      if (this.$auth?.user?.UserId == bid_item.UserId)
        return this.$auth?.user?.UserFullName + " <b>(My Bid)</b>";

      return bid_item.UserName;
    },
    getBroadcast({ type, obj }) {
      if (type != "Bid" || obj.ItemId != this.$route.params.id) return;

      this.product.CurrentBid = obj.BidAmount;
      this.product.BidderCount = obj.BidderCount;
      this.product.BidCount = obj.BidCount;
      if (this.$auth?.user?.UserId == obj.UserId) {
        this.product.UserLastBid = obj.BidAmount;
      }

      this.bid_items.unshift(obj);
    },
    getProductDetails() {
      if (this.loading) return;

      this.loading = true;

      this.$http
        .post(
          this.$store.state.Configs.getProductDetails
            .replace(":id", this.$route.params.id)
            .replace(":slug", this.$route.params.slug)
        )
        .then((result) => {
          this.product = result.data;
          this.getBiddingDetails(this.product.AuctionId);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getBiddingDetails(id) {
      this.$http
        .post(this.$store.state.Configs.getAllBid.replace(":auctionId", id))
        .then((result) => {
          this.loading = false;
          this.bid_items = result.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  beforeDestroy() {
    this.$notificationHub.$off("broadcast");
    this.Fire.$off("auto-matic-bid-updated");
    this.Fire.$off("auto-matic-bid-deleted");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/total_bid.scss";
</style>
