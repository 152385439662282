<template>
  <v-btn
    @click="payNow"
    :loading="loading"
    :disabled="loading"
    block
    tile
    depressed
    class="brand white--text text-none"
    >Pay Now</v-btn
  >
</template>
<script>
export default {
  data: () => ({
    loading: false,
  }),
  props: {
    referanceType: String,
    referanceId: Number,
    amount: Number,
    cartType: String,
    availEmi: Boolean,
    paymentGateway: String,
  },
  methods: {
    eventFire(isLoading) {
      this.Fire.$emit("onlineGatewayResponse", {
        loading: isLoading,
      });
    },
    payNow() {
      this.loading = true;
      this.eventFire(true);

      let obj = {
        ReferanceType: this.referanceType,
        ReferanceId: this.referanceId,
        Amount: this.amount,
        IsAvailEmi: this.availEmi,
        PaymentGateway: this.paymentGateway,
      };

      this.$http
        .post(this.$store.state.Configs.getPaymentGatewayURL, { ...obj })
        .then((res) => {
          this.$store
            .dispatch("setCartItemsToEmpty", this.cartType)
            .then(() => {
              window.location.href = res.data;
              //this.loading = false;
              //this.eventFire(false);
            });
        })
        .catch((error) => {
          this.loading = false;
          this.eventFire(false);

          //console.log(error);
          this.$store.commit("set_flash", {
            type: "error",
            message: error.response.data,
          });
        });
    },
  },
};
</script>
