<template>
  <v-flex>
    <data-not-found class="mt-10">
      <h1>Page Not Found</h1>
      <v-icon>mdi-trending-neutral mdi-rotate-180</v-icon>
      <router-link :to="{ name: 'Home' }"> Back To Home</router-link>
    </data-not-found>
  </v-flex>
</template>

<script>
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "NotFound",
  components: {
    DataNotFound,
  },
};
</script>
