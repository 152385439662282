<template>
  <!-- success greeting popup -->
  <v-dialog persistent v-model="dialog" width="914">
    <v-card>
      <v-card-title>
        <span class="headline" v-text="$t('Address')"></span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-flex class="d-flex justify-center">
            <ValidationObserver
              class="d-block validation-observer"
              ref="form"
              v-slot="{ handleSubmit }"
            >
              <form @submit.prevent="handleSubmit(onSubmit)">
                <v-flex class="personal-information">
                  <v-row>
                    <!-- address title -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-12 py-0 mb-3"
                      name="address title"
                      rules="required|max:256"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Address Title') + '*'"
                        ></v-flex>
                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          type="text"
                          :label="$t('Ex: Office/Home address')"
                          v-model="formData.Title"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </v-row>
                  <v-row>
                    <!-- full Name -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-12 py-0 mb-3"
                      name="name"
                      rules="required|max:256"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Name') + '*'"
                        ></v-flex>
                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          type="text"
                          :label="$t('Your Name')"
                          v-model="formData.Name"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </v-row>

                  <v-row>
                    <!-- phone -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="phone"
                      :rules="{
                        required: true,
                        max: 14,
                        regex: /^(?:\+88|88)?(01[3-9]\d{8})$/,
                      }"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Phone') + '*'"
                        ></v-flex>

                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          type="number"
                          :label="$t('Phone Number')"
                          v-model="formData.Mobile"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>

                    <!-- Email -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="e-mail"
                      rules="email"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Email')"
                        ></v-flex>

                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          type="email"
                          :label="$t('Type Email')"
                          v-model="formData.Email"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </v-row>

                  <v-row>
                    <!-- region -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="region"
                      rules="required"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Division') + '*'"
                        ></v-flex>

                        <v-select
                          :items="divisions"
                          item-value="Id"
                          item-text="Name"
                          :label="$t('Select division')"
                          solo
                          flat
                          hide-details
                          outlined
                          v-model="formData.DivisionId"
                          @change="getDistricts(formData.DivisionId)"
                        ></v-select>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>

                    <!-- District -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="district"
                      rules="required"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('District') + '*'"
                        ></v-flex>

                        <v-select
                          :items="districts"
                          item-value="Id"
                          item-text="Name"
                          :label="$t('Select district')"
                          solo
                          flat
                          hide-details
                          outlined
                          v-model="formData.DistrictId"
                        ></v-select>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>

                    <!-- address -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-12 py-0"
                      name="address"
                      rules="required|max:256"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Address') + '*'"
                        ></v-flex>

                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          type="text"
                          :label="$t('Type address')"
                          v-model="formData.Address1"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>

                      <!-- <v-checkbox
                        hide-details
                        color="brand"
                        label="Is Default"
                        v-model="formData.IsDefault"
                      ></v-checkbox>-->
                    </ValidationProvider>
                  </v-row>
                </v-flex>

                <v-flex class="d-flex justify-space-between align-right">
                  <v-btn
                    type="submit"
                    class="publish-ad mt-5 green white--text text-none"
                    v-text="isEdit ? $t('Update') : $t('Save')"
                  ></v-btn>
                  <v-btn
                    class="mt-5 danger white--text text-none"
                    text
                    @click="closeModal"
                    v-text="$t('Close')"
                  ></v-btn>
                </v-flex>
              </form>
            </ValidationObserver>
          </v-flex>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddressForm",
  data() {
    return {
      formData: {},
      divisions: [],
      districts: [],
      dialog: false,
      isEdit: false,
      addressSubmitUrl: this.$store.state.Configs.addAddress,
    };
  },
  methods: {
    modalOpen(obj) {
      this.getDivisions().then(() => {
        if (obj && obj.DivisionId) {
          this.getDistricts(obj.DivisionId);
        }
      });
      this.getSelectedData(obj);
      this.dialog = true;
    },
    closeModal() {
      this.formData = {};
      this.$refs.form.reset();
      this.dialog = false;
    },
    getSelectedData(selectedObj) {
      if (selectedObj && Object.keys(selectedObj).length) {
        this.addressSubmitUrl = this.$store.state.Configs.updateAddress.replace(
          ":id",
          selectedObj.Id
        );
        this.formData = { ...selectedObj };
        this.isEdit = true;
      } else {
        this.formData = {};
        this.isEdit = false;
        this.addressSubmitUrl = this.$store.state.Configs.addAddress;
      }
    },
    getDivisions() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.Configs.getDivisions)
          .then((res) => {
            this.divisions = res.data;
            resolve(this.divisions);
          })
          .catch((err) => reject(err));
      });
    },
    getDistricts(divisionId) {
      if (divisionId) {
        this.$http
          .post(
            this.$store.state.Configs.getDistrictsByDivision.replace(
              ":divisionId",
              parseInt(divisionId)
            )
          )
          .then((resp) => {
            this.districts = resp.data;
          })
          .catch((err) => {
            this.districts = [];
            console.log(err);
          });
      } else {
        this.districts = [];
      }
    },
    addressSubmit() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.addressSubmitUrl, this.formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.addressSubmit()
          .then((res) => {
            this.$nextTick(() => {
              this.formData = {};
              this.$refs.form.reset();
              this.dialog = false;
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
              this.Fire.$emit("address-updated", res.data.ResponseData);
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/shipping_billing.scss";
</style>
