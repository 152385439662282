<template>
  <v-flex class="shop-banner pb-4">
    <v-img
      max-height="200"
      :src="$root.generateMediaImageUrl(bannerInfos.bannerUrl)"
      v-if="bannerInfos.bannerUrl"
    >
    </v-img>
    <v-img v-else :src="require('@/assets/images/shop-banner-1.jpg')">
      <v-flex class="h-100 d-flex justify-center align-center">
        <v-flex class="shop-info pa-8 text-center flex-grow-0 rounded">
          <h3 v-text="bannerTitle"></h3>
          <img
            v-if="bannerInfos.IsVerified"
            :src="require('@/assets/images/verified-seller.png')"
          />
        </v-flex>
      </v-flex>
    </v-img>
  </v-flex>
</template>

<script>
export default {
  name: "shopBanner",
  props: {
    bannerObj: Object,
  },
  data() {
    return {
      bannerInfos: {},
    };
  },
  computed: {
    bannerTitle() {
      let title = this.bannerInfos.title;
      let skipMaxNumber = this.$store.state.Configs.skipMaxNumberInput;

      [...title].forEach((c, i) => {
        if (
          this.$store.state.Configs.charCodeListsForBot.includes(
            title.charCodeAt(i)
          )
        ) {
          if (skipMaxNumber < 1) {
            title = this.$root.replaceAt(title, i, "*");
          }
          skipMaxNumber--;
        }
      });
      return title;
    },
  },
  created() {
    if (this.bannerObj.type == "company") {
      this.bannerInfos = {
        bannerUrl:
          this.bannerObj.Company && this.bannerObj.Company.BannerImageUrl
            ? this.bannerObj.Company.BannerImageUrl
            : "",
        logoUrl:
          this.bannerObj.Company && this.bannerObj.Company.LogoUrl
            ? this.bannerObj.Company.LogoUrl
            : "",
        title:
          this.bannerObj.Company && this.bannerObj.Company.CompanyName
            ? this.bannerObj.Company.CompanyName
            : this.bannerObj.FullName,

        IsVerified: this.bannerObj.IsVerified,
      };
    } else {
      this.bannerInfos = {
        bannerUrl: this.bannerObj.BannerUrl ? this.bannerObj.BannerUrl : "",
        logoUrl: this.bannerObj.ImageUrl ? this.bannerObj.ImageUrl : "",
        title: this.bannerObj.BrandName,
        IsVerified: false,
      };
    }
  },
};
</script>
<style lang="scss">
@import "@/sass/app/index.scss";

/*============Shop Banner===========*/
.shop-banner {
  padding: 0;
  margin: 0;
  img {
    max-width: 100%;
  }
}

.shop-info {
  background-color: #fff;
  border: 1px solid #e4e1dc;
  h3 {
    padding-bottom: rem-calc(4px);
    @include font(primary, 24px, 28px, medium);
  }
  span {
    white-space: nowrap;
    max-width: 350px;
    @include truncate-clamp();
  }
}
/*============End Shop Banner===========*/
</style>
