<template>
  <div>
    <v-skeleton-loader
      class="mx-auto pl-5 pr-5"
      type="list-item-avatar-three-line, image, article"
      v-if="loading"
    ></v-skeleton-loader>
    <v-sheet
      wrap
      class="related-products-slider"
      tag="section"
      v-else-if="relatedProducts.length"
    >
      <h2>Similar Listings</h2>
      <v-slide-group center-active max="2" show-arrows class="slide-item-wrap">
        <v-slide-item v-for="product in relatedProducts" :key="product.Id">
          <v-sheet class="slide-item pb-7" width="295">
            <product-grid-card :product="product"></product-grid-card>
          </v-sheet>
        </v-slide-item>
      </v-slide-group>
      <v-flex class="text-center" v-if="false">
        <v-btn href="#" depressed rounded class="brand white--text text-none"
          >View all product</v-btn
        >
      </v-flex>
    </v-sheet>
  </div>
</template>

<script>
import ProductGridCard from "./ProductGridCard";

export default {
  name: "RelatedProduct",
  components: {
    ProductGridCard,
  },
  data() {
    return {
      loading: false,
      relatedProducts: [],
    };
  },
  created() {
    this.getRelatedProducts();
  },
  methods: {
    getRelatedProducts() {
      this.loading = true;

      this.$http
        .post(
          this.$store.state.Configs.getRelatedProducts.replace(
            ":id",
            this.$route.params.id
          ) + "?length=15"
        )
        .then((result) => {
          this.relatedProducts = result.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/related_products.scss";
</style>
