<template>
  <v-flex class="delivery-wrap">
    <!-- inner banner -->
    <inner-banner :options="bannerContent"></inner-banner>

    <v-flex class="tab-panel">
      <v-container class="py-0">
        <v-layout wrap class="justify-space-between">
          <v-flex class="tab-left flex-grow-0">
            <v-tabs show-arrows :vertical="vertical" v-if="categories.length">
              <v-tab
                class="text-none"
                v-for="(cat, catId) in categories"
                :key="catId"
                v-text="cat"
                @click="openPanel()"
              ></v-tab>

              <v-tab-item v-for="(faqArr, catId) in faqs" :key="catId">
                <v-expansion-panels v-model="panel" accordion tile>
                  <v-expansion-panel
                    v-for="(item, index) in faqArr"
                    :key="index"
                  >
                    <v-expansion-panel-header
                      v-text="item.Question"
                    ></v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <p v-text="item.Answer"></p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-tab-item>
            </v-tabs>

            <!-- Data not found -->
            <data-not-found v-else class="mt-10">
              <h1>No FAQs found.</h1>
            </data-not-found>
          </v-flex>
          <v-flex class="tab-right flex-grow-0">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <v-flex class="tab-right-box">
                  <h2>Can't find an answer to your question?</h2>
                  <div class="input-wrap">
                    <ValidationProvider
                      tag="div"
                      name="subject"
                      rules="required"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-text-field
                          solo
                          flat
                          hide-details
                          outlined
                          rows="3"
                          label="Subject"
                          v-model="formData.Subject"
                        ></v-text-field>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider
                      tag="div"
                      name="Message"
                      rules="required"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-textarea
                          solo
                          flat
                          hide-details
                          outlined
                          rows="3"
                          label="Ask your question"
                          v-model="formData.Message"
                        ></v-textarea>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <v-btn
                    type="submit"
                    depressed
                    tile
                    class="faq-btn mt-1 brand white--text text-none"
                    >Submit</v-btn
                  >
                </v-flex>
              </form>
            </ValidationObserver>

            <v-flex class="faq-contact">
              <v-layout class="faq-contact-title align-center">
                <v-flex class="flex-grow-0">
                  <img
                    :src="require('@/assets/images/faq-contact.png')"
                    alt="faq contact icon"
                  />
                </v-flex>
                <h3>Contact Info</h3>
              </v-layout>

              <span v-if="Object.keys(siteSettings).length">
                Email:
                <a :href="'mailto:' + siteSettings.Email">
                  {{ siteSettings.Email }}
                </a>
              </span>
              <span v-if="Object.keys(siteSettings).length">
                Phone:
                <a :href="'tel:' + siteSettings.Mobile">
                  {{ siteSettings.Mobile }}
                </a>
              </span>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import InnerBanner from "@/components/sites/common/InnerBanner";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "Faq",
  components: {
    InnerBanner,
    DataNotFound,
  },

  data() {
    return {
      loading: false,
      formData: {},
      ask_question: "",
      panel: 0,

      bannerContent: {
        title: "How can we help you?",
        image: "faq.jpg",
      },
      faqs: {},
      categories: [],
      questionSubmitUrl: this.$store.state.Configs.addInquiry,
    };
  },
  created() {
    this.getAllFaqs();
  },
  computed: {
    vertical() {
      if (this.$root.windowSize < 1264) {
        return false;
      } else {
        return true;
      }
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  methods: {
    openPanel() {
      this.panel = 0;
    },
    getAllFaqs() {
      if (this.loading) return;
      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.allFaqs)
        .then((result) => {
          this.categories = this._.map(
            this._.uniqBy(result.data, "CategoryId"),
            "Category"
          );
          this.faqs = this._.groupBy(result.data, "CategoryId");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    questionSubmit() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.questionSubmitUrl, this.formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      let check = this.$root.checkAuth();
      if (check) return;
      this.$refs.form.validate().then(() => {
        this.questionSubmit()
          .then((res) => {
            this.$nextTick(() => {
              this.formData = {};
              this.$refs.form.reset();
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/faq.scss";
</style>
