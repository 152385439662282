<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <v-layout wrap>
        <!-- account-sidebar -->
        <account-sidebar></account-sidebar>

        <v-flex class="account-wrap">
          <v-card tile elevation="0">
            <v-flex class="account-panel">
              <v-layout wrap class="account-panel-top align-center justify-space-between">
                <h2>
                  {{$t("My Participated Nilam")}}
                  <span v-text="`(${total} ${$t('Auctions')})`"></span>
                </h2>
              </v-layout>

              <!-- filters-bar -->
              <v-layout class="filter-bar flex-wrap">
                <div class="input-wrap">
                  <v-text-field
                    solo
                    flat
                    hide-details
                    outlined
                    :label="$t('Keyword')"
                    v-model="filterObj.Keyword"
                  ></v-text-field>
                </div>

                <div class="input-wrap">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="filterObj.date_range"
                    persistent
                    width="300px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterObj.date_range"
                        solo
                        flat
                        hide-details
                        outlined
                        :label="$t('Date range')"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker range v-model="filterObj.date_range" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(filterObj.date_range)"
                      >OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div class="input-wrap">
                  <v-select
                    :items="status"
                    dense
                    solo
                    flat
                    hide-details
                    outlined
                    :label="$t('Auction Status')"
                    v-model="filterObj.Status"
                  ></v-select>
                </div>

                <div class="input-wrap">
                  <v-select
                    v-model="filterObj.WinStatus"
                    item-key="key"
                    item-value="key"
                    item-text="text"
                    :items="myStatus"
                    :label="$t('Win Status')"
                    dense
                    solo
                    flat
                    hide-details
                    full-width
                    outlined
                  ></v-select>
                </div>
                <v-btn
                  @click="getMyParticipatedAuctions()"
                  height="42"
                  type="submit"
                  tile
                  depressed
                  class="brand white--text text-none"
                >{{$t('Search')}}</v-btn>
              </v-layout>

              <v-skeleton-loader
                class="mx-auto pl-5 pr-5"
                type="article,article,article"
                v-if="loading"
              ></v-skeleton-loader>

              <template v-else>
                <v-flex
                  class="account-panel-table"
                  v-if="
                    Object.keys(productsObj).length && productsObj.data.length
                  "
                >
                  <template v-if="$root.windowSize > 959">
                    <table>
                      <thead>
                        <tr>
                          <th v-text="$t('Product')"></th>
                          <th>
                            <div v-text="$t('Price')"></div>
                          </th>
                          <th>
                            <span class="winning-auction" v-text="$t('Winning Auction')"></span>
                          </th>
                          <th>
                            <span class="status" v-text="$t('Auction Status')"></span>
                          </th>
                          <th v-text="$t('Address')"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="product in productsObj.data" :key="product.Id">
                          <td data-label="Product">
                            <v-flex class="wining-status d-flex">
                              <v-img
                                v-if="product.WinStatus == 1"
                                contain
                                max-width="65"
                                height="28"
                                :src="require('@/assets/images/win.png')"
                                alt="Image"
                              ></v-img>
                              <v-img
                                v-if="product.WinStatus == 2"
                                contain
                                max-width="65"
                                height="28"
                                :src="require('@/assets/images/lost.png')"
                                alt="Image"
                              ></v-img>
                            </v-flex>

                            <v-layout class="pt-2 my-product-img fill-height">
                              <v-img
                                contain
                                max-width="45"
                                :src="
                                  $root.generateImageUrl(
                                    product,
                                    $root.dimentions.thumb
                                  )
                                "
                                alt="product.ItemName"
                              >
                                <router-link
                                  class="d-block fill-height"
                                  :to="{
                                    name: 'ProductDetails',
                                    params: {
                                      id: product.Id,
                                      slug: product.Slug,
                                    },
                                  }"
                                ></router-link>
                              </v-img>

                              <v-flex class="my-product-title">
                                <h4 class="product">
                                  <router-link
                                    v-text="product.ItemName"
                                    class="d-block fill-height primary--text"
                                    :to="{
                                      name: 'ProductDetails',
                                      params: {
                                        id: product.Id,
                                        slug: product.Slug,
                                      },
                                    }"
                                  ></router-link>
                                </h4>

                                <v-flex>
                                  <span
                                    class="qty"
                                    v-text="
                                      $t('Qty')+' - ' +
                                      product.StockQuantity +
                                      ' (' +
                                      product.MeasurementName +
                                      ')'
                                    "
                                  ></span>
                                  <v-chip
                                    label
                                    small
                                    v-if="product.SaleType == 'Auction'"
                                    class="type px-2"
                                  >{{$t('Nilam')}}</v-chip>
                                </v-flex>

                                <v-flex>
                                  <span
                                    class="order-date"
                                    v-text="
                                      $root.formateServerDateTime(
                                        product.BidTime
                                      )
                                    "
                                  ></span>
                                </v-flex>
                              </v-flex>
                            </v-layout>

                            <v-flex
                              class="pt-2 d-flex align-center"
                              v-if="product.WinStatus == 1 && !product.OrderId"
                            >
                              <buy-now-btn
                                :product="product"
                                :is-normal="true"
                                class="white--text mr-2"
                              >{{$t('Confirm Order')}}</buy-now-btn>

                              <v-sheet class="auction-time-holder">
                                <countdown
                                  v-if="
                                    $root.getCountdownTime(
                                      product.OrderConfirmationLastTime
                                    )
                                  "
                                  :time="
                                    $root.getCountdownTime(
                                      product.OrderConfirmationLastTime
                                    )
                                  "
                                  :transform="$root.transformShorter"
                                >
                                  <template slot-scope="props">
                                    <span
                                      class="time-title"
                                      :class="
                                        $root.countDown.Color[
                                          product.AuctionStatus
                                        ]
                                      "
                                    >
                                      {{
                                      $root.countDown.Text[
                                      product.AuctionStatus
                                      ]
                                      }}:
                                    </span>
                                    <b class="time-value">
                                      {{ props.days }} {{ props.hours }}
                                      {{ props.minutes }}
                                      {{ props.seconds }}
                                    </b>
                                  </template>
                                </countdown>
                                <span v-else>You miss the opportunity to place the order.</span>
                              </v-sheet>
                            </v-flex>
                            <v-flex
                              class="pt-2 d-flex align-center"
                              v-if="product.WinStatus == 1 && !product.OrderId"
                            >{{$t('Waiting for order confirmation, please order before time.')}}</v-flex>
                          </td>
                          <td data-label="Price">
                            <span
                              v-text="$root.showAmount(product.BasePrice)"
                              class="order-mony d-block"
                            ></span>
                            <span
                              v-if="product.SaleType == 'Auction'"
                              class="d-block grey--text"
                              v-text="$t('Starting bid')"
                            ></span>
                          </td>
                          <td data-label="Winning Auction">
                            <v-layout class="winning align-center">
                              <v-img
                                v-if="
                                  product.AuctionStatus == 'Closed' ||
                                  product.AuctionStatus == 'ShortListed' ||
                                  product.AuctionStatus == 'Ordered'
                                "
                                contain
                                width="36"
                                height="36"
                                :src="require('@/assets/images/wining-bid.png')"
                              ></v-img>
                              <v-img
                                v-else
                                contain
                                width="28"
                                height="28"
                                :src="
                                  require('@/assets/images/bid_participate.png')
                                "
                              ></v-img>
                              <v-flex class="winning-bid">
                                <span
                                  v-text="
                                    $root.showAmount(product.MaxBidAmount)
                                  "
                                  class="brand--text"
                                ></span>
                                <span class="d-block">
                                  {{
                                  product.AuctionStatus == "Closed" ||
                                  product.AuctionStatus == "ShortListed" ||
                                  product.AuctionStatus == "Ordered"
                                  ? $t('Winning Bid')
                                  : $t('Current Bid')
                                  }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </td>

                          <td data-label="Auction Status">
                            <v-flex class="auction-time" v-if="product.AuctionStatus == 'Bidding'">
                              <countdown
                                v-if="$root.getTime(product)"
                                :time="$root.getTime(product)"
                                :transform="$root.transformShorter"
                              >
                                <template slot-scope="props">
                                  <b class="time-value d-block">
                                    <v-icon size="17">mdi-calendar-clock</v-icon>
                                    {{ props.days }} {{ props.hours }}
                                    {{ props.minutes }}
                                    {{ props.seconds }}
                                  </b>
                                </template>
                              </countdown>
                            </v-flex>

                            <router-link
                              :to="{
                                name: 'ProductDetails',
                                params: {
                                  id: product.Id,
                                  slug: product.Slug,
                                },
                              }"
                              v-text="product.AuctionStatus"
                            ></router-link>
                          </td>

                          <td class="border-right" data-label="Address">
                            <h4 class="payment-method">
                              {{$t('Shipped By')}}:
                              <strong
                                v-text="
                                  product.ShippedBy == 'Both'
                                    ? 'Nilam or Seller'
                                    : product.ShippedBy
                                "
                              ></strong>
                            </h4>
                            <h4 class="payment-method">
                              Address:
                              <strong v-text="product.Location"></strong>
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-else>
                    <v-layout
                      wrap
                      class="order-product-wrap align-center"
                      v-for="product in productsObj.data"
                      :key="product.Id"
                    >
                      <v-flex class="top-section d-flex flex-wrap flex-grow-0 align-center">
                        <v-flex class="column au-top-first flex-grow-0">
                          <v-flex class="wining-status">
                            <v-img
                              v-if="product.WinStatus == 1"
                              contain
                              max-width="65"
                              height="28"
                              :src="require('@/assets/images/win.png')"
                              alt="Image"
                            ></v-img>
                            <v-img
                              v-if="product.WinStatus == 2"
                              contain
                              max-width="65"
                              height="28"
                              :src="require('@/assets/images/lost.png')"
                              alt="Image"
                            ></v-img>
                          </v-flex>

                          <v-layout class="pt-2">
                            <router-link
                              class="d-block fill-height"
                              :to="{
                                name: 'ProductDetails',
                                params: {
                                  id: product.Id,
                                  slug: product.Slug,
                                },
                              }"
                            >
                              <img
                                class="product-img mr-2"
                                contain
                                max-width="80"
                                :src="
                                  $root.generateImageUrl(
                                    product,
                                    $root.dimentions.thumb
                                  )
                                "
                                alt="product.ItemName"
                              />
                            </router-link>

                            <v-flex class="product-info">
                              <h4>
                                <router-link
                                  v-text="product.ItemName"
                                  class="d-block fill-height primary--text"
                                  :to="{
                                    name: 'ProductDetails',
                                    params: {
                                      id: product.Id,
                                      slug: product.Slug,
                                    },
                                  }"
                                ></router-link>
                              </h4>

                              <v-flex>
                                <span
                                  class="qty"
                                  v-text="
                                    'Qty - ' +
                                    product.StockQuantity +
                                    ' (' +
                                    product.MeasurementName +
                                    ')'
                                  "
                                ></span>
                                <v-chip
                                  label
                                  small
                                  v-if="product.SaleType == 'Auction'"
                                  class="type px-2 ml-2"
                                >{{$t("Nilam")}}</v-chip>
                              </v-flex>

                              <v-flex>
                                <span
                                  class="order-date"
                                  v-text="
                                    $root.formateServerDateTime(product.BidTime)
                                  "
                                ></span>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex class="column au-top-second flex-grow-0">
                          <v-flex
                            v-if="
                              product.WinStatus == 1 &&
                              !product.OrderId &&
                              !$root.getCountdownTime(
                                product.OrderConfirmationLastTime
                              )
                            "
                          >
                            <v-sheet class="auction-time-holder">
                              <v-flex class="d-flex align-center">
                                <countdown
                                  class="d-flex align-center"
                                  :time="
                                    $root.getCountdownTime(
                                      product.OrderConfirmationLastTime
                                    )
                                  "
                                  :transform="$root.transformShorter"
                                >
                                  <template slot-scope="props">
                                    <span
                                      class="time-title pr-1"
                                      :class="
                                        $root.countDown.Color[
                                          product.AuctionStatus
                                        ]
                                      "
                                    >
                                      <!-- {{
                                      $root.countDown.Text[
                                      product.AuctionStatus
                                      ]
                                      }}-->
                                      <v-icon size="18">mdi-clock-outline</v-icon>
                                    </span>
                                    <b class="time-value">
                                      {{ props.days }} {{ props.hours }}
                                      {{ props.minutes }}
                                      {{ props.seconds }}
                                    </b>
                                  </template>
                                </countdown>
                              </v-flex>

                              <v-flex
                                class="pt-2 d-flex align-center miss"
                                v-text="$t('Waiting for order confirmation, please order before time.')"
                              ></v-flex>
                              <buy-now-btn
                                :product="product"
                                :is-normal="true"
                                class="white--text mr-2"
                              >{{$t('Confirm Order')}}</buy-now-btn>
                            </v-sheet>
                          </v-flex>
                          <v-flex
                            v-else
                            class="d-flex align-center miss"
                            v-text="$t('You missed the opportunity to place the order.')"
                          ></v-flex>
                        </v-flex>

                        <v-flex class="column au-top-third flex-grow-0">
                          <span
                            v-text="$root.showAmount(product.BasePrice)"
                            class="order-mony d-block brand--text"
                          ></span>
                          <span
                            v-if="product.SaleType == 'Auction'"
                            class="d-block grey--text"
                          >Starting bid</span>
                        </v-flex>
                      </v-flex>

                      <v-flex class="btm-section d-flex flex-wrap align-center">
                        <v-flex class="column au-top-first flex-grow-0">
                          <v-flex class="winning-bid pl-2">
                            <span
                              v-text="$root.showAmount(product.MaxBidAmount)"
                              class="brand--text"
                            ></span>
                            <span class="d-block">
                              {{
                              product.AuctionStatus == "Closed" ||
                              product.AuctionStatus == "ShortListed" ||
                              product.AuctionStatus == "Ordered"
                              ? "Winning Bid"
                              : "Current Bid"
                              }}
                            </span>
                          </v-flex>
                        </v-flex>

                        <v-flex class="column au-top-second flex-grow-0">
                          <v-flex class="auction-time" v-if="product.AuctionStatus == 'Bidding'">
                            <countdown
                              v-if="$root.getTime(product)"
                              :time="$root.getTime(product)"
                              :transform="$root.transformShorter"
                            >
                              <template slot-scope="props">
                                <b class="time-value d-block">
                                  <v-icon size="17">mdi-calendar-clock</v-icon>
                                  {{ props.days }} {{ props.hours }}
                                  {{ props.minutes }}
                                  {{ props.seconds }}
                                </b>
                              </template>
                            </countdown>
                          </v-flex>

                          <router-link
                            :to="{
                              name: 'ProductDetails',
                              params: {
                                id: product.Id,
                                slug: product.Slug,
                              },
                            }"
                            v-text="product.AuctionStatus"
                          ></router-link>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </template>

                  <v-flex
                    class="d-flex justify-end account-pagination"
                    v-if="productsObj.TotalPage > 1"
                  >
                    <pagination :page-no="productsObj.Page" :length="productsObj.TotalPage"></pagination>
                  </v-flex>
                </v-flex>
                <!-- Data not found start-->
                <data-not-found v-else class="mt-10">
                  <h1 v-text="$t('No records found.')"></h1>
                  <h5
                    class="mt-1"
                    v-text="$t('Looks like you haven\'t participated any auction yet.')"
                  ></h5>
                  <v-btn class="mt-1" x-small color="primary" @click="onReset()">{{$t("Reset")}}</v-btn>
                </data-not-found>
                <!-- Data not found end-->
              </template>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import Pagination from "@/components/services/Pagination";
import BuyNowBtn from "@/components/sites/common/BuyNowBtn";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "MyAuction",
  components: {
    AccountSidebar,
    Pagination,
    BuyNowBtn,
    DataNotFound,
  },

  data() {
    return {
      loading: false,
      modal: false,
      productsObj: [],
      drawer: false,
      total: 0,
      status: ["Bidding", "Closed", "ShortListed", "Ordered"],
      myStatus: [
        { key: 1, text: "Won Auction" },
        { key: 2, text: "Lost Auction" },
        { key: 3, text: "Ongoing Auction" },
      ],
      filterObj: {
        date_range: [],
        DateFrom: "",
        DateTo: "",
        Keyword: "",
        WinStatus: 0,
        Type: 1,
        Page: 1,
        Length: 10,
      },
    };
  },
  created() {
    this.getMyParticipatedAuctions();
    this.Fire.$on("pageChanged", (val) => {
      console.log(val);
      this.filterObj.Page = parseInt(val);
      this.getMyParticipatedAuctions();
    });
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
  },
  methods: {
    onReset() {
      this.filterObj.date_range = [];
      this.filterObj.DateFrom = "";
      this.filterObj.DateTo = "";
      this.filterObj.Keyword = "";
      this.filterObj.WinStatus = 0;
      this.filterObj.Type = 1;
      this.filterObj.Page = 1;
      this.filterObj.Length = 10;
      this.getMyParticipatedAuctions();
    },
    getMyParticipatedAuctions() {
      if (this.loading) return;
      this.loading = true;
      this.formatFilterObj();
      this.$http
        .post(
          this.$store.state.Configs.getMyParticipatedAuction,
          this.filterObj
        )
        .then((result) => {
          this.productsObj = result.data;
          this.loading = false;
          this.total = this.productsObj.recordsTotal;
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatFilterObj() {
      if (this.filterObj.date_range.length > 0) {
        if (this.filterObj.date_range.length == 1) {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[0];
        } else {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[1];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/account_table.scss";
</style>
