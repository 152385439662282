<template>
  <v-card tile class="product-sidebar">
    <v-flex class="product-sidebar-box">
      <v-layout class="justify-space-between">
        <v-card-title v-text="'Keyword'"></v-card-title>
        <v-sheet class="clear-all" @click="clearAll">
          <v-icon size="17">mdi-close</v-icon>
          {{ $t("Clear all") }}
        </v-sheet>
      </v-layout>
      <v-divider></v-divider>
      <v-flex class="input-wrap pt-4 pb-0">
        <v-text-field
          v-model="keyword"
          @input="keywordTyping"
          solo
          flat
          hide-details
          outlined
          :label="$t('Type Keyword')"
          prepend-inner-icon="mdi-keyboard"
        ></v-text-field>
      </v-flex>
    </v-flex>

    <!-- Filters Section -->
    <!-- <v-flex class="product-sidebar-box" v-if="itemGroups && itemGroups.length">
      <v-card-title v-text="'Group'"></v-card-title>
      <v-divider></v-divider>
      <v-radio-group v-model="filterObj.ItemGroup">
        <v-radio
          v-for="itemGroup in itemGroups"
          hide-details
          color="brand"
          :key="itemGroup.Id"
          :label="itemGroup.Name"
          :value="itemGroup.Id.toString()"
        ></v-radio>
      </v-radio-group>
    </v-flex>-->

    <!-- Filters Section -->
    <v-flex class="product-sidebar-box">
      <v-card-title v-text="'Nature'"></v-card-title>
      <v-divider></v-divider>
      <v-flex class="product-sidebar-in">
        <v-checkbox
          v-for="filter in filters"
          :key="filter.id"
          hide-details
          color="brand"
          v-model="filterObj[filter.key]"
          :label="filter.title"
          :value="filter.value"
        ></v-checkbox>
      </v-flex>
    </v-flex>

    <!-- Filters Section -->
    <!-- <v-flex class="product-sidebar-box">
      <v-card-title v-text="'Condition'"></v-card-title>
      <v-divider></v-divider>
      <v-checkbox
        v-for="filter in conditions"
        :key="filter.id"
        hide-details
        color="brand"
        v-model="filterObj[filter.key]"
        :label="filter.title"
        :value="filter.value"
      ></v-checkbox>
    </v-flex>-->

    <!-- <v-flex class="product-sidebar-box">
      <v-card-title v-text="$t('Filters')"></v-card-title>
      <v-divider></v-divider>
      <v-checkbox
        v-model="filterObj.Type"
        @change="typeChanged(filterObj.Type)"
        v-for="obj in TypeArr"
        :key="obj.value"
        hide-details
        color="brand"
        :label="obj.text"
        :value="obj.value"
      ></v-checkbox>
    </v-flex>-->

    <!-- Price Section -->
    <v-flex class="product-sidebar-box">
      <v-card-title v-text="$t('Price')"></v-card-title>
      <v-divider></v-divider>
      <v-layout align-center class="min-order pt-4">
        <v-text-field
          solo
          flat
          outlined
          v-model.number="min"
          hide-details
          single-line
          type="number"
          style="width: 60px"
        ></v-text-field>
        <span class="px-1">-</span>
        <v-text-field
          solo
          flat
          outlined
          v-model.number="max"
          hide-details
          single-line
          type="number"
          style="width: 60px"
        ></v-text-field>
        <v-btn class="ml-1" outlined depressed tile @click="minMaxChanged">{{
          $t("OK")
        }}</v-btn>
      </v-layout>
    </v-flex>

    <v-flex class="product-sidebar-box" v-if="!filterObj.BrandId">
      <v-card-title>
        <v-select
          ref="select"
          @click="loadAllParentCategories()"
          :items="allParentCategories"
          v-model="selectedParentCategoryId"
          :placeholder="$t('Select Category')"
          hide-details
          solo
          flat
          dense
          item-text="Name"
          item-value="Id"
          :label="$t('Select Category')"
          @change="parentCategoryChanged(selectedParentCategoryId)"
        ></v-select>
      </v-card-title>

      <template v-if="!childCategoryLoader">
        <v-flex
          class="product-sidebar-in"
          v-if="childCategories && childCategories.length"
        >
          <!-- Category Section -->
          <v-treeview
            class="pt-2"
            dense
            selectable
            transition
            v-model="filterObj.Category"
            :items="childCategories"
            item-children="Children"
            item-text="Name"
            item-value="Id"
            item-key="Id"
          ></v-treeview>
        </v-flex>
        <v-flex v-else class="product-sidebar-in text-center">
          <small class="danger--text" v-if="selectedParentCategoryId">{{
            $t("No sub category found")
          }}</small>
        </v-flex>
      </template>
      <v-flex v-else class="product-sidebar-in text-center">{{
        $t("Loading...")
      }}</v-flex>
    </v-flex>

    <template v-if="attributesArr.length">
      <v-flex
        class="product-sidebar-box"
        v-for="(group, index) in attributesArr"
        :key="index"
      >
        <v-card-title v-text="group.Name"></v-card-title>
        <v-divider></v-divider>

        <v-flex class="product-sidebar-in">
          <v-checkbox
            v-for="attibute in group.Attributes"
            :key="attibute.Id"
            hide-details
            color="brand"
            :ripple="false"
            dense
            v-model="filterObj.Attributes"
            :label="attibute.Name"
            :value="attibute.Id"
          ></v-checkbox>
        </v-flex>
      </v-flex>
    </template>
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import { SlugifyMixin } from "@/mixins/SlugifyMixin";
export default {
  name: "ProductSidebar",
  mixins: [SlugifyMixin],
  data() {
    return {
      loding: false,
      selectedParentCategoryId: 0,
      childCategories: [],
      filterObj: {
        ItemGroup: [],
        Category: [],
        SaleType: [],
        Condition: [],
        StockType: [],
        Attributes: [],
      },
      keyword: "",
      min: 0,
      max: 0,
      panel: [0], //panel for opening category Dropdown
      filters: [
        {
          id: 1,
          title: "Nilam",
          key: "SaleType",
          value: "Auction",
        },
        {
          id: 2,
          title: "Buy Now",
          key: "SaleType",
          value: "Gallery",
        },
        {
          id: 3,
          title: "Bargain",
          key: "SaleType",
          value: "Bargain",
        },
      ],
      conditions: [
        { id: 1, title: "New", key: "Condition", value: "New" },
        { id: 2, title: "Re-Condition", key: "Condition", value: "ReNew" },
        { id: 3, title: "Used", key: "Condition", value: "Used" },
      ],
      TypeArr: [
        { value: "1", text: "Featured Nilam" },
        { value: "2", text: "Featured Product" },
        { value: "8", text: "Featured Bargain" },
        { value: "3", text: "Nilam Closing Soon" },
        { value: "4", text: "Tranding Product" },
      ],
      attributesArr: [],
      childCategoryLoader: false,
    };
  },
  computed: {
    clonedFilterObj: function () {
      return Object.assign({}, this.filterObj);
    },
    itemGroups: function () {
      return this.$store.state.itemGroups;
    },
    allParentCategories: function () {
      return this.$store.state.allParentCategories;
    },
  },
  mounted() {
    //when user reload/refresh browser
    this.queryObjToFilterObj(this.$route.query);
    if (this.$route.query.ParentCategoryId) {
      this.loadAllParentCategories();
      this.getChildCategories(this.$route.query.ParentCategoryId);
      this.getCategoryWiseAttributes(this.$route.query.ParentCategoryId);
    }
  },
  methods: {
    loadAllParentCategories() {
      if (this.allParentCategories && this.allParentCategories.length) {
        return true;
      }
      this.$store.dispatch("getAllParentCategories");
    },
    parentCategoryChanged(selectedParentCategoryId) {
      let catObj = this._.find(this.allParentCategories, {
        Id: selectedParentCategoryId,
      });

      let queryObj = this.$route.query;
      queryObj = {
        ...queryObj,
        ...{ ParentCategoryId: selectedParentCategoryId },
      };
      return this.$router.push({
        name: "ProductSearch",
        params: { slug: this.slugify(catObj?.Name) },
        query: queryObj,
      });
    },
    getCategoryWiseAttributes(CategoryId) {
      if (!CategoryId) {
        this.attributesArr = [];
        return;
      }

      this.attributesArr = [];

      return this.$http
        .post(
          this.$store.state.Configs.getCategoryWiseAttribute
            .replace(":categoryId", CategoryId)
            .replace(":itemId", 0)
        )
        .then((res) => {
          this.attributesArr = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getChildCategories(ParentId) {
      if (!ParentId) {
        this.childCategories = [];
        return;
      }

      this.childCategoryLoader = true;

      this.childCategories = [];

      return this.$http
        .post(
          this.$store.state.Configs.categoryGetallById.replace(
            ":ParentId",
            ParentId
          )
        )
        .then((res) => {
          let fullData = res.data;

          this.childCategories = fullData.Data;
          this.childCategoryLoader = false;

          delete fullData.Data;
          return fullData;
        })
        .then((res) => {
          let MetaDescription = null;
          if (res.MetaDescription) {
            MetaDescription = res.MetaDescription.replace(/\s+/g, " ").trim();
          }

          let MetaKeyword = null;

          if (res.MetaKeyword) {
            MetaKeyword = res.MetaKeyword.replace(/\s+/g, " ").trim();
          }

          this.$store.commit("update_meta", {
            PageTitle: res.MetaTitle,
            MetaKeywords: MetaKeyword,
            MetaDescription: MetaDescription,
            SocialLogoPath: res.FilePath,
          });

          if (res.Description) {
            this.$store.commit(
              "setSeoFooterDescription",
              res.Description.replace(/\s+/g, " ").trim()
            );
          } else {
            this.$store.commit("setSeoFooterDescription", "");
          }
        })
        .catch((err) => {
          console.log(err);
          this.childCategoryLoader = false;
        });
    },
    typeChanged(type) {
      //for type value int error
      if (!type) {
        delete this.filterObj.Type;
      }
    },
    keywordTyping: debounce(function (val) {
      if (!val) {
        delete this.filterObj.Keyword;
      } else {
        this.filterObj.Keyword = val;
      }
      this.filterObj = { ...this.filterObj };
    }, 600),
    minMaxChanged() {
      let min = Number(this.min);
      let max = Number(this.max);

      if (min || max) {
        if (min > max) {
          this.max = min;
          this.min = max;
        }
        this.filterObj = {
          ...this.filterObj,
          Min: this.min,
          Max: this.max,
        };
      } else {
        delete this.filterObj.Min;
        delete this.filterObj.Max;
        this.filterObj = { ...this.filterObj };
      }
    },
    queryObjToFilterObj(obj) {
      //console.log(obj);
      //set initial route quyery object to filter obj
      let {
        Category,
        SaleType,
        Condition,
        StockType,
        ItemGroup,
        Attributes,
        Min,
        Max,
        ...other
      } = obj;

      let minMaxObj = {};
      if (Max) {
        minMaxObj = {
          Min: Number(Min),
          Max: Number(Max),
        };
        this.min = Number(Min);
        this.max = Number(Max);
      }

      if (obj.Keyword) {
        this.keyword = obj.Keyword;
      }

      this.selectedParentCategoryId = Number(obj.ParentCategoryId) || 0;

      Category = this._.castArray(Category || []);
      Category = Category.map((i) => Number(i)); //string value to int

      SaleType = this._.castArray(SaleType || []);
      Condition = this._.castArray(Condition || []);
      StockType = this._.castArray(StockType || []);
      ItemGroup = this._.castArray(ItemGroup || []);

      Attributes = this._.castArray(Attributes || []);
      Attributes = Attributes.map((i) => Number(i)); //string value to int

      this.filterObj = {
        Category,
        SaleType,
        Condition,
        StockType,
        ItemGroup,
        Attributes,
        ...minMaxObj,
        ...other,
      };
    },
    handleFilterObjectChanged(newVal) {
      let { ItemGroup } = newVal;
      ItemGroup = this._.castArray(ItemGroup || []);
      let obj = { ...newVal, ...{ ItemGroup: ItemGroup } };
      this.Fire.$emit("allFilterChanged", obj);
    },
    clearAll() {
      this.keyword = "";
      this.selectedParentCategoryId = 0;
      this.attributesArr = [];
      this.childCategories = [];

      this.filterObj = {
        Category: [],
        SaleType: [],
        Condition: [],
        StockType: [],
        ItemGroup: [],
        Attributes: [],
      };
      this.Fire.$emit("clearFilter", this.filterObj);
    },
  },
  watch: {
    $route(to) {
      if (!to.query.ParentCategoryId) {
        this.$store.commit("setSeoFooterDescription", "");
      }
      //for only category changed
      this.queryObjToFilterObj(to.query);
    },
    clonedFilterObj: {
      handler(newVal, oldVal) {
        if (!this._.isEqual(newVal, oldVal)) {
          this.handleFilterObjectChanged(newVal);
        }
      },
      deep: true,
    },
    "$route.query.ParentCategoryId"(newVal) {
      this.getChildCategories(newVal);
      this.getCategoryWiseAttributes(newVal);
    },
  },
  beforeDestroy() {
    this.$store.commit("setSeoFooterDescription", "");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/product_sidebar.scss";
</style>
<style>
.v-list-item__content {
  width: 120px !important;
  min-width: 50px !important;
}
</style>
