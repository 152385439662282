var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{staticClass:"site-content-wrap site_bg"},[_c('v-flex',{staticClass:"create-ad-wrap mx-auto"},[_c('v-container',{staticClass:"py-0"},[_c('v-card',{attrs:{"tile":"","elevation":"1"}},[_c('v-flex',{staticClass:"create-ad"},[_c('v-flex',{staticClass:"create-ad-top",style:({
              background:
                'url(' +
                require('@/assets/images/create-post.jpg') +
                ') center center',
            })},[_c('h2',{domProps:{"textContent":_vm._s(_vm.$t('Post your item here'))}}),(
                _vm.balanceObj &&
                Object.keys(_vm.balanceObj).length &&
                _vm.siteSettings.IsUsePoint
              )?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-flex',{staticClass:"note"},[_vm._v(" "+_vm._s(_vm.$t("*N.B: Required point to create a post:"))+" "),_c('span',{domProps:{"textContent":_vm._s(_vm.applicationPolicy.RequiredPointOnPostProduct)}},[_vm._v("0")]),_c('v-tooltip',{attrs:{"right":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"18"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,3945099303)},[_c('span',{staticClass:"tooltip-info",domProps:{"textContent":_vm._s(
                        _vm.$t(
                          'This is the number of point required to submit a post for this ad.'
                        )
                      )}})])],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-flex',{staticClass:"available-point flex-grow-0"},[_c('span',{staticClass:"allowance"},[_vm._v(" "+_vm._s(_vm.$t("Available points :"))+" "),_c('strong',{domProps:{"textContent":_vm._s(_vm.balanceObj.AvailablePoint)}},[_vm._v("0")])]),_c('router-link',{staticClass:"buy-points",attrs:{"to":{ name: 'PostingSummary' }},domProps:{"textContent":_vm._s(_vm.$t('Buy points'))}})],1)],1)],1):_vm._e()],1),_c('v-flex',{staticClass:"pick-category"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('Selected'))}}),_c('v-flex',{staticClass:"d-flex"},[_c('h3',{domProps:{"textContent":_vm._s(_vm.$t('Categories') + ':')}}),_c('ul',{staticClass:"d-flex flex-wrap"},[_c('li',{domProps:{"textContent":_vm._s(_vm.selectedObj.CategoryName)}})])])],1),(_vm.allCategories && _vm.allCategories.length)?[_c('div',{staticClass:"input-wrap mb-5"},[_c('v-text-field',{attrs:{"label":_vm.$t('Search Category'),"solo":"","flat":"","outlined":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-flex',{staticClass:"cat-select-wrap"},[_c('v-treeview',{attrs:{"activatable":"","dense":"","color":"brand","items":_vm.allCategories,"search":_vm.search,"filter":_vm.filter,"open":_vm.open,"active":_vm.active,"item-children":"Children","item-text":"Name","item-key":"Id","return-object":true,"open-on-click":""},on:{"update:open":function($event){_vm.open=$event},"update:active":[function($event){_vm.active=$event},_vm.onUpdate]},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(item.children)?_c('v-icon',{domProps:{"textContent":_vm._s(
                      `mdi-${
                        item.id === 1 ? 'home-variant' : 'folder-network'
                      }`
                    )}}):_vm._e()]}}],null,false,1935734101)})],1)]:_vm._e(),_c('v-layout',{staticClass:"continue justify-end pt-4"},[_c('v-btn',{staticClass:"brand white--text text-none",attrs:{"to":{ name: 'postProductStep2', params: { id: _vm.routeParamId } },"tile":"","depressed":""}},[_vm._v(_vm._s(_vm.$t("Continue")))])],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }