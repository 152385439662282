<template>
  <v-flex
    class="login-register-bg"
    :style="{
      background:
        'url(' + require('@/assets/images/login-bg.png') + ') left top',
    }"
  >
    <v-card
      tile
      elevation="3"
      class="signin-signup-wrap mx-auto"
      :style="{
        background:
          '#fff url(' +
          require('@/assets/images/login-bar.png') +
          ') left top repeat-x',
      }"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        height="3"
        color="light"
        aria-label="loading status"
      ></v-progress-linear>
      <v-flex class="forgot-image d-flex justify-center">
        <v-img
          max-width="200"
          :src="require('@/assets/images/forgot-password.png')"
        ></v-img>
      </v-flex>

      <v-flex class="forgot-text">
        <h3>{{ $t("Forgot Password") }}</h3>
        <p>
          {{
            $t(
              "Please enter your registered email or mobile number to retrieve your password"
            )
          }}
        </p>
      </v-flex>

      <ValidationObserver
        class="signin-signup-form forgot-form"
        tag="div"
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            tag="div"
            name="phone number or email"
            rules="required"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-flex class="input-label">{{ $t("Email or Phone") }}</v-flex>
              <v-text-field
                solo
                outlined
                flat
                type="text"
                autocomplete="off"
                hide-details
                :label="$t('Please enter your phone number or email')"
                v-model="formData.email_or_phone"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <v-btn
            width="100%"
            type="submit"
            depressed
            :disabled="loading"
            :loading="loading"
            class="signin-signup-btn brand white--text text-none mt-md-8"
            >{{ $t("Send") }}</v-btn
          >
        </form>
      </ValidationObserver>
      <v-flex class="account-text grey--text text-center">
        <v-icon>mdi-trending-neutral mdi-rotate-180</v-icon>
        <router-link
          :to="{ name: 'Login' }"
          v-text="$t('Back To Sign In')"
        ></router-link>
      </v-flex>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "ForgotPassword",

  data() {
    return {
      loading: false,
      formData: {
        email_or_phone: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.loading) return;

      this.$refs.form.validate().then(() => {
        this.loading = true;

        this.$http({
          method: "post",
          url: this.$store.state.Configs.sendOtp,
          data: { username: this.formData.email_or_phone },
        })
          .then((res) => {
            this.loading = false;

            this.$store.commit("set_flash", {
              type: res.data.ResponseType,
              message: res.data.ResponseMessage,
            });

            this.$router
              .push({
                name: "CreateNewPassword",
                params: { username: this.formData.email_or_phone },
              })
              .catch((err) => console.log(err));

            this.formData.email_or_phone = "";
            this.$nextTick(() => {
              this.$refs.form.reset();
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
            this.$vuetify.goTo(0);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/login_register.scss";
</style>
