<template>
  <v-flex class="product-details-summery">
    <auction-instruction
      v-if="isLoggedIn"
      :product-obj="product"
    ></auction-instruction>

    <v-flex class="product-details-box">
      <small class="category" v-text="product.CategoryName"> </small>
      <h1 v-text="product.ItemName"></h1>
    </v-flex>

    <ul>
      <li>
        {{ $t("Condition") }}:
        <span v-text="product.Condition"></span>
      </li>
      <li>
        {{ $t("Stock Type") }}:
        <span v-text="product.StockType"></span>
      </li>
      <li class="availablity">
        {{ $t("Available Quantity") }}:
        <span
          v-text="product.StockQuantity + ' ' + product.MeasurementName"
        ></span>
        <img
          v-if="product.StockQuantity <= 0"
          class="sold-out"
          :src="require('@/assets/images/sold-out.png')"
          alt="Products"
        />
      </li>
    </ul>
    <v-flex class="more-info">
      <a
        @click="gotTo('more-info')"
        href="javascript:void(0)"
        v-text="$t('View More Info')"
      ></a>
    </v-flex>

    <v-flex class="time-holder">
      <template v-if="product.AuctionStatus == 'Pending'">
        {{ $t("Your item is waiting for admin approval.") }}
      </template>
      <countdown
        v-else-if="
          product.AuctionStatus == 'Bidding' ||
          product.AuctionStatus == 'Published'
        "
        class="d-flex"
        :time="$root.getTime(product)"
        :transform="$root.transform"
        :emit-events="true"
        @end="countdownEnded"
      >
        <template slot-scope="props">
          <v-flex
            class="time-left flex-grow-0"
            :class="$root.countDown.Color[product.AuctionStatus]"
          >
            <div class="time-left-in">
              {{ $root.countDown.Text[product.AuctionStatus] }}: &nbsp;
            </div>
          </v-flex>
          <v-flex class="flex-grow-0">
            <span v-html="props.days"></span>
            <span v-html="props.hours"></span>
            <span v-html="props.minutes"></span>
            <span v-html="props.seconds"></span>
          </v-flex>
        </template>
      </countdown>
      <template class="time-holder" v-else>
        <v-alert v-if="product.AuctionStatus == 'Bidding'" text type="success">
          <span v-text="$t('কয়েক সেকেন্ড পর নিলাম শুরু হবে')"></span>
        </v-alert>
        <v-alert v-else text type="error">
          <span v-text="$t('Nilam is closed.')"></span
        ></v-alert>
      </template>
    </v-flex>

    <ul>
      <li>
        {{ $t("Active Bidders") }}:
        <span class="d-inline-block mr-2" v-text="product.BidderCount"></span>

        {{ $t("Total Bid") }}:
        <span v-text="product.BidCount"></span>

        <router-link
          :to="{
            name: 'biddingDetails',
            params: { id: product.Id, slug: product.Slug },
          }"
          class="all-bidders ml-3"
        >
          Bidding Details
        </router-link>
      </li>

      <li v-if="product.BidCount > 0">
        {{ $t("Current Bid") }}:
        <span
          class="current-Bid"
          v-text="$root.showAmount(product.CurrentBid)"
        ></span>
        &nbsp;&nbsp;
        <template v-if="product.UserLastBid">
          {{ $t("Your Bid") }}:
          <span v-text="$root.showAmount(product.UserLastBid)"></span>
        </template>
      </li>
      <li v-if="product.BidCount == 0">
        {{ $t("Starting Bid") }}:
        <span
          class="current-Bid"
          v-text="$root.showAmount(product.BasePrice)"
        ></span>
      </li>

      <li>
        ({{ $t("Reserve Price") }}:
        <span v-text="$root.showAmount(product.ApproximatePrice)"></span>)
        <v-tooltip right color="success" max-width="400" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
          </template>
          <span class="tooltip-info" v-text="note.reservePrice.text"></span>
        </v-tooltip>
      </li>
      <li v-text="$t('You will review this before it\'s final')"></li>
    </ul>
    <v-flex class="place-bid-wrap" v-if="isBiddingEnable">
      <v-flex
        class="bid-label"
        v-text="$t('Enter your available bid (It\’s Free)')"
      ></v-flex>

      <!-- bidding form here -->
      <bidding-form v-bind:id="product.AuctionId"></bidding-form>

      <v-flex class="automatic-bid">
        <a
          @click="$refs.automaticBidDialog.modalOpen()"
          href="javascript:void(0);"
          v-text="$t('Place an automatic bid')"
        ></a>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
          </template>
          <span
            class="tooltip-info"
            v-text="$t('Place your automatic bid')"
          ></span>
        </v-tooltip>
        <br />

        <auto-matic-bid
          v-if="product && Object.keys(product).length"
          :auction-id="product.AuctionId"
          :auto-matic-bid-obj="product.AutomaticBid"
          ref="automaticBidDialog"
        ></auto-matic-bid>
      </v-flex>
    </v-flex>

    <v-flex class="pt-3" v-if="isBuyNowEnable">
      {{ $t("Buy Now Price") }}:
      <span class="info-text">
        <strong>{{ $root.showAmount(product.BuyNowPrice) }}</strong>
        /{{ product.MeasurementName }}
        <buy-now-btn :product="product" :is-normal="true"></buy-now-btn>
        <br />
        {{ $t("You don’t need to attend on bidding, just buy this") }}
        <span
          v-if="product.StockType == 'Lot'"
          v-text="product.StockType"
        ></span>
        <span v-else v-text="$t('Product')"></span>
      </span>
    </v-flex>

    <v-layout class="share-product align-center">
      <span>{{ $t("Share") }}:</span>
      <!-- Product-Social -->
      <product-social></product-social>
    </v-layout>
  </v-flex>
</template>

<script>
import ProductSocial from "@/components/sites/pages/ProductSocial.vue";
import BiddingForm from "@/components/sites/pages/BiddingForm.vue";
import AutoMaticBid from "@/components/sites/common/AutoMaticBid.vue";
import BuyNowBtn from "@/components/sites/common/BuyNowBtn.vue";
import AuctionInstruction from "@/components/sites/common/AuctionInstruction.vue";
export default {
  name: "ProductDetailsSummeryAuction",
  components: {
    ProductSocial,
    BiddingForm,
    AutoMaticBid,
    BuyNowBtn,
    AuctionInstruction,
  },
  data() {
    return {
      getbidItem: [],
      dialog: false,
      formData: {
        maximum_price: "",
        auto_price: "",
      },
      metaObj: {},
    };
  },
  props: {
    product: Object,
  },
  computed: {
    totalBid() {
      var totalBidCounter = this.getbidItem.length;
      return totalBidCounter;
    },
    isOwner() {
      if (
        this.product.Documents[0] &&
        this.product.Documents[0].UserId == this.$auth?.user?.UserId
      ) {
        return true;
      }
      return false;
    },
    isBiddingEnable() {
      if (this.product.AuctionStatus != "Bidding") {
        return false;
      }

      if (this.isOwner) {
        return false;
      }

      return true;
    },
    isBuyNowEnable() {
      if (this.product.CurrentBid >= this.product.BuyNowPrice) {
        return false;
      }

      if (
        this.product.AuctionStatus != "Published" &&
        this.product.AuctionStatus != "Bidding"
      ) {
        return false;
      }

      if (this.isOwner) {
        return false;
      }

      return true;
    },
    note() {
      return this.$store.state.Configs.priceNote;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    // Listen to score changes coming from SignalR events
    this.$notificationHub.$on("broadcast", this.getBroadcast);
  },
  mounted() {
    this.Fire.$on("BidInserted", () => {
      this.getbidItem = JSON.parse(localStorage.getItem("auction-bids"));
    });

    this.Fire.$on("auto-matic-bid-deleted", () => {
      this.product.AutomaticBid = {};
    });

    this.Fire.$on("auto-matic-bid-updated", (obj) => {
      this.product.AutomaticBid = obj;
    });
  },
  methods: {
    countdownEnded() {
      if (this.product.AuctionStatus == "Published") {
        this.product.AuctionStatus = "Bidding";
      } else {
        this.product.AuctionStatus = "Closed";
      }
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.formData.maximum_price = this.formData.auto_price = "";

        this.dialog = false;

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
    },
    getBroadcast({ type, obj }) {
      if (type != "Bid" || obj.ItemId != this.$route.params.id) return;
      this.product.CurrentBid = obj.BidAmount;
      this.product.BidderCount = obj.BidderCount;
      this.product.BidCount = obj.BidCount;

      if (this.$auth?.user?.UserId == obj.UserId) {
        this.product.UserLastBid = obj.BidAmount;
      }
    },
    gotTo(selector) {
      this.$vuetify.goTo("#" + selector, {
        duration: 1000,
        offset: this.$root.$refs.stickyheader.$el.clientHeight,
        easing: "easeInOutCubic",
      });
    },
  },
  beforeDestroy() {
    this.Fire.$off("broadcast");
    this.Fire.$off("BidInserted");
    this.Fire.$off("auto-matic-bid-updated");
    this.Fire.$off("auto-matic-bid-deleted");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/product_details_summery_auction.scss";
</style>
