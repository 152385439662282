var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user.UserId == _vm.productObj.UserId)?_c('span',{staticClass:"owner-block"},[(
        _vm.productObj.AuctionStatus == 'Closed' &&
        _vm.productObj.CurrentBid < _vm.productObj.ApproximatePrice
      )?_c('v-alert',{attrs:{"color":"green","dark":""}},[_vm._v(" This to inform you that the nilam is now closed but the highest bid amount is lower than the reserve price. If you think that you can sell your item at this price, please send an "),_c('a',{staticClass:"text-decoration-underline font-weight-bold",on:{"click":function($event){$event.preventDefault();return _vm.orderRequest.apply(null, arguments)}}},[_vm._v("order request")]),_vm._v(" to the max bidder or you can "),_c('a',{staticClass:"text-decoration-underline font-weight-bold",on:{"click":function($event){$event.preventDefault();return _vm.reNilamRequest.apply(null, arguments)}}},[_vm._v("re-nilam")]),_vm._v(" your item. ")]):(
        _vm.productObj.AuctionStatus == 'Closed' && !_vm.productObj.BidderCount
      )?_c('v-alert',{staticClass:"auction-closed no-bidder-found",attrs:{"color":"green","dark":""}},[_vm._v(" This to inform you that the nilam is now closed but no bidder bid on your nilam. Please "),_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){$event.preventDefault();return _vm.reNilamRequest.apply(null, arguments)}}},[_vm._v("re-nilam")]),_vm._v(" your item. ")]):(_vm.productObj.AuctionStatus == 'ShortListed')?_c('v-alert',{staticClass:"auction-shortListed owner",attrs:{"color":"green","dark":""}},[_vm._v(" The order link has been sent to the winner. The winner will be able to place an order within "),_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(
          _vm.$root.formateServerDateTime(_vm.productObj.OrderConfirmationLastTime)
        )}})]):(_vm.productObj.AuctionStatus == 'Ordered')?_c('v-alert',{staticClass:"auction-ordered owner",attrs:{"color":"green","dark":""}},[_vm._v(" Your item is sold. To see your "),_c('router-link',{staticClass:"text-decoration-underline font-weight-bold",attrs:{"to":{ name: 'MyOrderDetails', params: { id: _vm.productObj.OrderId } }}},[_vm._v(" order details ")])],1):_vm._e()],1):(_vm.user.UserId == _vm.productObj.MaxBidderId)?_c('span',[(
        _vm.productObj.AuctionStatus == 'Closed' &&
        _vm.productObj.CurrentBid < _vm.productObj.ApproximatePrice
      )?_c('v-alert',{staticClass:"auction-closed lowest-current-bid",attrs:{"color":"green","dark":""}},[_vm._v(" Thank you for bidding, the nilam is now closed. Although you’re the highest bidder, your bid amount is lower than the reserved price. This nilam may be canceled. Please wait for the seller's decision. ")]):(_vm.productObj.AuctionStatus == 'ShortListed')?_c('v-alert',{staticClass:"auction-shortListed winner",attrs:{"color":"green","dark":""}},[_vm._v(" Congratulations!! you are the winner of this nilam. Please place an order before "),_c('span',{staticClass:"text-bold",domProps:{"textContent":_vm._s(
          _vm.$root.formateServerDateTime(_vm.productObj.OrderConfirmationLastTime)
        )}}),_vm._v(". "),_c('buy-now-btn',{staticClass:"white--text mr-2",attrs:{"product":_vm.productObj,"is-normal":true}},[_vm._v("Confirm Your Order")])],1):(_vm.productObj.AuctionStatus == 'Ordered')?_c('v-alert',{staticClass:"auction-ordered winner",attrs:{"color":"green","dark":""}},[_vm._v(" You have orderd this item. To see your "),_c('router-link',{staticClass:"text-decoration-underline font-weight-bold",attrs:{"to":{ name: 'MyOrderDetails', params: { id: _vm.productObj.OrderId } }}},[_vm._v(" order details ")])],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }