<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <shop-banner
        v-if="!bannerLoading && Object.keys(bannerInfo).length"
        :banner-obj="bannerInfo"
      ></shop-banner>
      <v-flex wrap class="d-flex justify-space-between">
        <!-- sidebar sections -->
        <product-sidebar
          v-if="$root.windowSize >= 1024"
          ref="productSidebar"
        ></product-sidebar>
        <v-flex v-else>
          <v-scroll-x-reverse-transition>
            <v-btn
              tile
              depressed
              width="40"
              min-width="20"
              class="drawer-toggle d-lg-none"
              v-if="drawer"
              @click.stop="drawer = !drawer"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-navigation-drawer v-model="drawer" temporary app>
            <product-sidebar ref="productSidebar"></product-sidebar>
          </v-navigation-drawer>
        </v-flex>

        <v-card tile class="portlet">
          <!-- shipping option -->
          <v-layout class="shipping-option d-none">
            <v-radio-group v-model="filterObj.ShippedBy" row hide-details>
              <v-radio
                v-for="obj in ShippedByArr"
                :key="obj.key"
                hide-details
                color="brand"
                :label="obj.title"
                :value="obj.key"
              ></v-radio>
            </v-radio-group>
          </v-layout>

          <v-skeleton-loader
            class="mx-auto pl-5 pr-5"
            type="article,article,article,article"
            v-if="loading"
          ></v-skeleton-loader>
          <template v-else>
            <template
              v-if="Object.keys(productsObj).length && productsObj.data.length"
            >
              <!-- Sort by sections -->
              <v-layout
                class="product-top-filter d-flex align-center flex-grow-0 flex-wrap justify-space-between"
              >
                <!-- <site-breadcrumb :options="breadcrumbItems"></site-breadcrumb> -->
                <!-- sort by -->
                <v-flex class="short-by d-flex flex-wrap flex-grow-0">
                  <v-btn
                    height="30"
                    tile
                    depressed
                    class="filter-by-btn text-none px-2"
                    @click.stop="showhodefilter = !showhodefilter"
                  >
                    {{ $t("Best match") }}
                    <v-icon size="15" class="pl-1">mdi-arrow-down</v-icon>
                  </v-btn>
                  <v-flex v-show="showhodefilter" class="short-by-in flex-wrap">
                    <v-sheet class="pr-3 pb-2">
                      <v-flex
                        class="d-flex flex-wrap flex-grow-0 align-center sort-field-box"
                      >
                        <strong v-text="$t('Show')"></strong>
                        <v-flex class="sort-field show">
                          <v-select
                            v-model="filterObj.Length"
                            hide-details
                            :items="showArr"
                            dense
                            solo
                            flat
                            outlined
                            label="Please Select Length"
                          ></v-select>
                        </v-flex>
                      </v-flex>
                    </v-sheet>

                    <v-sheet class="pb-2">
                      <v-flex
                        class="d-flex flex-grow-0 align-center sort-field-box"
                      >
                        <strong v-text="$t('Sort By')"></strong>
                        <v-flex class="sort-field">
                          <v-select
                            v-model="filterObj.SortBy"
                            hide-details
                            :items="SortArr"
                            dense
                            solo
                            flat
                            outlined
                            label="Please Select"
                          ></v-select>
                        </v-flex>
                      </v-flex>
                    </v-sheet>
                  </v-flex>

                  <v-flex class="grid-list">
                    <v-flex
                      class="d-flex flex-wrap justify-space-between align-center"
                    >
                      <v-flex class="view-indicator flex-grow-0">
                        <!-- <template v-if="$root.windowSize > 698">
                          <a
                            :class="{ active: listView }"
                            @click="listView = true"
                            href="javascript:;"
                          >
                            <v-icon>mdi-format-list-bulleted</v-icon>
                          </a>
                          <a
                            :class="{ active: !listView }"
                            @click="listView = false"
                            href="javascript:;"
                          >
                            <v-icon>mdi-view-grid-outline</v-icon>
                          </a>
                        </template>-->
                        <v-btn
                          height="30"
                          tile
                          depressed
                          dark
                          color="primary"
                          class="filter-btn pl-0 pr-3"
                          @click.stop="drawer = !drawer"
                        >
                          <v-icon size="18">mdi-filter-outline</v-icon>
                          {{ $t("Filter") }}
                        </v-btn>
                      </v-flex>
                    </v-flex>
                  </v-flex>
                </v-flex>

                <v-sheet class="showing-summery">
                  <span v-html="getProducsSummary(productsObj)"></span>
                </v-sheet>
              </v-layout>

              <!-- Products sections -->
              <v-flex class="products-wraper">
                <v-row>
                  <v-col
                    class="product-items"
                    :class="{ product_list_items: listView }"
                    md="3"
                    sm="4"
                    v-for="product in productsObj.data"
                    :key="product.id"
                  >
                    <product-grid-card
                      v-if="!listView"
                      :product="product"
                    ></product-grid-card>

                    <product-list-card
                      v-if="listView"
                      :product="product"
                    ></product-list-card>
                  </v-col>

                  <!-- Post your add section -->
                  <v-col
                    v-if="productsObj.postAd && !listView"
                    class="product-items"
                    lg="4"
                    sm="4"
                  >
                    <v-sheet height="100%" class="product-box">
                      <v-card
                        height="100%"
                        elevation="5"
                        :to="{ name: 'AboutUs' }"
                      >
                        <v-sheet
                          height="100%"
                          class="d-flex align-end"
                          :style="{
                            'background-image':
                              'url(' +
                              require('@/assets/images/sell-banner.png') +
                              ')',
                            'background-size': '100% 100%',
                          }"
                        >
                          <v-flex class="sell-btn-wrap">
                            <v-btn
                              dark
                              color="brand"
                              depressed
                              tile
                              class="sell-btn text-none"
                            >
                              <v-icon>mdi-plus-thick</v-icon>
                              {{ $t("Add Product") }}
                            </v-btn>
                          </v-flex>
                        </v-sheet>
                      </v-card>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-flex>

              <!-- Products Pagination sections -->
              <v-flex
                class="product-pagination"
                v-if="productsObj.TotalPage > 1"
              >
                <pagination
                  :page-no="filterObj.Page"
                  :length="productsObj.TotalPage"
                ></pagination>
              </v-flex>
            </template>
            <!-- Data not found -->
            <data-not-found v-else class="mt-10">
              <h1 v-text="$t('No Product Found')"></h1>
              <h4>
                <a
                  @click="$refs.productSidebar.clearAll()"
                  v-text="$t('Click here to reset the filter')"
                ></a>
              </h4>
            </data-not-found>
          </template>
        </v-card>
      </v-flex>
    </v-container>
  </v-flex>
</template>

<script>
import ProductSidebar from "@/components/sites/pages/ProductSidebar";
import ShopBanner from "@/components/sites/pages/ShopBanner";
import ProductGridCard from "@/components/sites/common/ProductGridCard";
import ProductListCard from "@/components/sites/common/ProductListCard";
import Pagination from "@/components/services/Pagination";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "Products",
  components: {
    ProductSidebar,
    ShopBanner,
    ProductGridCard,
    ProductListCard,
    Pagination,
    DataNotFound,
  },

  data() {
    return {
      drawer: false,
      loading: false,
      showhodefilter: true,
      bannerLoading: false,
      bannerInfo: {},
      productsObj: {},
      filterObj: {
        level: "",
        //SortBy: "",
        Page: 1,
        Length: 24,
      },
      listView: false,
      SortArr: [
        { value: "NameAsc", text: "Name (A - Z)" },
        { value: "NameDesc", text: "Name (Z - A)" },
        { value: "PriorityDesc", text: "Priority (High - Low)" },
        { value: "PriorityAsc", text: "Priority (Low - High)" },
        { value: "PriceAsc", text: "Price (Low - High)" },
        { value: "PriceDesc", text: "Price (High - Low)" },
        { value: "OrderAsc", text: "Order (Low - High)" },
        { value: "OrderDesc", text: "Order (High - Low)" },
        { value: "DateAsc", text: "Oldest First" },
        { value: "DateDesc", text: "Newest First" },
      ],
      showArr: [
        { value: 12, text: "12 Items" },
        { value: 24, text: "24 Items" },
        { value: 36, text: "36 Items" },
        { value: 48, text: "48 Items" },
      ],
      ShippedByArr: [
        { key: "Both,Nilam,Self", title: "Shipped by Nilam / Seller" },
        { key: "Nilam", title: "Shipped by Nilam" },
        { key: "Self", title: "Shipped by Seller" },
      ],
      breadcrumbItems: [
        {
          text: "All Categories",
          disabled: false,
          href: "/",
        },
        {
          text: "Drop Shipping",
          disabled: true,
          href: "/",
        },
      ],
      cartLoading: false,
    };
  },
  computed: {
    clonedFilterObj: function () {
      return { ...this.filterObj };
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    carts() {
      return this.$store.state.carts;
    },
  },
  created() {
    this.Fire.$on("allFilterChanged", (val) => {
      this.queryObjToFilterObj(val);
    });

    this.Fire.$on("clearFilter", (val) => {
      let obj = {
        //SortBy: "",
        Page: 1,
        Length: 12,
      };
      this.bannerInfo = [];
      this.queryObjToFilterObj({ ...val, ...obj });
    });

    //if no parameter in query then watch will not run, so ..
    if (!Object.keys(this.$route.query).length) {
      this.getProducts({});
    } else {
      if (this.$route.query.BrandId) {
        this.getBrandsInfo(this.$route.query.BrandId);
      } else if (this.$route.query.OwnerId) {
        this.getCompanyInfo(this.$route.query.OwnerId);
      }
    }

    this.getOrResetCardIemsForLocalStorage(this.isLoggedIn);
  },
  beforeDestroy() {
    this.Fire.$off("allFilterChanged");
    this.Fire.$off("pageChanged");
    this.Fire.$off("clearFilter");
  },
  mounted() {
    this.Fire.$on("pageChanged", (val) => {
      this.filterObj.Page = parseInt(val);
    });
    if (this.$root.windowSize < 1024) {
      this.showhodefilter = false;
    }
  },
  methods: {
    getBannerInfos(url, type) {
      if (this.bannerLoading) {
        return;
      }

      this.bannerLoading = true;

      this.$http
        .post(url)
        .then((result) => {
          this.bannerInfo = { ...result.data, ...{ type: type } };

          this.$store.commit("update_meta", {
            PageTitle: this.bannerInfo?.FullName,
          });

          this.bannerLoading = false;
        })
        .then(() => {
          this.$vuetify.goTo("#app");
        })
        .catch((error) => {
          this.bannerLoading = false;
          this.bannerInfo = [];
          console.log(error);
        });
    },
    getBrandsInfo(BrandId) {
      return this.getBannerInfos(
        this.$store.state.Configs.getBrandInfos.replace(":brandId", BrandId),
        "brand"
      );
    },
    getCompanyInfo(OwnerId) {
      return this.getBannerInfos(
        this.$store.state.Configs.getCompanyInfos.replace(":ownerId", OwnerId),
        "company"
      );
    },
    queryObjToFilterObj(obj) {
      let { SortBy, Page, Length, ...other } = obj;
      Page = parseInt(Page) || 1;
      Length = parseInt(Length) || this.filterObj.Length;
      // if (!SortBy) {
      //   SortBy = this.filterObj.SortBy;
      // }
      this.filterObj = {
        ...other,
        SortBy,
        Page,
        Length,
      };
    },
    getProducsSummary(obj) {
      let len = (obj.Page - 1) * obj.Length;
      return `Showing <strong>
        ${len + 1} – ${len + obj.data.length}
        </strong> products out of
        <strong>${obj.recordsTotal}</strong> products`;
    },

    getProducts(Obj) {
      if (this.loading) return;

      this.loading = true;
      this.$http
        .post(this.$store.state.Configs.getProducts, { ...Obj })
        .then((result) => {
          this.productsObj = result.data;
          this.loading = false;
          //console.log(this.productsObj);
        })
        .then(() => {
          this.$vuetify.goTo("#app");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getOrResetCardIemsForLocalStorage(IsLoggedIn) {
      if (this.cartLoading) return;

      this.cartLoading = true;

      if (IsLoggedIn) {
        if (!this.carts.Ecommerce.length) {
          this.$store.dispatch("getEcommerceItems").then(() => {
            this.cartLoading = false;
          });
        }
      } else {
        this.$store.state.carts.Ecommerce = [];
        this.$store.state.carts.BuyNow = [];
        this.cartLoading = false;
      }
    },
  },
  watch: {
    $route(to) {
      if (!to.query.BrandId && !to.query.OwnerId) {
        this.bannerInfo = [];
      }
    },
    clonedFilterObj(newVal) {
      let resolved = this.$router.resolve({
        name: "ProductSearch",
        params: this.$route.params,
        query: newVal,
      });
      //changed filter object value to url as query without triggering route change event
      window.history.replaceState(
        "NilamPushState",
        "ProductSearch",
        resolved.href
      );
      this.getProducts(newVal);
    },
    "$root.windowSize": function (newVal) {
      if (newVal < 700) {
        this.listView = false;
      }
      if (newVal < 1024) {
        this.showhodefilter = false;
      }
    },
    isLoggedIn(newVal) {
      this.getOrResetCardIemsForLocalStorage(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/product.scss";
.sort-field-box {
  strong {
    @include font(primary, 15px, 25px, medium);
  }
  .sort-field {
    max-width: 180px;
    margin-left: rem-calc(8px);

    &.show {
      max-width: 100px;
    }

    &::v-deep .v-input {
      fieldset {
        border: 1px solid lighten(map-get($colors, grey), 35);
        border-radius: 0;
      }
      .v-input__slot {
        padding-right: 0 !important;
      }
      .v-select__selection,
      .v-label {
        @include font(primary, 14px, 18px, false);
      }
    }
  }
}
</style>
