<template>
  <!-- success greeting popup -->
  <v-dialog persistent v-model="dialog" width="914">
    <v-card>
      <v-card-title>
        <span class="headline">Pick up From</span>
      </v-card-title>
      <v-card-text>
        <v-flex class="address-list">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <v-radio-group v-model="pickupAddressId" hide-details>
                <v-simple-table
                  fixed-header
                  height="300px"
                  class="list-table ma-0"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th style="width: 35%">Address</th>
                        <th>Division</th>
                        <th>District</th>
                        <th>Thana</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(pickupaddress, index) in pickupAddressObj"
                        :key="index"
                      >
                        <td>
                          <v-radio
                            :label="pickupaddress.Title"
                            :value="pickupaddress.Id"
                          ></v-radio>
                        </td>
                        <td>
                          {{ pickupaddress.Address1 }}
                          <br />
                          <strong>Phone:</strong> {{ pickupaddress.Mobile }}
                        </td>
                        <td>{{ pickupaddress.DivisionName }}</td>
                        <td>{{ pickupaddress.DistrictName }}</td>
                        <td>{{ pickupaddress.ThanaName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-radio-group>

              <v-flex class="d-flex justify-end align-right">
                <v-btn
                  width="140"
                  height="38"
                  elevation="0"
                  class="primary white--text text-none mr-2"
                  text
                  @click="closeModal"
                  v-text="$t('Close')"
                ></v-btn>
                <v-btn
                  elevation="0"
                  width="140"
                  height="38"
                  type="submit"
                  color="brand"
                  class="green white--text text-none"
                  v-text="$t('Confirm')"
                ></v-btn>
              </v-flex>
            </form>
          </ValidationObserver>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PickupAddressList",
  data() {
    return {
      pickupAddressId: 0,
      dialog: false,
      pickupAddressObj: {},
    };
  },
  computed: {
    selectedPickupAddressId() {
      return this.$store.state.selectedPickupAddressId ?? 0;
    },
  },
  methods: {
    modalOpen(obj) {
      this.pickupAddressId = Number(this.selectedPickupAddressId);
      this.pickupAddressObj = obj;
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    onSubmit() {
      //this.$refs.form.reset();
      this.dialog = false;
      this.$store.commit("set_flash", {
        type: "Success",
        message: "Pickup Point Selected",
      });
      this.Fire.$emit("pickupAddressConfirmed", this.pickupAddressId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app/index.scss";
.address-list {
  &::v-deep table {
    border: 1px solid lighten(map-get($colors, grey), 35);

    tr {
      th {
        font-size: 14px !important;
      }
      td {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        @include font(primary, 15px, 22px, false);

        .v-label {
          @include font(primary, 15px, 22px, false);
        }
      }
    }
  }
}
</style>
