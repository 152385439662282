<template>
  <v-flex class="contact-us-wrap">
    <!-- inner banner -->
    <inner-banner :options="bannerContent"></inner-banner>

    <v-flex class="contact-panel">
      <v-container class="py-0">
        <v-row>
          <v-col
            lg="7"
            md="6"
            cols="12"
            v-if="Object.keys(siteSettings).length"
          >
            <v-flex class="contact-top" v-if="Object.keys(siteSettings).length">
              <h3 v-text="siteSettings.SiteTitle"></h3>
              <p v-text="siteSettings.SiteSummary"></p>
            </v-flex>
            <v-flex class="contact-left">
              <v-flex class="contact-left-box">
                <h4>Address</h4>
                <address>{{ siteSettings.Address.Address1 }}</address>
              </v-flex>

              <v-flex class="contact-left-box">
                <h4>Phone</h4>
                <a href>{{ siteSettings.HotLine }}</a>
                <br />
                <a href>{{ siteSettings.Mobile }}</a>
              </v-flex>

              <v-flex class="contact-left-box">
                <h4>Email</h4>
                <a href>{{ siteSettings.Email }}</a>
              </v-flex>
            </v-flex>
          </v-col>
          <v-col lg="5" md="6" cols="12">
            <v-flex
              class="contact-right"
              v-if="Object.keys(siteSettings).length"
            >
              <h2>Send your query</h2>
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <ValidationProvider
                    tag="div"
                    name="Name"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        prepend-inner-icon="mdi-account"
                        solo
                        flat
                        hide-details
                        outlined
                        label="Name"
                        v-model="formData.Name"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    name="Mobile"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        prepend-inner-icon="mdi-phone-in-talk"
                        solo
                        flat
                        hide-details
                        outlined
                        label="Phone"
                        v-model="formData.Mobile"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    name="Email"
                    rules="required|email"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        prepend-inner-icon="mdi-email"
                        solo
                        flat
                        hide-details
                        outlined
                        label="Email"
                        v-model="formData.Email"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    name="Subject"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        prepend-inner-icon="mdi-message-text"
                        solo
                        flat
                        hide-details
                        outlined
                        label="Subject"
                        v-model="formData.Subject"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    name="Address"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        prepend-inner-icon="mdi-map-marker"
                        solo
                        flat
                        hide-details
                        outlined
                        label="Address"
                        v-model="formData.Address"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    name="ad title"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-textarea
                        prepend-inner-icon="mdi-chat"
                        solo
                        rows="4"
                        flat
                        hide-details
                        outlined
                        label="Message"
                        v-model="formData.Message"
                      ></v-textarea>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <v-btn
                    type="submit"
                    rounded
                    depressed
                    class="contact-btn mt-1 brand white--text text-none"
                    >Submit</v-btn
                  >
                </form>
              </ValidationObserver>
            </v-flex>
          </v-col>
        </v-row>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import InnerBanner from "@/components/sites/common/InnerBanner";

export default {
  name: "Contact",
  components: {
    InnerBanner,
  },

  data() {
    return {
      formData: {},
      bannerContent: {
        title: "Contact",
        image: "contact-us.jpg",
      },
      inquirySubmitUrl: this.$store.state.Configs.addInquiry,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  methods: {
    inquirySubmit() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.inquirySubmitUrl, this.formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.inquirySubmit()
          .then((res) => {
            this.$nextTick(() => {
              this.formData = {};
              this.$refs.form.reset();
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/contact_us.scss";
</style>
