<template>
  <div class="multi-image-uploader">
    <div v-if="loading">Loading......</div>
    <h3 v-html="title || 'Pictures'"></h3>
    <vue-upload-multiple-image
      class="multi-image-uploader-main"
      @upload-success="uploadImageSuccess"
      @before-remove="beforeRemove"
      @edit-image="editImage"
      @mark-is-primary="markIsPrimary"
      :data-images="dataImages"
      :id-upload="id || 'myIdUpload'"
      :edit-upload="editId || 'myIdEdit'"
      :primary-text="options.primaryText || 'Default'"
      :show-edit="options.showEdit"
      :show-primary="options.showPrimary"
      :show-delete="options.showDelete"
      :mark-is-primary-text="options.markIsPrimaryText || 'Set as default'"
      :drag-text="options.dragText || 'Drag images (many)'"
      :browse-text="options.browseText || '(or) Select'"
      :drop-text="options.dropText || 'Drop your file here ...'"
      :popup-text="
        options.popupText || 'This image will be displayed as default'
      "
      :multiple="options.multiple"
      :max-image="options.maxImage || 10"
    ></vue-upload-multiple-image>

    <small
      v-if="imgConfig && Object.keys(imgConfig).length"
      v-html="
        'NB: Allowed image extensions: (<strong>' +
        imgConfig.Extension.join(', ') +
        '</strong>) , max size: <strong>' +
        imgConfig.Size +
        '</strong> KB & with resolution: <strong>' +
        imgConfig.Width +
        ' x ' +
        imgConfig.Height +
        '</strong> pixel'
      "
    ></small>    

    <v-snackbar 
      v-if="Object.keys(snackbarObj).length" 
      centered 
      v-model="snackbarObj.type" 
      :color="snackbarObj.type?snackbarObj.type.toLowerCase():''"
      timeout="4000"
    >
      <span v-text="snackbarObj.message"></span>

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarObj = {}"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  name: "MultiImageUploader",
  data() {
    return {
      loading: false,
      snackbarObj: {},
    };
  },
  components: {
    VueUploadMultipleImage,
  },
  props: {
    title: String,
    id: String,
    editId: String,
    url: String,
    deleteUrl: String,
    options: Object,
    dataImages: Array,
    imgConfig: Object,
  },
  methods: {
    //custom method
    xhrRequest(url, obj) {
      // if (this.loading) return;//Not applicable here for multiple uploading
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(url, obj)
          .then((res) => {
            resolve(res);
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            reject(err);
            this.loading = false;
          });
      });
    },
    //custom method
    uploadMedia(formData, index, fileList) {
      formData.append("ReferenceType", this.options.referenceType);
      formData.append("ReferenceId", this.options.referenceId);
      if (this.options.OtherReferenceId) {
        formData.append("OtherReferenceId", this.options.OtherReferenceId);
      }
      this.xhrRequest(this.$store.state.Configs.addMedia, formData)
        .then((res) => {
          if (res.data.ResponseType == "Success") {
            fileList[index].Id = res.data.ResponseData.Id;
            if (this.options.referenceType == "ProfileImage") {
              this.Fire.$emit("image-uploaded", res.data);
            }
            this.snackbarObj = {};
          } else {
            this.deleteImgIfError(index, res.data.ResponseMessage);
          }
        })
        .catch((error) => {
          //console.log(error.response);
          this.deleteImgIfError(index, error.response.data);
        });
    },
    uploadImageSuccess(formData, index, fileList) {
      this.uploadMedia(formData, index, fileList);
    },
    markIsPrimary(index, fileList) {
      //fileList re-ordering problem
      index = fileList.findIndex((obj) => {
        return obj.highlight == 1;
      });

      let Obj = fileList[index]; //fileList re-ordering problem
      this.xhrRequest(
        this.$store.state.Configs.markAsCover.replace(":id", Obj.Id)
      ).then((res) => {
         this.snackbarObj = {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          }
        console.log("done");
      });
    },
    beforeRemove(index, done, fileList) {
      let Obj = fileList[index];
      var r = confirm("Are you sure ?");
      if (r == true) {
        this.xhrRequest(
          this.$store.state.Configs.deleteMediaImage.replace(":id", Obj.Id)
        ).then((res) => {
          if (res.data.ResponseType == "Success") {
              done();
              this.snackbarObj = {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              }
          } else {
            this.snackbarObj = {
              type: "error",
              message:  res.data.ResponseMessage??'Can\'t delete this image.',
            }
          }         
        })
        .catch((err) => {
         this.snackbarObj = {
            type: "error",
            message:  err.response?.data??'Can\'t delete this image.',
          }
        });
      }
    },
    editImage(formData, index, fileList) {
      this.uploadMedia(formData, index, fileList);
    },
    deleteImgIfError(index, message) {
      this.snackbarObj = {
        type: "error",
        message: message,
      };
      this.$children[0].images.splice(index, 1);
    },
  },
};
</script>
