import { render, staticRenderFns } from "./ProductAttributeUpdate.vue?vue&type=template&id=7981b670&scoped=true&"
import script from "./ProductAttributeUpdate.vue?vue&type=script&lang=js&"
export * from "./ProductAttributeUpdate.vue?vue&type=script&lang=js&"
import style0 from "./ProductAttributeUpdate.vue?vue&type=style&index=0&id=7981b670&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7981b670",
  null
  
)

export default component.exports