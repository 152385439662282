<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-flex class="account-panel">
                <v-layout
                  wrap
                  class="account-panel-top align-center justify-space-between"
                  v-if="brandsObj.length"
                >
                  <h2>My brands</h2>
                </v-layout>
                <v-flex class="my-brands">
                  <v-row v-if="brandsObj.length">
                    <v-col
                      v-for="(brand, index) in brandsObj"
                      :key="index"
                      cols="12"
                      md="4"
                      sm="6"
                    >
                      <v-flex class="brand-box transition-swing">
                        <v-flex class="brand-banner">
                          <img
                            class="d-block"
                            :title="brand.BrandName"
                            :src="$root.generateMediaImageUrl(brand.BannerUrl)"
                            alt="brand.BrandName"
                          />
                        </v-flex>

                        <v-flex class="brand-infos pa-2">
                          <v-layout class="align-center">
                            <v-layout class="align-center pr-1">
                              <img
                                :title="brand.BrandName"
                                class="d-block"
                                :src="
                                  $root.generateMediaImageUrl(brand.IconUrl)
                                "
                                :lazy-src="
                                  require('@/assets/images/no-img.jpg')
                                "
                                alt="brand.BrandName"
                              />
                              <h4 class="pl-2" v-text="brand.BrandName"></h4>
                            </v-layout>
                            <span class="own-brand" v-if="brand.IsFeatured"
                              >Own Brand</span
                            >
                          </v-layout>
                          <v-flex class="featured pt-2">
                            <span class="pr-2"
                              >Products
                              <span
                                class="brand--text"
                                v-text="'(' + brand.ItemCount + ')'"
                              ></span
                            ></span>
                            <span class="pr-2" v-if="brand.IsFeatured">
                              | Resticted for others</span
                            >
                          </v-flex>

                          <v-flex class="btn-wrap text-right pt-2">
                            <!-- <v-btn
                              height="28"
                              tile
                              depressed 
                              class="b-hover silver_tree mx-1 white--text text-none transition-swing"
                              @click="dialog=!dialog"
                            >Edit</v-btn>-->
                            <v-btn
                              :to="{
                                name: 'ProductSearch',
                                query: {
                                  OwnerId: getAuthUserId(),
                                  BrandId: brand.Id,
                                },
                              }"
                              height="28"
                              tile
                              depressed
                              class="
                                b-hover
                                primary
                                mx-1
                                white--text
                                text-none
                                transition-swing
                              "
                              >Details</v-btn
                            >
                          </v-flex>
                        </v-flex>
                      </v-flex>
                    </v-col>
                  </v-row>
                  <data-not-found v-else>No Brand tagged yet</data-not-found>
                </v-flex>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-flex class="pa-10">
          <h3>Edit mode Coming soon</h3>
        </v-flex>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "MyBrands",
  components: {
    AccountSidebar,
    DataNotFound,
  },

  data() {
    return {
      loading: false,
      dialog: false,
      brandsObj: [],
    };
  },
  created() {
    this.getMyBrands();
  },
  methods: {
    getAuthUserId() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.UserId;
      }
      return 0;
    },
    getMyBrands() {
      if (this.loading) return;

      this.loading = true;
      this.$http
        .post(
          this.$store.state.Configs.getMyBrands.replace(
            ":ownerId",
            this.getAuthUserId()
          )
        )
        .then((result) => {
          this.brandsObj = result.data;
          this.loading = false;
        })
        .then(() => {
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_brands.scss";
</style>
