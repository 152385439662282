<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-layout wrap class="account-panel">
                <!-- summary sections -->

                <v-flex class="campaing-btn">
                  <router-link class="active" to="/">Flash Sale</router-link>
                  <router-link to="/">Daily Deal</router-link>
                  <router-link to="/">Special Promotions</router-link>
                </v-flex>
                <v-flex class="campaing-details d-flex">
                  <v-flex class="image-box flex-grow-0">
                    <img :src="require('@/assets/temp/' + campaign.Image)" />
                  </v-flex>
                  <v-flex class="campaing-mid flex-grow-0">
                    <h3 v-text="campaign.Title"></h3>
                    <p v-text="campaign.Details"></p>
                    <ul>
                      <li>- Live period: 7-13 June</li>
                      <li>- Registration Period: till 3rd June</li>
                      <li>- Minimum Discount: 2% of the average selling price and 8% on non-selling SKUs</li>
                      <li>- Daily Price Check will be conducted & any deal with higher offering than current price will be eliminated from the campaign.</li>
                      <li>- Minimum Stock count 10. Maximum Stock 100</li>
                    </ul>
                  </v-flex>
                  <v-flex class="campaing-right flex-grow-0">
                    <h3>Campaign Period( 7 Days)</h3>
                    <span>Start at 07 Jun 2020 00:00 GMT+06:00</span>
                    <span>End at 13 Jun 2020 23:59 GMT+06:00</span>
                    <h4>Registration Ends in</h4>
                    <span>07 Jun 2020 00:00 GMT+06:00</span>
                    <span class="join">Join Before: 2d 23h 46m</span>
                    <v-btn
                      height="40"
                      block
                      :to="{name:'JoinCampaign'}"
                      tile
                      depressed
                      class="brand white--text text-none"
                    >Join Now</v-btn>
                    <span class="sellers">860 Sellers are joining | 0 Products</span>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";

export default {
  name: "AllCampaign",
  components: {
    AccountSidebar,
  },
  data() {
    return {
      campaign: {
        Id: "1",
        Title: "12.12 Vouchers Campaign (Marketplace Sellers) Week - 1",
        Details:
          "Join Daraz Weekly Flash sales & avail the chance to include your products in a campaign with higher traffic & exciting features of new Daraz.",
        Image: "campaign_1.png",
        MinNumOfDays: "",
        MinNumOfOrders: "",
        MinDiscount: "Minimum price discount",
        MinNumOfQty: "",
        Note: "",
        JoiningStartDate: "Nov 27, 2021",
        JoiningEndDate: "03 Jun 2020 23:59 GMT+06:00",
        CampaignStartDate: "Dec 5, 2021",
        CampaignEndDate: "Dec 23, 2021",
        LivePeriod: "7-13 June",
        Type: "Flash Sale",
        Status: "Live",
        SellersCount: "320",
        ProductsCounts: "320",
      },
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/campaign_details.scss";
</style>
