<template>
  <div>
    <!-- <span>{{ productObj }}</span> -->
    <!--================== owner block start ================ -->
    <span v-if="user.UserId == productObj.UserId" class="owner-block">
      <v-alert
        color="green"
        dark
        v-if="
          productObj.AuctionStatus == 'Closed' &&
          productObj.CurrentBid < productObj.ApproximatePrice
        "
      >
        This to inform you that the nilam is now closed but the highest bid
        amount is lower than the reserve price. If you think that you can sell
        your item at this price, please send an
        <a
          class="text-decoration-underline font-weight-bold"
          @click.prevent="orderRequest"
          >order request</a
        >
        to the max bidder or you can
        <a
          class="text-decoration-underline font-weight-bold"
          @click.prevent="reNilamRequest"
          >re-nilam</a
        >
        your item.
      </v-alert>
      <v-alert
        color="green"
        dark
        v-else-if="
          productObj.AuctionStatus == 'Closed' && !productObj.BidderCount
        "
        class="auction-closed no-bidder-found"
      >
        This to inform you that the nilam is now closed but no bidder bid on
        your nilam. Please
        <a class="text-decoration-underline" @click.prevent="reNilamRequest"
          >re-nilam</a
        >
        your item.
      </v-alert>

      <v-alert
        color="green"
        dark
        v-else-if="productObj.AuctionStatus == 'ShortListed'"
        class="auction-shortListed owner"
      >
        The order link has been sent to the winner. The winner will be able to
        place an order within
        <span
          class="font-weight-bold"
          v-text="
            $root.formateServerDateTime(productObj.OrderConfirmationLastTime)
          "
        ></span>
      </v-alert>

      <v-alert
        color="green"
        dark
        v-else-if="productObj.AuctionStatus == 'Ordered'"
        class="auction-ordered owner"
      >
        Your item is sold. To see your
        <router-link
          class="text-decoration-underline font-weight-bold"
          :to="{ name: 'MyOrderDetails', params: { id: productObj.OrderId } }"
        >
          order details
        </router-link>
      </v-alert>
    </span>
    <!--====================== owner block end ================= -->

    <!--========== max bidder block start===== -->
    <span v-else-if="user.UserId == productObj.MaxBidderId">
      <v-alert
        color="green"
        dark
        v-if="
          productObj.AuctionStatus == 'Closed' &&
          productObj.CurrentBid < productObj.ApproximatePrice
        "
        class="auction-closed lowest-current-bid"
      >
        Thank you for bidding, the nilam is now closed. Although you’re the
        highest bidder, your bid amount is lower than the reserved price. This
        nilam may be canceled. Please wait for the seller's decision.
      </v-alert>

      <v-alert
        color="green"
        dark
        v-else-if="productObj.AuctionStatus == 'ShortListed'"
        class="auction-shortListed winner"
      >
        Congratulations!! you are the winner of this nilam. Please place an
        order before
        <span
          class="text-bold"
          v-text="
            $root.formateServerDateTime(productObj.OrderConfirmationLastTime)
          "
        ></span
        >.
        <buy-now-btn
          :product="productObj"
          :is-normal="true"
          class="white--text mr-2"
          >Confirm Your Order</buy-now-btn
        >
      </v-alert>

      <v-alert
        color="green"
        dark
        v-else-if="productObj.AuctionStatus == 'Ordered'"
        class="auction-ordered winner"
      >
        You have orderd this item. To see your
        <router-link
          class="text-decoration-underline font-weight-bold"
          :to="{ name: 'MyOrderDetails', params: { id: productObj.OrderId } }"
        >
          order details
        </router-link>
      </v-alert>
    </span>
    <!--========== max bidder block end===== -->
  </div>
</template>

<script>
import BuyNowBtn from "@/components/sites/common/BuyNowBtn";
export default {
  name: "AuctionInstruction",
  components: {
    BuyNowBtn,
  },
  props: {
    productObj: Object,
  },
  computed: {
    user() {
      return this.$auth.user;
    },
  },
  methods: {
    async reNilamRequest() {
      const yes = await this.$confirm(
        "If you re-nilam this item the bidding time will be extended. Are you sure ?",
        {
          title: "Warning",
        }
      );
      if (!yes) return;

      this.$http
        .post(
          this.$store.state.Configs.reAuction.replace(":id", this.productObj.Id)
        )
        .then((res) => {
          this.$store.commit("set_flash", {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async orderRequest() {
      var bidAmount = this.$root.showAmount(this.productObj.CurrentBid);
      var reserveAmount = this.$root.showAmount(
        this.productObj.ApproximatePrice
      );

      const yes = await this.$confirm(
        "Max bidder will be able to place an order with " +
          bidAmount +
          " but your reserve price is " +
          reserveAmount +
          ". Are you sure ?",
        {
          title: "Warning",
        }
      );
      if (!yes) return;

      this.$http
        .post(
          this.$store.state.Configs.sendOrderRequest.replace(
            ":id",
            this.productObj.Id
          )
        )
        .then((res) => {
          this.$store.commit("set_flash", {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>
