<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap" v-if="Object.keys(profileObj).length">
            <v-card tile elevation="0">
              <v-flex class="account-panel">
                <ValidationObserver
                  tag="div"
                  class="profile-info-form"
                  ref="form"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <!-- Image Uploader -->
                    <multi-image-uploader
                      title="Profile Picture <small>(PNG/JPG/JPEG, Max. 3MB) </small>"
                      id="images-uploader-1"
                      edit-id="images-uploader-edit-1"
                      :options="imageOptions"
                      :data-images="images"
                    ></multi-image-uploader>

                    <!-- Personal Information -->
                    <v-flex class="account-personal-info">
                      <h3 v-text="$t('Personal Information')"></h3>
                      <v-row>
                        <!-- First name -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="First Name"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('First Name')"></v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('First Name')"
                              v-model="formData.FirstName"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <!-- Last name -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="Last Name"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Last Name')"></v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Your Last Name')"
                              v-model="formData.LastName"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <!-- Email -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="Email"
                          rules="email"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">    
                            <update-phone-or-email :type="'Email'" :hide-user-name="true"></update-phone-or-email>                       
                            <v-text-field                             
                              filled
                              disabled
                              hide-details
                              :label="$t('Mail address')"
                              v-model="formData.Email"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>                          
                        </ValidationProvider>

                        <!-- Phone -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="phone"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <update-phone-or-email :type="'Mobile'"  :hide-user-name="true"></update-phone-or-email>                       
                            <v-text-field
                              filled
                              disabled
                              hide-details
                              :label="$t('Phone')"
                              v-model="formData.Mobile"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                          
                        </ValidationProvider>

                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="phone"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Date Of Birth')"></v-flex>
                            <v-menu
                              v-model="dateIsOpen"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="formData.DOB"
                                  hint="YYYY-MM-DD format"
                                  persistent-hint
                                  solo
                                  flat
                                  outlined
                                  prepend-inner-icon="mdi-calendar"
                                  v-bind="attrs"
                                  v-on="on"
                                  placeholder="Select Date"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="formData.DOB" @input="dateIsOpen = false"></v-date-picker>
                            </v-menu>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="gender"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Gender')"></v-flex>
                            <v-select
                              :items="genders"
                              dense
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Select gender')"
                              v-model="formData.Gender"
                            ></v-select>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </v-row>
                    </v-flex>
                    <!-- <v-divider class="mt-4 mb-7"></v-divider> -->

                    <!-- Company Information -->
                    <v-flex class="account-personal-info">
                      <h3 v-text="$t('Company Information')"></h3>
                      <v-row>
                        <!-- Company Name -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="company name"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Name')"></v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Company Name')"
                              v-model="formData.Company.CompanyName"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <!-- Company Address -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="company address"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Summary')"></v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Company Address')"
                              v-model="formData.Company.CompanySummary"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <!-- Company Email -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="company email"
                          rules="email"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Email')"></v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Company Email')"
                              v-model="formData.Company.Email"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <!-- Company Phone -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="company phone"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-flex class="input-label" v-text="$t('Phone')"></v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Company Phone')"
                              v-model="formData.Company.ContactNo"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>

                        <div class="input-wrap col-md-12 py-0">
                          <v-flex class="upload-section">
                            <!-- Document Image Uploader -->
                            <multi-image-uploader
                              title="Upload Document"
                              id="images-uploader-2"
                              edit-id="images-uploader-edit-2"
                              :img-config.sync="imgConfig"
                              :options="profileDocumentOptions"
                              :data-images="selectedProfileDocuments"
                            ></multi-image-uploader>
                          </v-flex>
                        </div>

                        <!-- Company is use -->
                        <ValidationProvider
                          tag="div"
                          class="col-md-6 py-0"
                          name="company IsUseCompanySalesProfile"
                          :bails="false"
                          v-slot="{ errors, classes }"
                        >
                          <div class="input-wrap" :class="classes">
                            <v-checkbox
                              v-model="formData.IsUseCompanySalesProfile"
                              :label="$t('Are You Use This Company Information As Your Sales Profile')"
                              color="primary"
                              :value="false"
                              hide-details
                            ></v-checkbox>
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </v-row>
                    </v-flex>

                    <v-btn
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                      tile
                      depressed
                      class="save-info-btn mt-1 brand white--text text-none"
                    >{{$t('Save')}}</v-btn>
                  </form>
                </ValidationObserver>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import MultiImageUploader from "@/components/services/MultiImageUploader";
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import UpdatePhoneOrEmail from "../components/sites/common/UpdatePhoneOrEmail.vue";

export default {
  name: "MyAccount",
  components: {
    MultiImageUploader,
    AccountSidebar,
    UpdatePhoneOrEmail
  },
  data() {
    return {
      profile: Object,
      dateIsOpen: false,
      loading: false,
      modal: false,
      genders: ["Male", "Female", "Other"],
      imageOptions: {
        idUpload: "file",
        multiple: false,
        showPrimary: false,
        showDelete: false,
        referenceType: "ProfileImage",
        referenceId: 0,
      },
      profileDocumentOptions: {
        idUpload: "file",
        multiple: true,
        showPrimary: true,
        showDelete: true,
        referenceType: "UserDocument",
        referenceId: 0,
        showEdit: false,
      },
      selectedProfileDocuments: [],
      images: [
        {
          path: "",
          name: "",
          default: 1,
          highlight: 1,
          caption: "Profile Image",
        },
      ],
      formData: {
        Address: {},
        Company: {},
        SocialAddress: {},
      },
      change_password: false,
    };
  },
  computed: {
    profileObj() {
      return this.$store.state.profileObj;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    imgConfig() {
      return this._.find(this.siteSettings.MediaConfiguration, {
        IsDefault: true,
        Entity: "Item",
      });
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(() => {
        if (this.loading) return;

        this.loading = true;

        this.$http
          .post(this.$store.state.Configs.updateUserProfile, this.formData)
          .then((res) => {
            this.$store.dispatch("getUserProfile").then(() => {
              this.loading = false;
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
            });
          })
          .then(() => {
            this.$vuetify.goTo(0);
          })
          .catch((error) => {
            this.$store.commit("set_flash", {
              type: error.ResponseType,
              message: error.ResponseMessage,
            });
            this.loading = false;
            console.log(error);
          });
      });
    },
    fromDataPrepare() {
      let { Id, Address, Company, SocialAddress, ProfileImageUrl, ...other } =
        this.profileObj;

      this.formData = other;
      this.formData.Address = Address || {};
      this.formData.Company = Company || {};
      this.formData.SocialAddress = SocialAddress || {};

      this.imageOptions.referenceId = Id;
      this.images[0].path = this.$root.generateMediaImageUrl(ProfileImageUrl);
    },
    generateUploaderData(Arr) {
      var dataArr = [];
      Arr.forEach((element) => {
        let obj = {
          Id: element.Id,
          default: element.IsCover,
          path: encodeURI(this.$root.generateMediaImageUrl(element.FilePath)),
          name: element.Title,
        };
        dataArr.push(obj);
      });
      return dataArr;
    },
  },
  created() {
    this.fromDataPrepare();
    this.Fire.$on("image-uploaded", (obj) => {
      this.$store.state.profileObj.ProfileImageUrl = obj.ResponseData.FilePath;
    });

    this.profileDocumentOptions.referenceId = this.profileObj.Id;

    if (this.profileObj.Documents && this.profileObj.Documents.length) {
      this.selectedProfileDocuments = this.generateUploaderData(
        this.profileObj.Documents
      );
    }
  },
  beforeDestroy() {
    this.Fire.$off("qtyUpdate");
  },
  watch: {
    profileObj: function (newVal) {
      this.fromDataPrepare(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_account.scss";
</style>
