<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap" v-if="Object.keys(orderObj).length">
            <v-card tile elevation="0" color="transparent">
              <v-flex class="account-panel">
                <v-flex class="account-panel-top">
                  <v-row>
                    <v-col lg="8" cols="12" class="py-0">
                      <v-alert
                        v-if="
                          orderObj.Message &&
                          Object.keys(orderObj.Message).length
                        "
                        :type="orderObj.Message.ResponseType.toLowerCase()"
                        elevation="2"
                        dismissible
                        outlined
                        text
                        prominent
                        class="mb-5"
                        >{{ orderObj.Message.ResponseMessage }}</v-alert
                      >

                      <v-flex class="order-details-info">
                        <v-flex class="d-flex flex-wrap justify-space-between">
                          <h2>Order Details</h2>

                          <cancel-order
                            v-if="
                              orderObj.Order.Status == 'Pending' ||
                              orderObj.Order.Status == 'Confirmed'
                            "
                            :order-id="orderObj.Order.Id"
                          ></cancel-order>
                        </v-flex>

                        <template
                          v-if="orderObj.Order.BuyerId == $auth.user.UserId"
                        >
                          <v-layout
                            wrap
                            class="order-wrap justify-space-between align-center py-2"
                          >
                            <v-flex class="flex-grow-0 px-4 py-1">
                              <span
                                class="order-no d-block"
                                v-text="
                                  'Order Number: ' + orderObj.Order.DisplayId
                                "
                              ></span>
                              <span
                                class="placed-on silver_tree--text d-block"
                                v-text="
                                  $root.formateServerDateTime(
                                    orderObj.Order.OrderedAt
                                  )
                                "
                              ></span>
                            </v-flex>

                            <v-flex class="flex-grow-0 px-4 py-1">
                              <span class="price d-block silver_tree--text">
                                Total:
                                <strong
                                  v-text="
                                    $root.showAmount(orderObj.Order.TotalAmount)
                                  "
                                ></strong>
                              </span>
                            </v-flex>
                          </v-layout>

                          <div class="tab-timeline">
                            <ul class="d-flex flex-wrap m-0 p-0">
                              <li
                                v-for="(status, index) in orderProgress"
                                :key="index"
                                :class="{
                                  active: getPackageActiveStage(
                                    index,
                                    orderObj.Order.Status
                                  ),
                                }"
                              >
                                <span class="bar">
                                  <i class="tab-icon"></i>
                                  <div v-text="status" class="tab-info"></div>
                                </span>
                                <span class="arrow"></span>
                              </li>
                            </ul>
                          </div>

                          <!-- Package timeline -->
                          <div class="tab-timeline">
                            <v-flex
                              class="timeline-content"
                              v-if="orderObj.Activities.length"
                            >
                              <v-flex
                                v-for="activity in orderObj.Activities"
                                :key="activity.Id"
                                class="package-info d-flex justify-space-between"
                              >
                                <span
                                  class="package-time"
                                  v-text="
                                    $root.formateServerDateTime(
                                      activity.ActivityAt
                                    )
                                  "
                                ></span>
                                <span
                                  v-text="activity.Activity"
                                  class="package-text"
                                ></span>
                              </v-flex>
                            </v-flex>
                            <!-- Bank payment -->
                            <bank-payment
                              v-if="orderObj.Order.PaymentMode == 'Bank'"
                              :order-obj="orderObj.Order"
                              :documents="orderObj.Documents"
                            ></bank-payment>
                          </div>
                        </template>
                      </v-flex>

                      <order-package
                        v-for="(packageObj, index) in orderObj.OrderPackages"
                        :key="index"
                        :order-id="orderObj.Order.Id"
                        :sequence="index + 1"
                        :package-obj="packageObj"
                      ></order-package>

                      <v-layout
                        wrap
                        class="pt-2 justify-space-between"
                        v-if="false"
                      >
                        <router-link
                          :to="{ name: 'ProductDetails' }"
                          class="silver_tree--text"
                          >WRITE A REVIEW</router-link
                        >
                      </v-layout>
                    </v-col>

                    <!-- Right sitebar -->
                    <v-col lg="4" cols="12" class="py-0">
                      <v-flex class="cart-summary">
                        <h2 class="d-flex align-center">Total Summary</h2>
                        <v-flex class="cart-summary-box">
                          <v-flex class="cart-info">
                            <strong>Subtotal:</strong>
                            <span
                              v-text="
                                $root.showAmount(orderObj.Order.NetTotalAmount)
                              "
                            ></span>
                          </v-flex>

                          <v-flex
                            class="cart-info"
                            v-if="orderObj.Order.PromoAmount"
                          >
                            <strong>Promo Amount:</strong>
                            <span
                              v-text="
                                $root.showAmount(orderObj.Order.PromoAmount) +
                                ' (' +
                                orderObj.Order.PromoCode +
                                ')'
                              "
                            ></span>
                          </v-flex>

                          <v-flex class="cart-info">
                            <strong>Shipping Fee:</strong>
                            <span
                              v-text="
                                $root.showAmount(
                                  orderObj.Order.TotalShippingCharge
                                )
                              "
                            ></span>
                          </v-flex>
                          <v-flex class="cart-info">
                            <strong>Payment Mode:</strong>
                            <span>
                              <v-layout class="by-cash">
                                <v-avatar
                                  size="21"
                                  color="grey"
                                  class="mr-1 lighten-4"
                                >
                                  <v-icon color="silver_tree" size="15"
                                    >mdi-check-bold</v-icon
                                  >
                                </v-avatar>
                                {{ orderObj.Order.PaymentMode }}
                              </v-layout>
                            </span>
                          </v-flex>
                          <v-flex class="cart-info">
                            <strong>Payment Status:</strong>
                            <span v-text="orderObj.Order.PaymentStatus"></span>
                          </v-flex>
                          <v-flex class="cart-info flex-wrap">
                            <strong>Total:</strong>
                            <span
                              class="grand-total brand--text"
                              v-text="
                                $root.showAmount(orderObj.Order.TotalAmount)
                              "
                            ></span>

                            <v-flex class="pt-4">
                              <pay-now-btn
                                v-if="
                                  orderObj.Order.PaymentStatus == 'UnPaid' &&
                                  orderObj.Order.PaymentMode != 'IPDC' &&
                                  (orderObj.Order.Status == 'Pending' ||
                                    orderObj.Order.Status == 'Confirmed') &&
                                  orderObj.Order.BuyerId == $auth.user.UserId
                                "
                                ref="payNowBtn"
                                referance-type="Order"
                                :referance-id="orderObj.Order.Id"
                                :amount="orderObj.Order.TotalAmount"
                                :avail-emi="orderObj.Order.IsAvailEmi"
                                :payment-gateway="orderObj.Order.PaymentMode"
                              ></pay-now-btn>
                            </v-flex>
                          </v-flex>
                        </v-flex>
                        <!-- Shipping Address -->
                        <h2 class="d-flex align-center">Shipping Address</h2>
                        <v-flex class="ship-info">
                          <v-flex class="address">
                            <address
                              v-if="
                                orderObj.Order.ShippingAddress.IsPickupPoint
                              "
                            >
                              <!-- <span>
                                <strong
                                  v-text="orderObj.Order.Buyer.FullName"
                                ></strong>
                              </span>
                              <span>
                                Phone:
                                <strong
                                  v-text="orderObj.Order.Buyer.Mobile"
                                ></strong>
                              </span>
                              <span class="text-truncate">
                                Email:
                                <strong
                                  v-text="orderObj.Order.Buyer.Email"
                                ></strong>
                              </span> -->

                              <div class="mt-2">
                                <span>
                                  <strong
                                    class="brand--text"
                                    v-text="
                                      orderObj.Order.ShippingAddress.Title
                                    "
                                  ></strong>
                                </span>
                                <span>
                                  <strong
                                    v-text="
                                      orderObj.Order.ShippingAddress.Address1
                                    "
                                  ></strong>
                                </span>
                              </div>
                            </address>
                            <address v-else>
                              <span>
                                Name:
                                <strong
                                  v-text="orderObj.Order.ShippingAddress.Name"
                                ></strong>
                              </span>
                              <span>
                                Phone:
                                <strong
                                  v-text="orderObj.Order.ShippingAddress.Mobile"
                                ></strong>
                              </span>
                              <span class="text-truncate">
                                Email:
                                <strong
                                  v-text="orderObj.Order.ShippingAddress.Email"
                                ></strong>
                              </span>
                              <span>
                                Address:
                                <strong
                                  v-text="
                                    orderObj.Order.ShippingAddress.Address1
                                  "
                                ></strong>
                              </span>
                              <span
                                v-if="
                                  orderObj.Order.ShippingAddress.DistrictInfo
                                "
                              >
                                <strong
                                  class="success--text"
                                  v-text="
                                    orderObj.Order.ShippingAddress.DistrictInfo
                                      .Name
                                  "
                                ></strong>
                              </span>
                            </address>
                          </v-flex>
                        </v-flex>
                        <!-- /Shipping Address -->

                        <!-- Billing Address -->
                        <h2 class="d-flex align-center">Billing Address</h2>
                        <v-flex class="ship-info">
                          <v-flex class="address">
                            <address>
                              <span>
                                Name:
                                <strong
                                  v-text="orderObj.Order.BillingAddress.Name"
                                ></strong>
                              </span>
                              <span>
                                Phone:
                                <strong
                                  v-text="orderObj.Order.BillingAddress.Mobile"
                                ></strong>
                              </span>
                              <span class="text-truncate">
                                Email:
                                <strong
                                  v-text="orderObj.Order.BillingAddress.Email"
                                ></strong>
                              </span>
                              <span>
                                Address:
                                <strong
                                  v-text="
                                    orderObj.Order.BillingAddress.Address1
                                  "
                                ></strong>
                              </span>
                              <span
                                v-if="
                                  orderObj.Order.BillingAddress.DistrictInfo
                                "
                              >
                                <strong
                                  class="success--text"
                                  v-text="
                                    orderObj.Order.BillingAddress.DistrictInfo
                                      .Name
                                  "
                                ></strong>
                              </span>
                            </address>
                          </v-flex>
                        </v-flex>
                        <!-- /Billing Address -->
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import PayNowBtn from "@/components/sites/common/PayNowBtn";
import BankPayment from "@/components/sites/pages/order/BankPayment";
import OrderPackage from "@/components/sites/pages/order/OrderPackage.vue";
import CancelOrder from "../components/sites/common/CancelOrder.vue";

export default {
  name: "MyProducts",
  components: {
    AccountSidebar,
    PayNowBtn,
    BankPayment,
    OrderPackage,
    CancelOrder,
  },
  data() {
    return {
      orderObj: {},
    };
  },
  created() {
    this.getOrderDetails();
  },
  computed: {
    orderProgress() {
      return this.$store.state.Configs.orderProgress;
    },
  },
  mounted() {
    this.Fire.$on("orderCanceled", () => {
      this.getOrderDetails();
    });
  },
  beforeDestroy() {
    this.Fire.$off("orderCanceled");
  },
  methods: {
    getOrderDetails() {
      this.product = [];
      this.$http
        .post(
          this.$store.state.Configs.getMyOrderDetails.replace(
            ":id",
            this.$route.params.id
          )
        )
        .then((result) => {
          this.orderObj = result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPackageActiveStage(stageIndex, packageStatus) {
      if (stageIndex <= this.orderProgress.indexOf(packageStatus)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/statusbar.scss";
@import "@/sass/my_order_details.scss";
@import "@/sass/shipping_billing.scss";
</style>
