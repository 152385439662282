<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-flex class="account-panel">
                <v-layout wrap class="account-panel-top align-center justify-space-between">
                  <h2>
                    {{$t('Orders')}}
                    <span v-text="`(Total Orders: ${total})`"></span>
                  </h2>
                </v-layout>

                <!-- filters-bar -->
                <v-layout class="filter-bar flex-wrap">
                  <div class="input-wrap">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Keyword')"
                      v-model="filterObj.Keyword"
                    ></v-text-field>
                  </div>

                  <div class="input-wrap">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="filterObj.date_range"
                      persistent
                      width="300px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterObj.date_range"
                          solo
                          flat
                          hide-details
                          outlined
                          :label="$t('Date range')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker range v-model="filterObj.date_range" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(filterObj.date_range)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>

                  <div class="input-wrap">
                    <v-select
                      :items="status"
                      dense
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Order Status')"
                      v-model="filterObj.Status"
                    ></v-select>
                  </div>

                  <!-- <div class="input-wrap" v-if="!siteSettings.IsECommerce">
                    <v-select
                      v-model="filterObj.Type"
                      item-key="key"
                      item-value="key"
                      item-text="text"
                      :items="myStatus"
                      label="Order Mode"
                      dense
                      solo
                      flat
                      hide-details
                      full-width
                      outlined
                    ></v-select>
                  </div>-->
                  <v-btn
                    @click="getMyOrders()"
                    height="42"
                    type="submit"
                    tile
                    depressed
                    class="brand white--text text-none"
                  >{{$t("Search")}}</v-btn>
                </v-layout>

                <v-skeleton-loader
                  class="mx-auto pl-5 pr-5"
                  type="article,article,article"
                  v-if="loading"
                ></v-skeleton-loader>

                <template v-else>
                  <v-flex
                    class="account-panel-table"
                    v-if="Object.keys(orderObj).length && orderObj.data.length"
                  >
                    <template v-if="$root.windowSize > 959">
                      <table v-for="order in orderObj.data" :key="order.Id">
                        <thead>
                          <tr>
                            <th>
                              <span
                                class="order-number"
                                v-text="'Order Number: ' + order.DisplayId"
                              ></span>
                              <span
                                class="order-date"
                                v-text="
                                  $t('Order Date')+ ': ' +
                                  $root.formateServerDateTime(order.OrderedAt)
                                "
                              ></span>
                            </th>
                            <th class="tbl-2">
                              <div class="my-product-title" v-text="$t('Product')"></div>
                            </th>
                            <th>
                              <div
                                class="order-price"
                                v-text="
                                  $t('Order Total')+': ' +
                                  $root.showAmount(order.TotalAmount)
                                "
                              ></div>
                            </th>
                            <th class="tbl-4">{{$t('Status')}}</th>
                            <th class="tbl-5">
                              <span class="status">{{$t('Payment Status')}}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="border-right" data-label="Account">
                              <h4 class="payment-method">
                                {{$t('Payment Method')}}:
                                <strong v-text="order.PaymentMode"></strong>
                              </h4>
                              <address class="address">
                                <h4>{{$t('Shipping Address')}}:</h4>
                                <span v-text="order.Name"></span>
                                <span v-text="order.Address1"></span>
                                <span v-text="order.Mobile"></span>
                                <span v-text="order.Email"></span>
                              </address>
                              <router-link
                                class="details"
                                :to="{
                                  name: 'MyOrderDetails',
                                  params: {
                                    id: order.Id,
                                  },
                                }"
                                v-text="$t('Details')"
                              ></router-link>

                              <cancel-order
                                v-if="
                                  order.Status == 'Pending' ||
                                  order.Status == 'Confirmed'
                                "
                                :order-id="order.Id"
                              ></cancel-order>
                            </td>

                            <td data-label="Product">
                              <v-flex class="pb-2 d-flex justify-lg-start justify-center">
                                <v-img
                                  v-if="order.IsReceived"
                                  contain
                                  max-width="137"
                                  height="28"
                                  :src="
                                    require('@/assets/images/received-order.png')
                                  "
                                  alt="Image"
                                ></v-img>
                                <v-img
                                  v-else
                                  contain
                                  max-width="117"
                                  height="28"
                                  :src="
                                    require('@/assets/images/given-order.png')
                                  "
                                  alt="Image"
                                ></v-img>
                              </v-flex>
                              <v-layout class="my-product-img fill-height">
                                <v-flex class="my-product-title">
                                  <h4 class="product" v-text="order.Items"></h4>

                                  <v-flex>
                                    <span class="qty" v-text="$t('Shipment') + ' #' + order.Id"></span>
                                    <v-chip
                                      v-if="order.OrderType == 'Auction'"
                                      class="type"
                                    >{{$t('Auction')}}</v-chip>
                                  </v-flex>
                                </v-flex>
                              </v-layout>
                            </td>

                            <td
                              :data-label="
                                'Order Total :' +
                                $root.showAmount(order.TotalAmount)
                              "
                            >
                              <span
                                v-text="
                                  $t('Order Total') + ' :' +
                                  $root.showAmount(order.TotalAmount)
                                "
                                class="order-mony d-block"
                              ></span>

                              <span
                                class="d-block grey--text"
                                v-text="
                                $t('Received') + ' :' +
                                  $root.showAmount(order.TotalReceivedAmount)
                                "
                              ></span>
                            </td>

                            <td data-label="Status">
                              <span
                                v-text="order.Status"
                                :class="[
                                  order.Status == 'Delivered'
                                    ? 'brand--text'
                                    : order.Status == 'Cancelled'
                                    ? 'error--text'
                                    : '',
                                ]"
                              ></span>
                            </td>
                            <td data-label="Payment Status" v-text="order.PaymentStatus"></td>
                          </tr>
                        </tbody>
                      </table>
                    </template>

                    <template v-else>
                      <v-layout
                        wrap
                        class="order-product-wrap align-center"
                        v-for="order in orderObj.data"
                        :key="order.Id"
                      >
                        <v-flex class="column flex-grow-0">
                          <span
                            class="d-block"
                            v-text="$t('Order Number') + ' :' + order.DisplayId"
                          ></span>
                          <span
                            class="d-block order-date"
                            v-text="
                            $t('Order Date') + ' :'+ $root.formateServerDateTime(order.OrderedAt)"
                          ></span>
                        </v-flex>

                        <v-flex class="column flex-grow-0">
                          <v-img
                            v-if="order.IsReceived"
                            width="120"
                            height="25"
                            :src="require('@/assets/images/received-order.png')"
                            alt="Image"
                          ></v-img>
                          <v-img
                            v-else
                            contain
                            width="120"
                            height="25"
                            :src="require('@/assets/images/given-order.png')"
                            alt="Image"
                          ></v-img>
                          <v-chip v-if="order.OrderType == 'Auction'" class="type">Auction</v-chip>
                          <span class="qty" v-text="$t('Shipment')+ ' #' + order.Id"></span>
                        </v-flex>

                        <v-flex class="column flex-grow-0">
                          <div
                            class="order-price"
                            v-html="
                              `${$t('Order Total')}: <b>${$root.showAmount(
                                order.TotalAmount
                              )}</b>`
                            "
                          ></div>
                        </v-flex>

                        <v-flex class="column flex-grow-0">
                          <span
                            v-text="order.Status"
                            :class="[
                              order.Status == 'Delivered'
                                ? 'brand--text'
                                : order.Status == 'Cancelled'
                                ? 'error--text'
                                : '',
                            ]"
                          ></span>
                        </v-flex>

                        <v-flex class="column flex-grow-0">
                          <span class="silver_tree--text" v-text="order.PaymentStatus"></span>
                        </v-flex>

                        <v-flex class="column flex-grow-0 column-6">
                          <router-link
                            class="details"
                            :to="{
                              name: 'MyOrderDetails',
                              params: {
                                id: order.Id,
                              },
                            }"
                          >Details</router-link>
                        </v-flex>
                      </v-layout>
                    </template>

                    <v-flex
                      class="d-flex justify-end account-pagination"
                      v-if="orderObj.TotalPage > 1"
                    >
                      <pagination :page-no="orderObj.Page" :length="orderObj.TotalPage"></pagination>
                    </v-flex>
                  </v-flex>
                  <!-- Data not found start-->
                  <data-not-found v-else class="mt-10">
                    <h1 v-text="$t('No records found.')"></h1>
                    <h5 class="mt-1">{{$t("We don't find any order for you.")}}</h5>
                    <v-btn class="mt-1" x-small color="primary" @click="onReset()">{{$t('Reset')}}</v-btn>
                  </data-not-found>
                  <!-- Data not found end-->
                </template>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import Pagination from "@/components/services/Pagination";
import DataNotFound from "@/components/sites/common/DataNotFound";
import CancelOrder from "../components/sites/common/CancelOrder.vue";

export default {
  name: "MyOrder",
  components: {
    AccountSidebar,
    Pagination,
    DataNotFound,
    CancelOrder,
  },

  data() {
    return {
      loading: false,
      modal: false,
      orderObj: [],
      total: 0,
      status: [
        "Pending",
        "Confirmed",
        "Processing",
        "Delivered",
        "Hold",
        "Returned",
        "Canceled",
      ],
      // myStatus: [
      //   { key: 1, text: "Received Order" },
      //   { key: 2, text: "Given Order" },
      // ],
      filterObj: {
        date_range: [],
        DateFrom: "",
        DateTo: "",
        Keyword: "",
        Page: 1,
        Length: 10,
      },
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  created() {
    if (this.$route.name == "MyOrder") {
      this.filterObj.Type = 2; //for customer
    } else {
      this.filterObj.Type = 1; //for seller
    }
    this.getMyOrders();
  },
  mounted() {
    this.Fire.$on("pageChanged", (val) => {
      this.filterObj.Page = parseInt(val);
      this.getMyOrders();
    });
    this.Fire.$on("orderCanceled", (orderId) => {
      var itemIndex = this.orderObj.data.findIndex((obj) => obj.Id === orderId);
      this.orderObj.data[itemIndex].Status = "Canceled";
    });
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
    this.Fire.$off("orderCanceled");
  },
  methods: {
    onReset() {
      this.filterObj.date_range = [];
      this.filterObj.DateFrom = "";
      this.filterObj.DateTo = "";
      this.filterObj.Keyword = "";
      this.filterObj.Status = "";
      this.filterObj.Page = 1;
      this.filterObj.Length = 10;
      this.getMyOrders();
    },
    getMyOrders() {
      if (this.loading) return;
      this.loading = true;
      this.formatFilterObj();
      this.$http
        .post(this.$store.state.Configs.getMyOrders, this.filterObj)
        .then((result) => {
          this.orderObj = result.data;
          this.loading = false;
          this.total = this.orderObj.recordsTotal;
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatFilterObj() {
      if (this.filterObj.date_range.length > 0) {
        if (this.filterObj.date_range.length == 1) {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[0];
        } else {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[1];
        }
      }
    },
  },
  watch: {
    $route(to) {
      //because this component use in two route //myOrder and ReceivedOrder
      if (to.name == "MyOrder") {
        this.filterObj.Type = 2; //for customer
      } else {
        this.filterObj.Type = 1; //for seller
      }
      this.onReset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/account_table.scss";
</style>
