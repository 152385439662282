<template>
  <common-pages v-if="pageId" :page-id="pageId"></common-pages>
</template>

<script>
import CommonPages from "@/components/sites/common/CommonPages";

export default {
  name: "Pages",
  components: {
    CommonPages,
  },
  data() {
    return {
      pageId: 0,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    aboutUsObj() {
      return this._.find(this.siteSettings.FooterMenus, { Slug: "about-us" });
    },
  },
  created() {
    this.pageId = this.aboutUsObj.Id;
  },
};
</script>
