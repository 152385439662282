<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-card tile elevation="1">
          <v-flex class="create-ad">
            <v-card-title
              class="add-title"
              v-text="$t('Provide your item information')"
            ></v-card-title>
            <v-flex class="pick-category d-flex">
              <h3 v-text="$t('Categories') + ':'"></h3>

              <strong
                class="d-flex flex-wrap pl-2"
                v-text="selectedObj.CategoryName"
              ></strong>
              <router-link
                :to="{ name: 'postProductStep1', params: { Id: routeParamId } }"
                class="edit-category brand--text"
                v-text="$t('Edit')"
              ></router-link>
            </v-flex>

            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                enctype="multipart/form-data"
              >
                <v-row>
                  <!-- Condition section-->
                  <ValidationProvider
                    tag="div"
                    class="conditions d-flex align-center col-md-6"
                    name="Condition"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <h4 v-text="$t('Condition')"></h4>
                    <div class="control" :class="classes">
                      <v-radio-group
                        v-model="formData.Condition"
                        row
                        hide-details
                      >
                        <v-radio
                          v-for="condition in conditions"
                          :key="condition.key"
                          color="brand"
                          :label="condition.title"
                          :value="condition.key"
                        ></v-radio>
                      </v-radio-group>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <!-- Is Imported section-->
                  <ValidationProvider
                    tag="div"
                    class="input-wrap d-flex align-center col-md-6"
                    name="Is Imported"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-checkbox
                        ref="country"
                        v-model="formData.IsImported"
                        :label="$t('Is Imported') + ' ?'"
                        color="primary"
                        :true-value="true"
                        :false-value="false"
                      ></v-checkbox>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-row>
                <v-row>
                  <!-- Ad Title -->
                  <ValidationProvider
                    tag="div"
                    class="col-md-6 py-0"
                    name="title"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-flex
                        class="input-label"
                        v-text="$t('Title') + '*'"
                      ></v-flex>
                      <v-text-field
                        solo
                        flat
                        hide-details
                        outlined
                        :label="$t('Type Title') + '*'"
                        v-model="formData.ItemName"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <!-- Brand -->
                  <ValidationProvider
                    tag="div"
                    class="col-md-6 py-0"
                    name="Brand"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-flex class="input-label" v-text="$t('Brand')"></v-flex>

                      <v-autocomplete
                        v-model="formData.BrandName"
                        :items="brands"
                        chips
                        flat
                        clearable
                        :label="$t('Your Brand')"
                        hide-details
                        solo
                        outlined
                      ></v-autocomplete>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <ValidationProvider
                    tag="div"
                    class="col-md-12 py-0"
                    name="MetaKeywords"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-flex class="input-label">
                        {{ $t("Keywords") }}*
                        <small
                          v-text="'(' + $t('Please press enter key') + ')'"
                        ></small>
                      </v-flex>
                      <v-combobox
                        v-model="formData.MetaKeywords"
                        :items="metaTags"
                        :search-input.sync="keywordSearch"
                        chips
                        flat
                        clearable
                        :label="$t('Add Keyword') + '*'"
                        hide-details
                        multiple
                        solo
                        outlined
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="removeTag(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No results matching "<strong>{{
                                  keywordSearch
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-row>

                <v-row v-if="attributesArr.length">
                  <div
                    class="input-wrap col-md-3"
                    v-for="(group, index) in attributesArr"
                    :key="index"
                  >
                    <v-flex class="input-label" v-text="group.Name"></v-flex>

                    <v-select
                      :items="group.Attributes"
                      :multiple="group.IsMultiple"
                      item-key="Id"
                      item-value="Id"
                      item-text="Name"
                      :label="'Select ' + group.Name"
                      solo
                      flat
                      hide-details
                      outlined
                      v-model="selectedArr[group.Id]"
                    ></v-select>
                  </div>
                </v-row>

                <!-- Ad Warrenty -->
                <v-row>
                  <ValidationProvider
                    tag="div"
                    class="input-wrap col-md-2"
                    name="warrenty"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-checkbox
                        ref="country"
                        v-model="formData.HasWarranty"
                        :label="$t('Is Warranty available') + '?'"
                        color="primary"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                      ></v-checkbox>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <!-- Ad warranty description -->
                  <ValidationProvider
                    v-if="formData.HasWarranty"
                    tag="div"
                    class="col-md-4 py-0"
                    name="product warranty note"
                    :rules="'max:50|required'"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        solo
                        flat
                        hide-details
                        outlined
                        :label="$t('Example 1 year 5 month')"
                        v-model="formData.WarrantyNote"
                      ></v-text-field>

                      <small
                        class="grey--text lighten-2"
                        v-text="$t('Type product warrenty')"
                      ></small>
                      <span class="pl-2">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-row>

                <!-- Image Uploader -->
                <multi-image-uploader
                  title="Photo Gallery"
                  id="images-uploader-1"
                  edit-id="images-uploader-edit-1"
                  :img-config.sync="imgConfig"
                  :options="uploadOptions"
                  :data-images="selectedImages"
                ></multi-image-uploader>

                <!-- check auction -->
                <v-radio-group
                  v-model="formData.SaleType"
                  row
                  hide-details
                  @change="
                    stockTypeChange(formData.SaleType, formData.StockType)
                  "
                >
                  <v-row class="auction-check">
                    <v-col sm="4" cols="12" class="py-0 mb-2">
                      <v-radio
                        class="general"
                        :label="$t('General Sale')"
                        value="Gallery"
                      ></v-radio>
                    </v-col>
                    <v-col sm="4" cols="12" class="py-0 mb-2">
                      <v-radio
                        class="sale-by-nilam"
                        color="brand"
                        :label="$t('Sale by Nilam')"
                        value="Auction"
                      ></v-radio>
                    </v-col>
                    <v-col sm="4" cols="12" class="py-0 mb-2">
                      <v-radio
                        class="bargain"
                        color="brand"
                        :label="$t('Sale by Bargain')"
                        value="Bargain"
                      ></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>

                <v-row class="pt-3">
                  <!-- measurement -->
                  <ValidationProvider
                    tag="div"
                    class="col-md-4 py-0"
                    name="measurement"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-flex
                        class="input-label"
                        v-text="$t('Measurement') + '*'"
                      ></v-flex>
                      <v-select
                        :items="measurements"
                        item-key="Id"
                        item-value="Id"
                        item-text="Name"
                        :label="$t('Select Measurement') + '*'"
                        solo
                        flat
                        hide-details
                        outlined
                        v-model="formData.DefaultMeasurementId"
                      ></v-select>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <!-- Stock Type -->
                  <ValidationProvider
                    tag="div"
                    class="col-md-4 py-0"
                    name="stock type"
                    rules="required"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-flex
                        class="input-label"
                        v-text="$t('Stock Type') + '*'"
                      ></v-flex>
                      <v-select
                        :items="stockTypes"
                        item-key="key"
                        item-value="key"
                        item-text="title"
                        :label="$t('Select Stock Type') + '*'"
                        solo
                        flat
                        hide-details
                        outlined
                        @change="
                          stockTypeChange(formData.SaleType, formData.StockType)
                        "
                        v-model="formData.StockType"
                      ></v-select>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>

                  <!-- stock quantity -->
                  <ValidationProvider
                    tag="div"
                    class="col-md-4 py-0"
                    name="stock quantity"
                    rules="required"
                    :bails="false"
                    v-if="stockQtyEnable"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-flex
                        class="input-label"
                        v-text="$t('Stock Quantity') + '*'"
                      ></v-flex>
                      <v-text-field
                        solo
                        flat
                        hide-details
                        outlined
                        @wheel="$event.target.blur()"
                        type="number"
                        :label="$t('Type Your Quantity')"
                        v-model="formData.StockQuantity"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-row>

                <!-- Sell with auction -->
                <v-flex
                  v-if="formData.SaleType == 'Auction'"
                  class="nilam-sale-panel"
                >
                  <v-flex class="with-auction mt-3">
                    <v-row>
                      <!-- Starting Price -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-4 py-0"
                        name="Starting Price"
                        rules="required"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap" :class="classes">
                          <v-flex
                            class="input-label"
                            v-text="$t('Starting Price (TK)') + '*'"
                          ></v-flex>
                          <v-flex class="tooltip-wrap">
                            <v-flex>
                              <v-text-field
                                solo
                                flat
                                hide-details
                                outlined
                                @wheel="$event.target.blur()"
                                type="number"
                                :label="$t('Example 700 TK')"
                                v-model="formData.BasePrice"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </v-flex>
                            <v-flex class="tooltip-holder">
                              <v-tooltip top max-width="400" color="success">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-information-outline</v-icon
                                  >
                                </template>
                                <span
                                  class="tooltip-info"
                                  v-text="note.startingPrice.text"
                                ></span>
                              </v-tooltip>
                            </v-flex>
                          </v-flex>
                        </div>
                      </ValidationProvider>

                      <!-- Buy Now Price -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-4 py-0"
                        name="Buy Now Price"
                        :rules="'required|min_value:' + buyNowPriceValidatin()"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap" :class="classes">
                          <v-flex
                            class="input-label"
                            v-text="$t('Buy Now Price') + '*'"
                          ></v-flex>
                          <v-flex class="tooltip-wrap">
                            <v-flex>
                              <v-text-field
                                solo
                                flat
                                hide-details
                                outlined
                                @wheel="$event.target.blur()"
                                type="number"
                                :label="$t('Example 800 TK')"
                                v-model="formData.SalesPrice"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </v-flex>
                            <v-flex class="tooltip-holder">
                              <v-tooltip top max-width="400" color="success">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-information-outline</v-icon
                                  >
                                </template>
                                <span
                                  class="tooltip-info"
                                  v-text="note.buyNowPrice.text"
                                ></span>
                              </v-tooltip>
                            </v-flex>
                          </v-flex>
                        </div>
                      </ValidationProvider>

                      <!-- Reserve price -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-4 py-0"
                        name="Reserve Price"
                        ref="reservePrice"
                        :rules="
                          'required|min_value:' +
                          reservePriceValidation(true) +
                          '|max_value:' +
                          reservePriceValidation()
                        "
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap" :class="classes">
                          <v-flex
                            class="input-label"
                            v-text="$t('Reserve Price (Last Price) (TK)')"
                          ></v-flex>
                          <v-flex class="tooltip-wrap">
                            <v-flex>
                              <v-text-field
                                solo
                                flat
                                hide-details
                                outlined
                                @wheel="$event.target.blur()"
                                type="number"
                                :label="$t('Example 7200 TK')"
                                v-model="formData.StandardPrice"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </v-flex>
                            <v-flex class="tooltip-holder">
                              <v-tooltip top max-width="400" color="success">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on"
                                    >mdi-information-outline</v-icon
                                  >
                                </template>
                                <span
                                  class="tooltip-info"
                                  v-text="note.reservePrice.text"
                                ></span>
                              </v-tooltip>
                            </v-flex>
                          </v-flex>
                        </div>
                      </ValidationProvider>
                      <!-- Company is use -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-12 py-0 pt-4"
                        name="company IsAutomaticReAuction"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap renilam" :class="classes">
                          <v-checkbox
                            v-model="formData.IsAutomaticReAuction"
                            :label="
                              $t(
                                'Automatic Re-Nilam (Maximum Bid Price is lower than Reserve Price.)'
                              ) + '?'
                            "
                            color="primary"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                          ></v-checkbox>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>

                      <ValidationProvider
                        tag="div"
                        class="col-md-12 py-0"
                        name="company HasSpecialAuctionRequest"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap renilam" :class="classes">
                          <v-checkbox
                            v-model="formData.HasSpecialAuctionRequest"
                            :label="
                              $t('Request for nilam house (special nilam)') +
                              '?'
                            "
                            color="primary"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                          ></v-checkbox>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>

                      <v-col cols="12" md="12">
                        <ul class="note">
                          <div v-text="$t('Note') + ':'"></div>
                          <li v-for="(val, index) in notesArr" :key="index">
                            <small
                              class="silver_tree--text"
                              v-text="val.title"
                            ></small>
                            <small v-text="' : ' + val.text"></small>
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-flex>

                  <v-flex
                    class="auction-time mt-5"
                    v-if="
                      Object.keys(auctionPolicy).length &&
                      auctionPolicy.HasUserPrivilegeOnAuctionTime
                    "
                  >
                    <h3 v-text="$t('Auction Time & Duration')"></h3>
                    <v-sheet
                      color="transparent"
                      v-text="
                        $t(
                          'Bidding will be start after approving this post by Nilam. If you want change the bidding time as your own you can set here'
                        )
                      "
                    ></v-sheet>
                    <v-layout wrap class="align-center">
                      <v-flex
                        class="input-label flex-grow-0 pr-3"
                        v-text="$t('Bid Date Range Time')"
                      ></v-flex>

                      <div
                        class="input-wrap pb-0"
                        v-if="
                          Object.keys(auctionPolicy).length &&
                          minimumDate &&
                          maximumDate
                        "
                      >
                        <date-range-picker
                          ref="picker"
                          opens="center"
                          :time-picker="true"
                          :time-picker24-hour="false"
                          :ranges="false"
                          :min-date="minimumDate.toLocaleDateString()"
                          :max-date="maximumDate.toLocaleDateString()"
                          control-container-class="form-control reportrange-text"
                          :locale-data="{
                            format: 'dd-mmm-yyyy HH:mm:ss',
                            firstDay: 1,
                          }"
                          v-model="formData.dateRange"
                        >
                          <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                          >
                            {{ picker.startDate | date }}
                            -
                            {{ picker.endDate | date }}
                          </template>
                        </date-range-picker>
                      </div>
                    </v-layout>
                  </v-flex>
                </v-flex>

                <!-- Bargain Sale-->
                <v-flex
                  v-if="formData.SaleType == 'Bargain'"
                  class="with-auction mt-3 bargain-sale-panel"
                >
                  <v-row>
                    <!-- Starting Price -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="Sales Price"
                      rules="required"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Sales Price (TK)') + '*'"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              :label="$t('Example 800 TK')"
                              v-model="formData.SalesPrice"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                          <v-flex class="tooltip-holder">
                            <v-tooltip top max-width="400" color="success">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span
                                class="tooltip-info"
                                v-text="note.salesPriceBargain.text"
                              ></span>
                            </v-tooltip>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <!-- Last price -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="Last Price"
                      :rules="'required|max_value:' + lastPriceValidation()"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Last Price (TK)') + ' *'"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              :label="$t('Example 7200 TK')"
                              v-model="formData.StandardPrice"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                          <v-flex class="tooltip-holder">
                            <v-tooltip top max-width="400" color="success">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span
                                class="tooltip-info"
                                v-text="note.lastPrice.text"
                              ></span>
                            </v-tooltip>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <!-- Discount amount -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 py-0"
                      name="Discount"
                      :rules="''"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Discount (TK)')"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              :label="$t('Example 200 TK')"
                              @blur="setItNull"
                              v-model.number="formData.ItemDiscount.Discount"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <!-- Discount Start At -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 pt-1"
                      name="Discount Start At"
                      :rules="''"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <v-flex
                        class="input-label"
                        v-text="$t('Discount Start At')"
                      ></v-flex>
                      <div class="input-wrap" :class="classes">
                        <v-dialog
                          ref="dialog1"
                          v-model="bargainDiscountStartAtModal"
                          :return-value.sync="formData.ItemDiscount.StartAt"
                          persistent
                          width="300px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formData.ItemDiscount.StartAt"
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Date Start')"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </template>

                          <v-date-picker
                            v-model="formData.ItemDiscount.StartAt"
                            scrollable
                            :allowed-dates="allowedDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="bargainDiscountStartAtModal = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dialog1.save(
                                  formData.ItemDiscount.StartAt
                                )
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </ValidationProvider>

                    <!-- Discount End At -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 pt-1"
                      name="Discount End At"
                      :rules="''"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <v-flex
                        class="input-label"
                        v-text="$t('Discount End At')"
                      ></v-flex>
                      <div class="input-wrap" :class="classes">
                        <v-dialog
                          ref="dialog2"
                          v-model="bargainDiscountEndAtModal"
                          :return-value.sync="formData.ItemDiscount.EndAt"
                          persistent
                          width="300px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="formData.ItemDiscount.EndAt"
                              solo
                              flat
                              hide-details
                              outlined
                              :label="$t('Date End')"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </template>

                          <v-date-picker
                            v-model="formData.ItemDiscount.EndAt"
                            scrollable
                            :allowed-dates="allowedDates"
                            :min="formData.ItemDiscount.StartAt"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="bargainDiscountEndAtModal = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dialog2.save(formData.ItemDiscount.EndAt)
                              "
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </ValidationProvider>

                    <!-- Discount isParcentage -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-12"
                      name="Discount Percentage"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap renilam" :class="classes">
                        <v-checkbox
                          v-model="formData.ItemDiscount.IsPercentage"
                          :label="
                            $t(
                              'Percentage (Item discount will be calculated by percentage of discount amount.)'
                            ) + ' ?'
                          "
                          color="primary"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                        ></v-checkbox>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>

                    <v-col cols="12" md="12">
                      <ul class="note">
                        <div v-text="$t('Note') + ':'"></div>
                        <li v-for="(val, index) in notesArr" :key="index">
                          <small
                            class="silver_tree--text"
                            v-text="val.title"
                          ></small>
                          <small v-text="' : ' + val.text"></small>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-flex>

                <!-- Gallery Sale-->
                <v-flex
                  v-if="formData.SaleType == 'Gallery'"
                  class="with-auction mt-3 general-sale-panel"
                >
                  <v-row>
                    <!-- Starting Price -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-6 py-0"
                      name="Sales Price"
                      rules="required"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div
                        class="input-wrap d-flex flex-wrap align-center"
                        :class="classes"
                      >
                        <v-flex
                          class="input-label flex-grow-0 pr-3"
                          v-text="$t('Sales Price (TK)') + '*'"
                        ></v-flex>
                        <v-flex class="tooltip-wrap d-flex">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              :label="$t('Example 800 TK')"
                              v-model="formData.SalesPrice"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                          <v-flex class="tooltip-holder">
                            <v-tooltip top max-width="400" color="success">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span
                                class="tooltip-info"
                                v-text="note.salesPriceGallery.text"
                              ></span>
                            </v-tooltip>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <v-row class="pl-4">
                      <!-- Discount amount -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-3"
                        name="Discount"
                        :rules="''"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap" :class="classes">
                          <v-flex
                            class="input-label"
                            v-text="$t('Discount (TK)')"
                          ></v-flex>
                          <v-flex class="tooltip-wrap">
                            <v-flex>
                              <v-text-field
                                solo
                                flat
                                hide-details
                                outlined
                                @wheel="$event.target.blur()"
                                type="number"
                                :label="$t('Example 200 TK')"
                                @blur="setItNull"
                                v-model.number="formData.ItemDiscount.Discount"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </v-flex>
                          </v-flex>
                        </div>
                      </ValidationProvider>

                      <!-- Discount Start At -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-3"
                        name="Discount Start At"
                        :rules="''"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <v-flex
                          class="input-label pb-1"
                          v-text="$t('Discount Start At')"
                        ></v-flex>
                        <div class="input-wrap" :class="classes">
                          <v-dialog
                            ref="dialog3"
                            v-model="galleryDiscountStartAtModal"
                            :return-value.sync="formData.ItemDiscount.StartAt"
                            persistent
                            width="300px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formData.ItemDiscount.StartAt"
                                solo
                                flat
                                hide-details
                                outlined
                                :label="$t('Date Start')"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </template>

                            <v-date-picker
                              v-model="formData.ItemDiscount.StartAt"
                              scrollable
                              :allowed-dates="allowedDates"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="galleryDiscountStartAtModal = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.dialog3.save(
                                    formData.ItemDiscount.StartAt
                                  )
                                "
                                >OK</v-btn
                              >
                            </v-date-picker>
                          </v-dialog>
                        </div>
                      </ValidationProvider>

                      <!-- Discount End At -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-3"
                        name="Discount End At"
                        :rules="''"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <v-flex
                          class="input-label pb-1"
                          v-text="$t('Discount End At')"
                        ></v-flex>
                        <div class="input-wrap" :class="classes">
                          <v-dialog
                            ref="dialog4"
                            v-model="galleryDiscountEndAtModal"
                            :return-value.sync="formData.ItemDiscount.EndAt"
                            persistent
                            width="300px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formData.ItemDiscount.EndAt"
                                solo
                                flat
                                hide-details
                                outlined
                                :label="$t('Date End')"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </template>

                            <v-date-picker
                              v-model="formData.ItemDiscount.EndAt"
                              scrollable
                              :allowed-dates="allowedDates"
                              :min="formData.ItemDiscount.StartAt"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="galleryDiscountEndAtModal = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.dialog4.save(
                                    formData.ItemDiscount.EndAt
                                  )
                                "
                                >OK</v-btn
                              >
                            </v-date-picker>
                          </v-dialog>
                        </div>
                      </ValidationProvider>

                      <!-- Discount isParcentage -->
                      <ValidationProvider
                        tag="div"
                        class="col-md-12 py-0 pt-4"
                        name="Discount Percentage"
                        :bails="false"
                        v-slot="{ errors, classes }"
                      >
                        <div class="input-wrap renilam" :class="classes">
                          <v-checkbox
                            v-model="formData.ItemDiscount.IsPercentage"
                            :label="
                              $t(
                                'Percentage (Item discount will be calculated by percentage of discount amount.)'
                              ) + '?'
                            "
                            color="primary"
                            :true-value="true"
                            :false-value="false"
                            hide-details
                          ></v-checkbox>
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </v-row>

                    <v-col cols="12" md="12">
                      <ul class="note">
                        <div v-text="$t('Note') + ':'"></div>
                        <li v-for="(val, index) in notesArr" :key="index">
                          <small
                            class="silver_tree--text"
                            v-text="val.title"
                          ></small>
                          <small v-text="' : ' + val.text"></small>
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-flex>

                <v-flex
                  class="input-label mt-8 pb-1"
                  v-text="$t('Item Weight')"
                ></v-flex>
                <v-flex class="product-masurment">
                  <v-row>
                    <!-- Weight -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 py-0"
                      name="Weight"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Weight (KG)')"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              step="any"
                              :label="$t('Weight in kg')"
                              v-model="formData.ItemWeight.Weight"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <!-- Length -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 py-0"
                      name="Length"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Length (CM)')"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              step="any"
                              :label="$t('Length in cm')"
                              v-model="formData.ItemWeight.Length"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <!-- Width -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 py-0"
                      name="Width"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Width (CM)')"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              step="any"
                              :label="$t('Width in cm')"
                              v-model="formData.ItemWeight.Width"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>

                    <!-- Height -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-3 py-0"
                      name="Height"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Height (CM)')"
                        ></v-flex>
                        <v-flex class="tooltip-wrap">
                          <v-flex>
                            <v-text-field
                              solo
                              flat
                              hide-details
                              outlined
                              @wheel="$event.target.blur()"
                              type="number"
                              step="any"
                              :label="$t('Height in cm')"
                              v-model="formData.ItemWeight.Height"
                            ></v-text-field>
                            <span>{{ errors[0] }}</span>
                          </v-flex>
                        </v-flex>
                      </div>
                    </ValidationProvider>
                  </v-row>
                </v-flex>

                <!-- Item Summary & Description -->
                <v-flex class="product-masurment mt-3">
                  <v-row>
                    <!-- Ad Description -->
                    <ValidationProvider
                      tag="div"
                      class="col-md-12 py-0"
                      name="Summary"
                      rules="max:512"
                      :bails="false"
                      v-slot="{ errors, classes }"
                    >
                      <div class="input-wrap" :class="classes">
                        <v-flex
                          class="input-label"
                          v-text="$t('Summary')"
                        ></v-flex>
                        <v-textarea
                          solo
                          flat
                          hide-details
                          outlined
                          :label="$t('Product summary')"
                          v-model="formData.Summary"
                        ></v-textarea>
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </v-row>
                </v-flex>
                <!-- Item Summary & Description -->
                <v-flex class="mt-6">
                  <div class="input-label" v-text="$t('Details')"></div>
                  <vue-editor
                    useCustomImageHandler
                    @image-added="handleImageAdded"
                    v-model="formData.Description"
                  ></vue-editor>
                </v-flex>

                <v-flex class="d-flex justify-space-between align-center">
                  <router-link
                    :to="{
                      name: 'postProductStep1',
                      params: { Id: routeParamId },
                    }"
                    class="back primary--text"
                    v-text="$t('Back')"
                  ></router-link>
                  <v-btn
                    type="submit"
                    tile
                    depressed
                    class="publish-ad mt-5 brand white--text text-none"
                    >{{ $t("Submit") }}</v-btn
                  >
                </v-flex>
              </form>
            </ValidationObserver>
          </v-flex>
        </v-card>
      </v-container>
    </v-flex>

    <!-- success greeting popup -->
    <v-dialog content-class="success-popup" v-model="dialog" width="914">
      <v-sheet class="popup-wrap">
        <v-flex class="d-flex justify-center">
          <v-img
            max-width="72"
            max-height="66"
            :src="require('@/assets/images/success.png')"
            alt="success"
          ></v-img>
        </v-flex>
        <h3 v-text="$t('Your Item Has Been Submitted') + '!'"></h3>
        <span class="published d-block">
          {{ $t("View Your All") }}
          <router-link
            :to="{ name: 'MyProducts' }"
            v-text="$t('Products')"
          ></router-link>
        </span>
        <v-flex class="text-right">
          <v-btn
            :to="{ name: 'MyProducts' }"
            tile
            depressed
            class="brand white--text text-none"
            >{{ $t("Back To Product List") }}</v-btn
          >
        </v-flex>
        <img
          class="popup-hand"
          :src="require('@/assets/images/popup_hand.png')"
          alt="success"
        />
      </v-sheet>
    </v-dialog>
  </v-flex>
</template>

<script>
import MultiImageUploader from "@/components/services/MultiImageUploader";
import { VueEditor } from "vue2-editor";
import DateRangePicker from "vue2-daterange-picker";

export default {
  name: "postProductStep2",
  components: {
    MultiImageUploader,
    VueEditor,
    DateRangePicker,
  },
  data() {
    return {
      selectedObj: {},
      routeParamId: null,
      auctionPolicy: {},
      conditions: [
        { key: "New", title: "New" },
        { key: "Used", title: "Used" },
        { key: "ReNew", title: "Renew" },
      ],
      brands: [],
      stockTypes: [
        { key: "Lot", title: "Lot" },
        { key: "Single", title: "Single" },
      ],
      measurements: [],
      selectedImages: [],
      uploadOptions: {
        idUpload: "file",
        multiple: true,
        showPrimary: true,
        showDelete: true,
        referenceType: "Item",
        referenceId: 0,
        showEdit: false,
      },
      textFieldProps: {
        solo: true,
        flat: true,
        hideDetails: true,
        outlined: true,
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      minimumDate: null,
      maximumDate: null,
      formData: {
        ShippedBy: "Both",
        Condition: "New",
        SaleType: "Auction",
        dateRange: { startDate: null, endDate: null },
        IsAutomaticReAuction: false,
        HasSpecialAuctionRequest: false,
        HasWarranty: false,
        ItemDiscount: {
          IsPercentage: false,
          StartAt: null,
          EndAt: null,
        },
        AttributeIds: [],
        ItemWeight: {},
        MetaKeywords: [],
      },
      stockQtyEnable: true,
      dialog: false,
      galleryDiscountStartAtModal: false,
      galleryDiscountEndAtModal: false,
      bargainDiscountStartAtModal: false,
      bargainDiscountEndAtModal: false,
      attributesArr: [],
      selectedArr: [],
      metaTags: [],
      keywordSearch: null,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    imgConfig() {
      return this._.find(this.siteSettings.MediaConfiguration, {
        IsDefault: true,
        Entity: "Item",
      });
    },
    note() {
      return this.$store.state.Configs.priceNote;
    },
    notesArr() {
      return this._.filter(this.note, {
        SaleType: this.formData.SaleType,
      });
    },
  },
  created() {
    if (Object.keys(this.$route.params) && this.$route.params.id) {
      this.routeParamId = this.$route.params.id;
      this.uploadOptions.referenceId = this.routeParamId;
    }
    this.getAuctionPolicy();

    this.getStorageData().then((formData) => {
      this.stockTypeChange(formData.SaleType, formData.StockType);
      this.getTags(formData.CategoryId);
      this.getBrands();
      this.getCategoryWiseAttributes(formData.CategoryId, formData.Id);
    });

    this.getMeasurements();
    this.getMedia();
  },
  methods: {
    setItNull() {
      if (!this.formData.ItemDiscount.Discount) {
        delete this.formData.ItemDiscount.Discount;
      }
    },
    getTags(CategoryId) {
      this.$http
        .post(
          this.$store.state.Configs.getMetaTags.replace(
            ":categoryId",
            CategoryId
          )
        )
        .then((res) => {
          this.metaTags = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    removeTag(item) {
      this.formData.MetaKeywords.splice(
        this.formData.MetaKeywords.indexOf(item),
        1
      );
    },
    getBrands() {
      this.$http
        .post(this.$store.state.Configs.getAllBrands)
        .then((res) => {
          this.brands = res.data.map((el) => el.BrandName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    intiSelectedArr(group) {
      if (group.IsMultiple) {
        this.selectedArr[group.Id] = group.Selected;
      } else {
        this.selectedArr[group.Id] = group.Selected[0];
      }
    },
    getCategoryWiseAttributes(CategoryId, ItemId) {
      this.$http
        .post(
          this.$store.state.Configs.getCategoryWiseAttribute
            .replace(":categoryId", CategoryId)
            .replace(":itemId", ItemId || 0)
        )
        .then((res) => {
          this.attributesArr = res.data;
          this.attributesArr.forEach((element) => {
            this.intiSelectedArr(element);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    allowedDates: (val) => Date.parse(val) > Date.now() - 8.64e7,
    buyNowPriceValidatin() {
      let salesPrice = Number(this.formData.SalesPrice) || 0;
      let startingPrice = Number(this.formData.BasePrice) || 0;
      if (salesPrice) {
        return parseFloat(startingPrice) + 1;
      } else {
        return 0;
      }
    },
    reservePriceValidation(min) {
      let salesPrice = Number(this.formData.SalesPrice) || 0;
      let startingPrice = Number(this.formData.BasePrice) || 0;
      let reservePrice = Number(this.formData.StandardPrice) || 0;
      if (min) {
        return parseFloat(startingPrice) + 1;
      } else {
        if (!salesPrice) {
          return parseFloat(reservePrice);
        } else {
          return parseFloat(salesPrice);
        }
      }
    },
    lastPriceValidation() {
      let salesPrice = Number(this.formData.SalesPrice) || 0;
      if (salesPrice) {
        return parseFloat(salesPrice) - 1;
      } else {
        return 0;
      }
    },
    minDate(preparationHours, timesFactor) {
      let minDate = new Date();
      minDate.setSeconds(minDate.getSeconds() + preparationHours * timesFactor);
      return minDate;
    },
    maxDate(minDate, maximumBiddingDuration, timesFactor) {
      let maxDate = new Date(minDate.getTime());
      maxDate.setSeconds(
        minDate.getSeconds() + maximumBiddingDuration * timesFactor
      );
      return maxDate;
    },
    getStorageData() {
      return new Promise((resolve, reject) => {
        var selected = localStorage.getItem("postProductSelected");
        if (selected) {
          this.selectedObj = JSON.parse(selected);
          this.formData = { ...this.formData, ...this.selectedObj };
          if (this.selectedObj.MetaKeyword) {
            this.formData.MetaKeywords =
              this.selectedObj.MetaKeyword.split(",");
          }
          resolve(this.formData);
        } else {
          reject("selected object not found");
        }
      });
    },
    generateUploaderData(Arr) {
      var dataArr = [];
      Arr.forEach((element) => {
        let obj = {
          Id: element.Id,
          default: element.IsCover,
          path: encodeURI(this.$root.generateSlideImageUrl(element)),
          name: element.Title,
        };
        dataArr.push(obj);
      });
      return dataArr;
    },
    getMedia() {
      this.$http
        .post(
          this.$store.state.Configs.getMediaImages
            .replace(":type", "Item")
            .replace(":refId", this.routeParamId || 0)
        )
        .then((res) => {
          if (res.data.length) {
            this.selectedImages = this.generateUploaderData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAuctionPolicy() {
      this.$http
        .post(this.$store.state.Configs.getAuctionPolicy)
        .then((res) => {
          this.auctionPolicy = res.data;
        })
        .then(() => {
          this.minimumDate = this.minDate(
            this.auctionPolicy.PreparationDuration,
            this.auctionPolicy.TimesFactor
          );
        })
        .then(() => {
          this.maximumDate = this.maxDate(
            this.minimumDate,
            this.auctionPolicy.MaximumBiddingDuration,
            this.auctionPolicy.TimesFactor
          );
        })
        .then(() => {
          var obj = {
            dateRange: {
              startDate: this.minimumDate,
              endDate: this.maximumDate,
            },
          };
          this.formData = {
            ...this.formData,
            ...obj,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMeasurements() {
      this.$http
        .post(this.$store.state.Configs.getMeasurements)
        .then((res) => {
          this.measurements = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("ReferenceType", "Editor");
      formData.append("ReferenceId", this.$auth?.user?.UserId);

      this.$http({
        url: this.$store.state.Configs.addMedia,
        method: "POST",
        data: formData,
      })
        .then((result) => {
          let url = this.$root.generateMediaImageUrl(
            result.data.ResponseData.FilePath
          );
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    stockTypeChange(SaleType, StockType) {
      if (SaleType == "Auction") {
        if (StockType == "Single") {
          this.stockQtyEnable = false;
          this.formData.StockQuantity = 1;
        } else {
          this.formData.StockQuantity = null;
          this.stockQtyEnable = true;
        }

        this.formData.ItemDiscount = {
          IsPercentage: false,
          StartAt: null,
          EndAt: null,
        };
      } else {
        this.formData.BasePrice = 0;
        this.formData.StockQuantity = this.selectedObj.StockQuantity;
        this.formData.IsAutomaticReAuction = false;
        this.formData.HasSpecialAuctionRequest = false;
        // this.formData.dateRange = { startDate: null, endDate: null };

        this.stockQtyEnable = true;
        if (SaleType == "Gallery") {
          this.formData.StandardPrice = 0;
        }
      }
    },
    productSubmit() {
      return new Promise((resolve, reject) => {
        if (this.formData.dateRange) {
          this.formData.BidStartTime = this.formData.dateRange.startDate;
          this.formData.BidEndTime = this.formData.dateRange.endDate;
        }
        if (this.selectedArr.length) {
          this.formData.AttributeIds = this._.filter(
            this._.flatten(this.selectedArr)
          );
        }

        this.selectedObj = { ...this.selectedObj, ...this.formData };

        this.$http
          .post(this.$store.state.Configs.saveProduct, this.selectedObj)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      this.$refs.form.validate().then(() => {
        // Wait until the models are updated in the UI
        this.productSubmit()
          .then(() => {
            this.$nextTick(() => {
              this.selectedObj = {};
              this.formData.OriginAddress = {};
              localStorage.removeItem("postProductSelected");
              this.dialog = true;
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              //console.log(err)
              //this.$vuetify.goTo(0);

              localStorage.setItem(
                "postProductSelected",
                JSON.stringify(this.selectedObj)
              );
              this.$store.commit("set_flash", {
                type: "error",
                message:
                  err.response?.data ?? "Something error! Please try again",
              });
            });
          });
      });
    },
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  mounted() {
    if (this.siteSettings.IsECommerce) {
      let resolved = this.$router.resolve({
        name: "post-product-2",
      });
      //changed filter object value to url as query without triggering route change event
      window.history.replaceState(
        "NilamPushState",
        "postProduct2",
        resolved.href
      );
      this.$router.push("/page-not-found");
    }
  },
  watch: {
    "formData.HasWarranty": {
      handler(val) {
        if (!val) {
          this.formData.WarrantyNote = "";
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vue2-daterange-picker/dist/vue2-daterange-picker.css";
@import "@/sass/create_free_ad.scss";
</style>
