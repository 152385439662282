<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-layout wrap class="account-panel">
                <!-- summary sections -->
                <v-flex class="join-campaign-wrap">
                  <h2 class="pb-1">Flash Sales 7-13 June</h2>
                  <v-layout wrap class="breadcrumb-container align-center">
                    <site-breadcrumb
                      :options="items"
                      class="mr-4"
                    ></site-breadcrumb>

                    <v-btn depressed rounded class="brand white--text text-none"
                      >Campaign Registration in Progress</v-btn
                    >
                  </v-layout>

                  <!-- Campaign timeline -->
                  <div class="tab-timeline">
                    <ul class="d-flex flex-wrap m-0 p-0">
                      <li
                        v-for="(stape, index) in stapes"
                        :key="index"
                        :class="{ active: stape.active }"
                      >
                        <span class="bar">
                          <i class="tab-icon"></i>
                          <div class="tab-info" v-text="stape.text"></div>
                        </span>
                        <span class="arrow"></span>
                      </li>
                    </ul>
                  </div>
                  <!-- End campaign timeline -->

                  <a
                    class="add-product-btn d-block text-center"
                    @click="dialog = !dialog"
                    >+ {{ $t("Add Product") }}</a
                  >

                  <!-- Campaign table -->
                  <v-flex class="campaign-tbl">
                    <v-layout class="campaign-tbl-item tbl-header">
                      <v-flex class="selected justify-center">Selected</v-flex>
                      <v-flex class="sku">SKU</v-flex>
                      <v-flex class="image">Image</v-flex>
                      <v-flex class="name">Product Name</v-flex>
                      <v-flex class="cat">Category</v-flex>
                      <v-flex class="price">Price</v-flex>
                      <v-flex class="cam-price">Campaign Price</v-flex>
                      <v-flex class="c-stock">Current Stock</v-flex>
                      <v-flex class="r-stock">Required Stock</v-flex>
                      <v-flex class="actions justify-center">Actions</v-flex>
                    </v-layout>

                    <v-layout
                      v-for="(flashProduct, index) in flashProducts"
                      :key="index"
                      class="campaign-tbl-item"
                    >
                      <v-flex class="selected justify-center">
                        <v-checkbox
                          hide-details
                          :ripple="false"
                          v-model="selected"
                          :value="index"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex class="sku">
                        <span v-text="flashProduct.SKU"></span>
                      </v-flex>
                      <v-flex class="image">
                        <img
                          :src="
                            require('@/assets/images/' + flashProduct.Image)
                          "
                        />
                      </v-flex>
                      <v-flex class="name">
                        <h5 v-text="flashProduct.Name"></h5>
                      </v-flex>
                      <v-flex class="cat">
                        <span v-text="flashProduct.Category"></span>
                      </v-flex>
                      <v-flex class="price">
                        <span v-text="flashProduct.Price"></span>
                      </v-flex>
                      <v-flex class="cam-price">
                        <span v-text="flashProduct.Campaign_Price"></span>
                      </v-flex>
                      <v-flex class="c-stock">
                        <span v-text="flashProduct.Current_Stock"></span>
                      </v-flex>
                      <v-flex class="r-stock">
                        <span v-text="flashProduct.Required_Stock"></span>
                      </v-flex>
                      <v-flex class="actions justify-center">
                        <router-link to="/">
                          <v-icon size="20">mdi-pencil</v-icon>
                        </router-link>
                        <router-link to="/">
                          <v-icon size="20">mdi-eye</v-icon>
                        </router-link>
                        <router-link to="/">
                          <v-icon size="19">mdi-delete</v-icon>
                        </router-link>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <!--End Campaign table -->

                  <!-- Table Buttons-->
                  <v-layout wrap class="table-btn mt-10 pa-4 justify-end">
                    <v-btn
                      href="#"
                      depressed
                      height="38"
                      outlined
                      rounded
                      color="grey"
                      class="text-none mr-5"
                    >
                      Selected Product
                      <span
                        class="brand--text pl-1"
                        v-text="`(${totalSelected})`"
                      ></span>
                    </v-btn>

                    <v-btn
                      href="#"
                      depressed
                      rounded
                      height="38"
                      class="brand white--text text-none"
                      >Submit For Campaign</v-btn
                    >
                  </v-layout>
                  <!-- End Table Buttons -->
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>

    <v-dialog v-model="dialog" width="1100">
      <v-card>
        <v-flex class="pa-5">
          <!-- filters-bar -->
          <v-layout wrap class="top-filter">
            <v-flex
              class="selected-product d-flex align-center flex-grow-0 pr-5"
            >
              <span
                class="d-flex align-center justify-center mr-2"
                v-text="totalselectedCampaign"
              ></span>
              Select Product
            </v-flex>
            <div class="input-wrap">
              <v-select
                :items="conditions"
                dense
                solo
                flat
                hide-details
                outlined
                label="Category"
                v-model="status"
              ></v-select>
            </div>
            <div class="input-wrap">
              <v-text-field
                solo
                flat
                hide-details
                outlined
                label="Product Name"
                v-model="keyword"
              ></v-text-field>
            </div>
            <div class="input-wrap">
              <v-text-field
                solo
                flat
                hide-details
                outlined
                label="Product SKU"
                v-model="keyword"
              ></v-text-field>
            </div>

            <v-btn
              height="42"
              type="submit"
              tile
              depressed
              class="brand white--text text-none"
              >Search</v-btn
            >
          </v-layout>
          <v-flex class="campaign-tbl">
            <v-layout class="campaign-tbl-item tbl-header">
              <v-flex class="selected justify-center">Selected</v-flex>
              <v-flex class="sku">SKU</v-flex>
              <v-flex class="image">Image</v-flex>
              <v-flex class="name">Product Name</v-flex>
              <v-flex class="cat">Category</v-flex>
              <v-flex class="price">Price</v-flex>
              <v-flex class="cam-price">Status</v-flex>
              <v-flex class="c-stock">Current Stock</v-flex>
            </v-layout>

            <v-layout
              v-for="(flashProduct, index) in flashProducts"
              :key="index"
              class="campaign-tbl-item"
            >
              <v-flex class="selected justify-center">
                <v-checkbox
                  hide-details
                  :ripple="false"
                  v-model="selectedCampaign"
                  :value="index"
                ></v-checkbox>
              </v-flex>
              <v-flex class="sku">
                <span v-text="flashProduct.SKU"></span>
              </v-flex>
              <v-flex class="image">
                <img :src="require('@/assets/images/' + flashProduct.Image)" />
              </v-flex>
              <v-flex class="name">
                <h5 v-text="flashProduct.Name"></h5>
              </v-flex>
              <v-flex class="cat">
                <span v-text="flashProduct.Category"></span>
              </v-flex>
              <v-flex class="price">
                <span v-text="flashProduct.Price"></span>
              </v-flex>
              <v-flex class="cam-price">
                <span v-text="'Eligible'"></span>
              </v-flex>
              <v-flex class="c-stock">
                <span v-text="flashProduct.Current_Stock"></span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-layout wrap class="mt-5 pa-4 justify-end">
            <v-btn
              href="#"
              depressed
              height="38"
              outlined
              rounded
              color="grey"
              class="text-none mr-5"
            >
              Selected Product
              <span
                class="brand--text pl-1"
                v-text="`(${totalselectedCampaign})`"
              ></span>
            </v-btn>

            <v-btn
              href="#"
              depressed
              rounded
              height="38"
              class="brand white--text text-none"
              >Submit For Campaign</v-btn
            >
          </v-layout>
        </v-flex>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import SiteBreadcrumb from "@/components/sites/common/SiteBreadcrumb";

export default {
  name: "JoinCampaign",
  components: {
    AccountSidebar,
    SiteBreadcrumb,
  },
  data() {
    return {
      selected: [],
      selectedCampaign: [],
      keyword: "",
      conditions: ["New", "Used", "ReNew"],
      status: "",
      dialog: false,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Campaign Management",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Flash Sales 7-13 November",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Add Product",
          disabled: true,
          href: "breadcrumbs_dashboard",
        },
      ],
      stapes: [
        {
          text: "Select Product",
          active: true,
        },
        {
          text: "Campaign Price",
          active: false,
        },
        {
          text: "Submit",
          active: false,
        },
      ],
      flashProducts: [
        {
          SKU: "I- 211205018967",
          Image: "no-img.jpg",
          Name: "Mediker SafeLife Hand Wash 170ml Refill -12 pcs",
          Category: "Highlighters & Contour",
          Price: "460",
          Campaign_Price: "400",
          Current_Stock: "865",
          Required_Stock: "600 Min",
        },
        {
          SKU: "I- 211205018967",
          Image: "no-img.jpg",
          Name: "Mediker SafeLife Hand Wash 170ml Refill -12 pcs",
          Category: "Highlighters & Contour",
          Price: "460",
          Campaign_Price: "400",
          Current_Stock: "865",
          Required_Stock: "600 Min",
        },
      ],
    };
  },

  methods: {},

  computed: {
    totalSelected() {
      return this.selected.length;
    },
    totalselectedCampaign() {
      return this.selectedCampaign.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/statusbar.scss";
@import "@/sass/campaign_tbl.scss";
@import "@/sass/join_campaign.scss";
</style>
