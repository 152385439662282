<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-card tile elevation="1">
          <v-flex class="create-ad">
            <!-- <v-img height="auto" :src="require('@/assets/images/create-post.jpg')"> -->
            <v-flex
              class="create-ad-top"
              :style="{
                background:
                  'url(' +
                  require('@/assets/images/create-post.jpg') +
                  ') center center',
              }"
            >
              <h2 v-text="$t('Post your item here')"></h2>

              <v-row
                v-if="
                  balanceObj &&
                  Object.keys(balanceObj).length &&
                  siteSettings.IsUsePoint
                "
              >
                <v-col class="py-0" cols="12" md="6">
                  <v-flex class="note">
                    {{ $t("*N.B: Required point to create a post:") }}
                    <span v-text="applicationPolicy.RequiredPointOnPostProduct"
                      >0</span
                    >
                    <v-tooltip right max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" size="18" v-bind="attrs" v-on="on"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        class="tooltip-info"
                        v-text="
                          $t(
                            'This is the number of point required to submit a post for this ad.'
                          )
                        "
                      ></span>
                    </v-tooltip>
                  </v-flex>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <v-flex class="available-point flex-grow-0">
                    <span class="allowance">
                      {{ $t("Available points :") }}
                      <strong v-text="balanceObj.AvailablePoint">0</strong>
                    </span>

                    <router-link
                      class="buy-points"
                      :to="{ name: 'PostingSummary' }"
                      v-text="$t('Buy points')"
                    ></router-link>
                  </v-flex>
                </v-col>
              </v-row>
            </v-flex>

            <v-flex class="pick-category">
              <span v-text="$t('Selected')"></span>
              <v-flex class="d-flex">
                <h3 v-text="$t('Categories') + ':'"></h3>

                <ul class="d-flex flex-wrap">
                  <li v-text="selectedObj.CategoryName"></li>
                </ul>
              </v-flex>
            </v-flex>

            <template v-if="allCategories && allCategories.length">
              <div class="input-wrap mb-5">
                <v-text-field
                  v-model="search"
                  :label="$t('Search Category')"
                  solo
                  flat
                  outlined
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </div>

              <v-flex class="cat-select-wrap">
                <v-treeview
                  activatable
                  dense
                  color="brand"
                  :items="allCategories"
                  :search="search"
                  :filter="filter"
                  :open.sync="open"
                  :active.sync="active"
                  item-children="Children"
                  item-text="Name"
                  item-key="Id"
                  :return-object="true"
                  open-on-click
                  @update:active="onUpdate"
                >
                  <template v-slot:prepend="{ item }">
                    <v-icon
                      v-if="item.children"
                      v-text="
                        `mdi-${
                          item.id === 1 ? 'home-variant' : 'folder-network'
                        }`
                      "
                    ></v-icon>
                  </template>
                </v-treeview>
              </v-flex>
            </template>
            <v-layout class="continue justify-end pt-4">
              <v-btn
                :to="{ name: 'postProductStep2', params: { id: routeParamId } }"
                tile
                depressed
                class="brand white--text text-none"
                >{{ $t("Continue") }}</v-btn
              >
            </v-layout>
          </v-flex>
        </v-card>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import { format } from "date-fns";
export default {
  name: "postProductStep1",
  data: () => ({
    categories: [],
    selectedCategory: [],
    search: null,
    active: [],
    open: [],
    caseSensitive: false,
    selectedObj: {},
    balanceObj: {},
    routeParamId: null,
    loading: false,
  }),
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    allCategories() {
      return this.$store.state.allCategories.Data;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    applicationPolicy() {
      return this.siteSettings.ApplicationPolicy;
    },
  },
  created() {
    this.loadCategory();

    if (Object.keys(this.$route.params) && this.$route.params.id) {
      this.routeParamId = this.$route.params.id;
    }

    this.getUserBalance();

    this.getStorageData();
    //debugger;
    if (this.routeParamId) {
      if (this.selectedObj.Id && this.routeParamId == this.selectedObj.Id) {
        this.setActiveAndOpen();
      } else {
        this.getData(this.routeParamId)
          .then(() => {
            this.setActiveAndOpen();
          })
          .catch((err) => {
            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
            this.routeParamId = null;
            this.$router.push({ name: "postProductStep1" }).catch(() => {});
            localStorage.removeItem("postProductSelected");
          });
      }
    } else {
      if (!this.selectedObj) {
        localStorage.removeItem("postProductSelected");
      } else if (this.selectedObj.Id) {
        localStorage.removeItem("postProductSelected");
        this.selectedObj = {};
      } else {
        this.setActiveAndOpen();
      }
    }
  },
  methods: {
    loadCategory() {
      if (this.loading || (this.allCategories && this.allCategories.length)) {
        return true;
      }

      this.loading = true;

      this.$store.dispatch("getAllCategories").then(() => {
        this.loading = false;
      });
    },
    getUserBalance() {
      this.$http
        .post(this.$store.state.Configs.getUserBalance)
        .then((result) => {
          this.balanceObj = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStorageData() {
      var selected = localStorage.getItem("postProductSelected");
      if (selected) {
        this.selectedObj = JSON.parse(selected);
      }
    },
    setActiveAndOpen() {
      if (this.selectedObj && Object.keys(this.selectedObj).length) {
        this.active = [{ Id: this.selectedObj.CategoryId }];
        var arr = [];
        if (this.selectedObj.Parents && this.selectedObj.Parents.length) {
          this.selectedObj.Parents.forEach((element) => {
            arr.push({ Id: element });
          });
        }
        this.open = arr;
      }
    },
    onUpdate(selection) {
      if (selection && selection.length) {
        let obj = this._.first(selection);
        if (obj && Object.keys(obj).length) {
          this.selectedObj.CategoryId = parseInt(obj.Id);
          this.selectedObj.CategoryName = obj.Name;
          this.selectedObj.Parents = obj.Parents;

          localStorage.setItem(
            "postProductSelected",
            JSON.stringify(this.selectedObj)
          );
        }
      }
    },
    getData(ItemId) {
      return new Promise((resolve, reject) => {
        this.$http
          .post(
            this.$store.state.Configs.getProductDetailsForEdit.replace(
              ":id",
              ItemId
            )
          )
          .then((result) => {
            // for edit item discount start and end time formate start
            if (result.data.ItemDiscount.StartAt) {
              result.data.ItemDiscount.StartAt = format(
                new Date(result.data.ItemDiscount.StartAt),
                "yyyy-MM-dd"
              );
            }
            if (result.data.ItemDiscount.EndAt) {
              result.data.ItemDiscount.EndAt = format(
                new Date(result.data.ItemDiscount.EndAt),
                "yyyy-MM-dd"
              );
            }
            //end

            this.selectedObj = result.data;
            localStorage.setItem(
              "postProductSelected",
              JSON.stringify(this.selectedObj)
            );
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  mounted() {
    if (this.siteSettings.IsECommerce) {
      let resolved = this.$router.resolve({
        name: "post-product-1",
      });
      //changed filter object value to url as query without triggering route change event
      window.history.replaceState(
        "NilamPushState",
        "postProduct1",
        resolved.href
      );
      this.$router.push("/page-not-found");
    }
  },
};
</script>

<style lang="scss">
@import "@/sass/create_free_ad.scss";
</style>
