<template>
  <ValidationObserver class="bid-form" tag="div" ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-layout wrap class="justify-space-between">
        <ValidationProvider
          ref="biddingPrice"
          tag="div"
          class="place-bid-field"
          name="bid amount"
          rules="required"
          :bails="false"
          v-slot="{ errors, classes }"
        >
          <div class="input-wrap" :class="classes">
            <v-text-field
              solo
              type="number"
              step="any"
              flat
              hide-details
              autocomplete="off"
              outlined
              :label="$t('Bid Amount')"
              v-model="formData.bid_price"
            ></v-text-field>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <!-- Product Price & Description -->

        <v-btn
          type="submit"
          tile
          depressed
          class="place-bid-btn brand white--text text-none"
        >{{('Place Bid')}}</v-btn>
      </v-layout>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "BiddingForm",
  props: {
    id: Number,
  },
  data() {
    return {
      formData: {
        bid_price: "",
      },

      auctionBids: [],
    };
  },

  methods: {
    onSubmit() {
      let check = this.$root.checkAuth();
      if (check) return;

      this.$refs.form.validate().then(() => {
        this.$http
          .post(this.$store.state.Configs.placeBid, {
            AuctionId: this.id,
            BidAmount: this.formData.bid_price,
          })
          .then((result) => {
            if (result.data.ResponseType == "Success") {
              this.formData.bid_price = "";
              // Wait until the models are updated in the UI
              this.$nextTick(() => {
                this.$refs.biddingPrice.reset();
                this.$refs.form.reset();
              });
            } else {
              this.$refs.biddingPrice.applyResult({
                errors: [result.data.ResponseMessage],
                valid: false,
                failedRules: {},
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },

  created() {},
};
</script>

<style lang="scss">
@import "@/sass/app/index.scss";

.bid-form {
  .place-bid-field {
    width: 49%;

    .input-wrap {
      padding: 0;
    }
  }
  .v-btn.place-bid-btn {
    width: 49%;
    height: 43px;
    @include font(light, 16px, 20px, medium);
  }
}

@include media(479px) {
  .bid-form {
    .place-bid-field {
      width: 100%;
    }
    .v-btn.place-bid-btn {
      width: 100%;
      margin-top: rem-calc(10px);
    }
  }
}
</style>
