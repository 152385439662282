<template>
  <v-sheet id="review" wrap class="review-ratings-wrap" tag="section">
    <h2>Reviews and Ratings</h2>
    <v-flex class="review-ratings-box">
      <!-- <span class="writing-info d-block" style="display:none;">
        How to write a good review
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
          </template>
          <span class="tooltip-info"></span>
        </v-tooltip>
      </span> -->

      <v-layout wrap class="rating-area">
        <!-- Rating Area -->

        <v-flex class="avarage-rating flex-grow-0">
          <v-flex class="relat">
            <strong v-text="product.AverageRating.toFixed(1) + '/5'"></strong>
            <span v-text="product.NoOfReview + ' Ratings'"></span>
          </v-flex>

          <v-sheet>
            <v-rating
              dense
              readonly
              half-increments
              :value="product.AverageRating"
              background-color="orange lighten-3"
              color="brand"
              size="40"
            ></v-rating>
          </v-sheet>
        </v-flex>

        <!-- Rating Items Section -->
        <v-flex class="flex-grow-0 rating-items-box">
          <v-flex
            v-for="ratingItem in ratingItems"
            :key="ratingItem.id"
            class="rating-items d-flex pt-1"
          >
            <v-sheet class="rating-items-left">
              <v-rating
                dense
                readonly
                :value="ratingItem.Scale"
                :length="ratingItem.Scale"
                background-color="orange lighten-3"
                color="brand"
                size="16"
              ></v-rating>
            </v-sheet>
            <v-sheet class="rating-items-right d-flex flex-grow-1 align-center">
              <span v-html="ratingItem.Count"></span>
              <v-flex>
                <v-progress-linear
                  height="8"
                  rounded
                  class="flex-grow-1"
                  background-color="grey lighten-4"
                  color="brand lighten-1"
                  :value="getBarTotal(ratingItem)"
                  aria-label="loading status"
                ></v-progress-linear>
              </v-flex>
            </v-sheet>
          </v-flex>
        </v-flex>
      </v-layout>

      <v-divider class="mt-15"></v-divider>

      <v-flex class="review-commant-wrap">
        <v-skeleton-loader
          class="mx-auto pl-5 pr-5"
          type="article,article,article"
          v-if="loading"
        ></v-skeleton-loader>

        <template v-else>
          <template v-if="reviewSummaryObj.data.length">
            <v-flex
              v-for="reviewSummary in reviewSummaryObj.data"
              :key="reviewSummary.id"
              class="review-commant-item d-flex flex-wrap justify-space-between"
            >
              <v-sheet class="review-commant-left">
                <span
                  class="primary--text"
                  v-html="reviewSummary.UserName"
                ></span>
                <span
                  class="grey--text"
                  v-html="$root.formateServerDateTime(reviewSummary.CreatedAt)"
                ></span>

                <v-rating
                  class="pt-1"
                  dense
                  readonly
                  half-increments
                  color="yellow darken-3"
                  background-color="grey"
                  empty-icon="$ratingFull"
                  :value="reviewSummary.Rating"
                  size="15"
                ></v-rating>
              </v-sheet>
              <v-sheet class="review-commant-right">
                <p v-html="reviewSummary.Comment"></p>

                <v-layout wrap class="review-image" v-if="reviewSummary.Images">
                  <div
                    class="d-flex flex-wrap light-gallery"
                    :id="`lightgallery-${reviewSummary.Id}`"
                  >
                    <!-- review-image-box flex-grow-0 -->
                    <a
                      :href="$root.generateMediaImageUrl(element.FilePath)"
                      class="review-image-box"
                      v-for="element in reviewSummary.Images"
                      :key="element.Id"
                    >
                      <img
                        :src="$root.generateMediaImageUrl(element.FilePath)"
                        alt="Review Image"
                      />
                    </a>
                  </div>
                </v-layout>

                <v-sheet class="thumbs" v-if="false">
                  <v-btn depressed outlined color="grey">
                    <v-icon class="grey--text">mdi-thumb-up-outline</v-icon>
                  </v-btn>
                  <v-btn depressed outlined color="grey">
                    <v-icon class="grey--text">mdi-thumb-down-outline</v-icon>
                  </v-btn>
                </v-sheet>
              </v-sheet>
            </v-flex>
          </template>
          <v-flex v-else> No review found</v-flex>
          <v-flex
            class="d-flex justify-end account-pagination"
            v-if="reviewSummaryObj.TotalPage"
          >
            <pagination
              :page-no="reviewSummaryObj.Page"
              :length="reviewSummaryObj.TotalPage"
            ></pagination>
          </v-flex>
        </template>
      </v-flex>
    </v-flex>
  </v-sheet>
</template>

<script>
import lightGallery from "lightgallery";
import Pagination from "@/components/services/Pagination.vue";

export default {
  name: "ReviewsAndRatings",
  components: {
    Pagination,
  },
  data() {
    return {
      Page: 1,
      loading: false,
      reviewSummaryObj: {},
      getIemReviewSummaryUrl: this.$store.state.Configs.getIemReviewSummary,
      getIemSearchViewUrl: this.$store.state.Configs.getIemSearchView,
      ratingItems: [
        { id: 1, Scale: 5, Count: 0, bar_value: 0 },
        { id: 2, Scale: 4, Count: 0, bar_value: 0 },
        { id: 3, Scale: 3, Count: 0, bar_value: 0 },
        { id: 4, Scale: 2, Count: 0, bar_value: 0 },
        { id: 5, Scale: 1, Count: 0, bar_value: 0 },
      ],
    };
  },
  props: {
    product: Object,
  },
  created() {
    this.getItemReviewSummary();
    this.getItemReviewSearch();
  },
  computed: {
    totalPersonReted() {
      var total = 0;
      this.ratingItems.forEach((element) => {
        total += element.Count;
      });
      return total;
    },
  },
  mounted() {
    this.Fire.$on("pageChanged", (val) => {
      this.Page = parseInt(val);
      this.getItemReviewSearch();
    });
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
  },
  methods: {
    setLightGalleryObj() {
      this.reviewSummaryObj.data.forEach((reviewData) => {
        lightGallery(document.getElementById(`lightgallery-${reviewData.Id}`), {
          thumbnail: true,
          animateThumb: true,
          counter: true,
          download: true,
          showThumbByDefault: false,
          slideEndAnimatoin: true,
          escKey: true,
          enableTouch: true,
        });
      });
    },
    getItemReviewSummary() {
      this.$http
        .post(this.getIemReviewSummaryUrl.replace(":id", this.product.Id))
        .then((res) => {
          res.data.forEach((itemReview) => {
            this.ratingItems.find((element) => {
              if (element.Scale == itemReview.Scale) {
                element.Count = itemReview.Count;
              }
            });
          });
        })
        .catch(() => {});
    },
    getItemReviewSearch() {
      if (this.loading) return;
      this.loading = true;

      this.$http
        .post(this.getIemSearchViewUrl, {
          ItemId: this.product.Id,
          Page: this.Page,
          Length: 3,
        })
        .then((res) => {
          this.reviewSummaryObj = res.data;
          this.loading = false;
        })
        .then(() => {
          if (this.reviewSummaryObj.data.Length) {
            this.setLightGalleryObj();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getBarTotal(item) {
      return Math.ceil((item.Count * 100) / this.totalPersonReted);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~lightgallery/css/lightgallery.css";
@import "@/sass/reviews_and_ratings.scss";
</style>
