export default [
  {
    who: "Buyer",
    data: [
      {
        title: "My Order",
        countsArr: [
          {
            itemName: "Total Order",
            varName: "TotalOrderCountBuyer",
          },
          {
            itemName: "Processing Order",
            varName: "ProcessingOrderCountBuyer",
          },
          {
            itemName: "Pending Order",
            varName: "PendingOrderCountBuyer",
          },
          {
            itemName: "Confirmed Order",
            varName: "ConfirmedOrderCountBuyer",
          },
          {
            itemName: "Order Delivered",
            varName: "ProcessingOrderCountBuyer",
          },
          {
            itemName: "Order Holded",
            varName: "HoldOrderBuyer",
          },
          {
            itemName: "Returned Order",
            varName: "ReturnedOrderBuyer",
          },
          {
            itemName: "Cancel Order",
            varName: "CanceledOrderBuyer",
          },
        ],
      },
      {
        title: "Nilam",
        countsArr: [
          {
            itemName: "Participated Nilam",
            varName: "ParticipatedAuctionCount",
          },
          {
            itemName: "Waiting for Order Confirmation by Nilam Winning",
            varName: "OrderRequestReceivedAuctionCount",
          },
          {
            itemName: "Ordered placed Nilam",
            varName: "OrderedPlacedAuctionCount",
          },
        ],
      },
    ],
  },
  {
    who: "Seller",
    data: [
      {
        title: "My Item",
        countsArr: [
          {
            itemName: "Total Item",
            varName: "TotalItemCount",
          },
          {
            itemName: "Pending Item",
            varName: "PendingItemCount",
          },
          {
            itemName: "Published Item",
            varName: "PublishedItemCount",
          },
          {
            itemName: "Total Nilam Item",
            varName: "AuctionItemCount",
          },
          {
            itemName: "Ongoing Niam Item",
            varName: "TotalAuctionCount",
          },
          {
            itemName: "Pending Nilam Item",
            varName: "PendingAuctionCount",
          },
          {
            itemName: "Published Nilam Item",
            varName: "PublishedAuctionCount",
          },
        ],
      },
      {
        title: "My Order",
        countsArr: [
          {
            itemName: "Total Order",
            varName: "TotalOrderCountSeller",
          },
          {
            itemName: "Total Shop Order",
            varName: "OrderedItemCount",
          },
          {
            itemName: "Total Nilam Order",
            varName: "OrderedAuctionCount",
          },
          {
            itemName: "New Order",
            varName: "NewOrderCountSeller",
          },
          {
            itemName: "Confirmed Order",
            varName: "ConfirmedOrderCountSeller",
          },
          {
            itemName: "Pending Order",
            varName: "PendingOrderCountSeller",
          },
          {
            itemName: "Processing Order",
            varName: "ProcessingOrderCountSeller",
          },
          {
            itemName: "Delivered Order",
            varName: "DelivereOrderCountSeller",
          },
          {
            itemName: "Hold Order",
            varName: "HoldOrderSeller",
          },
          {
            itemName: "Returned Order",
            varName: "ReturnedOrderSeller",
          },
          {
            itemName: "Canceled Order",
            varName: "CanceledOrderSeller",
          },
        ],
      },
      {
        title: "Nilam",
        countsArr: [
          {
            itemName: "Nilam Bidding",
            varName: "BiddingAuctionCount",
          },
          {
            itemName: "Nilam Shortlisted",
            varName: "ShortListedAuctionCount",
          },
          {
            itemName: "Closed Nilam",
            varName: "ClosedAuctionCount",
          },
          {
            itemName: "Nilam Canceled",
            varName: "CanceledAuctionCount",
          },
        ],
      },
      {
        title: "Point",
        countsArr: [
          {
            itemName: "Total Point",
            varName: "TotalPoint",
          },
          {
            itemName: "Available Point",
            varName: "AvailablePoint",
          },
          {
            itemName: "Total Amount",
            varName: "TotalAmount",
          },
          {
            itemName: "Available Amount",
            varName: "AvailableAmount",
          },
        ],
      },
    ],
  },
];
