<template>
  <span>
    <a href="javascript:;" class="ml-2 danger--text" v-text="$t('Cancel')" @click="cancelNow()"></a>

    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-text class="pt-3">
          <v-alert
            v-if="Object.keys(alertObj).length"
            dense
            text
            dismissible
            ref="alertBox"
            class="mb-3"
            :type="alertObj.type.toLowerCase()"
          >
            <template slot="close">
              <button
                type="button"
                @click.stop="clearAlertObj"
                class="v-btn v-btn--flat v-btn--icon v-btn--round theme--dark v-size--small error--text"
              >
                <span class="v-btn__content">
                  <i class="v-icon notranslate mdi mdi-close-circle theme--dark error--text"></i>
                </span>
              </button>
            </template>
            <span v-text="alertObj.message"></span>
          </v-alert>

          <ValidationObserver
            class="signin-signup-form forgot-form"
            tag="div"
            ref="form"
            v-slot="{ handleSubmit }"
          >
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div class="input-wrap pb-0">
                <v-toolbar-title
                  class="grey--text text--darken-4"
                >Reasons you want to cancel this order</v-toolbar-title>
              </div>
              <v-layout>
                <v-flex>
                  <div class="input-wrap pa-0">
                    <v-container fluid class="pa-0">
                      <v-checkbox
                        v-for="(
                          orderCancelationReason, index
                        ) in orderCancelationReasons"
                        :key="index"
                        hide-details
                        v-model="selected"
                        :label="orderCancelationReason"
                        :value="orderCancelationReason"
                      ></v-checkbox>

                      <v-checkbox v-model="other" :label="`Other`"></v-checkbox>

                      <v-textarea
                        v-if="other"
                        hide-details
                        fluid
                        name="input-7-1"
                        filled
                        label="Type other reason for cancelation"
                        auto-grow
                        v-model="otherReason"
                      ></v-textarea>
                    </v-container>
                  </div>
                </v-flex>
              </v-layout>

              <v-btn
                width="100%"
                height="50"
                type="submit"
                depressed
                tile
                :disabled="loading"
                :loading="loading"
                class="brand white--text text-none d-block mt-4"
              >Submit</v-btn>
            </form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="deep-orange" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: "CancelOrder",
  data() {
    return {
      selected: [],
      formData: {},
      dialog: false,
      loading: false,
      alertObj: {},
      other: false,
      otherReason: "",
    };
  },
  props: {
    orderId: Number,
  },
  computed: {
    orderCancelationReasons() {
      return this.$store.state.Configs.orderCancelationReasons;
    },
  },
  mounted() {},
  methods: {
    cancelNow() {
      this.formData = {};
      this.dialog = true;
    },
    onSubmit() {
      if (this.loading) return;
      if (this.otherReason) {
        this.selected = [...this.selected, this.otherReason];
      }

      if (!this.selected.length) {
        this.alertObj = {
          type: "error",
          message: "Minimum one reason is required",
        };
        return;
      }

      this.formData.Note = this._.join(this.selected, ", ");
      this.formData.Id = this.orderId;
      this.formData.Status = "Canceled";

      this.$refs.form.validate().then(() => {
        this.loading = true;

        this.$http({
          method: "post",
          url: this.$store.state.Configs.orderUpdateStatus,
          data: this.formData,
        })
          .then((res) => {
            this.$refs.form.reset();
            this.closeDialog();
            this.Fire.$emit("orderCanceled", this.orderId);

            this.$store.commit("set_flash", {
              type: res.data.ResponseType,
              message: res.data.ResponseMessage,
            });
          })
          .catch(() => {
            this.loading = false;
            this.alertObj = {
              type: "error",
              message: "Error occurred please try again",
            };
          });
      });
    },
    clearAlertObj() {
      this.alertObj = {};
    },
    closeDialog() {
      this.formData = {};
      this.$refs.form.reset();
      this.dialog = false;
      this.loading = false;
      this.selected = [];
      this.otherReason = "";
      this.other = false;
      this.clearAlertObj();
    },
  },
};
</script>
