<template>
  <v-flex class="mx-auto">
    <v-container class="py-0 pt-8 pb-8">
      <v-card elevation="5" class="success-fail text-center">
        <v-flex class="d-flex justify-center">
          <v-icon size="90" color="green">mdi-check-decagram</v-icon>
        </v-flex>
        <h3>Your order has been successfully placed</h3>
        <h4>Thank you for your purchase!</h4>
        <h4>You order ID is: {{ successOrderId }}</h4>
        <p>
          You will receive an order confirmation email with details of your
          order
        </p>
        <v-flex class="payment-info">
          <v-flex class="pt-6 text-right">
            <v-btn
              v-if="successOrderId"
              tile
              depressed
              color="brand"
              class="white--text"
              @click="goToOrderDetails(successOrderId)"
              >Order details</v-btn
            >
          </v-flex>
        </v-flex>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: "OrderSuccess",
  data() {
    return {
      timeoutId: 0,
    };
  },
  computed: {
    successOrderId() {
      return this.$store.state.successOrderId;
    },
  },
  methods: {
    goToOrderDetails(orderId) {
      this.$store.commit("removeSuccessOrderId");
      this.$router.push({
        name: "MyOrderDetails",
        params: { id: orderId },
      });
    },
  },
  mounted() {
    this.timeoutId = setTimeout(() => {
      this.goToOrderDetails(this.successOrderId);
    }, 6000);
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/payment_success_fail.scss";
</style>
