<template>
  <v-flex class="autobid-popup-wrap">
    <v-flex v-if="AutoMaticBidObj && Object.keys(AutoMaticBidObj).length">
      <span class="set-bid d-block pb-1" v-text="AutoMaticBidText"></span>
      <span
        class="primary--text darken-4 text-decoration-underline pointer"
        @click="deleteAutomatiBid()"
        v-text="$t('Delete Auto Bid')"
      ></span>
    </v-flex>

    <!-- automatic bid popup -->
    <v-dialog content-class="autobid-popup" v-model="dialog" width="670">
      <v-sheet class="autobid-wrap">
        <h3 v-text="$t('How do automatic bids work here?')"></h3>
        <v-flex class="autobid-text">
          <p>
            By setting up a programmed offer, our framework will naturally put
            new offers for your sake each time you are outbid. You should simply
            enter the most extreme value you are happy to offer for a thing. Our
            framework will at that point continue offering for you, by the
            littlest conceivable increment each time, until your most extreme
            cost is reached.
          </p>
        </v-flex>

        <ValidationObserver class="autobid-form" tag="div" ref="form" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              tag="div"
              class="place-bid-field"
              name="maximum bid price"
              rules="required|max_value:99999999"
              :bails="false"
              v-slot="{ errors, classes }"
            >
              <div class="input-wrap" :class="classes">
                <v-flex class="input-label">Maximum bid price</v-flex>
                <v-text-field
                  solo
                  type="number"
                  step="any"
                  flat
                  hide-details
                  outlined
                  :label="$t('Maximum bid price')"
                  v-model="formData.MaxAmount"
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <ValidationProvider
              tag="div"
              class="place-bid-field"
              name="automatic per bid price"
              rules="required|max_value:24999"
              :bails="false"
              v-slot="{ errors, classes }"
            >
              <div class="input-wrap" :class="classes">
                <v-flex class="input-label">Automatic per bid price</v-flex>
                <v-text-field
                  solo
                  type="number"
                  step="any"
                  flat
                  hide-details
                  outlined
                  :label="$t('Automatic increase rate per bid. e.g. tk 3')"
                  v-model="formData.IncreaseRate"
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <!-- Product Price & Description -->

            <v-flex class="text-right">
              <v-hover v-slot="{ hover }">
                <v-btn
                  class="modal-close text-none white--text transition-swing"
                  tile
                  depressed
                  :class="hover ? 'primary' : 'primary lighten-2'"
                  @click.stop="dialog = !dialog"
                >Close</v-btn>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-btn
                  type="submit"
                  tile
                  depressed
                  class="place-bid-btn brand white--text text-none transition-swing"
                  :class="hover ? 'brand darken-1' : 'brand'"
                >{{$t('Save automatic bid')}}</v-btn>
              </v-hover>
            </v-flex>
          </form>
        </ValidationObserver>
      </v-sheet>
    </v-dialog>
  </v-flex>
</template>

<script>
export default {
  name: "DataNotFound",
  data() {
    return {
      dialog: false,
      formData: {
        IsPercentage: false,
      },
      AutoMaticBidText: "",
      addAutomaticBidUrl: this.$store.state.Configs.addAutomaticBid,
      deleteAutomaticBidUrl: this.$store.state.Configs.deleteAutomaticBid,
    };
  },
  props: {
    AuctionId: Number,
    AutoMaticBidObj: Object,
  },
  created() {
    if (this.AutoMaticBidObj) {
      this.formData = this.AutoMaticBidObj;
      this.setAutoMaticBidButtonText(this.AutoMaticBidObj);
    }
  },
  methods: {
    setAutoMaticBidButtonText(obj) {
      this.AutoMaticBidText = `You have set an automatic bid where maximum bid amount was ${this.$root.showAmount(
        obj.MaxAmount
      )} and increase rate per bid was ${this.$root.showAmount(
        obj.IncreaseRate
      )}`;
    },
    modalOpen() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    addAutomaticBid() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.addAutomaticBidUrl, {
            ...this.formData,
            AuctionId: this.AuctionId,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      let check = this.$root.checkAuth();
      if (check) return;

      this.$refs.form.validate().then(() => {
        this.addAutomaticBid()
          .then((res) => {
            this.$nextTick(() => {
              this.$refs.form.reset();
              this.dialog = false;
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });

              this.setAutoMaticBidButtonText({
                ...this.formData,
                AuctionId: this.AuctionId,
              });

              this.Fire.$emit("auto-matic-bid-updated", {
                ...this.formData,
                AuctionId: this.AuctionId,
              });
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
          });
      });
    },
    async deleteAutomatiBid() {
      const yes = await this.$confirm("Do you really want to delete?", {
        title: "Warning",
      });
      if (!yes) return;

      this.$http
        .post(this.deleteAutomaticBidUrl.replace(":auctionId", this.AuctionId))
        .then((res) => {
          this.$store.commit("set_flash", {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          });

          this.formData = { IsPercentage: false };

          this.Fire.$emit("auto-matic-bid-deleted");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/product_details_summery_auction.scss";
</style>
