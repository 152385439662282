<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0" height="100%">
              <v-flex class="account-panel">
                <v-layout
                  class="notification-top justify-space-between align-center flex-wrap"
                >
                  <v-flex>
                    <h2>
                      {{ $t("Notification") }}
                      <span>
                        (Total:
                        <b v-text="profileObj.UnReadNotificationCount"></b> new
                        notification)
                      </span>
                    </h2>
                  </v-flex>

                  <v-flex class="notifi-check flex-grow-0 d-flex">
                    <v-radio-group v-model="filterObj.Type" row>
                      <v-radio label="All" color="brand" value="All"></v-radio>
                      <v-radio
                        label="Un-Read"
                        color="brand"
                        value="Un-Read"
                      ></v-radio>
                      <v-radio
                        label="Read"
                        color="brand"
                        value="Read"
                      ></v-radio>
                    </v-radio-group>
                    <v-btn
                      v-if="profileObj.UnReadNotificationCount"
                      @click="markAllAsRead"
                      >Mark All As Read</v-btn
                    >
                  </v-flex>
                </v-layout>

                <v-skeleton-loader
                  class="mx-auto pl-5 pr-5"
                  type="article,article,article,article"
                  v-if="loading"
                ></v-skeleton-loader>
                <template v-else>
                  <template
                    v-if="
                      Object.keys(notificaionObj).length &&
                      notificaionObj.data.length
                    "
                  >
                    <ul class="notification-wrap">
                      <li
                        v-for="notification in notificaionObj.data"
                        :key="notification.id"
                        @click="goDetails(notification)"
                      >
                        <v-flex
                          class="notification-in"
                          :class="{ read: notification.IsRead }"
                        >
                          <h3 v-text="notification.ReferenceType"></h3>
                          <p v-text="notification.Message"></p>
                          <small
                            v-if="notification.IsRead"
                            v-text="
                              $root.formateServerDateTime(notification.ReadAt)
                            "
                          ></small>
                        </v-flex>
                      </li>
                    </ul>
                    <!-- Products Pagination sections -->
                    <v-flex
                      class="product-pagination d-flex justify-end"
                      v-if="notificaionObj.TotalPage > 1"
                    >
                      <pagination
                        :page-no="filterObj.Page"
                        :length="notificaionObj.TotalPage"
                      ></pagination>
                    </v-flex>
                  </template>
                  <v-flex v-else class="account-panel">
                    <data-not-found class="pt-10 pb-10">
                      <h2>No {{ filterObj.Type }} Notification Found</h2>
                    </data-not-found>
                  </v-flex>
                </template>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- Send bargain price -->
      <bargain-modal ref="bargainModal" :item-id="0" :qty="1"></bargain-modal>
    </v-flex>
  </v-flex>
</template>
<script>
import DataNotFound from "@/components/sites/common/DataNotFound";
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import Pagination from "@/components/services/Pagination";
import BargainModal from "@/components/sites/common/BargainModal";

export default {
  name: "Notification",
  components: {
    AccountSidebar,
    DataNotFound,
    Pagination,
    BargainModal,
  },
  data() {
    return {
      read: "",
      loading: false,
      notificaionObj: {},
      filterObj: {
        Page: 1,
        Length: 20,
        Type: "All",
      },
    };
  },
  computed: {
    profileObj() {
      return this.$store.state.profileObj;
    },
    clonedFilterObj: function () {
      return Object.assign({}, this.filterObj);
    },
  },
  created() {
    this.getNotifications();
  },
  methods: {
    goDetails(notificationObj) {
      if (
        notificationObj.ReferenceType == "Bargain" &&
        notificationObj.ReferenceId > 0
      ) {
        this.$refs.bargainModal.showModal(notificationObj.ReferenceId);
      }
      if (
        notificationObj.ReferenceType == "Order" &&
        notificationObj.ReferenceId > 0
      ) {
        this.$router
          .push({
            name: "MyOrderDetails",
            params: {
              id: notificationObj.ReferenceId,
            },
          })
          .catch((err) => console.log(err));
      }
      if (
        notificationObj.ReferenceType == "Auction" &&
        notificationObj.ReferenceId > 0
      ) {
        this.$router
          .push({
            name: "ProductDetails",
            params: {
              id: notificationObj.ReferenceId,
              slug: "",
            },
          })
          .catch((err) => console.log(err));
      }
    },
    getNotifications() {
      if (this.loading) return;
      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.getAllNotification, this.filterObj)
        .then((result) => {
          this.notificaionObj = result.data;
          this.$store.commit("update_notification_count", {
            isTotal: true,
            count: this.notificaionObj.OtherCount,
          });
          this.loading = false;
        })
        .then(() => {
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    markAllAsRead() {
      if (this.loading) return;
      this.loading = true;
      this.$http
        .post(this.$store.state.Configs.notificaionMarkAllAsRead)
        .then(() => {
          this.$store.commit("update_notification_count", 0);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.Fire.$on("pageChanged", (val) => {
      this.filterObj.Page = parseInt(val);
    });
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
  },
  watch: {
    clonedFilterObj: {
      deep: true,
      handler(newVal, oldVal) {
        if (!this._.isEqual(newVal, oldVal)) {
          this.getNotifications();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/notification.scss";
</style>
