var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{staticClass:"login-register-bg",style:({
    background:
      'url(' + require('@/assets/images/login-bg.png') + ') left top',
  })},[_c('v-card',{staticClass:"signin-signup-wrap mx-auto",style:({
      background:
        '#fff url(' +
        require('@/assets/images/login-bar.png') +
        ') left top repeat-x',
    }),attrs:{"tile":"","elevation":"3"}},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","height":"3","color":"light","aria-label":"loading status"}}),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Enter Code")]),_c('span',{staticClass:"otp-message d-block",domProps:{"textContent":_vm._s(_vm.instruction)}}),_c('ValidationObserver',{ref:"form",staticClass:"signin-signup-form login-form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"place-bid-field",attrs:{"tag":"div","name":"otp","rules":"required","bails":false},scopedSlots:_vm._u([{key:"default",fn:function({ errors, classes }){return [_c('div',{staticClass:"input-wrap",class:classes},[_c('v-text-field',{attrs:{"solo":"","type":"text","step":"any","flat":"","hide-details":"","outlined":"","label":"OTP","prepend-inner-icon":"mdi-lock"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('v-layout',{staticClass:"remember justify-end",attrs:{"wrap":""}},[_c('send-otp-btn',{staticClass:"v-icon notranslate mdi mdi-rotate-left theme--light",attrs:{"username":_vm.username,"type":"otp","icon":"mdi-rotate-left","btn-text":"Resend"},on:{"update:username":function($event){_vm.username=$event}}})],1),_c('v-btn',{staticClass:"signin-signup-btn brand white--text text-none d-block",attrs:{"width":"100%","type":"submit","depressed":"","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v("Submit OTP")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }