<template>
  <v-flex class="inner-banner">
    <v-img :src="getSrcImage()" alt="options.title + 'Image'">
      <v-container class="fill-height d-flex align-center">
        <h1 v-html="options.title"></h1>
      </v-container>
    </v-img>
  </v-flex>
</template>

<script>
export default {
  name: "InnerBanner",
  props: {
    options: Object,
  },
  created() {},
  methods: {
    getSrcImage() {
      if (this.options.PageBannerUrl) {
        return this.$root.generateMediaImageUrl(this.options.PageBannerUrl);
      }
      return require("@/assets/images/inner-banners/" + this.options.image);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/sass/app/index.scss";

.inner-banner {
  padding: 0;

  h1 {
    @include font(primary, 40px, 50px, medium);

    span {
      padding-top: rem-calc(5px);
      display: block;
      @include font(false, 16px, 22px, regular);
    }
  }
}
@include media(md) {
  .inner-banner {
    h1 {
      @include font(primary, 30px, 40px, medium);
    }
  }
}
</style>
