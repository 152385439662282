var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{staticClass:"site-content-wrap site_bg"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto pl-5 pr-5",attrs:{"type":"list-item-avatar-three-line, image, article"}}):[(_vm.product && Object.keys(_vm.product).length)?_c('v-container',{staticClass:"py-0"},[_c('v-card',{attrs:{"tile":""}},[_c('v-sheet',{staticClass:"product-details-wrap"},[_c('site-breadcrumb',{staticClass:"product-details-breadcrumb",attrs:{"options":_vm.breadcrumb}}),_c('v-layout',{staticClass:"product-details-top justify-space-between",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"product-details-images"},[_c('v-flex',{staticClass:"product-image"},[_c('wish-list-btn',{attrs:{"is-favorite":_vm.product.IsFavorite,"product-id":_vm.product.Id,"is-elevation":true}}),(_vm.imgError)?_c('v-img',{attrs:{"title":_vm.product.ItemName,"src":"","lazy-src":require('@/assets/images/no-img.jpg'),"alt":_vm.product.ItemName}}):_c('a',{attrs:{"href":_vm.$root.generateSlideImageUrl(
                      _vm.defaultImage,
                      _vm.$root.dimentions.original
                    ),"id":"Zoom-1","data-options":"variableZoom: true; cssClass: thumbnails-style-shaded; selectorTrigger: hover; transitionEffect: false;"}},[_c('img',{attrs:{"src":_vm.$root.generateSlideImageUrl(_vm.defaultImage),"alt":_vm.product.ItemName},on:{"error":_vm.setImgError}})])],1),_c('v-flex',{staticClass:"product-thumb"},[(!_vm.imgError)?_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.product.Documents),function(image){return _c('v-slide-item',{key:image.Id},[_c('a',{staticClass:"thumb-holder",attrs:{"href":_vm.$root.generateSlideImageUrl(
                          image,
                          _vm.$root.dimentions.original
                        ),"data-zoom-id":"Zoom-1","data-image":_vm.$root.generateSlideImageUrl(image)}},[_c('img',{attrs:{"src":_vm.$root.generateSlideImageUrl(
                            image,
                            _vm.$root.dimentions.thumb
                          ),"alt":_vm.product.ItemName}})])])}),1):_vm._e()],1)],1),(_vm.product.SaleType == 'Auction')?_c('product-details-summery-auction',{attrs:{"product":_vm.product}}):_c('product-details-summery',{attrs:{"product":_vm.product}}),(_vm.$root.windowSize <= 1263)?_c('product-information',{attrs:{"product":_vm.product}}):_vm._e(),_c('v-flex',{staticClass:"product-details-info"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-flex',{staticClass:"delivery-option"},[_c('v-card-title',{staticClass:"pa-0 pb-3 d-flex justify-space-between"},[_vm._v(" Delivery Options "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[_c('span',{staticClass:"tooltip-info",domProps:{"textContent":_vm._s(
                          'When you order any product, After order confirmation, We will deliver you within 3 to 7 days'
                        )}})])],1),_c('v-card-text',{staticClass:"pa-0 pb-2 d-flex justify-space-between align-center"},[_c('span',[_vm._v(" Home Delivery "),_c('br'),_c('span',{staticClass:"grey--text text--darken-3 font-weight-medium",domProps:{"textContent":_vm._s(
                          _vm.siteSettings.DeliveryFrom +
                          ' - ' +
                          _vm.siteSettings.DeliveryTo +
                          ' Days'
                        )}})]),(_vm.siteSettings.DeliveryCharge)?_c('span',{staticClass:"grey--text text--darken-3 font-weight-medium",domProps:{"textContent":_vm._s(_vm.$root.showAmount(_vm.siteSettings.DeliveryCharge))}}):_vm._e()]),_c('v-card-text',{staticClass:"pa-0 pb-2"},[_vm._v("Cash on Delivery Available")])],1),_c('v-divider'),_c('v-flex',{staticClass:"delivery-option"},[_c('v-card-title',{staticClass:"pa-0 pb-3"},[_vm._v("Seller information")]),_c('v-card-text',{staticClass:"pa-0 pb-2"},[_c('router-link',{staticClass:"pr-6",attrs:{"to":{
                        name: 'ProductSearch',
                        params: { slug: _vm.slugify(_vm.CompanyName) },
                        query: { OwnerId: _vm.product.SellerId },
                      }}},[_c('span',{staticClass:"brand--text text-decoration-underline",domProps:{"textContent":_vm._s(_vm.CompanyName)}})]),_c('v-flex',{staticClass:"pt-2"},[_c('span',{staticClass:"grey--text text--darken-3"},[_vm._v("Review")]),_c('span',{staticClass:"brand--text"},[_vm._v(" ( "+_vm._s(_vm.product.SellerPositiveRating)+" "),_c('v-icon',{staticClass:"brand--text star"},[_vm._v("mdi-star")]),_vm._v(") ")],1)])],1),_c('v-card-text',{staticClass:"pa-0 pb-2"},[_c('span',{staticClass:"grey--text text--darken-3",domProps:{"textContent":_vm._s(
                        _vm.product.SellerResponseRating > 0
                          ? _vm.product.SellerResponseRating +
                            ' Positive feedback'
                          : 'No feedback yet'
                      )}})])],1),_c('v-divider'),_c('v-flex',{staticClass:"delivery-option"},[_c('v-card-title',{staticClass:"pa-0 pb-3 d-flex justify-space-between"},[_vm._v(" Return & Warranty "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,false,4218361139)},[(_vm.product.HasWarranty)?_c('span',{staticClass:"tooltip-info",domProps:{"textContent":_vm._s(
                          'If you want to claim warranty? please contact us, possible to include copies of applicable documents such as the receipt or warranty statement. and product with full box.'
                        )}}):_c('span',{staticClass:"tooltip-info",domProps:{"textContent":_vm._s('Warranty not available')}})])],1),_c('v-card-text',{staticClass:"pa-0 pb-2"},[(_vm.product.HasWarranty)?_c('span',{staticClass:"d-block font-weight-medium grey--text text--darken-3 pb-2",domProps:{"textContent":_vm._s(_vm.product.WarrantyNote)}}):_c('span',{staticClass:"d-block font-weight-medium grey--text text--darken-3 pb-2",domProps:{"textContent":_vm._s('Warranty not available')}})])],1),_c('v-divider'),_c('v-flex',{staticClass:"delivery-option"},[_c('v-card-title',{staticClass:"pa-0 pb-3"},[_vm._v("Payments Method")]),_c('v-card-text',{staticClass:"pa-0 pb-2"},[_c('v-img',{attrs:{"src":require('@/assets/images/payment-methods.png'),"alt":"Pyment Method","max-width":"260"}})],1)],1)],1)],1)],1),(_vm.$root.windowSize >= 1264)?_c('product-information',{attrs:{"product":_vm.product}}):_vm._e(),_c('v-flex',{staticClass:"product-dtls-related-product"},[_c('related-products')],1),(true)?_c('reviews-and-ratings',{attrs:{"product":_vm.product}}):_vm._e()],1)],1),(false)?_c('v-card',{staticClass:"mt-4 py-4",attrs:{"tile":"","elevation":"2"}},[_c('v-card-text',{staticClass:"pa-0 black--text text-center please-contact"},[_vm._v(" If you want to sell your product create new post? "),_c('router-link',{attrs:{"to":{ name: 'postProductStep1' }}},[_vm._v("Post Your Item")])],1)],1):_vm._e()],1):_c('data-not-found',{staticClass:"mt-10"},[_c('h1',[_vm._v("No Product Found")])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }