<template>
  <v-flex class="product-details-summery">
    <v-flex class="product-details-box">
      <v-alert class="mb-3" type="info" outlined text v-if="isOwnProduct"
        >This is your product</v-alert
      >
      <small class="category" v-text="product.CategoryName"></small>
      <h1 v-text="product.ItemName"></h1>
    </v-flex>

    <ul>
      <li>
        Condition:
        <span v-text="product.Condition"></span>
      </li>
      <li>
        Stock Type:
        <span v-text="product.StockType"></span>
      </li>
      <li v-if="product.Brand">
        Brand:
        <span v-text="product.Brand"></span>
      </li>
    </ul>

    <v-sheet
      class="d-flex align-center rating pointer"
      @click="gotTo('review')"
      href="javascript:;"
    >
      <v-rating
        dense
        readonly
        v-model="rating"
        color="yellow darken-3"
        background-color="grey"
        empty-icon="$ratingFull"
        size="18"
      ></v-rating>
      <span class="d-block ml-1">{{ product.NoOfReview }} reviews</span>
    </v-sheet>

    <v-flex class="availablity">
      Availablity:
      <span class="pieces"
        >{{ product.StockQuantity }} {{ product.MeasurementName }}</span
      >
      <b>{{ product.StockQuantity > 0 ? "In Stock" : "Out of Stock" }}</b>
      <img
        v-if="product.StockQuantity <= 0"
        class="sold-out"
        :src="require('@/assets/images/sold-out.png')"
        alt="Products"
      />
    </v-flex>

    <v-flex class="more-info">
      <a @click="gotTo('more-info')" href="javascript:void(0)"
        >View More Info</a
      >
    </v-flex>

    <v-flex class="price d-flex align-center flex-wrap">
      <v-sheet
        tag="span"
        class="price-payable"
        v-text="$root.showAmount(product.SalesPrice)"
      ></v-sheet>
      <v-sheet
        v-if="product.DiscountedPrice"
        tag="span"
        class="bidding-status text-decoration-line-through pl-2"
        v-text="$root.showAmount(product.DiscountedPrice)"
      ></v-sheet>
      <v-sheet
        v-if="product.DiscountTag"
        tag="span"
        class="discount-tag pl-2"
        v-text="'(' + product.DiscountTag + ')'"
      ></v-sheet>
    </v-flex>

    <span v-for="(attributes, index) in attributsArr" :key="index">
      <v-flex v-if="attributes.length > 1" class="d-flex align-start pb-5">
        <v-flex
          v-text="'Select ' + index"
          class="quantity-title flex-grow-0"
        ></v-flex>
        <v-radio-group
          class="pt-0"
          row
          hide-details
          v-model="selectedAttributeIds[index]"
        >
          <v-radio
            :ripple="false"
            v-for="(val, index) in attributes"
            :key="index"
            :selected="11499"
            :label="val.Name"
            :value="val.Id"
          ></v-radio>
        </v-radio-group>
      </v-flex>
    </span>

    <v-sheet class="quantity d-flex align-center">
      <span class="quantity-title">Quantity</span>

      <!-- Quantity-Holder -->
      <quantity-holder
        :qty="product.StockQuantity"
        :cart-quantity="product.CartQuantity"
        :productId="product.Id"
      ></quantity-holder>
    </v-sheet>

    <!-- Emi-Holder -->
    <v-flex class="emi-holder" v-if="product.IsEmi">
      <v-checkbox
        v-model="isEmi"
        label="Avail For EMI (কার্ড অথবা কার্ড বিহীন সহজ কিস্তি)"
        color="primary"
        hide-details
        :ripple="false"
        class="pa-0 emi"
      ></v-checkbox>
    </v-flex>

    <v-layout wrap class="btn-holder justify-space-between">
      <v-flex class="btn-box" v-if="!isOwnProduct">
        <buy-now-btn
          :product="product"
          :selected-attributes.sync="selectedAttributes"
          :is-emi.sync="isEmi"
        ></buy-now-btn>
      </v-flex>
      <v-flex class="btn-box" v-if="!isOwnProduct && product.HasAddToCart">
        <add-to-cart-btn
          class="cart-btn"
          :product="product"
          :selected-attributes.sync="selectedAttributes"
          :is-emi.sync="isEmi"
          :local-cartable-obj="localCarableObj"
        ></add-to-cart-btn>
        <div v-if="selectedItemCartQuantity" class="green--text text-center">
          <small>Already added in cart</small>
        </div>
      </v-flex>
      <v-flex class="btn-box bargain" v-if="product.SaleType == 'Bargain'">
        <v-btn
          @click="$refs.bargainModal.showModal()"
          block
          tile
          depressed
          class="silver_tree white--text text-none"
        >
          <span v-if="isOwnProduct">Show Bargain Details</span>
          <span v-else>Bargain</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout class="share-product align-center">
      <span>Share:</span>
      <!-- Product-Social -->
      <product-social></product-social>
    </v-layout>

    <!-- Send bargain price -->
    <bargain-modal ref="bargainModal" :item-id="product.Id"></bargain-modal>
  </v-flex>
</template>

<script>
import QuantityHolder from "@/components/sites/common/QuantityHolder.vue";
import ProductSocial from "@/components/sites/pages/ProductSocial.vue";
import BuyNowBtn from "@/components/sites/common/BuyNowBtn.vue";
import AddToCartBtn from "@/components/sites/common/AddToCartBtn.vue";
import BargainModal from "@/components/sites/common/BargainModal.vue";
export default {
  name: "ProductDetailsSummery",
  components: {
    QuantityHolder,
    ProductSocial,
    BuyNowBtn,
    AddToCartBtn,
    BargainModal,
  },
  props: {
    product: Object,
  },
  data() {
    return {
      rating: 0,
      metaObj: {},
      selectedAttributeIds: {},
      isEmi: false,
      isOnlyEmi: false,
      localCarableObj: {},
    };
  },
  computed: {
    isOwnProduct() {
      if (
        this.$auth &&
        this.$auth.user &&
        this.$auth.user.UserId == this.product.UserId
      ) {
        return true;
      }
      return false;
    },
    attributsArr() {
      return this._.groupBy(
        this._.reject(this.product.Attributes, { IsMultiple: false }),
        "CategoryName"
      );
    },
    selectedAttributes() {
      return this._.filter(this.product.Attributes, (item) => {
        return Object.values(this.selectedAttributeIds).includes(item.Id);
      });
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    carts() {
      return this.$store.state.carts.Ecommerce;
    },
    selectedItem() {
      return this._.find(this.carts, { ItemId: this.product.Id });
    },
    selectedItemCartQuantity() {
      return this.selectedItem?.CartQuantity || 0;
    },
  },
  methods: {
    gotTo(selector) {
      this.$vuetify.goTo("#" + selector, {
        duration: 1000,
        offset: this.$root.$refs.stickyheader.$el.clientHeight,
        easing: "easeInOutCubic",
      });
    },
  },
  created() {
    if (this.isLoggedIn && !this.carts.length) {
      this.$store.dispatch("getEcommerceItems").then(() => {
        if (this.selectedItem && this.selectedItem.Attributes) {
          let obj = {};
          this.selectedItem.Attributes.forEach((ele) => {
            obj[ele.CategoryName] = ele.Id;
          });
          this.selectedAttributeIds = obj;
        }
      });
    } else {
      if (this.selectedItem && this.selectedItem.Attributes) {
        let obj = {};
        this.selectedItem.Attributes.forEach((ele) => {
          obj[ele.CategoryName] = ele.Id;
        });
        this.selectedAttributeIds = obj;
      }
    }

    // use for adding cart after login redirect
    if (this.isLoggedIn) {
      let localCartableObj = JSON.parse(
        localStorage.getItem("local-cartable-obj")
      );
      this.localCarableObj = localCartableObj || {};
    }
  },
  mounted() {
    this.rating = this.product.AverageRating;
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/product_details_summery.scss";
</style>
