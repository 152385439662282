<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>
          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-skeleton-loader
                class="mx-auto pl-5 pr-5"
                type="article,article,article,article"
                v-if="loading"
              ></v-skeleton-loader>
              <template v-else>
                <v-layout wrap class="account-panel" v-if="!loading">
                  <v-flex class="profile-information">
                    <h2 class="d-flex pb-2 align-center">
                      <span v-text="$t('Address')"></span>
                      <v-btn
                        class="brand white--text text-none ml-3"
                        @click="$refs.addressdialog.modalOpen()"
                      >{{$t('Add')}}</v-btn>
                    </h2>

                    <template v-if="allAddress.length">
                      <v-flex
                        class="profile-information-box"
                        v-for="(address, id) in allAddress"
                        :key="id"
                      >
                        <h2 class="d-flex justify-end">
                          <v-btn
                            class="info white--text text-none mr-1"
                            @click="editAddress(address)"
                          >{{$t('Edit')}}</v-btn>
                          <v-btn
                            v-if="
                              !address.IsDefault &&
                              currentAddressId != address.Id
                            "
                            class="danger white--text text-none"
                            @click="addressDelete(address.Id)"
                          >{{$t('Delete')}}</v-btn>
                        </h2>
                        <ul>
                          <li>
                            <strong v-text="address.Title"></strong>
                          </li>
                          <li>
                            <span v-text="$t('Name')"></span>
                            <span class="profile-info" v-text="address.Name"></span>
                          </li>
                          <li>
                            <span v-text="$t('Phone')"></span>
                            <span class="profile-info" v-text="address.Mobile"></span>
                          </li>
                          <li>
                            <span v-text="$t('Email')"></span>
                            <span class="profile-info" v-text="address.Email"></span>
                          </li>
                          <li>
                            <span v-text="$t('Address')"></span>
                            <span class="profile-info" v-text="showFullAddress(address)"></span>
                          </li>
                          <li>
                            <p
                              v-if="address.IsDefault"
                              class="brand--text"
                              v-text="'Default Address'"
                            ></p>
                            <v-btn
                              v-else
                              class="grey white--text"
                              @click="markAsDefaultSubmit(address.Id)"
                            >{{$t('Mark as Default')}}</v-btn>
                          </li>
                          <li
                            v-if="isSeller && currentAddressId == address.Id"
                            class="success--text"
                          >{{$t("Note: All product's delivery costs will be calculated based on this address, Nilam is considering it as your shop address.")}}</li>
                        </ul>
                      </v-flex>
                    </template>
                  </v-flex>
                </v-layout>
              </template>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <address-form ref="addressdialog"></address-form>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import AddressForm from "../components/sites/common/AddressForm.vue";

export default {
  name: "Address",
  components: {
    AccountSidebar,
    AddressForm,
  },
  data() {
    return {
      allAddress: [],
      isDefault: "isDefaultRadio",
      addressObj: {},
      loading: false,
    };
  },

  computed: {
    currentAddressId() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.CurrentAddressId;
      }
      return 0;
    },
    isSeller() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.IsVerified;
      }
      return false;
    },
  },
  methods: {
    editAddress(obj) {
      this.$refs.addressdialog.modalOpen(obj);
    },
    showFullAddress(addressObj) {
      let {
        Address1 = "",
        DistrictName = "",
        DivisionName = "",
      } = addressObj || {};
      let arr = [Address1, DistrictName, DivisionName];
      return arr.filter((x) => x != "" && x != null).join(",");
    },
    getAddress() {
      if (this.loading) return;

      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.getShippingAddress)
        .then((result) => {
          this.allAddress = result.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async markAsDefaultSubmit(addressId) {
      if (!addressId || this.loading) return;

      const confirm = await this.$confirm("Are you sure?");
      if (!confirm) return;

      this.$http
        .post(
          this.$store.state.Configs.markAsDefaultAddress.replace(
            ":id",
            addressId
          )
        )
        .then((res) => {
          this.$store.commit("set_flash", {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          });
          this.getAddress();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async addressDelete(addressId) {
      if (!addressId || this.loading) return;

      const yes = await this.$confirm("Do you really want to delete?", {
        title: "Warning",
      });
      if (!yes) return;

      this.$http
        .post(this.$store.state.Configs.deleteAddress.replace(":id", addressId))
        .then((res) => {
          this.$store.commit("set_flash", {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          });
          this.getAddress();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.getAddress();
    this.Fire.$on("address-updated", () => {
      this.getAddress();
    });
  },
  beforeDestroy() {
    this.Fire.$off("address-updated");
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/my_profile.scss";
</style>
