<template>
  <v-flex class="site-content-wrap site_bg">
    <v-skeleton-loader
      class="mx-auto pl-5 pr-5"
      type="list-item-avatar-three-line, image, article"
      v-if="loading"
    ></v-skeleton-loader>
    <template v-else>
      <v-container class="py-0" v-if="product && Object.keys(product).length">
        <v-card tile>
          <v-sheet class="product-details-wrap">
            <!-- breadcrumb sections -->
            <site-breadcrumb
              class="product-details-breadcrumb"
              :options="breadcrumb"
            ></site-breadcrumb>

            <v-layout wrap class="product-details-top justify-space-between">
              <!-- product details images -->
              <v-flex class="product-details-images">
                <v-flex class="product-image">
                  <wish-list-btn
                    :is-favorite="product.IsFavorite"
                    :product-id="product.Id"
                    :is-elevation="true"
                  ></wish-list-btn>
                  <v-img
                    v-if="imgError"
                    :title="product.ItemName"
                    src
                    :lazy-src="require('@/assets/images/no-img.jpg')"
                    :alt="product.ItemName"
                  ></v-img>
                  <a
                    v-else
                    :href="
                      $root.generateSlideImageUrl(
                        defaultImage,
                        $root.dimentions.original
                      )
                    "
                    id="Zoom-1"
                    data-options="variableZoom: true; cssClass: thumbnails-style-shaded; selectorTrigger: hover; transitionEffect: false;"
                  >
                    <img
                      @error="setImgError"
                      :src="$root.generateSlideImageUrl(defaultImage)"
                      :alt="product.ItemName"
                    />
                  </a>
                </v-flex>

                <v-flex class="product-thumb">
                  <v-slide-group multiple show-arrows v-if="!imgError">
                    <v-slide-item
                      v-for="image in product.Documents"
                      :key="image.Id"
                    >
                      <a
                        class="thumb-holder"
                        :href="
                          $root.generateSlideImageUrl(
                            image,
                            $root.dimentions.original
                          )
                        "
                        data-zoom-id="Zoom-1"
                        :data-image="$root.generateSlideImageUrl(image)"
                      >
                        <img
                          :src="
                            $root.generateSlideImageUrl(
                              image,
                              $root.dimentions.thumb
                            )
                          "
                          :alt="product.ItemName"
                        />
                      </a>
                    </v-slide-item>
                  </v-slide-group>
                </v-flex>
              </v-flex>

              <!-- product details summery auction-->
              <product-details-summery-auction
                v-if="product.SaleType == 'Auction'"
                :product="product"
              ></product-details-summery-auction>
              <!-- product details summery -->
              <product-details-summery
                v-else
                :product="product"
              ></product-details-summery>

              <product-information
                v-if="$root.windowSize <= 1263"
                :product="product"
              ></product-information>

              <!-- product-details-info -->
              <v-flex class="product-details-info">
                <v-card outlined>
                  <v-flex class="delivery-option">
                    <v-card-title
                      class="pa-0 pb-3 d-flex justify-space-between"
                    >
                      Delivery Options
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span
                          class="tooltip-info"
                          v-text="
                            'When you order any product, After order confirmation, We will deliver you within 3 to 7 days'
                          "
                        ></span>
                      </v-tooltip>
                    </v-card-title>

                    <v-card-text
                      class="pa-0 pb-2 d-flex justify-space-between align-center"
                    >
                      <span>
                        Home Delivery
                        <br />
                        <span
                          class="grey--text text--darken-3 font-weight-medium"
                          v-text="
                            siteSettings.DeliveryFrom +
                            ' - ' +
                            siteSettings.DeliveryTo +
                            ' Days'
                          "
                        ></span>
                      </span>
                      <span
                        v-if="siteSettings.DeliveryCharge"
                        class="grey--text text--darken-3 font-weight-medium"
                        v-text="$root.showAmount(siteSettings.DeliveryCharge)"
                      ></span>
                    </v-card-text>

                    <v-card-text class="pa-0 pb-2"
                      >Cash on Delivery Available</v-card-text
                    >
                  </v-flex>

                  <v-divider></v-divider>
                  <v-flex class="delivery-option">
                    <v-card-title class="pa-0 pb-3"
                      >Seller information</v-card-title
                    >

                    <v-card-text class="pa-0 pb-2">
                      <router-link
                        :to="{
                          name: 'ProductSearch',
                          params: { slug: slugify(CompanyName) },
                          query: { OwnerId: product.SellerId },
                        }"
                        class="pr-6"
                      >
                        <span
                          class="brand--text text-decoration-underline"
                          v-text="CompanyName"
                        >
                        </span>
                        <!-- <small
                          class="grey--text"
                          v-text="' (' + product.SellerName + ')'"
                        ></small> -->
                      </router-link>

                      <v-flex class="pt-2">
                        <span class="grey--text text--darken-3">Review</span>
                        <span class="brand--text">
                          ( {{ product.SellerPositiveRating }}
                          <v-icon class="brand--text star">mdi-star</v-icon>)
                        </span>
                      </v-flex>
                    </v-card-text>
                    <v-card-text class="pa-0 pb-2">
                      <span
                        class="grey--text text--darken-3"
                        v-text="
                          product.SellerResponseRating > 0
                            ? product.SellerResponseRating +
                              ' Positive feedback'
                            : 'No feedback yet'
                        "
                      ></span>
                      <!-- <div class="pt-2">
                        Shipping & Handling (Customer Pick-up)
                      </div> -->
                    </v-card-text>
                  </v-flex>

                  <v-divider></v-divider>
                  <v-flex class="delivery-option">
                    <v-card-title
                      class="pa-0 pb-3 d-flex justify-space-between"
                    >
                      Return & Warranty
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span
                          class="tooltip-info"
                          v-if="product.HasWarranty"
                          v-text="
                            'If you want to claim warranty? please contact us, possible to include copies of applicable documents such as the receipt or warranty statement. and product with full box.'
                          "
                        ></span>
                        <span
                          class="tooltip-info"
                          v-else
                          v-text="'Warranty not available'"
                        ></span>
                      </v-tooltip>
                    </v-card-title>

                    <v-card-text class="pa-0 pb-2">
                      <span
                        class="d-block font-weight-medium grey--text text--darken-3 pb-2"
                        v-if="product.HasWarranty"
                        v-text="product.WarrantyNote"
                      ></span>
                      <span
                        class="d-block font-weight-medium grey--text text--darken-3 pb-2"
                        v-else
                        v-text="'Warranty not available'"
                      ></span>
                    </v-card-text>
                  </v-flex>

                  <v-divider></v-divider>
                  <v-flex class="delivery-option">
                    <v-card-title class="pa-0 pb-3"
                      >Payments Method</v-card-title
                    >

                    <v-card-text class="pa-0 pb-2">
                      <v-img
                        :src="require('@/assets/images/payment-methods.png')"
                        alt="Pyment Method"
                        max-width="260"
                      ></v-img>
                    </v-card-text>
                  </v-flex>
                </v-card>
              </v-flex>
            </v-layout>

            <!-- Product Information sections -->
            <product-information
              v-if="$root.windowSize >= 1264"
              :product="product"
            ></product-information>

            <!-- Related Products sections -->
            <v-flex class="product-dtls-related-product">
              <related-products></related-products>
            </v-flex>
            <!-- Reviews And Rattings sections -->
            <reviews-and-ratings
              v-if="true"
              :product="product"
            ></reviews-and-ratings>
          </v-sheet>
        </v-card>

        <v-card tile elevation="2" class="mt-4 py-4" v-if="false">
          <v-card-text class="pa-0 black--text text-center please-contact">
            If you want to sell your product create new post?
            <router-link :to="{ name: 'postProductStep1' }"
              >Post Your Item</router-link
            >
          </v-card-text>
        </v-card>
      </v-container>
      <data-not-found v-else class="mt-10">
        <h1>No Product Found</h1>
      </data-not-found>
    </template>
  </v-flex>
</template>

<script>
import SiteBreadcrumb from "@/components/sites/common/SiteBreadcrumb";
import ProductDetailsSummeryAuction from "@/components/sites/pages/ProductDetailsSummeryAuction";
import ProductDetailsSummery from "@/components/sites/pages/ProductDetailsSummery";
import ProductInformation from "@/components/sites/pages/ProductInformation";
import ReviewsAndRatings from "@/components/sites/pages/ReviewsAndRatings";
import RelatedProducts from "@/components/sites/common/RelatedProducts";
import WishListBtn from "@/components/sites/common/WishListBtn";
import DataNotFound from "@/components/sites/common/DataNotFound";
import { SlugifyMixin } from "@/mixins/SlugifyMixin";

import "@/assets/magiczoomplus/magiczoomplus.js";

export default {
  name: "ProductsDetails",
  mixins: [SlugifyMixin],
  data() {
    return {
      loading: false,
      product: {},
      rating: 4,
      breadcrumb: [],
      metaObj: {},
      imgError: false,
    };
  },
  components: {
    SiteBreadcrumb,
    ProductDetailsSummery,
    ProductDetailsSummeryAuction,
    ProductInformation,
    RelatedProducts,
    WishListBtn,
    DataNotFound,
    ReviewsAndRatings,
  },
  computed: {
    hasClientToken: function () {
      return this.$store.getters.hasClientToken;
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    CompanyName() {
      let title = this.product.CompanyName;
      let skipMaxNumber = this.$store.state.Configs.skipMaxNumberInput;

      [...title].forEach((c, i) => {
        if (
          this.$store.state.Configs.charCodeListsForBot.includes(
            title.charCodeAt(i)
          )
        ) {
          if (skipMaxNumber < 1) {
            title = this.$root.replaceAt(title, i, "*");
          }
          skipMaxNumber--;
        }
      });
      return title;
    },
    defaultImage() {
      let defaul = this._.find(this.product.Documents, { IsCover: true });
      if (!defaul) {
        defaul = this.product.Documents[0];
      }
      return defaul;
    },
  },
  created() {
    this.getProductDetails();
  },
  methods: {
    setImgError() {
      this.imgError = true;
    },
    getProductDetails() {
      if (this.loading) return;
      this.loading = true;

      this.product = {};
      this.$http
        .post(
          this.$store.state.Configs.getProductDetails
            .replace(":id", this.$route.params.id)
            .replace(":slug", this.$route.params.slug)
        )
        .then((result) => {
          let dataObj = result.data;
          this.product = dataObj;

          // pixel event for AddPaymentInfo Start
          window.fbq("track", "ViewContent", {
            content_ids: [parseInt(this.product.Id)],
            content_type: "product",
            value: this.product.SalesPrice,
            currency: "BDT",
          });
          // pixel event for AddPaymentInfo End

          //console.log(this.product);

          let bradcrumpsData = dataObj.BradcrumbCategories;

          delete dataObj.BradcrumbCategories;

          this.breadcrumb = [];

          if (bradcrumpsData.length) {
            let routeParams = this.$route.params;

            bradcrumpsData.forEach((element) => {
              if (element.Id) {
                let resolved = this.$router.resolve({
                  name: "ProductSearch",
                  params: routeParams,
                  query: { ParentCategoryId: element.Id },
                });

                this.breadcrumb.push({
                  text: element.CategoryName,
                  disabled: false,
                  href: resolved.href,
                });
              }
            });
          }
          this.breadcrumb.push({
            text: this.product.ItemName,
            disabled: true,
            href: "/",
          });

          this.loading = false;
        })
        .then(() => {
          let MetaDescription = null;
          if (this.product.MetaDescription) {
            MetaDescription = this.product.MetaDescription.replace(
              /\s+/g,
              " "
            ).trim();
          }

          let MetaKeyword = null;

          if (this.product.MetaKeyword) {
            MetaKeyword = this.product.MetaKeyword.replace(/\s+/g, " ").trim();
          }

          this.$store.commit("update_meta", {
            PageTitle: this.product.MetaTitle,
            MetaKeywords: MetaKeyword,
            MetaDescription: MetaDescription,
            SocialLogoPath: this.product.Documents[0].FilePath,
          });
        })
        .then(() => {
          return document.getElementById("Zoom-1");
        })
        .then((dom) => {
          this.refreshMagicZoom(dom);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    refreshMagicZoom(dom) {
      /* eslint-disable */
      window.setTimeout(() => {
        MagicZoom.refresh(dom);
      }, 1000);
      /* eslint-enable */
    },
  },
  beforeDestroy() {
    this.$store.commit("update_meta", {
      PageTitle: null,
      MetaKeywords: null,
      MetaDescription: null,
      SocialLogoPath: this.siteSettings.SocialLogoPath,
    });
  },
  watch: {
    $route() {
      this.getProductDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/product_details.scss";
</style>
