export var AddressMixins = {
    methods: {
        getSelectedAddress(addressArr, Id) {
            let address;

            if (Id) {
                address = this._.find(addressArr, (obj) => {
                    return obj.Id == Id;
                });
                if (!address) {
                    address = this._.find(addressArr, (obj) => {
                        return obj.IsDefault == true;
                    });
                }
            } else {
                address = this._.find(addressArr, (obj) => {
                    return obj.IsDefault == true;
                });
            }
            return address || {};
        },
        getAddressListsWithoutSelected(addressArr, selectedId) {
            return this._.reject(addressArr, { Id: selectedId });
        },
    }
}