<template>
  <v-flex class="data-not-found text-center">
    <slot></slot>
    <v-flex>
      <img alt="nodata image" :src="require('@/assets/images/no-data-1.png')" />
    </v-flex>
  </v-flex>
</template>

<script>
export default {
  name: "DataNotFound",
};
</script>
<style lang="scss" scoped>
@import "@/sass/app/index.scss";
.data-not-found {
  text-align: center;
  h1 {
    padding: 5% 0 2%;
  }
  h4 {
    padding: 0 0 12%;
    @include font(false, 16px, 22px, regular);

    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  img {
    max-width: 100%;
  }
}
@include media(1400px) {
  .data-not-found {
    h1 {
      padding: 0 0 2%;
    }
    h4 {
      padding: 0 0 3%;
    }
  }
}
</style>
