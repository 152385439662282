<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-flex class="account-panel">
                <!-- filters-bar -->
                <v-layout wrap class="top-filter">
                  <div class="input-wrap">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Keyword')"
                      v-model="filterObj.Keyword"
                    ></v-text-field>
                  </div>

                  <div class="input-wrap">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="filterObj.date_range"
                      persistent
                      width="300px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterObj.date_range"
                          solo
                          flat
                          hide-details
                          outlined
                          :label="$t('Date range')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        range
                        v-model="filterObj.date_range"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(filterObj.date_range)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </div>

                  <div class="input-wrap">
                    <v-select
                      :items="status"
                      dense
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Status')"
                      v-model="filterObj.Status"
                    ></v-select>
                  </div>

                  <div class="input-wrap">
                    <v-select
                      :items="conditions"
                      dense
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Condition')"
                      attach
                      chips
                      multiple
                      v-model="filterObj.Condition"
                    ></v-select>
                  </div>
                  <div class="input-wrap">
                    <v-select
                      :items="saleTypes"
                      dense
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Sale Type')"
                      attach
                      chips
                      multiple
                      item-text="title"
                      item-value="value"
                      v-model="filterObj.saleType"
                    ></v-select>
                  </div>
                  <div class="input-wrap">
                    <v-select
                      :items="brands"
                      dense
                      solo
                      flat
                      hide-details
                      outlined
                      :label="$t('Brands')"
                      item-text="BrandName"
                      item-value="Id"
                      v-model="filterObj.BrandId"
                    ></v-select>
                  </div>
                  <v-btn
                    @click="getMyProducts()"
                    height="42"
                    type="submit"
                    tile
                    depressed
                    class="brand white--text text-none"
                    >{{ $t("Search") }}</v-btn
                  >
                </v-layout>

                <v-skeleton-loader
                  class="mx-auto pl-5 pr-5"
                  type="article,article,article"
                  v-if="loading"
                ></v-skeleton-loader>
                <template v-else>
                  <template
                    v-if="
                      Object.keys(productsObj).length && productsObj.data.length
                    "
                  >
                    <v-card class="my-product" outlined tile>
                      <v-flex
                        class="product-top d-flex flex-wrap justify-space-between"
                      >
                        <span class="total-products">
                          <strong>{{ productsObj.recordsTotal }}</strong>
                          {{ $t("Products Found") }}
                        </span>
                      </v-flex>

                      <v-layout
                        wrap
                        v-for="product in productsObj.data"
                        :key="product.id"
                        class="product-item"
                      >
                        <v-flex class="product-image flex-grow-0">
                          <v-img
                            :title="product.ItemName"
                            :src="
                              $root.generateImageUrl(
                                product,
                                $root.dimentions.thumb
                              )
                            "
                            :lazy-src="require('@/assets/images/no-img.jpg')"
                            :alt="product.ItemName"
                          >
                            <router-link
                              :to="{
                                name: 'ProductDetails',
                                params: { id: product.Id, slug: product.Slug },
                              }"
                              class="fill-height d-block"
                            ></router-link>

                            <v-card-text
                              class="product-top-labels py-2 px-4 d-flex justify-space-between"
                            >
                              <v-flex class="flex-grow-0">
                                <v-sheet
                                  v-if="product.SaleType == 'Auction'"
                                  tag="span"
                                  dark
                                  color="brand"
                                  class="py-1 px-3 font-weight-light text-center d-inline-block align-center"
                                  >{{ product.SaleType }}</v-sheet
                                >
                              </v-flex>
                            </v-card-text>
                          </v-img>
                        </v-flex>

                        <v-flex class="product-mid flex-grow-0">
                          <span
                            class="category"
                            v-text="product.CategoryName"
                          ></span>

                          <h2>
                            <router-link
                              :to="{
                                name: 'ProductDetails',
                                params: { id: product.Id, slug: product.Slug },
                              }"
                              v-text="product.ItemName"
                            ></router-link>
                          </h2>

                          <v-flex class="condition-type">
                            <span
                              :class="
                                colorsArr[product.Condition.toLowerCase()] +
                                '--text'
                              "
                              >{{ product.Condition }}</span
                            >
                            <span
                              :class="
                                colorsArr[product.StockType.toLowerCase()] +
                                '--text'
                              "
                              >({{ product.StockType }})</span
                            >
                          </v-flex>
                          <v-layout>
                            <h3
                              v-text="$root.showAmount(product.BasePrice)"
                              v-if="product.SaleType == 'Auction'"
                            ></h3>
                            <h3
                              v-text="$root.showAmount(product.SalesPrice)"
                              v-if="product.SaleType != 'Auction'"
                            ></h3>
                            <product-attribute-update
                              v-if="product.SaleType != 'Auction'"
                              :product="product"
                              :type="'SalesPrice'"
                            ></product-attribute-update>
                          </v-layout>

                          <v-sheet
                            class="start-bid"
                            v-if="product.SaleType == 'Auction'"
                          >
                            <span
                              v-if="product.BidCount"
                              v-text="$t('Current Bid')"
                            ></span>
                            <span v-else v-text="$t('Starting bid')"></span>
                          </v-sheet>

                          <v-layout v-if="product.SaleType != 'Auction'">
                            <v-sheet>
                              {{ $t("Discount") }}:
                              <span
                                v-if="product.DiscountedPrice"
                                tag="span"
                                class="bidding-status text-decoration-line-through pl-2 pt-1"
                                v-text="
                                  $root.showAmount(product.DiscountedPrice)
                                "
                              ></span>
                              <span
                                v-if="product.DiscountTag"
                                tag="span"
                                class="discount-tag pl-2 pt-1"
                                v-text="'(' + product.DiscountTag + ')'"
                              ></span>
                            </v-sheet>

                            <product-attribute-update
                              v-if="product.SaleType != 'Auction'"
                              :product="product"
                              :type="'ItemDiscount'"
                            ></product-attribute-update>
                          </v-layout>

                          <v-layout>
                            <v-sheet>
                              {{ $t("Availablity") }}:
                              <span
                                v-if="product.StockQuantity > 0"
                                v-html="
                                  product.StockQuantity +
                                  ' ' +
                                  product.MeasurementName +
                                  '<b>' +
                                  $t('In Stock') +
                                  '</b>'
                                "
                              ></span>
                              <span v-else>
                                <b v-text="$t('Out of Stock')"></b>
                              </span>
                            </v-sheet>

                            <product-attribute-update
                              v-if="product.SaleType != 'Auction'"
                              :product="product"
                              :type="'StockQuantity'"
                            ></product-attribute-update>
                          </v-layout>

                          <v-sheet>
                            {{ $t("Sale Type") }}:
                            <span
                              v-html="'<b>' + product.SaleType + '</b>'"
                            ></span>
                          </v-sheet>

                          <v-sheet class="posted-on">
                            {{ $t("Posted") }}:
                            <span v-text="product.CreatedAtFormated"></span>
                          </v-sheet>
                        </v-flex>

                        <v-flex class="product-right flex-grow-0">
                          <v-flex>
                            <v-chip small class="mr-2">
                              <v-icon size="15" class="mr-1">mdi-eye</v-icon>
                              {{ $t("View") }}
                              <strong class="ml-1">{{
                                product.ViewCount
                              }}</strong>
                            </v-chip>
                            <v-chip small>
                              <v-icon size="15" class="mr-1">mdi-eye</v-icon>
                              {{ $t("Click") }}
                              <strong class="ml-1">{{
                                product.ClickCount
                              }}</strong>
                            </v-chip>
                          </v-flex>
                          <h2>
                            {{
                              product.Status == "Pending"
                                ? $t("You can edit this information")
                                : $t("Your item is available to public market.")
                            }}
                          </h2>
                          <v-sheet class="bublished-status">
                            {{
                              product.SaleType == "Auction"
                                ? product.AuctionStatus
                                : product.Status
                            }}
                          </v-sheet>

                          <template v-if="product.SaleType == 'Bargain'">
                            <bargain-modal
                              :ref="'bargainModal-' + product.Id"
                              :item-id="product.Id"
                            ></bargain-modal>
                            <v-chip
                              @click="
                                showBargainDetailsModal(
                                  'bargainModal-' + product.Id
                                )
                              "
                              small
                              brand
                            >
                              <v-icon size="15" class="mr-1">mdi-eye</v-icon>
                              {{ $t("Show Bargain Details") }}
                            </v-chip>
                          </template>

                          <v-sheet class="short-desc"></v-sheet>
                          <v-flex v-if="product.Status == 'Pending'">
                            <v-btn
                              height="30"
                              depressed
                              rounded
                              class="mr-2 text-none"
                              :to="{
                                name: 'postProductStep1',
                                params: { id: product.Id },
                              }"
                            >
                              <v-icon class="mr-1" size="16" color="grey"
                                >mdi-lead-pencil</v-icon
                              >
                              {{ $t("Edit") }}
                            </v-btn>

                            <v-btn
                              v-if="false"
                              height="30"
                              depressed
                              rounded
                              class="text-none"
                              @click="deleteProduct(product.Id)"
                            >
                              <v-icon class="mr-1" size="16" color="grey"
                                >mdi-delete</v-icon
                              >
                              {{ $t("Delete") }}
                            </v-btn>
                          </v-flex>
                          <v-flex
                            class="d-flex justify-end"
                            v-if="
                              product.SaleType == 'Auction' &&
                              product.Status == 'Published'
                            "
                          >
                            <v-sheet class="auction-time-holder">
                              <countdown
                                v-if="$root.getTime(product)"
                                :time="$root.getTime(product)"
                                :transform="$root.transformShorter"
                              >
                                <template slot-scope="props">
                                  <span
                                    class="time-title"
                                    :class="
                                      $root.countDown.Color[
                                        product.AuctionStatus
                                      ]
                                    "
                                  >
                                    {{
                                      $root.countDown.Text[
                                        product.AuctionStatus
                                      ]
                                    }}:
                                  </span>
                                  <b class="time-value">
                                    {{ props.days }} {{ props.hours }}
                                    {{ props.minutes }}
                                    {{ props.seconds }}
                                  </b>
                                </template>
                              </countdown>
                              <span v-else>Bidding Closed</span>
                            </v-sheet>
                          </v-flex>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <v-flex
                      class="account-panel-table"
                      v-if="productsObj.TotalPage > 1"
                    >
                      <v-flex class="d-flex justify-end account-pagination">
                        <pagination
                          :page-no="productsObj.Page"
                          :length="productsObj.TotalPage"
                        ></pagination>
                      </v-flex>
                    </v-flex>
                  </template>
                  <!-- Data not found start-->
                  <data-not-found v-else class="mt-10">
                    <h1>{{ $t("No records found.") }}</h1>
                    <h5 class="mt-1">
                      {{ $t("Looks like you haven't post any product yet.") }}
                    </h5>
                    <v-btn
                      class="mt-1"
                      x-small
                      color="primary"
                      @click="onReset()"
                      >{{ $t("Reset") }}</v-btn
                    >
                  </data-not-found>
                  <!-- Data not found end-->
                </template>
              </v-flex>
            </v-card>

            <v-card
              tile
              elevation="0"
              class="mt-7"
              v-if="!siteSettings.IsECommerce"
            >
              <v-flex class="sell text-center">
                <h2 v-text="$t('Do you have something to sell?')"></h2>
                <h3 v-text="$t('Post your item here')"></h3>
                <router-link
                  class="post-promotion"
                  :to="{ name: 'postProductStep1' }"
                >
                  <v-icon size="20" class="mr-1">mdi-plus-thick</v-icon>
                  {{ $t("Post Your Item") }}
                </router-link>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import Pagination from "@/components/services/Pagination";
import DataNotFound from "@/components/sites/common/DataNotFound";
import ProductAttributeUpdate from "@/components/sites/common/ProductAttributeUpdate";
import BargainModal from "@/components/sites/common/BargainModal";
export default {
  name: "MyProducts",
  components: {
    AccountSidebar,
    Pagination,
    DataNotFound,
    ProductAttributeUpdate,
    BargainModal,
  },
  data() {
    return {
      loading: false,
      brandsLoading: false,
      modal: false,
      productsObj: [],
      brands: [],
      formData: {},
      status: [
        "Pending",
        "Published",
        "Bidding",
        "Closed",
        "ShortListed",
        "Ordered",
        "Canceled",
      ],
      conditions: ["New", "Used", "ReNew"],
      saleTypes: [
        { title: "Ganeral Sale", value: "Gallery" },
        { title: "Sale by Nilam", value: "Auction" },
        { title: "Sale by Bargain", value: "Bargain" },
      ],
      active_status: ["selected"],
      checkbox_items: ["All", "Active", "In-active"],
      colorsArr: {
        new: "silver_tree",
        renew: "silver_tree",
        used: "black",
        single: "grey",
        lot: "grey",
      },
      filterObj: {
        Condition: [],
        date_range: [],
        Page: 1,
        Length: 10,
      },
      productDeleteUrl: this.$store.state.Configs.productDelete,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  created() {
    this.getMyProducts();
    this.getMyBrands();
  },
  mounted() {
    this.Fire.$on("pageChanged", (val) => {
      this.filterObj.Page = parseInt(val);
      this.getMyProducts();
    });
    var _this = this;
    this.Fire.$on("product-attribute-updated", (updatedData) => {
      //for update current item attribute
      if (updatedData.productAttributeUpdateType == "ItemDiscount") {
        let itemIndex = _this.productsObj.data.findIndex(
          (obj) => obj.Id === updatedData.ItemId
        );
        _this.productsObj.data[itemIndex].SalesPrice =
          updatedData.discountedData.SalesPrice;
        _this.productsObj.data[itemIndex]["DiscountedPrice"] =
          updatedData.discountedData.DiscountedPrice;
        _this.productsObj.data[itemIndex]["DiscountTag"] =
          updatedData.discountedData.DiscountTag;
        _this.$vuetify.goTo(0);
      } else {
        let itemIndex = _this.productsObj.data.findIndex(
          (obj) => obj.Id === updatedData.Id
        );
        _this.productsObj.data[itemIndex][
          updatedData.productAttributeUpdateType
        ] = updatedData[updatedData.productAttributeUpdateType];
        _this.$vuetify.goTo(0);
      }
    });
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
    this.Fire.$off("product-attribute-updated");
  },
  methods: {
    onReset() {
      this.filterObj.date_range = [];
      this.filterObj.DateFrom = "";
      this.filterObj.DateTo = "";
      this.filterObj.Keyword = "";
      this.filterObj.Page = 1;
      this.filterObj.Status = null;
      this.filterObj.Length = 10;
      this.getMyProducts();
    },
    showBargainDetailsModal(target) {
      this.$refs[target][0].showModal();
    },

    getAuthUserId() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.UserId;
      }
      return 0;
    },
    getMyBrands() {
      if (this.brandsLoading) return;
      this.brandsLoading = true;
      this.$http
        .post(
          this.$store.state.Configs.getMyBrands.replace(
            ":ownerId",
            this.getAuthUserId()
          )
        )
        .then((result) => {
          this.brands = result.data;
          this.brandsLoading = false;
        })
        .then(() => {
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          this.brandsLoading = false;
          console.log(error);
        });
    },

    getMyProducts() {
      if (this.loading) return;

      this.loading = true;

      this.formatFilterObj();
      this.$http
        .post(this.$store.state.Configs.getMyProducts, this.filterObj)
        .then((result) => {
          this.productsObj = result.data;
          this.loading = false;
        })
        .then(() => {
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatFilterObj() {
      if (this.filterObj.date_range.length > 0) {
        if (this.filterObj.date_range.length == 1) {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[0];
        } else {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[1];
        }
      }
    },
    async deleteProduct(ItemId) {
      if (!this.productDeleteUrl || this.loading) return;

      const yes = await this.$confirm("Do you really want to delete?", {
        title: "Warning",
      });
      if (!yes) return;
      this.loading = true;
      this.$http
        .post(this.productDeleteUrl.replace(":id", ItemId))
        .then((res) => {
          this.$store.commit("set_flash", {
            type: res.data.ResponseType,
            message: res.data.ResponseMessage,
          });
          this.getMyProducts();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_products.scss";
</style>
