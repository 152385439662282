<template>
  <span class="profile-info d-flex flex-wrap">
    <template v-if="!onlyDialog">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex" v-bind="attrs" v-on="on">
            <p v-text="userName" v-if="!hideUserName"></p>
            <v-icon v-if="isVerified" class="pl-1" color="silver_tree" size="16"
              >mdi-check-decagram</v-icon
            >
            <v-icon v-else class="pl-1" color="deep-orange" size="16"
              >mdi-information</v-icon
            >
          </div>
        </template>
        <small>
          This {{ type }} is {{ isVerified ? "" : "un" }}verified
          <br />
        </small>
        <!-- You can {{ isVerified ? "change" : "verify" }} this {{ type }}. -->
      </v-tooltip>

      <a
        href="javascript:;"
        class="ml-1 verify-now pointer brand--text"
        v-text="'Update'"
        @click="verifyNow()"
      ></a>
    </template>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-text class="pt-3">
          <v-alert v-if="onlyDialog" color="info" dense text class="mb-3">
            E-mail Address is required for online payment. Please input your
            E-mail here.</v-alert
          >

          <v-alert
            v-if="Object.keys(alertObj).length"
            dense
            text
            dismissible
            ref="alertBox"
            class="mb-3"
            :type="alertObj.type.toLowerCase()"
          >
            <template slot="close">
              <button
                type="button"
                @click.stop="clearAlertObj"
                class="v-btn v-btn--flat v-btn--icon v-btn--round theme--dark v-size--small error--text"
              >
                <span class="v-btn__content">
                  <i
                    class="v-icon notranslate mdi mdi-close-circle theme--dark error--text"
                  ></i>
                </span>
              </button>
            </template>
            <span v-text="alertObj.message"></span>
          </v-alert>

          <ValidationObserver
            class="signin-signup-form forgot-form"
            tag="div"
            ref="form"
            v-slot="{ handleSubmit }"
          >
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div class="input-wrap pb-0">
                <v-flex class="input-label">{{ type }}</v-flex>
              </div>
              <v-layout>
                <v-flex>
                  <ValidationProvider
                    v-if="type == 'Mobile'"
                    tag="div"
                    :name="type"
                    :rules="{
                      required: true,
                      max: 14,
                      regex: /^(?:\+88|88)?(01[3-9]\d{8})$/,
                    }"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        solo
                        outlined
                        flat
                        type="text"
                        autocomplete="off"
                        hide-details
                        label
                        v-model="formData.username"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    v-else
                    tag="div"
                    rules="required"
                    :name="type"
                    :bails="false"
                    v-slot="{ errors, classes }"
                  >
                    <div class="input-wrap" :class="classes">
                      <v-text-field
                        solo
                        outlined
                        flat
                        type="text"
                        autocomplete="off"
                        hide-details
                        label
                        v-model="formData.username"
                      ></v-text-field>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </v-flex>

                <v-flex class="flex-grow-0">
                  <send-otp-btn
                    style="height: 43px"
                    :change-url="true"
                    :username.sync="formData.username"
                    :type="type"
                    class="mb-2 v-btn--has-bg v-btn--tile success"
                    btn-text="Send Otp"
                  ></send-otp-btn>
                </v-flex>
              </v-layout>

              <ValidationProvider
                tag="div"
                name="otp"
                rules="required"
                :bails="false"
                v-slot="{ errors, classes }"
              >
                <div class="input-wrap" :class="classes">
                  <v-flex class="input-label">OTP</v-flex>
                  <v-text-field
                    solo
                    outlined
                    flat
                    type="text"
                    autocomplete="off"
                    hide-details
                    label="Ex: 1243"
                    v-model="formData.otp"
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <v-btn
                width="100%"
                height="50"
                type="submit"
                depressed
                tile
                :disabled="loading"
                :loading="loading"
                class="brand white--text text-none d-block mt-4"
                >Submit</v-btn
              >
            </form>
          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="deep-orange" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import SendOtpBtn from "@/components/sites/common/SendOtpBtn";

export default {
  name: "UpdatePhoneOrEmail",
  components: {
    SendOtpBtn,
  },
  data() {
    return {
      formData: {},
      dialog: false,
      loading: false,
      change: false,
      alertObj: {},
    };
  },
  props: {
    type: String,
    hideUserName: Boolean,
    onlyDialog: Boolean,
  },
  computed: {
    profileObj() {
      return this.$store.state.profileObj;
    },
    userName() {
      if (this.type == "Mobile") {
        return this.profileObj.Mobile;
      }
      if (this.type == "Email") {
        return this.profileObj.Email;
      }
      return null;
    },
    isVerified() {
      if (this.type == "Mobile" && this.profileObj.MobileVerifiedAt) {
        return true;
      }
      if (this.type == "Email" && this.profileObj.EmailVerifiedAt) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.Fire.$on("otpUpdate", (val) => {
      this.alertObj = val;
    });
  },
  beforeDestroy() {
    this.Fire.$off("otpUpdate");
  },
  methods: {
    verifyNow() {
      this.formData = {
        username: this.userName,
      };
      this.dialog = true;
    },
    onSubmit() {
      if (this.loading) return;

      this.$refs.form.validate().then(() => {
        this.loading = true;

        this.$http({
          method: "post",
          url: this.$store.state.Configs.validateOtp,
          data: this.formData,
        })
          .then((res) => {
            this.$store.dispatch("getUserProfile").then(() => {
              this.$refs.form.reset();
              this.loading = false;
              this.dialog = false;

              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
            });
          })
          .catch((err) => {
            this.loading = false;
            this.alertObj = {
              type: "error",
              message: err.response.data,
            };
          });
      });
    },
    clearAlertObj() {
      this.alertObj = {};
    },
    closeDialog() {
      this.formData.otp = "";
      this.$refs.form.reset();
      this.dialog = false;
      this.loading = false;
      this.clearAlertObj();
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-now {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
</style>
