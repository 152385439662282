<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <v-layout wrap>
        <!-- account-sidebar -->
        <account-sidebar></account-sidebar>

        <v-flex class="account-wrap">
          <v-card tile elevation="0">
            <v-flex class="account-panel">
              <v-layout
                wrap
                class="account-panel-top align-center justify-space-between"
              >
                <h2>
                  {{ $t("My Bargain Items") }}
                  <span v-text="`(${total})` + $t('Bargain')"></span>
                </h2>
              </v-layout>

              <!-- filters-bar -->
              <v-layout wrap class="filter-bar">
                <div class="input-wrap">
                  <v-text-field
                    solo
                    flat
                    hide-details
                    outlined
                    :label="$t('Keyword')"
                    v-model="filterObj.Keyword"
                  ></v-text-field>
                </div>

                <div class="input-wrap">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="filterObj.date_range"
                    persistent
                    width="300px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterObj.date_range"
                        solo
                        flat
                        hide-details
                        outlined
                        :label="$t('Date range')"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      range
                      v-model="filterObj.date_range"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(filterObj.date_range)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div class="input-wrap">
                  <v-select
                    :items="status"
                    dense
                    solo
                    flat
                    hide-details
                    outlined
                    :label="$t('Bargain Status')"
                    v-model="filterObj.Status"
                  ></v-select>
                </div>

                <!-- <div class="input-wrap">
                  <v-select
                    :items="types"
                    item-text="value"
                    item-value="key"
                    dense
                    solo
                    flat
                    hide-details
                    outlined
                    label="Bargain Type"
                    v-model="filterObj.Type"
                  ></v-select>
                </div>-->

                <v-btn
                  @click="getMyBargainItems()"
                  height="42"
                  type="submit"
                  tile
                  depressed
                  class="brand white--text text-none"
                  >{{ $t("Search") }}</v-btn
                >
              </v-layout>

              <v-skeleton-loader
                class="mx-auto pl-5 pr-5"
                type="article,article,article"
                v-if="loading"
              ></v-skeleton-loader>

              <template v-else>
                <v-flex
                  class="account-panel-table"
                  v-if="
                    Object.keys(bargainObj).length && bargainObj.data.length
                  "
                >
                  <template v-if="$root.windowSize > 959">
                    <table>
                      <thead>
                        <tr>
                          <th v-text="$t('Last Bargain')"></th>
                          <th v-text="$t('Product')"></th>
                          <th>
                            <div
                              style="width: 110px"
                              v-text="$t('My Price')"
                            ></div>
                          </th>
                          <th>
                            <div
                              style="width: 130px"
                              v-text="$t('Seller Last Price')"
                            ></div>
                          </th>
                          <th>
                            <span v-text="$t('Status')"></span>
                          </th>
                          <th>
                            <span v-text="$t('Action')"></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="product in bargainObj.data"
                          :key="product.Id"
                        >
                          <td>
                            <strong
                              v-text="$t('Your Last Message') + ' : '"
                            ></strong>
                            <span v-text="product.LastBuyerMessage"></span>
                            <br />
                            <strong
                              v-text="$t('Seller Last Message') + ' : '"
                            ></strong>
                            <span v-text="product.LastSellerMessage"></span>
                            <span
                              class="d-block"
                              v-text="
                                $root.formateServerDateTime(
                                  product.LastBargainAt
                                )
                              "
                            ></span>
                          </td>
                          <td>
                            <v-layout
                              class="pt-2 bargain-product-img fill-height"
                            >
                              <v-img
                                contain
                                max-width="50"
                                :src="
                                  $root.generateMediaImageUrl(product.ImageUrl)
                                "
                                alt="product.ItemName"
                              >
                                <router-link
                                  class="d-block fill-height"
                                  :to="{
                                    name: 'ProductDetails',
                                    params: {
                                      id: product.ItemId,
                                      slug: product.Slug,
                                    },
                                  }"
                                ></router-link>
                              </v-img>

                              <v-flex class="my-product-title">
                                <h4 class="product">
                                  <router-link
                                    v-text="product.ItemName"
                                    class="d-block fill-height primary--text"
                                    :to="{
                                      name: 'ProductDetails',
                                      params: {
                                        id: product.ItemId,
                                        slug: product.Slug,
                                      },
                                    }"
                                  ></router-link>
                                </h4>
                              </v-flex>
                            </v-layout>
                          </td>

                          <td>
                            <span
                              v-text="$root.showAmount(product.LastBuyerAmount)"
                              class="last-price d-block"
                            ></span>
                          </td>

                          <td>
                            <span
                              v-text="
                                $root.showAmount(product.LastSellerAmount)
                              "
                              class="order-mony d-block"
                            ></span>
                          </td>

                          <td>
                            <v-sheet class="auction-time-holder">
                              <span
                                v-text="product.Status"
                                :class="
                                  product.Status == 'Canceled'
                                    ? 'deep-orange--text'
                                    : 'brand--text'
                                "
                                class="d-block"
                              ></span>
                            </v-sheet>
                          </td>

                          <td>
                            <v-flex class="btn-box">
                              <v-btn
                                @click="
                                  $refs.bargainModal.showModal(product.Id)
                                "
                                block
                                tile
                                depressed
                                class="silver_tree white--text text-none"
                                >{{ $t("Bargain") }}</v-btn
                              >
                            </v-flex>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>

                  <template v-else>
                    <v-layout
                      v-for="product in bargainObj.data"
                      :key="product.Id"
                      class="bargain-item justify-space-between align-center"
                    >
                      <v-flex class="column brgn-1 flex-grow-0">
                        <v-layout class="bargain-product-img align-center">
                          <v-img
                            contain
                            max-width="50"
                            :src="$root.generateMediaImageUrl(product.ImageUrl)"
                            alt="product.ItemName"
                          >
                            <router-link
                              class="d-block fill-height"
                              :to="{
                                name: 'ProductDetails',
                                params: {
                                  id: product.ItemId,
                                  slug: 'sdfg',
                                },
                              }"
                            ></router-link>
                          </v-img>

                          <v-flex class="my-product-title">
                            <h4 class="product">
                              <router-link
                                v-text="product.ItemName"
                                class="d-block fill-height primary--text"
                                :to="{
                                  name: 'ProductDetails',
                                  params: {
                                    id: product.ItemId,
                                    slug: product.Slug,
                                  },
                                }"
                              ></router-link>
                            </h4>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex class="column brgn-2 flex-grow-0">
                        <span
                          class="price"
                          v-text="$t('My Price') + ': '"
                        ></span>
                        <span
                          v-text="$root.showAmount(product.LastBuyerAmount)"
                          class="last-price brand--text"
                        ></span>
                      </v-flex>

                      <v-flex class="column brgn-3 flex-grow-0">
                        <span
                          class="price"
                          v-text="$t('Seller price') + ': '"
                        ></span>
                        <span
                          v-text="$root.showAmount(product.LastSellerAmount)"
                          class="last-price"
                        ></span>
                      </v-flex>

                      <v-flex class="column brgn-4 flex-grow-0">
                        <span
                          v-text="product.Status"
                          :class="
                            product.Status == 'Canceled'
                              ? 'deep-orange--text'
                              : 'brand--text'
                          "
                          class="d-block status"
                        ></span>
                      </v-flex>

                      <v-flex class="column brgn-5 flex-grow-0">
                        <v-flex class="btn-box">
                          <v-btn
                            @click="$refs.bargainModal.showModal(product.Id)"
                            block
                            tile
                            depressed
                            class="silver_tree white--text text-none"
                            >{{ $t("Bargain") }}</v-btn
                          >
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </template>

                  <v-flex
                    class="d-flex justify-end account-pagination"
                    v-if="bargainObj.TotalPage > 1"
                  >
                    <pagination
                      :page-no="bargainObj.Page"
                      :length="bargainObj.TotalPage"
                    ></pagination>
                  </v-flex>
                </v-flex>

                <!-- Data not found start-->
                <data-not-found v-else class="mt-10">
                  <h1 v-text="$t('No records found.')"></h1>
                  <h5 class="mt-1">
                    {{ $t("Looks like you haven't bargain yet.") }}
                  </h5>
                  <v-btn class="mt-1" x-small color="primary" @click="onReset()"
                    >Reset</v-btn
                  >
                </data-not-found>
                <!-- Data not found end-->
              </template>

              <!-- Send bargain price -->
              <bargain-modal
                ref="bargainModal"
                :item-id="0"
                :qty="1"
              ></bargain-modal>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import Pagination from "@/components/services/Pagination";
import BargainModal from "@/components/sites/common/BargainModal";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "MyBargain",
  components: {
    AccountSidebar,
    Pagination,
    BargainModal,
    DataNotFound,
  },

  data() {
    return {
      loading: false,
      modal: false,
      bargainObj: {},
      drawer: false,
      total: 0,
      status: ["Progress", "Accepted", "Ordered", "Canceled"],
      // types: [
      //   {
      //     key: 2,
      //     value: "Send",
      //   },
      //   {
      //     key: 1,
      //     value: "Received",
      //   },
      // ],
      filterObj: {
        date_range: [],
        DateFrom: "",
        DateTo: "",
        Keyword: "",
        Page: 1,
        Length: 10,
      },
      showBargain: false,
    };
  },
  mounted() {
    this.Fire.$on("pageChanged", (val) => {
      this.filterObj.Page = parseInt(val);
      this.getMyBargainItems();
    });
  },
  created() {
    if (this.$route.name == "MyBargain") {
      this.filterObj.Type = 2; //for customer
    } else {
      this.filterObj.Type = 1; //for seller
    }
    this.getMyBargainItems();
  },
  methods: {
    onReset() {
      this.filterObj.date_range = [];
      this.filterObj.DateFrom = "";
      this.filterObj.DateTo = "";
      this.filterObj.Keyword = "";
      this.filterObj.Page = 1;
      this.filterObj.Status = null;
      this.filterObj.Length = 10;
      this.getMyBargainItems();
    },
    getMyBargainItems() {
      if (this.loading) return;
      this.loading = true;
      this.formatFilterObj();
      this.$http
        .post(this.$store.state.Configs.getMyBargainItem, this.filterObj)
        .then((result) => {
          this.loading = false;
          this.bargainObj = result.data;
          this.total = this.bargainObj.recordsTotal;
        })
        .then(() => {
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    formatFilterObj() {
      if (this.filterObj.date_range.length > 0) {
        if (this.filterObj.date_range.length == 1) {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[0];
        } else {
          this.filterObj.DateFrom = this.filterObj.date_range[0];
          this.filterObj.DateTo = this.filterObj.date_range[1];
        }
      }
    },
  },
  watch: {
    $route(to) {
      //because this component use in two route //myOrder and ReceivedOrder
      if (to.name == "MyBargain") {
        this.filterObj.Type = 2; //for customer
      } else {
        this.filterObj.Type = 1; //for seller
      }
      this.onReset();
    },
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_bargain.scss";
</style>
