<template>
  <v-flex class="contact-us-wrap">
    <v-container v-if="loading">
      <v-skeleton-loader
        class="mx-auto about-panel"
        type="article,article,article,article"
      ></v-skeleton-loader>
    </v-container>
    <template v-else>
      <!-- inner banner -->
      <inner-banner :options="bannerContent"></inner-banner>

      <v-flex class="about-panel">
        <v-flex class="about-box">
          <v-container class="py-0" v-if="Object.keys(pageInfo).length">
            <h3 v-text="pageInfo.PageTitle"></h3>
            <v-flex v-if="pageInfo.PageContent" v-html="pageInfo.PageContent">
            </v-flex>

            <!-- Data not found -->
            <data-not-found v-else class="mt-10">
              <h1>No Content found.</h1>
            </data-not-found>
          </v-container>
        </v-flex>
      </v-flex>
    </template>
  </v-flex>
</template>

<script>
import InnerBanner from "@/components/sites/common/InnerBanner";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "CommonPages",
  components: {
    InnerBanner,
    DataNotFound,
  },
  props: {
    pageId: Number,
  },
  data() {
    return {
      bannerContent: {
        title: "",
        image: "about-us.jpg",
      },
      loading: false,
      pageInfo: {},
    };
  },
  methods: {
    getPageDetailsById(id) {
      if (!id) return;

      if (this.loading) return;
      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.getPageInfoById.replace(":id", id))
        .then((result) => {
          this.pageInfo = result.data;

          this.$store.commit("update_meta", {
            PageTitle: result.data.PageTitle,
          });

          this.bannerContent.title = result.data.PageTitle;

          if (result.data.PageBannerUrl) {
            this.bannerContent.PageBannerUrl = result.data.PageBannerUrl;
          } else {
            this.bannerContent.PageBannerUrl = null;
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.getPageDetailsById(this.pageId);
  },
  watch: {
    $route() {
      this.getPageDetailsById(this.pageId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/about_us.scss";
</style>
