<template>
  <v-flex
    class="login-register-bg"
    :style="{
      background:
        'url(' + require('@/assets/images/login-bg.png') + ') left top',
    }"
  >
    <v-card
      tile
      elevation="3"
      class="signin-signup-wrap mx-auto"
      :style="{
        background:
          '#fff url(' +
          require('@/assets/images/login-bar.png') +
          ') left top repeat-x',
      }"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        height="3"
        color="light"
        aria-label="loading status"
      ></v-progress-linear>
      <v-card-title class="justify-center">Enter Code</v-card-title>
      <span class="otp-message d-block" v-text="instruction"></span>

      <ValidationObserver
        class="signin-signup-form login-form"
        tag="div"
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            tag="div"
            class="place-bid-field"
            name="otp"
            rules="required"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                solo
                type="text"
                step="any"
                flat
                hide-details
                outlined
                label="OTP"
                prepend-inner-icon="mdi-lock"
                v-model="formData.password"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <v-layout wrap class="remember justify-end">
            <send-otp-btn
              :username.sync="username"
              type="otp"
              icon="mdi-rotate-left"
              class="v-icon notranslate mdi mdi-rotate-left theme--light"
              btn-text="Resend"
            ></send-otp-btn>
          </v-layout>

          <v-btn
            width="100%"
            type="submit"
            depressed
            :loading="loading"
            :disabled="loading"
            class="signin-signup-btn brand white--text text-none d-block"
            >Submit OTP</v-btn
          >
        </form>
      </ValidationObserver>
    </v-card>
  </v-flex>
</template>

<script>
import SendOtpBtn from "@/components/sites/common/SendOtpBtn";

export default {
  name: "Otp",
  components: {
    SendOtpBtn,
  },
  data() {
    return {
      instruction: "",
      formData: {
        password: "",
        use_otp: true,
      },
      username: "",
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    loading: function () {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(() => {
        var authUser = JSON.parse(this.$store.state.auth);
        this.formData.username = authUser.UserMobile;

        this.$store
          .dispatch("login", this.formData)
          .then((res) => {
            var loginRedirect = localStorage.getItem("loginRedirect");
            if (loginRedirect) {
              localStorage.removeItem("loginRedirect");
              this.$router.push(loginRedirect).catch((err) => console.log(err));
            } else {
              this.$router
                .push({ name: "MyAccount" })
                .catch((err) => console.log(err));
            }
            this.$store.commit("set_flash", {
              type: res.data.ResponseType,
              message: res.data.ResponseMessage,
            });
          })
          .catch((err) => {
            // Resetting Values
            this.formData.otp = "";

            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
          });
      });
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push({ name: "Login" });
    }
    let authUser = JSON.parse(this.$store.state.auth);

    this.username = authUser.UserMobile;

    this.instruction =
      "Please check your username (" +
      authUser.UserMobile +
      "), we have sent an OTP for you.";
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/login_register.scss";
</style>
