<template>
  <v-flex
    class="login-register-bg"
    :style="{
      background:
        'url(' + require('@/assets/images/login-bg.png') + ') left top',
    }"
  >
    <v-card
      tile
      elevation="3"
      class="signin-signup-wrap mx-auto"
      :style="{
        background:
          '#fff url(' +
          require('@/assets/images/login-bar.png') +
          ') left top repeat-x',
      }"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        height="3"
        color="light"
        aria-label="loading status"
      ></v-progress-linear>
      <v-flex class="new-password-image d-flex justify-center">
        <v-img
          max-width="200"
          :src="require('@/assets/images/create-password.png')"
        ></v-img>
      </v-flex>

      <v-flex class="forgot-text">
        <h3 v-text="$t('Create new password')"></h3>
        <p
          v-text="
            $t('An OTP is sent to your registered email or mobile number.')
          "
        ></p>
      </v-flex>

      <ValidationObserver
        class="signin-signup-form forgot-form"
        tag="div"
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            tag="div"
            name="username"
            rules="required"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-flex class="input-label">{{ $t("User Name") }}</v-flex>
              <v-text-field
                disabled
                solo
                outlined
                flat
                type="text"
                autocomplete="off"
                hide-details
                label
                v-model="formData.username"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <ValidationProvider
            tag="div"
            name="otp"
            rules="required"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-flex class="input-label">OTP</v-flex>
              <v-text-field
                solo
                outlined
                flat
                type="text"
                autocomplete="off"
                hide-details
                :label="$t('Ex: 1243')"
                v-model="formData.otp"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <!-- password -->
          <ValidationProvider
            tag="div"
            name="password"
            rules="required|min:6"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-flex class="input-label d-flex justify-space-between">
                <span v-text="$t('Password')"></span>

                <small
                  v-text="$t('Passwords must be at least 6 characters')"
                ></small>
              </v-flex>
              <v-text-field
                autocomplete="off"
                solo
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                flat
                hide-details
                outlined
                v-model="formData.password"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <v-btn
            width="100%"
            type="submit"
            depressed
            :disabled="loading"
            :loading="loading"
            class="signin-signup-btn brand white--text text-none d-block mt-md-8"
            >{{ $t("Submit") }}</v-btn
          >

          <v-flex class="account-text grey--text text-center">
            {{ $t("Didn't receive the code?") }}
            <send-otp-btn
              :username.sync="formData.username"
              type="password"
              class="ma-2 text-none v-btn--outlined elevation-0 bg-0"
              :btn-text="$t('Resend')"
            ></send-otp-btn>
          </v-flex>
        </form>
      </ValidationObserver>
    </v-card>
  </v-flex>
</template>

<script>
import SendOtpBtn from "@/components/sites/common/SendOtpBtn";
export default {
  name: "Login",
  components: {
    SendOtpBtn,
  },
  data() {
    return {
      loading: false,
      show1: false,
      show2: false,
      formData: {
        username: this.$route.params.username,
        otp: "",
        password: "",
        confirmpassword: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.loading) return;

      this.$refs.form.validate().then(() => {
        this.loading = true;

        this.formData.confirmpassword = this.formData.password;
        this.$http({
          method: "post",
          url: this.$store.state.Configs.changedPassword,
          data: this.formData,
        })
          .then(() => {
            this.loading = false;

            this.$store
              .dispatch("login", this.formData)
              .then((res) => {
                var loginRedirect = localStorage.getItem("loginRedirect");
                if (loginRedirect) {
                  localStorage.removeItem("loginRedirect");
                  this.$router
                    .push(loginRedirect)
                    .catch((err) => console.log(err));
                } else {
                  this.$router
                    .push({ name: "MyAccount" })
                    .catch((err) => console.log(err));
                }

                this.$store.commit("set_flash", {
                  type: res.data.ResponseType,
                  message: res.data.ResponseMessage,
                });
              })
              .catch((err) => {
                this.$store.commit("set_flash", {
                  type: "error",
                  message: err.response.data,
                });
                this.$vuetify.goTo(0);
              });
          })
          .catch((err) => {
            this.loading = false;

            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
            this.$vuetify.goTo(0);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/login_register.scss";
</style>
