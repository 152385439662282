<template>
  <v-flex class="bank-wrap">
    <h3>How to pay your payment with bank</h3>
    <v-flex class="bank-info-wrap">
      <v-layout class="bank-top align-center justify-space-between flex-wrap">
        <v-flex class="bank-top-box flex-grow-0">
          <h4>Bank Payment</h4>
        </v-flex>

        <v-flex class="bank-top-box flex-grow-0">
          <span>Payable Amount</span>
          <strong v-text="$root.showAmount(orderObj.TotalAmount)"> </strong>
        </v-flex>

        <v-flex class="bank-top-box flex-grow-0">
          <span>Processing Time</span>
          <strong>1-3 days</strong>
        </v-flex>
      </v-layout>

      <v-layout class="bank-bottom align-center flex-wrap">
        <v-flex class="bank-info">
          <ul v-for="(bankDetail, index) in bankDetails" :key="index">
            <li>
              Bank Name:
              <b v-text="bankDetail.BankName"></b>
            </li>
            <li>
              Account Name:
              <b v-text="bankDetail.AccountName"></b>
            </li>
            <li>
              Account No:
              <b v-text="bankDetail.AccountNo"></b>
            </li>
          </ul>
        </v-flex>

        <v-flex class="upload-section">
          <!-- Image Uploader -->
          <multi-image-uploader
            title="Upload Bank/payment Document"
            id="images-uploader-1"
            edit-id="images-uploader-edit-1"
            :options="uploadOptions"
            :data-images="selectedImages"
          ></multi-image-uploader>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-flex>
</template>

<script>
import MultiImageUploader from "@/components/services/MultiImageUploader.vue";
export default {
  name: "BankPayment",
  components: {
    MultiImageUploader,
  },
  data() {
    return {
      selectedImages: [],
      uploadOptions: {
        idUpload: "file",
        multiple: true,
        showPrimary: true,
        showDelete: true,
        referenceType: "Order",
        referenceId: 0,
      },
    };
  },
  props: {
    orderObj: Object,
    documents: Array,
  },
  computed: {
    bankDetails() {
      return this.$store.state.siteSettings.BankDetails;
    },
  },
  created() {
    this.uploadOptions.referenceId = this.orderObj.Id;

    if (this.documents && this.documents.length) {
      this.selectedImages = this.generateUploaderData(this.documents);
    }
  },
  methods: {
    generateUploaderData(Arr) {
      var dataArr = [];
      Arr.forEach((element) => {
        let obj = {
          Id: element.Id,
          default: element.IsCover,
          path: this.$root.generateMediaImageUrl(element.FilePath),
          name: element.Title,
        };
        dataArr.push(obj);
      });
      return dataArr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_order_bank_details.scss";
</style>
