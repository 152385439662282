<template>
  <v-flex class="package-wrap">
    <v-layout wrap class="order-wrap justify-space-between align-center py-2">
      <v-flex class="flex-grow-0 px-4 py-1">
        <span class="order-no d-block"
          >Package Number: {{ packageObj.DisplayId }}</span
        >
        <span
          class="placed-on silver_tree--text d-block"
          v-text="'Seller: ' + sequence"
        ></span>
      </v-flex>

      <v-flex class="flex-grow-0 px-4 py-1">
        <span class="price d-block">
          Package Total:
          <strong>{{ $root.showAmount(packageObj.TotalAmount) }}</strong>
        </span>
      </v-flex>
    </v-layout>

    <!-- Package timeline -->
    <div class="tab-timeline">
      <ul class="d-flex flex-wrap m-0 p-0">
        <li
          v-for="(status, index) in orderProgress"
          :key="index"
          :class="{
            active: getPackageActiveStage(index, packageObj.Status),
          }"
        >
          <span class="bar">
            <i class="tab-icon"></i>
            <div v-text="status" class="tab-info"></div>
          </span>
          <span class="arrow"></span>
        </li>
      </ul>

      <v-flex class="timeline-content" v-if="packageObj.Activities.length">
        <v-flex
          v-for="activity in packageObj.Activities"
          :key="activity.Id"
          class="package-info d-flex justify-space-between"
        >
          <span
            class="package-time"
            v-text="$root.formateServerDateTime(activity.ActivityAt)"
          ></span>
          <span v-text="activity.Activity" class="package-text"></span>
        </v-flex>
      </v-flex>
    </div>

    <!-- Product table -->
    <v-flex class="order-product-box" v-if="packageObj.ItemDetails.length">
      <v-layout
        wrap
        class="product-wrap align-center"
        v-for="item in packageObj.ItemDetails"
        :key="item.Id"
      >
        <v-flex class="column product flex-grow-0">
          <router-link
            class="d-flex flex-wrap align-center"
            target="_blank"
            :to="{
              name: 'ProductDetails',
              params: { id: item.ItemId, slug: item.Slug },
            }"
          >
            <v-img
              contain
              max-width="90"
              :src="$root.generateMediaImageUrl(item.ImageUrl)"
              alt="Image"
            ></v-img>
            <v-flex class="product-box">
              <h3 v-text="item.ItemName"></h3>
              <template v-if="item.Attributes && item.Attributes.length">
                <v-flex class="selected-attIn">
                  <span
                    class="selected-att"
                    v-for="(val, index) in item.Attributes"
                    :key="index"
                  >
                    <span v-text="val.CategoryName + ': '"></span>
                    <strong v-text="val.Name"></strong>
                  </span>
                </v-flex>
              </template>
            </v-flex>
          </router-link>
        </v-flex>
        <v-flex class="column quantity flex-grow-0">
          <span
            v-text="'Qty: ' + item.Quantity + ' ' + item.MeasurementName"
          ></span>
        </v-flex>
        <v-flex class="column price">
          <span v-text="$root.showAmount(item.NetTotal)"></span>
        </v-flex>

        <v-flex
          class="review-modal-btn"
          v-if="packageObj.Status == 'Delivered'"
        >
          <span
            v-if="item.SellerId == authUserId"
            @click="
              (dialog = !dialog), getItemReviewSummary(item, item.Reviews)
            "
            :class="'silver_tree--text'"
            v-text="'Show Review'"
          ></span>

          <span
            v-else
            @click="
              (dialog = !dialog), getItemReviewSummary(item, item.Reviews)
            "
            :class="item.Reviews ? 'silver_tree--text' : 'red--text write'"
            v-text="item.Reviews ? 'Show Review' : 'Write Review'"
          ></span>
        </v-flex>
      </v-layout>
    </v-flex>

    <!-- ratting and revoew -->
    <v-dialog v-model="dialog" width="800">
      <v-card class="modal-carousel">
        <v-carousel v-model="model" hide-delimiters height="auto">
          <template v-slot:prev="{ on, attrs }" v-if="false">
            <span class="prev" v-bind="attrs" v-on="on">
              <v-icon size="20">mdi-arrow-left</v-icon>
            </span>
          </template>

          <template v-slot:next="{ on, attrs }" v-if="false">
            <span class="prev" v-bind="attrs" v-on="on">
              <v-icon size="20">mdi-arrow-right</v-icon>
            </span>
          </template>

          <v-carousel-item>
            <v-flex class="review-wrap">
              <v-layout wrap class="review-product align-center">
                <v-flex class="flex-grow-0 pr-3">
                  <router-link
                    class="d-flex flex-wrap align-center"
                    target="_blank"
                    :to="{
                      name: 'ProductDetails',
                      params: { id: modalItem.ItemId, slug: modalItem.Slug },
                    }"
                  >
                    <v-img
                      contain
                      max-width="90"
                      :src="$root.generateMediaImageUrl(modalItem.ImageUrl)"
                      alt="Image"
                    ></v-img>
                  </router-link>
                </v-flex>

                <h3 v-text="modalItem.ItemName"></h3>
              </v-layout>
              <v-divider></v-divider>

              <v-flex class="review-content">
                <v-flex class="review-area">
                  <v-card outlined tile class="px-3">
                    <ValidationObserver
                      tag="div"
                      :ref="`form-${sequence}`"
                      v-slot="{ handleSubmit }"
                    >
                      <form
                        @submit.prevent="handleSubmit(onSubmit())"
                        enctype="multipart/form-data"
                      >
                        <v-layout
                          wrap
                          class="pt-3 justify-space-between align-center"
                        >
                          <span class="writing-info pt-1 d-block"
                            >Please write your review</span
                          >

                          <span class="writing-info pt-1 d-block">
                            How to write a good review
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span class="tooltip-info"></span>
                            </v-tooltip>
                          </span>
                        </v-layout>
                        <v-rating
                          :disabled="isOwner"
                          class="py-3"
                          dense
                          v-model="formData.Rating"
                          background-color="orange lighten-3"
                          color="brand"
                          half-increments
                          hover
                          size="40"
                        ></v-rating>

                        <!-- Review Area -->
                        <v-sheet class="review-box">
                          <ValidationProvider
                            tag="div"
                            name="Commnet"
                            :bails="false"
                            rules="max:512"
                            v-slot="{ errors, classes }"
                          >
                            <v-flex class="textarea-wrap" :class="classes">
                              <v-textarea
                                :disabled="isOwner"
                                hide-details
                                flat
                                label="Please write your honest opinion and give a rating...."
                                v-model="formData.Comment"
                              ></v-textarea>
                              <span>{{ errors[0] }}</span>
                            </v-flex>
                          </ValidationProvider>

                          <v-flex class="upload-section">
                            <!-- Document Image Uploader -->
                            <multi-image-uploader
                              v-if="!isOwner"
                              title="Upload Image"
                              :id="'review-images-uploader-' + sequence"
                              :edit-id="
                                'review-images-uploader-edit-' + sequence
                              "
                              :options="reviewImageUploadOptions"
                              :data-images="selectedReviewImages"
                            ></multi-image-uploader>

                            <div
                              v-if="isOwner && selectedReviewImages.length"
                              class="d-flex flex-wrap light-gallery pt-2"
                              id="lightgallery"
                            >
                              <a
                                :href="element.path"
                                class="document-box pr-2"
                                v-for="element in selectedReviewImages"
                                :key="element.Id"
                              >
                                <img
                                  :src="element.path"
                                  alt="Review Image"
                                  width="100"
                                />
                                <!-- <v-icon size="16" color="green"
                                  >mdi-check-decagram</v-icon
                                >-->
                              </a>
                            </div>
                          </v-flex>

                          <v-layout wrap class="pt-2 justify-end">
                            <v-btn
                              width="100"
                              @click="dialog = !dialog"
                              tile
                              depressed
                              height="40"
                              class="red white--text text-none mr-2"
                              >Close</v-btn
                            >

                            <v-btn
                              v-if="!isOwner"
                              type="submit"
                              :disabled="isDisabled"
                              tile
                              depressed
                              height="40"
                              width="100"
                              class="primary white--text text-none"
                              >Submit</v-btn
                            >
                          </v-layout>
                        </v-sheet>
                      </form>
                    </ValidationObserver>
                  </v-card>
                </v-flex>
              </v-flex>
            </v-flex>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import MultiImageUploader from "@/components/services/MultiImageUploader.vue";
import lightGallery from "lightgallery";
export default {
  name: "OrderPackage",
  components: {
    MultiImageUploader,
  },
  data() {
    return {
      model: "asdf",
      formData: {
        ItemId: 0,
        Rating: 0,
        Comment: "",
      },
      dialog: false,
      modalItem: {},
      isDisabled: true,
      isOwner: false,
      itemReviewSubmitUrl: this.$store.state.Configs.itemReviewSubmit,
      getIemReviewUrl: this.$store.state.Configs.getIemReview,
      reviewImageUploadOptions: {
        idUpload: "file",
        multiple: true,
        showPrimary: true,
        showDelete: true,
        referenceType: "Review",
        referenceId: 0,
        OtherReferenceId: 0,
        showEdit: false,
      },
      selectedReviewImages: [],
    };
  },
  props: {
    packageObj: Object,
    orderId: Number,
    sequence: Number,
  },
  mounted() {},
  computed: {
    orderProgress() {
      return this.$store.state.Configs.orderProgress;
    },
    authUserId() {
      return this.$auth?.user?.UserId;
    },
  },
  methods: {
    getItemReviewSummary(item, itemReviews) {
      if (item.SellerId == this.authUserId) {
        this.isOwner = true;
        setTimeout(() => {
          this.setLightGalleryObj();
        }, 1000);
      } else {
        this.isOwner = false;
      }
      if (itemReviews) {
        this.$http
          .post(this.getIemReviewUrl.replace(":id", itemReviews.Id))
          .then((res) => {
            let reviewObj = res.data;
            // set form data
            this.formData.ItemId = reviewObj.ItemId;
            this.formData.OrderId = reviewObj.OrderId;
            this.formData.Rating = reviewObj.Rating;
            this.formData.Comment = reviewObj.Comment;

            // set review image options
            this.reviewImageUploadOptions.referenceId = reviewObj.OrderId;
            this.reviewImageUploadOptions.OtherReferenceId = reviewObj.ItemId;

            // set modal item
            this.modalItem = item;

            // set review uploaded images
            if (reviewObj.Images && reviewObj.Images.length) {
              this.selectedReviewImages = this.generateUploaderData(
                reviewObj.Images
              );
            } else {
              this.selectedReviewImages = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // set form data
        this.formData.ItemId = item.ItemId;
        this.formData.OrderId = this.orderId;
        this.formData.Rating = 0;
        this.formData.Comment = "";

        // set review image options
        this.reviewImageUploadOptions.referenceId = this.orderId;
        this.reviewImageUploadOptions.OtherReferenceId = item.ItemId;

        this.selectedReviewImages = [];

        // set modal item
        this.modalItem = item;
      }
    },
    setLightGalleryObj() {
      lightGallery(document.getElementById("lightgallery"), {
        thumbnail: true,
        animateThumb: true,
        counter: true,
        download: false,
        showThumbByDefault: false,
        slideEndAnimatoin: true,
        escKey: true,
        enableTouch: true,
      });
    },
    generateUploaderData(Arr) {
      var dataArr = [];
      Arr.forEach((element) => {
        let obj = {
          Id: element.Id,
          default: element.IsCover,
          path: encodeURI(this.$root.generateMediaImageUrl(element.FilePath)),
          name: element.Title,
        };
        dataArr.push(obj);
      });
      return dataArr;
    },
    submitReview() {
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.itemReviewSubmitUrl, this.formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      let check = this.$root.checkAuth();
      if (check) return;

      this.$refs["form-" + this.sequence].validate().then(() => {
        this.submitReview()
          .then((res) => {
            this.$nextTick(() => {
              this.dialog = false;

              //for update button text and Item review
              var itemIndex = this.packageObj.ItemDetails.findIndex(
                (obj) => obj.ItemId === this.formData.ItemId
              );
              this.packageObj.ItemDetails[itemIndex].Reviews =
                res.data.ResponseData;
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
              this.$vuetify.goTo(0);
            });
          })
          .catch(() => {
            this.$nextTick((err) => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
          });
      });
    },
    getPackageActiveStage(stageIndex, packageStatus) {
      if (stageIndex <= this.orderProgress.indexOf(packageStatus)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "formData.Rating": {
      deep: true,
      handler(newVal) {
        if (newVal > 0) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~lightgallery/css/lightgallery.css";
@import "@/sass/statusbar.scss";
@import "@/sass/my_order_package.scss";
@import "@/sass/rating.scss";
</style>
