<template>
  <v-flex
    :style="{
      background: `url(${require('@/assets/images/login-bg.png')}) left top`,
    }"
    class="login-register-bg"
  >
    <h3 v-text="$t('Welcome To Nilam')"></h3>
    <v-card
      tile
      elevation="3"
      class="signin-signup-wrap mx-auto position-relative"
      :style="{
        background:
          '#fff url(' +
          require('@/assets/images/login-bar.png') +
          ') left top repeat-x',
      }"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        height="4"
        color="light"
        aria-label="loading status"
      ></v-progress-linear>
      <v-card-title
        class="justify-center"
        v-text="$t('Sign Up')"
      ></v-card-title>
      <ValidationObserver
        class="signin-signup-form signup-form"
        tag="div"
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(onSubmit)">
          <v-row>
            <ValidationProvider
              tag="div"
              class="col-md-6 py-0"
              name="first name"
              rules="required"
              :bails="false"
              v-slot="{ errors, classes }"
            >
              <div class="input-wrap" :class="classes">
                <v-text-field
                  solo
                  type="text"
                  flat
                  prepend-inner-icon="mdi-account"
                  hide-details
                  outlined
                  :label="$t('First Name')"
                  v-model="formData.first_name"
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <ValidationProvider
              tag="div"
              class="col-md-6 py-0"
              name="last name"
              rules="required"
              :bails="false"
              v-slot="{ errors, classes }"
            >
              <div class="input-wrap" :class="classes">
                <v-text-field
                  solo
                  type="text"
                  flat
                  hide-details
                  outlined
                  :label="$t('Last Name')"
                  v-model="formData.last_name"
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </v-row>

          <ValidationProvider
            tag="div"
            class="phone"
            name="phone"
            :rules="{
              required: true,
              max: 14,
              regex: /^(?:\+88|88)?(0?1[3-9]\d{8})$/,
            }"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                solo
                type="tel"
                flat
                hide-details
                outlined
                v-model="formData.phone"
                id="phone"
                v-on:blur="numberBlur"
              ></v-text-field>
              <small class="use-as d-flex align-center silver_tree--text">
                <v-icon size="14" class="ma-0 mr-1 silver_tree--text"
                  >mdi-information-outline</v-icon
                >
                {{ $t("Used as username") }}
              </small>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <!-- E-Mail -->
          <ValidationProvider
            tag="div"
            name="e-mail"
            rules="email"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                solo
                type="email"
                flat
                hide-details
                outlined
                :label="$t('Email (optional)')"
                v-model="formData.email"
                prepend-inner-icon="mdi-email"
                autocomplete="off"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <!-- password -->
          <ValidationProvider
            tag="div"
            name="password"
            rules="required|min:6"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                ref="password"
                solo
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                flat
                hide-details
                outlined
                :label="$t('Password')"
                v-model="formData.password"
                prepend-inner-icon="mdi-lock"
                autocomplete="off"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <!-- confirm password -->
          <ValidationProvider
            tag="div"
            name="password confirmation"
            rules="required|confirmed:password"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div class="input-wrap" :class="classes">
              <v-text-field
                solo
                :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPass ? 'text' : 'password'"
                @click:append="showConfirmPass = !showConfirmPass"
                flat
                hide-details
                outlined
                :label="$t('Confirm Password')"
                v-model="formData.confirm_password"
                prepend-inner-icon="mdi-lock"
                autocomplete="off"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <!-- Agree -->
          <ValidationProvider
            v-if="
              siteSettings &&
              Object.keys(siteSettings).length &&
              siteSettings.TermsAndConditionURL
            "
            class="agree"
            tag="div"
            :bails="false"
            v-slot="{ errors, classes }"
          >
            <div :class="classes">
              <v-checkbox v-model="formData.agree">
                <template v-slot:label>
                  <div>
                    {{ $t("I agree with") }}
                    <a
                      target="_blank"
                      :href="siteSettings.TermsAndConditionURL"
                      @click.stop
                      >{{ $t("Terms And Conditions") }}</a
                    >
                    {{ $t("And") }}
                    <a
                      target="_blank"
                      :href="siteSettings.PrivacyPolicyURL"
                      @click.stop
                      >{{ $t("Privacy Policy") }}</a
                    >
                  </div>
                </template>
              </v-checkbox>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <v-btn
            width="100%"
            type="submit"
            depressed
            class="signin-signup-btn brand white--text text-none d-block"
            :loading="loading"
            :disabled="!formData.agree || loading"
            >{{ $t("Sign Up") }}</v-btn
          >
        </form>
      </ValidationObserver>
      <v-flex class="account-text grey--text text-center">
        {{ $t("Already have an account?") }}
        <router-link
          :to="{ name: 'Login' }"
          v-text="$t('Sign In')"
        ></router-link>
      </v-flex>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: "Register",

  data() {
    return {
      showPass: false,
      showConfirmPass: false,
      formData: {
        agree: true,
      },
      intelTell: null,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    loading: function () {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(() => {
        let data = {
          FirstName: this.formData.first_name,
          LastName: this.formData.last_name,
          Email: this.formData.email || null,
          CountryCode: this.formData.dial_code,
          MobileNumber: this.formData.phone,
          Password: this.formData.password,
          ConfirmPassword: this.formData.confirm_password,
        };

        this.$store
          .dispatch("register", data)
          .then(() => {
            this.$store
              .dispatch("login", {
                username: data.MobileNumber,
                password: data.Password,
              })
              .then(() => {
                this.$router.push({ name: "Otp" });
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            this.$store.commit("set_flash", {
              type: "error",
              message: err.response.data,
            });
            this.$vuetify.goTo(0);
          });
      });
    },
    numberBlur() {
      if (this.intelTell.isValidNumber(this.intelTell.telInput.value)) {
        this.formData.dial_code =
          this.intelTell.getSelectedCountryData().dialCode;
      } else {
        this.formData.phone = "";
      }
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      let resolved = this.$router.resolve({
        name: "register",
      });
      //changed filter object value to url as query without triggering route change event
      window.history.replaceState("NilamPushState", "register", resolved.href);
      this.$router.push({ name: "MyAccount" });
    }

    const input = document.querySelector("#phone");
    if (input) {
      this.intelTell = this.intlTelInput(input, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.0/js/utils.js",
        initialCountry: "bd",
        separateDialCode: true,
        onlyCountries: ["bd"],
        hiddenInput: "FormatedMobile",
        customPlaceholder: function (selectedCountryPlaceholder) {
          return "e.g. " + selectedCountryPlaceholder;
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/login_register.scss";
</style>
