<template>
  <v-flex class="d-flex align-center justify-center">
    <v-skeleton-loader
      class="mt-8"
      width="420"
      type="image"
      v-if="loading"
    ></v-skeleton-loader>
    <v-container v-else class="d-flex align-center justify-center">
      <v-card
        v-if="paymentObj.Status == 'Confirmed'"
        elevation="5"
        class="success-fail text-center"
      >
        <v-flex class="d-flex justify-center">
          <v-icon size="90" color="green">mdi-check-decagram</v-icon>
        </v-flex>
        <h3>Your payment was successful</h3>
        <v-flex class="payment-info">
          <ul>
            <li class="d-flex justify-space-between">
              <span class="payment-title">Payment Mode :</span>
              <span class="payment-info" v-text="paymentObj.PaymentMode"></span>
            </li>

            <li class="d-flex justify-space-between">
              <span class="payment-title">Transaction Id :</span>
              <span
                class="payment-info"
                v-text="paymentObj.TransactionId"
              ></span>
            </li>
            <li class="d-flex justify-space-between amount">
              <span class="payment-title">Amount :</span>
              <span
                class="payment-info brand--text"
                v-text="paymentObj.Amount + ' ' + paymentObj.Currency"
              ></span>
            </li>
          </ul>
          <v-flex class="pt-6 text-right">
            <v-btn
              v-if="paymentObj.ReferenceType == 'Order'"
              tile
              depressed
              color="brand"
              class="white--text"
              :to="{
                name: 'MyOrderDetails',
                params: { id: paymentObj.ReferenceId },
              }"
              >Order details</v-btn
            >
            <v-btn
              v-else
              tile
              depressed
              color="brand"
              class="white--text"
              :to="{ name: 'PostingSummary' }"
              >Balance Summary</v-btn
            >
          </v-flex>
        </v-flex>
      </v-card>

      <v-card v-else elevation="5" class="success-fail text-center">
        <v-flex class="d-flex justify-center">
          <v-icon size="90" color="deep-orange">mdi-progress-close</v-icon>
        </v-flex>
        <h3
          class="red--text"
          v-if="paymentObj && paymentObj.GatewayMessage?.length"
          v-text="paymentObj.GatewayMessage"
        ></h3>
        <h3 v-else>Your payment was fail !</h3>
        <v-flex class="payment-info">
          <v-flex class="pt-6 text-right">
            <v-btn
              tile
              depressed
              color="brand"
              class="white--text"
              :to="{ name: 'Home' }"
              >Continue Shopping</v-btn
            >
          </v-flex>
        </v-flex>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
export default {
  name: "PaymentSuccesOrFail",
  data() {
    return {
      loading: false,
      paymentObj: {},
    };
  },
  created() {
    this.getMyPaymentInfo();
  },
  methods: {
    getMyPaymentInfo() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      this.$http
        .post(
          this.$store.state.Configs.getMyPaymentInfo.replace(
            ":id",
            this.$route.query.id
          )
        )
        .then((result) => {
          this.paymentObj = result.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/payment_success_fail.scss";
</style>
