<template>
  <v-flex v-if="Object.keys(profileObj).length">
    <v-flex v-if="$root.windowSize >= 1264" class="account-sidebar flex-grow-0">
      <v-sheet tile class="user-box mb-5 d-flex align-center">
        <v-avatar size="75">
          <router-link :to="{ name: 'MyAccount' }">
            <v-img
              width="75"
              height="75"
              contain
              :src="$root.generateMediaImageUrl(profileObj.ProfileImageUrl)"
              alt="profile image"
            ></v-img>
          </router-link>
        </v-avatar>
        <v-sheet class="user-title pl-4">
          <router-link :to="{ name: 'MyAccount' }">
            <span>Hello,</span>

            <h4 v-text="profileObj.FullName"></h4>
          </router-link>
          <v-icon size="18" color="gray darken-2">mdi-phone-in-talk</v-icon>
          <span class="profile-info" v-text="profileObj.Mobile"></span>
          <img
            v-if="isSeller"
            alt="Verified Seller"
            class="verified"
            :src="require('@/assets/images/verified-seller.png')"
          />
        </v-sheet>
        <v-avatar class="lighten-4 notification" size="30" color="grey">
          <v-icon size="18" color="brand">mdi-bell</v-icon>
          <v-avatar
            size="18"
            color="silver_tree"
            class="notification-count"
            v-text="profileObj.UnReadNotificationCount"
          ></v-avatar>
        </v-avatar>
      </v-sheet>
      <v-card tile elevation="0">
        <v-list class="py-0" flat dense>
          <v-list-item-group color="brand" v-model="selectedItem">
            <v-divider></v-divider>
            <menu-render
              group="common"
              :menu-arr="profileMenus.common"
            ></menu-render>

            <template v-if="isSeller">
              <h5
                class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                v-text="profileMenus.seller.title"
              ></h5>
              <v-divider></v-divider>
              <menu-render
                group="seller"
                :menu-arr="profileMenus.seller.menuArr"
              ></menu-render>
              <h5
                class="separator pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                v-text="profileMenus.customer.title"
              ></h5>
              <v-divider></v-divider>
              <menu-render
                group="customer"
                :menu-arr="profileMenus.customer.menuArr"
              ></menu-render>
            </template>
            <template v-else>
              <h5
                class="pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                v-text="profileMenus.customer.title"
              ></h5>
              <v-divider></v-divider>
              <menu-render
                group="customer"
                :menu-arr="profileMenus.customer.menuArr"
              ></menu-render>

              <h5
                class="separator pt-5 pb-2 px-4 text-uppercase grey--text darken-1 font-weight-regular"
                v-text="profileMenus.seller.title"
              ></h5>
              <v-divider></v-divider>
              <menu-render
                group="seller"
                :menu-arr="profileMenus.seller.menuArr"
              ></menu-render>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-flex>
  </v-flex>
</template>

<script>
import MenuRender from "@/components/sites/common/MenuRender.vue";
export default {
  name: "AccountSidebar",
  components: {
    MenuRender,
  },
  data() {
    return {
      selectedItem: 0,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    profileObj() {
      return this.$store.state.profileObj;
    },
    profileMenus() {
      if (this.siteSettings.IsECommerce) {
        return this._.filter(this.$store.state.Configs.profileMenus, {
          isEcommerce: this.siteSettings.IsECommerce,
        });
      } else {
        return this.$store.state.Configs.profileMenus;
      }
    },
    isSeller() {
      if (this.$auth && this.$auth.user) {
        return this.$auth.user.IsVerified;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
@import "@/sass/app/index.scss";

.account-sidebar {
  width: 375px;

  h5 {
    font-size: 15px;
    &.separator {
      border-top: 20px solid #f1f3f6;
    }
  }

  .user-box {
    padding: rem-calc(18px 17px);
    position: relative;
    border: 1px solid map-get($colors, brand);
    .v-avatar {
      border: 1px solid lighten(map-get($colors, grey), 25);
      a {
        width: 100%;
        height: 100%;
        display: block;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    span {
      @include font(primary, 15px, 22px, false);
    }
    .verified {
      display: block;
    }
    h4 {
      @include font(false, 16px, 22px, semi_bold);
      @include truncate-clamp(1);
    }
    .notification {
      overflow: visible;
      @include absolute(false, false, right, 10px, top, 10px);
      .notification-count {
        @include font(light, 11px, 16px, regular);
        @include absolute(false, false, right, -5px, top, -5px);
      }
    }
  }
  .v-list-item__title {
    font-size: 15px !important;
  }
}

.drawer-toggle {
  left: 255px;
  top: 24px;
  z-index: 9;
  position: fixed;
  background: #fff;
}

@include media(1639px) {
  .account-sidebar {
    width: 275px;
  }
}

@include media(lg) {
  .account-sidebar {
    width: 300px;
    .user-box {
      padding: rem-calc(30px 15px 10px);
      border: none;
    }
  }
}
</style>
