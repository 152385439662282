<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <v-layout wrap>
        <!-- account-sidebar -->
        <account-sidebar></account-sidebar>

        <v-flex class="account-wrap">
          <v-card tile elevation="0">
            <v-flex class="account-panel">
              <v-skeleton-loader
                class="mx-auto pl-5 pr-5"
                type="article,article,article"
                v-if="loading"
              ></v-skeleton-loader>

              <template v-else>
                <template v-if="Object.keys(balanceSummary).length">
                  <v-card tile elevation="0">
                    <v-flex class="account-panel">
                      <v-flex class="summery">
                        <h2 v-text="$t('Your Balance Summary')"></h2>
                      </v-flex>

                      <v-layout class="allowance">
                        <v-flex class="allowance-left flex-grow-0">
                          <v-flex class="mb-10">
                            <h3>
                              {{$t('Your total available amount')}}:
                              <span
                                v-text="balanceSummary.AvailableAmount"
                              >0</span>
                            </h3>

                            <v-layout class="allowance-info justify-space-between">
                              <span class="allowance-text" v-text="$t('Total Amount')"></span>
                              <span class="allowance-value" v-text="balanceSummary.TotalAmount"></span>
                            </v-layout>

                            <v-layout class="allowance-info justify-space-between">
                              <span class="allowance-text" v-text="$t('Paid Amount')"></span>
                              <a
                                href="javascript:;"
                                class="allowance-value brand--text t"
                                v-text="balanceSummary.PaidAmount"
                              ></a>
                            </v-layout>

                            <v-layout class="allowance-info justify-space-between">
                              <span class="allowance-text" v-text="$t('Avaliable Amount')"></span>
                              <span class="allowance-value" v-text="balanceSummary.AvailableAmount"></span>
                            </v-layout>

                            <v-layout class="allowance-info justify-space-between">
                              <a
                                href="javascript:;"
                                class="allowance-value brand--text"
                                v-text="$t('View balance history')"
                              ></a>
                            </v-layout>
                          </v-flex>
                          <v-flex>
                            <h3>
                              {{$t('Your total available point')}}:
                              <span
                                v-text="balanceSummary.AvailablePoint"
                              ></span>
                            </h3>

                            <v-layout class="allowance-info justify-space-between">
                              <span class="allowance-text" v-text="$t('Total Points')"></span>
                              <span class="allowance-value" v-text="balanceSummary.TotalPoint"></span>
                            </v-layout>

                            <v-layout class="allowance-info justify-space-between">
                              <span class="allowance-text" v-text="$t('Avaliable Points')"></span>
                              <span class="allowance-value" v-text="balanceSummary.AvailablePoint"></span>
                            </v-layout>

                            <v-layout class="allowance-info justify-space-between">
                              <span class="allowance-text" v-text="$t('Used Points')"></span>
                              <a
                                href="javascript:;"
                                class="allowance-value brand--text t"
                                v-text="balanceSummary.UsedPoint"
                              ></a>
                            </v-layout>
                            <v-layout
                              class="allowance-info justify-space-between"
                              v-if="
                                applicationPolicy.RequiredPointOnPlaceBidding >
                                0
                              "
                            >
                              <span class="allowance-text">Required point for placing a bid.</span>
                              <span
                                class="allowance-value"
                                v-text="
                                  applicationPolicy.RequiredPointOnPlaceBidding
                                "
                              >0</span>
                            </v-layout>

                            <v-layout
                              class="allowance-info justify-space-between"
                              v-if="
                                applicationPolicy.RequiredPointOnPostProduct > 0
                              "
                            >
                              <span class="allowance-text">Required point to create a post:</span>
                              <span
                                class="allowance-value"
                                v-text="
                                  applicationPolicy.RequiredPointOnPostProduct
                                "
                              >0</span>
                            </v-layout>

                            <v-layout class="allowance-info justify-space-between">
                              <a
                                href="javascript:;"
                                class="allowance-value brand--text"
                                v-text="$t('View point history')"
                              ></a>
                            </v-layout>
                          </v-flex>

                          <v-flex class="points-container" v-if="siteSettings.IsUsePoint">
                            <h3 v-text="$t('Point packages')"></h3>
                            <p
                              v-text="$t('Please select your option and continue with (buy point button)')"
                            ></p>
                            <v-flex class="points-wrap">
                              <v-radio-group v-model="radios" class="p-0 mt-0" hide-details>
                                <v-layout wrap class="justify-space-between">
                                  <v-radio
                                    v-for="(point, index) in point_items"
                                    :key="index"
                                    :value="point"
                                    class="point-box"
                                  >
                                    <template v-slot:label>
                                      <v-flex>
                                        <span class="point-quantity">
                                          <b v-text="point"></b>
                                          {{$t('Point for')}}
                                        </span>
                                        <span
                                          class="quantity-price"
                                          v-text="
                                            $root.showAmount(
                                              point *
                                                applicationPolicy.ConversionRate
                                            )
                                          "
                                        ></span>
                                      </v-flex>
                                    </template>
                                  </v-radio>
                                  <v-radio :value="customPoint" class="point-box">
                                    <template v-slot:label>
                                      <v-flex>
                                        <v-layout
                                          class="custom-quantity align-center justify-center"
                                        >
                                          <v-flex class="point-input flex-grow-0">
                                            <v-text-field
                                              placeholder="Ex:60"
                                              type="number"
                                              step="1"
                                              outlined
                                              hide-details
                                              height="30"
                                              @change="pointChange"
                                              v-model.number="customPoint"
                                            ></v-text-field>
                                          </v-flex>
                                          <v-flex class="flex-grow-0" v-text="$t('Point for')"></v-flex>
                                        </v-layout>
                                        <span
                                          class="quantity-price"
                                          v-text="
                                            $root.showAmount(
                                              customPoint *
                                                applicationPolicy.ConversionRate
                                            )
                                          "
                                        ></span>
                                      </v-flex>
                                    </template>
                                  </v-radio>
                                </v-layout>
                              </v-radio-group>
                            </v-flex>
                            <v-flex class="membership">
                              <v-btn
                                @click="buyPoints()"
                                tile
                                depressed
                                class="brand white--text text-none"
                              >{{$t('Buy Point')}}</v-btn>
                            </v-flex>
                            <p
                              v-text="$t('If your free point has finished, you can buy points as your demand. To buy point please select your amount and click on Buy Point button.')"
                            ></p>
                          </v-flex>
                        </v-flex>

                        <v-flex class="allowance-right flex-grow-0 d-none d-md-flex justify-end">
                          <v-img
                            max-width="225"
                            max-height="233"
                            :src="require('@/assets/images/allowance.png')"
                            alt="Image"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-card>
                </template>
              </template>
            </v-flex>
          </v-card>

          <v-card tile elevation="0" class="mt-7" v-if="!siteSettings.IsECommerce">
            <v-flex class="account-panel">
              <v-row>
                <v-col cols="12" lg="9">
                  <v-flex class="rules">
                    <h3 v-text="$t('All ads posted on Nilam.com must follow our rules:')"></h3>
                    <ul>
                      <li v-for="(rule, index) in rules" :key="index">
                        <v-icon size="10" class="mr-1">mdi-record</v-icon>
                        <span v-text="rule.title"></span>
                      </li>
                    </ul>
                  </v-flex>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-img
                    max-width="297"
                    max-height="258"
                    :src="require('@/assets/images/rules.png')"
                    alt="Image"
                  ></v-img>
                </v-col>
              </v-row>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";

export default {
  name: "PostingSummary",
  components: {
    AccountSidebar,
  },
  data() {
    return {
      loading: false,
      radios: 20,
      customPoint: 20,
      balanceSummary: {},
      point_items: [20, 50, 100, 150, 200],
      rules: [
        { title: "Make sure you post in the correct category." },
        {
          title:
            "Do not post the same ad more than once or repost an ad within 10 days.",
        },
        {
          title:
            "Do not put your phone or email numbers in the title & description.",
        },
        {
          title:
            "Do not to post promotions containing different things except if it's a bundle bargain.",
        },
        { title: "Do not upload pictures with watermarks." },
      ],
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
    applicationPolicy() {
      return this.siteSettings.ApplicationPolicy;
    },
  },
  methods: {
    pointChange() {
      if (
        !this.customPoint ||
        isNaN(this.customPoint) ||
        this.customPoint < 20
      ) {
        this.customPoint = 20;
        this.radios = 20;
      } else {
        this.customPoint = parseInt(this.customPoint);
        this.radios = this.customPoint;
      }
    },
    getBalanceSummary() {
      if (this.loading) return;
      this.loading = true;
      this.$http
        .post(this.$store.state.Configs.getUserBalance)
        .then((result) => {
          this.balanceSummary = result.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    async buyPoints() {
      const confirm = await this.$confirm("Are you sure?");
      if (!confirm) return;

      let params = {
        ReferanceType: "Recharge",
        ReferanceId: this.$auth?.user?.UserId,
        Amount: this.radios * this.applicationPolicy.ConversionRate,
      };
      this.$http
        .post(this.$store.state.Configs.getPaymentGatewayURL, params)
        .then((res) => {
          window.location.href = res.data;
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getBalanceSummary();
  },
};
</script>

<style lang="scss">
@import "@/sass/posting_summary.scss";
</style>
