<template>
  <common-pages v-if="pageId" :page-id="pageId"></common-pages>
</template>

<script>
import CommonPages from "@/components/sites/common/CommonPages";

export default {
  name: "Pages",
  components: {
    CommonPages,
  },
  data() {
    return {
      pageId: 0,
    };
  },
  created() {
    this.pageId = Number(this.$route.params.id);
  },
  watch: {
    $route(newVal) {
      this.pageId = Number(newVal.params.id);
    },
  },
};
</script>
