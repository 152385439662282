<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <v-card tile>
        <v-sheet class="payment-list-wrap">
          <v-row>
            <v-col lg="8" cols="12" class="py-0">
              <v-flex class="payment-top">
                <h3>Payment Method</h3>
                <span>(Please select at least one payment method)</span>
              </v-flex>

              <v-container v-if="gatewayLoading">
                <v-row>
                  <v-col md="6" cols="12" class="py-0">
                    <v-skeleton-loader
                      class="mx-auto"
                      type="image"
                      height="100"
                    ></v-skeleton-loader
                  ></v-col>
                  <v-col md="6" cols="12" class="py-0">
                    <v-skeleton-loader
                      class="mx-auto"
                      type="image"
                      height="100"
                    ></v-skeleton-loader
                  ></v-col>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-flex
                  v-if="!paymentItems.length"
                  class="danger--text text-center py-5"
                >
                  No Gateway Found !! Please try again.
                </v-flex>
                <v-radio-group
                  v-else
                  class="payment-list pt-0 mt-0"
                  v-model="PaymentMode"
                >
                  <v-row>
                    <!-- <h4>
                    Currently Not Available
                    <small class="font-weight-regular">(Active Soon)</small>
                  </h4> -->
                    <v-col
                      v-for="paymentItem in paymentItems"
                      :key="paymentItem.Code"
                      md="6"
                      cols="12"
                      class="py-0"
                    >
                      <v-radio :value="paymentItem.Code">
                        <template v-slot:label>
                          <div class="payment-title d-flex flex-wrap">
                            <span
                              class="d-flex align-center"
                              v-text="paymentItem.Name"
                            ></span>
                            <v-tooltip
                              max-width="420"
                              color="success"
                              top
                              v-if="paymentItem.Description"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon color="grey lighten-1">
                                    mdi-information
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="paymentItem.Description"></span>
                            </v-tooltip>
                            <img
                              v-if="paymentItem.ImageUrl"
                              height="38"
                              :src="
                                $root.generateMediaImageUrl(
                                  paymentItem.ImageUrl
                                )
                              "
                              alt
                            />
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-container>

              <div class="input-wrap order-note">
                <v-flex class="input-label">
                  Order notes
                  <small>(optional)</small>
                </v-flex>
                <v-textarea
                  rows="3"
                  solo
                  flat
                  hide-details
                  outlined
                  v-model="OrderNote"
                  label="Notes about your order, e.g. special notes for delivery."
                ></v-textarea>
                <!-- <span>{{ errors[0] }}</span> -->
              </div>
            </v-col>

            <v-col lg="4" cols="12" class="py-0">
              <!-- order-summay information-->
              <order-summay
                :login="isLoggedIn"
                page="payment"
                :payment-mode.sync="PaymentMode"
                :order-note.sync="OrderNote"
                :gateway-list="paymentItems"
              ></order-summay>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card>
    </v-container>

    <update-phone-or-email
      ref="updateEmail"
      only-dialog
      type="Email"
    ></update-phone-or-email>
  </v-flex>
</template>

<script>
import OrderSummay from "@/components/sites/common/OrderSummay";
import UpdatePhoneOrEmail from "@/components/sites/common/UpdatePhoneOrEmail.vue";
export default {
  name: "payment",
  components: {
    OrderSummay,
    UpdatePhoneOrEmail,
  },
  data() {
    return {
      cartType: "BuyNow",
      PaymentMode: "CashOnDelivery",
      availEmi: false,
      gatewayLoading: false,
      paymentItems: [],
      OrderNote: "",
      gatewayType: "CommonGateway",
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    carts() {
      return this.$store.state.carts;
    },
    profileObj() {
      return this.$store.state.profileObj;
    },
  },
  created() {
    this.$store.dispatch("getExistingCarts").then(() => {
      this.checkEmpty();
      this.menupulateForGatewayAndEmiRequest();
    });
  },
  methods: {
    getGatewayLists() {
      this.gatewayLoading = true;

      this.$http
        .post(this.$store.state.Configs.getAllPaymentGateway, {
          Type: this.gatewayType,
        })
        .then((resp) => {
          this.paymentItems = resp.data;
          this.gatewayLoading = false;
        })
        .catch((err) => {
          this.paymentItems = [];
          console.log(err);
        });
    },
    menupulateForGatewayAndEmiRequest() {
      if (this.cartType == "BuyNow") {
        this.availEmi = this.carts.BuyNow[0].AvailEmi;

        if (this.availEmi) {
          this.PaymentMode = "Online";
          this.gatewayType = "EmiGateway";
        }
      }
      this.getGatewayLists();
    },
    checkEmpty() {
      let CartTypeName = this.$route.params.cartType;
      this.cartType =
        this.$root.capitalizeFirstLetter(
          this._.camelCase(this.$route.params.cartType)
        ) || "BuyNow";

      //if cart not set or no cart type
      if (!this.carts || !this.cartType) {
        this.$router.push({
          name: "Checkout",
          params: { cartType: CartTypeName },
        });
        return;
      }

      //if cart is empty
      if (!this.carts[this.cartType].length) {
        this.$router.push({
          name: "Checkout",
          params: { cartType: CartTypeName },
        });
        return;
      }

      //if no item is selected on carts for addToCart items
      if (
        this.cartType != "BuyNow" &&
        !this.carts.Ecommerce.some((cart) => cart.IsSelected == true)
      ) {
        this.$store.commit("set_flash", {
          type: "error",
          message: "Please select at least one item.",
        });
        this.$router.push({
          name: "Checkout",
          params: { cartType: CartTypeName },
        });
        return;
      }
    },
  },
  mounted() {
    this.Fire.$on("openUpdateEmailWindow", (val) => {
      if (val) {
        this.$refs.updateEmail.verifyNow();
      }
    });
  },
  beforeDestroy() {
    this.Fire.$off("openUpdateEmailWindow");
  },
  watch: {
    $route() {
      this.checkEmpty();
    },
    PaymentMode(nv) {
      if (nv == "Online" && !this.profileObj.Email) {
        this.$refs.updateEmail.verifyNow();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/payment.scss";
</style>
