<template>
  <v-container class="py-0">
    <v-row>
      <v-col cols="12">
        <v-flex tag="div" class="fan-wrap mx-auto">
          <div class="fan-top">
            <img
              class="w-100"
              alt="fan"
              :src="require('@/assets/images/fan_bg.png')"
            />
            <div class="fan-contain">
              <span class="d-inline-block px-2">Digital Sales Partner</span>
              <div class="fal-logo">
                <img
                  class=""
                  alt="fan logo"
                  :src="require('@/assets/images/fan_logo.svg')"
                />
              </div>

              <div class="slogan">
                You are the official<br />
                jersey owner of
              </div>
            </div>
          </div>

          <div class="fan-container">
            <div class="ss-logo text-center">
              <img
                :src="require('@/assets/images/ss_logo.svg')"
                alt="ss Logo"
              />
            </div>
            <div class="information">
              <span>Enter Your Information</span>
            </div>

            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider
                  tag="div"
                  name="Name"
                  rules="required"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="input-wrap" :class="classes">
                    <v-text-field
                      prepend-inner-icon="mdi-account"
                      solo
                      flat
                      hide-details
                      outlined
                      label="Your Name"
                      v-model="formData.Name"
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  class="phone"
                  name="Mobile"
                  :rules="{
                    required: true,
                    max: 14,
                    regex: /^(?:\+88|88)?(0?1[3-9]\d{8})$/,
                  }"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="input-wrap" :class="classes">
                    <v-text-field
                      solo
                      type="tel"
                      flat
                      hide-details
                      outlined
                      v-model="formData.Mobile"
                      id="Mobile"
                      v-on:blur="numberBlur"
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider
                  tag="div"
                  name="Email"
                  rules="email"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="input-wrap" :class="classes">
                    <v-text-field
                      prepend-inner-icon="mdi-email"
                      solo
                      flat
                      hide-details
                      outlined
                      label="Email Address (optional)"
                      v-model="formData.Email"
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  tag="div"
                  name="Gender"
                  rules="required"
                  :bails="false"
                  v-slot="{ errors, classes }"
                >
                  <div class="information gender-section">
                    <span>Your gender</span>
                  </div>
                  <div
                    class="male-female d-flex justify-center"
                    :class="classes"
                  >
                    <v-radio-group
                      hide-details
                      class="Gender"
                      row
                      v-model="formData.Gender"
                    >
                      <v-radio value="Male" class="gender-box">
                        <template v-slot:label>
                          <div class="d-flex align-center radio-holder">
                            <img
                              class="gender-active"
                              :src="
                                require('@/assets/images/gender_active.svg')
                              "
                              alt="employee"
                            />
                            <img
                              :src="require('@/assets/images/male.svg')"
                              alt="employee"
                            />

                            <span class="d-block">Male</span>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="Female" class="gender-box">
                        <template v-slot:label>
                          <div
                            class="text-center d-flex align-center radio-holder"
                          >
                            <img
                              class="gender-active"
                              :src="
                                require('@/assets/images/gender_active.svg')
                              "
                              alt="business"
                            />
                            <img
                              :src="require('@/assets/images/female.svg')"
                              alt="business"
                            />

                            <span class="d-block">Female</span>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <div class="btn-container">
                  <v-btn
                    type="submit"
                    rounded
                    depressed
                    class="fan-btn mt-1 white--text text-none"
                    >Claim Your Gift</v-btn
                  >
                </div>
              </form>
            </ValidationObserver>
          </div>
        </v-flex>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "congratulation",
  data() {
    return {
      formData: {
        Gender: "Male",
        Slug: null,
      },
      userCampaignEvent: this.$store.state.Configs.userCampaignEvent,
      intelTell: null,
      dialog: false,
    };
  },
  methods: {
    campaignEventSubmit() {
      return new Promise((resolve, reject) => {
        if (Object.keys(this.$route.params).length && this.$route.params.slug) {
          this.formData.Slug = this.$route.params.slug;
        }
        this.$http
          .post(this.userCampaignEvent, this.formData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    onSubmit() {
      this.$refs.form.validate().then(async () => {
        const yes = await this.$confirm("Are you sure?", {
          title: "Confirmation",
        });
        if (!yes) return;

        this.campaignEventSubmit()
          .then((res) => {
            // pixel event for addToCard Start
            if (window.fbq) {
              window.fbq("track", "SubmitApplication");
            }
            // pixel event for addToCard End

            this.$nextTick(() => {
              this.formData = {};
              this.$refs.form.reset();
              this.$store.commit("set_flash", {
                type: res.data.ResponseType,
                message: res.data.ResponseMessage,
              });
              this.dialog = true;
              this.$router.push({ name: "Home" });
            });
          })
          .catch((err) => {
            this.$nextTick(() => {
              this.$store.commit("set_flash", {
                type: "error",
                message: err.response.data,
              });
            });
          });
      });
    },
    numberBlur() {
      if (this.intelTell.isValidNumber(this.intelTell.telInput.value)) {
        this.formData.CountryCode =
          this.intelTell.getSelectedCountryData().dialCode;
      } else {
        this.formData.Mobile = "";
      }
    },
  },
  mounted() {
    this.$store.commit("update_meta", {
      PageTitle: "BPL Sylhet Strikers Fan Page",
      MetaKeywords:
        "bpl,t20,2024,sylhet strikers,nilam,nilam.xyz, fan verification,Mashrafe Bin Mortaza, Mash, Shanto,Mithun,tector,patel,sakib,ben cutting, howel",
      MetaDescription:
        "This is sylhet strikers authorize page by digital sales partner nilam.xyz",
      SocialLogoPath: "Media/1/SocialLogo/BPL-2024/fan-logo.jpg",
    });

    const input = document.querySelector("#Mobile");
    if (input) {
      this.intelTell = this.intlTelInput(input, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.0/js/utils.js",
        initialCountry: "bd",
        separateDialCode: true,
        onlyCountries: ["bd"],
        hiddenInput: "FormatedMobile",
        customPlaceholder: function (selectedCountryPlaceholder) {
          return "e.g. " + selectedCountryPlaceholder;
        },
      });
    }
  },
  // beforeDestroy() {
  //   this.$store.commit("update_meta", {
  //     PageTitle: null,
  //     MetaKeywords: null,
  //     MetaDescription: null,
  //     SocialLogoPath: this.siteSettings.SocialLogoPath,
  //   });
  // },
};
</script>

<style lang="scss" scoped>
@import "@/sass/congratulation_fan.scss";
</style>
