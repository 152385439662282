<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="create-ad-wrap mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-layout
                v-if="profileObj && Object.keys(profileObj).length"
                wrap
                class="account-panel"
              >
                <v-flex class="profile-image-wrapper">
                  <v-flex class="profile-image flex-grow-0">
                    <v-img
                      :src="
                        $root.generateMediaImageUrl(profileObj.ProfileImageUrl)
                      "
                    ></v-img>
                  </v-flex>
                </v-flex>

                <v-flex class="profile-information">
                  <v-flex class="profile-information-box">
                    <h2 class="d-flex justify-space-between">
                      <span v-text="$t('Personal Information')"></span>
                      <router-link
                        :to="{ name: 'MyAccountEdit' }"
                        v-text="$t('Edit')"
                      ></router-link>
                    </h2>

                    <ul>
                      <li>
                        <span v-text="$t('First Name')"></span>
                        <span
                          class="profile-info"
                          v-text="profileObj.FirstName"
                        ></span>
                      </li>

                      <li>
                        <span v-text="$t('Last Name')"></span>
                        <span
                          class="profile-info"
                          v-text="profileObj.LastName"
                        ></span>
                      </li>
                      <li>
                        <span v-text="$t('Phone')"></span>
                        <update-phone-or-email
                          :type="'Mobile'"
                        ></update-phone-or-email>
                      </li>
                      <li>
                        <span v-text="$t('Email')"></span>
                        <update-phone-or-email
                          :type="'Email'"
                        ></update-phone-or-email>
                      </li>
                      <li>
                        <span v-text="$t('Date Of Birth')"></span>
                        <span
                          class="profile-info"
                          v-text="profileObj.DOB"
                        ></span>
                      </li>
                      <li>
                        <span v-text="$t('Gender')"></span>
                        <span
                          class="profile-info"
                          v-text="profileObj.Gender"
                        ></span>
                      </li>
                    </ul>
                  </v-flex>

                  <v-flex class="profile-information-box">
                    <h2 class="d-flex justify-space-between">
                      <span v-text="$t('Company Information')"></span>
                    </h2>
                    <ul>
                      <li>
                        <span v-text="$t('Company Name')"></span>
                        <span
                          class="profile-info"
                          v-if="profileObj.Company"
                          v-text="profileObj.Company.CompanyName"
                        ></span>
                      </li>
                      <li>
                        <span v-text="$t('Phone')"></span>
                        <span
                          class="profile-info"
                          v-if="profileObj.Company"
                          v-text="profileObj.Company.ContactNo"
                        ></span>
                      </li>
                      <li>
                        <span v-text="$t('Email')"></span>
                        <span
                          class="profile-info"
                          v-if="profileObj.Company"
                          v-text="profileObj.Company.Email"
                        ></span>
                      </li>
                      <li>
                        <span v-text="$t('Summary')"></span>
                        <span
                          class="profile-info"
                          v-if="profileObj.Company"
                          v-text="profileObj.Company.CompanySummary"
                        ></span>
                      </li>
                      <li class="align-center">
                        <span v-text="$t('Documents')"></span>
                        <div
                          class="d-flex flex-wrap light-gallery"
                          id="lightgallery"
                        >
                          <a
                            :href="
                              $root.generateMediaImageUrl(element.FilePath)
                            "
                            class="document-box"
                            v-for="element in profileObj.Documents"
                            :key="element.Id"
                          >
                            <img
                              :src="
                                $root.generateMediaImageUrl(element.FilePath)
                              "
                              alt="Document"
                            />
                            <v-icon size="16" color="green"
                              >mdi-check-decagram</v-icon
                            >
                          </a>
                        </div>
                      </li>
                      <li>
                        <span
                          v-text="
                            $t(
                              'Is Used This Company Information As Your Sales Profile'
                            )
                          "
                        ></span>
                        <span
                          class="profile-info"
                          v-text="
                            profileObj.IsUseCompanySalesProfile ? 'Yes' : 'No'
                          "
                        ></span>
                      </li>
                    </ul>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-flex v-else>
                <h3 class="pa-3" v-text="$t('Data not found') + '!'"></h3>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";
import UpdatePhoneOrEmail from "@/components/sites/common/UpdatePhoneOrEmail.vue";
import lightGallery from "lightgallery";

export default {
  name: "MyAccount",
  components: {
    AccountSidebar,
    UpdatePhoneOrEmail,
  },
  data() {
    return {
      informations: null,
    };
  },
  mounted() {
    lightGallery(document.getElementById("lightgallery"), {
      thumbnail: true,
      animateThumb: true,
      counter: true,
      download: true,
      showThumbByDefault: false,
      slideEndAnimatoin: true,
      escKey: true,
      enableTouch: true,
    });
  },
  computed: {
    profileObj() {
      return this.$store.state.profileObj;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~lightgallery/css/lightgallery.css";
@import "@/sass/my_profile.scss";
</style>
