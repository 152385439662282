<template>
  <v-flex>
    <h2
      @click="isBillingOpen"
      class="d-flex align-center justify-space-between"
    >
      <span>Billing Address</span>
      <v-icon>{{
        isbillingOpen ? "mdi-chevron-down" : "mdi-chevron-up"
      }}</v-icon>
    </h2>
    <v-flex class="ship-info" v-bind:class="{ closed: isbillingOpen }">
      <v-flex class="address">
        <v-flex class="d-flex shiping-title justify-space-between">
          <span
            class="pr-3"
            v-if="Object.keys(selectedBillingAddress).length"
            v-text="selectedBillingAddress.Title"
          ></span>
          <v-flex class="change-address flex-grow-0">
            <v-menu
              transition="slide-x-transition"
              bottom
              left
              offset-y
              rounded="0"
              v-if="billingAddressList.length"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-flex
                  v-bind="attrs"
                  v-on="on"
                  class="pointer change-address-text"
                >
                  Change
                </v-flex>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="shipping in billingAddressList"
                  :key="shipping.Id"
                >
                  <v-list-item-title>
                    <a
                      @click="changeBillingAddress(shipping.Id)"
                      href="javascript:void(0)"
                      v-text="shipping.Title"
                    ></a>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-flex>
        <address v-if="Object.keys(selectedBillingAddress).length">
          <span>
            Name:
            <strong v-text="selectedBillingAddress.Name"></strong>
          </span>
          <span>
            Phone:
            <strong v-text="selectedBillingAddress.Mobile"></strong>
          </span>
          <span class="text-truncate">
            Email:
            <strong v-text="selectedBillingAddress.Email"></strong>
          </span>
          <span
            ><strong
              class="success--text"
              v-text="selectedBillingAddress.DistrictName"
            ></strong
          ></span>
        </address>
        <address v-else>
          <strong>Address not found</strong>
        </address>
      </v-flex>
    </v-flex>
  </v-flex>
</template>
<script>
import { AddressMixins } from "@/mixins/AddressMixins";
export default {
  name: "BillingAdddress",
  mixins: [AddressMixins],
  props: {
    addressList: Array,
  },

  data() {
    return {
      billingAddressList: [],
      selectedBillingAddress: {},
      isbillingOpen: true,
    };
  },
  computed: {
    selectedBillingAddressId() {
      return this.$store.state.selectedBillingAddressId;
    },
  },
  async created() {
    this.selectedBillingAddress = await this.getSelectedAddress(
      this.addressList,
      this.selectedBillingAddressId
    );

    //reset billing address id for mis-matching
    if (this.selectedBillingAddress.Id != this.selectedBillingAddressId) {
      this.$store.commit("setBillingAddressId", this.selectedBillingAddress.Id);
    }

    this.billingAddressList = this.getAddressListsWithoutSelected(
      this.addressList,
      this.selectedBillingAddress.Id
    );
  },
  methods: {
    isBillingOpen() {
      this.isbillingOpen = !this.isbillingOpen;
    },
    async changeBillingAddress(id) {
      if (!id) return;

      this.selectedBillingAddress = await this.getSelectedAddress(
        this.addressList,
        id
      );

      this.billingAddressList = this.getAddressListsWithoutSelected(
        this.addressList,
        this.selectedBillingAddress.Id
      );
    },
    async billingAddressUpdated(obj) {
      if (obj.Id == this.selectedBillingAddress.Id) {
        this.selectedBillingAddress = obj;
      } else {
        let temporaryAddressLists = await this.getAddressListsWithoutSelected(
          this.billingAddressList,
          obj.Id
        );

        this.billingAddressList = [...temporaryAddressLists, obj];
      }
    },
  },
  mounted() {
    this.Fire.$on("address-updated", (obj) => {
      if (obj) {
        this.billingAddressUpdated(obj);
      } else {
        console.log("updated object does not fetch properly");
      }
    });
  },
  beforeDestroy() {
    this.Fire.$off("address-updated");
  },
  watch: {
    selectedBillingAddress(newVal) {
      if (Object.keys(newVal).length) {
        this.$store.commit("setBillingAddressId", newVal.Id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/order_summery.scss";
</style>
