<template>
  <!-- Product section -->
  <v-flex class="product-box">
    <v-hover>
      <template v-slot="{ hover }">
        <v-card
          :elevation="hover ? 10 : 1"
          class="product-card transition-swing"
        >
          <v-img
            :title="product.ItemName"
            :src="$root.generateImageUrl(product, $root.dimentions.thumb)"
            :lazy-src="require('@/assets/images/no-img.jpg')"
            :alt="product.ItemName"
            class="product-image"
            aspect-ratio="1"
          >
            <router-link
              :to="{
                name: 'ProductDetails',
                params: { id: product.Id, slug: product.Slug },
              }"
              class="fill-height d-block"
            ></router-link>

            <v-card-text
              class="product-top-labels py-2 px-4 d-flex justify-space-between"
            >
              <v-flex class="flex-grow-0">
                <v-sheet
                  v-if="product.SaleType == 'Auction'"
                  v-text="'Nilam'"
                  dark
                  color="brand"
                  class="type px-3 font-weight-light text-center d-inline-block align-center"
                ></v-sheet>
                <v-sheet
                  v-if="product.SaleType == 'Bargain'"
                  v-text="'Bargain'"
                  dark
                  color="success"
                  class="type px-3 font-weight-light text-center d-inline-block align-center"
                ></v-sheet>

                <v-sheet
                  v-if="product.SaleType == 'Gallery'"
                  v-text="'Buy Now'"
                  dark
                  color="primary"
                  class="type px-3 font-weight-light text-center d-inline-block align-center"
                ></v-sheet>
              </v-flex>
            </v-card-text>

            <v-card-text class="pb-3 product-btm-labels">
              <v-sheet
                v-if="product.IsPromoted"
                color="site_bg"
                class="px-1 mr-2 grey--text font-weight-light"
                rounded
                >{{ $t("promoted") }}</v-sheet
              >
            </v-card-text>
          </v-img>

          <v-sheet class="product-list-mid">
            <v-card-subtitle
              class="product-sub-title font-weight-light"
              v-text="product.CategoryName"
            ></v-card-subtitle>

            <div class="product-title">
              <router-link
                :class="{ 'brand--text': hover }"
                :to="{
                  name: 'ProductDetails',
                  params: { id: product.Id, slug: product.Slug },
                }"
                v-text="product.ItemName"
              ></router-link>
            </div>
            <div class="condition">
              <span
                :class="colorsArr[product.Condition.toLowerCase()] + '--text'"
                >{{ product.Condition }}</span
              >
              <span
                v-if="product.StockType == 'Lot'"
                :class="colorsArr[product.StockType.toLowerCase()] + '--text'"
                >({{ product.StockType }})</span
              >
            </div>
            <v-layout class="justify-space-between flex-wrap time-and-bid-wrap">
              <countdown
                v-if="$root.getTime(product)"
                :time="$root.getTime(product)"
                :transform="$root.transformShorter"
              >
                <template slot-scope="props">
                  <span :class="$root.countDown.Color[product.AuctionStatus]"
                    >{{ $root.countDown.Text[product.AuctionStatus] }}:</span
                  >
                  <b>
                    {{ props.days }} {{ props.hours }} {{ props.minutes }}
                    {{ props.seconds }}
                  </b>
                </template>
              </countdown>
              <span v-else>Ended</span>

              <v-sheet
                class="auction-bid-holder"
                v-if="product.AuctionStatus !== 'Published'"
              >
                <span class="bid-title">Bid:</span>
                <span class="bid-value">
                  <strong>{{ product.BidCount }}</strong>
                </span>
              </v-sheet>
            </v-layout>
          </v-sheet>

          <v-card-text class="product-bottom">
            <v-card-text class="product-top-labels">
              <wish-list-btn
                :is-favorite="product.IsFavorite"
                :product-id="product.Id"
                :is-elevation="true"
              ></wish-list-btn>
              <add-to-cart-btn
                class="cart-icon"
                v-if="product.HasAddToCart"
                :is-toggle="true"
                :product="product"
                :local-cartable-obj="localCarableObj"
              >
                <v-icon>mdi-cart</v-icon>
              </add-to-cart-btn>
              <v-btn fab elevation="2" class="share-product-wrap">
                <v-icon class="grey--text">mdi-share-variant</v-icon>

                <!-- Product-Social -->
                <v-flex class="share-productIn">
                  <v-card class="share-product">
                    <product-social :meta-data="metaObj"></product-social>
                  </v-card>
                </v-flex>
              </v-btn>
            </v-card-text>
            <div
              class="product-price-box pb-2"
              v-if="product.SaleType == 'Auction'"
            >
              <v-sheet
                v-if="product.BasePrice"
                tag="span"
                class="product-price"
                v-text="
                  product.BidCount
                    ? $root.showAmount(product.MaxBidAmount)
                    : $root.showAmount(product.BasePrice)
                "
              ></v-sheet>
              <v-sheet
                v-if="product.BidCount"
                tag="span"
                class="bidding-status px-2"
                >Current Bid</v-sheet
              >
              <v-sheet v-else tag="span" class="bidding-status px-2"
                >Bid Start</v-sheet
              >
            </div>
            <div class="product-price-box pb-2" v-else>
              <v-sheet
                tag="span"
                class="product-price"
                v-text="$root.showAmount(product.SalesPrice)"
              ></v-sheet>
              <v-sheet
                v-if="product.DiscountedPrice"
                tag="span"
                class="bidding-status text-decoration-line-through"
                v-text="$root.showAmount(product.DiscountedPrice)"
              ></v-sheet>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
  </v-flex>
</template>

<script>
import ProductSocial from "@/components/sites/pages/ProductSocial";
import WishListBtn from "@/components/sites/common/WishListBtn";
import AddToCartBtn from "@/components/sites/common/AddToCartBtn";
export default {
  name: "ProductCard",
  components: {
    ProductSocial,
    WishListBtn,
    AddToCartBtn,
  },
  props: {
    product: Object,
  },
  data() {
    return {
      colorsArr: {
        new: "silver_tree",
        renew: "silver_tree",
        used: "black",
        single: "grey",
        lot: "grey",
      },
      metaObj: {},
      localCarableObj: {},
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  created() {
    this.setMetaObj();

    // use for adding cart after login redirect
    if (this.isLoggedIn) {
      let localCartableObj = JSON.parse(
        localStorage.getItem("local-cartable-obj")
      );
      this.localCarableObj = localCartableObj || {};
    }
  },
  methods: {
    setMetaObj() {
      this.metaObj.SocialLogoPath = this.$root.generateImageUrl(
        this.product,
        this.$root.dimentions.thumb
      );
      let ProductDetailsUrl = this.$store.state.Configs.getProductDetails
        .replace(":id", this.product.Id)
        .replace(":slug", this.product.Slug);

      this.metaObj.CurrentSocialShareUrl = ProductDetailsUrl;
    },
  },
};
</script>

<style lang="scss" scope>
@import "@/sass/product_list_cart.scss";
</style>
