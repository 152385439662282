<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container class="py-0">
      <v-skeleton-loader
        class="mx-auto pl-5 pr-5"
        type="article,article,article"
        v-if="loading"
      ></v-skeleton-loader>
      <template v-else>
        <v-card tile>
          <template
            v-if="
              Object.keys(wishListProducts).length &&
              wishListProducts.data.length
            "
          >
            <v-sheet class="watch-list-wrap">
              <h1 v-text="$t('Favorite Product')"></h1>
              <span
                class="watch-list-count"
                v-text="
                  wishListProducts.recordsTotal +
                  ' ' +
                  $t('product(s) in your wishlist')
                "
              ></span>

              <v-flex class="watch-list-table">
                <template v-if="$root.windowSize > 959">
                  <table>
                    <thead>
                      <tr>
                        <th v-text="$t('Product Name')"></th>
                        <th>
                          <div v-text="$t('Unit Price')"></div>
                        </th>
                        <th>
                          <div v-text="$t('Product Type')"></div>
                        </th>
                        <th v-text="$t('Stock Status')"></th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="product in wishListProducts.data"
                        :key="product.Id"
                      >
                        <td data-label="Product Name">
                          <v-layout class="wishlist-products-item">
                            <router-link
                              class="d-block fill-height primary--text"
                              :to="{
                                name: 'ProductDetails',
                                params: {
                                  id: product.Id,
                                  slug: product.Slug,
                                },
                              }"
                            >
                              <v-img
                                contain
                                max-width="80"
                                :src="
                                  $root.generateImageUrl(
                                    product,
                                    $root.dimentions.thumb
                                  )
                                "
                                alt="Image"
                              ></v-img>
                            </router-link>
                            <v-flex class="wishlist-product-title flex-grow-0">
                              <h4>
                                <router-link
                                  v-text="product.ItemName"
                                  class="d-block fill-height primary--text"
                                  :to="{
                                    name: 'ProductDetails',
                                    params: {
                                      id: product.Id,
                                      slug: product.Slug,
                                    },
                                  }"
                                ></router-link>
                              </h4>

                              <a href="javascript:void(0)">
                                <wish-list-btn
                                  :is-favorite="product.IsFavorite"
                                  :product-id="product.Id"
                                  :is-elevation="false"
                                >
                                  <v-icon>mdi-trash-can-outline</v-icon>
                                </wish-list-btn>
                              </a>
                            </v-flex>
                          </v-layout>
                        </td>

                        <td
                          data-label="Unit Price"
                          v-if="product.SaleType == 'Auction'"
                        >
                          <span
                            class="unit-price"
                            v-text="$root.showAmount(product.BasePrice)"
                          ></span>

                          <span class="bidding-status" v-if="product.BidCount"
                            >Current Bid</span
                          >
                          <span v-else>Bid Start</span>
                        </td>

                        <td v-else>
                          <span
                            class="unit-price"
                            v-text="$root.showAmount(product.SalesPrice)"
                          ></span>
                        </td>
                        <td data-label="Product Type">
                          <v-sheet
                            v-if="product.SaleType == 'Auction'"
                            tag="span"
                            dark
                            color="brand"
                            class="auction-label"
                            >Auction</v-sheet
                          >
                          <span
                            class="auction-label-text"
                            v-else
                            v-text="$t('Without Auction')"
                          ></span>
                        </td>

                        <td data-label="Stock Status">
                          <span
                            v-if="product.StockQuantity"
                            v-text="$t('In Stock')"
                            class="stock-status"
                          ></span>
                          <span
                            v-else
                            v-text="$t('Out of Stock')"
                            class="stock-status"
                          ></span>
                        </td>
                        <td data-label="Action" class="text-right">
                          <v-flex
                            v-if="product.HasAddToCart"
                            class="ml-auto"
                            style="max-width: 150px"
                          >
                            <add-to-cart-btn
                              class="cart-btn"
                              :product="product"
                              :is-disable="true"
                            ></add-to-cart-btn>
                          </v-flex>

                          <v-flex v-else>
                            <span
                              class="not-available"
                              v-text="
                                $t('This Product is not available for cart')
                              "
                            ></span>
                          </v-flex>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>

                <template v-else>
                  <v-layout
                    class="cart-product-wrap"
                    v-for="product in wishListProducts.data"
                    :key="product.Id"
                  >
                    <v-flex class="product-img flex-grow-0">
                      <img
                        :src="
                          $root.generateImageUrl(
                            product,
                            $root.dimentions.thumb
                          )
                        "
                        alt="Image"
                      />
                    </v-flex>

                    <v-flex class="product-info flex-grow-0 pl-2">
                      <h4>
                        <router-link
                          v-text="product.ItemName"
                          class="d-block fill-height primary--text"
                          :to="{
                            name: 'ProductDetails',
                            params: {
                              id: product.Id,
                              slug: product.Slug,
                            },
                          }"
                        ></router-link>
                      </h4>

                      <v-layout wrap class="justify-space-between align-center">
                        <v-flex
                          class="base-price flex-grow-0 pr-1"
                          v-if="product.SaleType == 'Auction'"
                        >
                          <span
                            class="unit-price pr-1"
                            v-text="$root.showAmount(product.BasePrice)"
                          ></span>

                          <span
                            class="bidding-status pr-1"
                            v-if="product.BidCount"
                            >Current Bid</span
                          >
                          <span v-else class="pr-1">Bid Start</span>
                        </v-flex>

                        <v-flex class="base-price flex-grow-0" v-else>
                          <span
                            class="unit-price"
                            v-text="$root.showAmount(product.SalesPrice)"
                          ></span>
                        </v-flex>
                        <v-flex class="flex-grow-0 sale-type">
                          <span
                            v-if="product.SaleType == 'Auction'"
                            tag="span"
                            dark
                            class="auction-label"
                            >Auction</span
                          >
                          <span class="auction-label-text" v-else
                            >Without Auction</span
                          >
                        </v-flex>

                        <v-flex class="flex-grow-0">
                          <span
                            v-if="product.StockQuantity"
                            v-text="$t('In Stock')"
                            class="stock-status"
                          ></span>
                          <span
                            v-else
                            v-text="$t('Out of Stock')"
                            class="stock-status deep-orange--text"
                          ></span>
                        </v-flex>
                      </v-layout>

                      <v-layout wrap class="justify-space-between align-center">
                        <v-flex class="flex-grow-0">
                          <add-to-cart-btn
                            class="cart-btn"
                            v-if="product.HasAddToCart"
                            :product="product"
                            :is-disable="true"
                          ></add-to-cart-btn>
                          <v-flex v-else>
                            <span class="not-available"
                              >This Product is not available for cart</span
                            >
                          </v-flex>
                        </v-flex>
                        <v-flex class="flex-grow-0 ml-auto">
                          <a href="javascript:void(0)">
                            <wish-list-btn
                              :is-favorite="product.IsFavorite"
                              :product-id="product.Id"
                              :is-elevation="false"
                            >
                              <v-icon>mdi-trash-can-outline</v-icon>
                            </wish-list-btn>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>

                <v-flex
                  class="pt-5 flex-wrap d-flex justify-space-between align-center"
                >
                  <!-- pagination -->
                  <pagination
                    v-if="wishListProducts.TotalPage > 1"
                    class="order-lg-2"
                    :pageNo="filterObj.Page"
                    :length="wishListProducts.TotalPage"
                  ></pagination>

                  <v-btn
                    :to="{ name: 'ProductSearch' }"
                    tile
                    depressed
                    class="more-product brand white--text text-none order-1"
                    >{{ $t("View More Product") }}</v-btn
                  >
                </v-flex>
              </v-flex>
            </v-sheet>
          </template>
          <data-not-found v-else class="pt-10 pb-10">
            <div>
              <h1 v-text="$t('No Wish List Found')"></h1>
            </div>
            <v-flex class="align-center">
              <v-btn
                :to="{ name: 'ProductSearch' }"
                tile
                depressed
                class="more-product brand white--text text-none order-1"
                >{{ $t("View More Product") }}</v-btn
              >
            </v-flex>
          </data-not-found>
        </v-card>
      </template>

      <v-card tile v-if="relatedProducts.length">
        <v-sheet class="watch-list-related-product">
          <!-- Related Products sections -->
          <related-products></related-products>
        </v-sheet>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import DataNotFound from "@/components/sites/common/DataNotFound";
import RelatedProducts from "@/components/sites/common/RelatedProducts";
import Pagination from "@/components/services/Pagination";
import WishListBtn from "@/components/sites/common/WishListBtn";
import AddToCartBtn from "@/components/sites/common/AddToCartBtn";

export default {
  name: "WatchList",
  components: {
    DataNotFound,
    RelatedProducts,
    Pagination,
    WishListBtn,
    AddToCartBtn,
  },
  data() {
    return {
      loading: false,
      wishListProducts: {},
      relatedProducts: [],
      filterObj: {
        Page: 1,
        Length: 12,
      },
    };
  },
  computed: {
    clonedFilterObj: function () {
      return Object.assign({}, this.filterObj);
    },
  },
  created() {
    this.getWishListProducts();
    this.Fire.$on("wish-list-deleted", () => {
      this.getWishListProducts();
    });
  },
  methods: {
    getWishListProducts() {
      if (this.loading) return;
      this.loading = true;

      this.$http
        .post(this.$store.state.Configs.getFavoriteProducts, {
          ...this.filterObj,
        })
        .then((result) => {
          this.wishListProducts = result.data;

          this.loading = false;
        })
        .then(() => {
          this.$vuetify.goTo(0);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    handleFilterObjectChanged(newVal) {
      let resolved = this.$router.resolve({
        name: "WishList",
        query: newVal,
      });
      //changed filter object value to url as query without triggering route change event
      window.history.replaceState("NilamPushState", "WishList", resolved.href);
      this.getWishListProducts();
    },
    deleteFavorite(productId) {
      this.$http
        .post(
          this.$store.state.Configs.toggleFavorite.replace(":id", productId)
        )
        .then((res) => {
          this.favorited = res.data.ResponseData;
          this.$store.commit("update_favorite_count", this.favorited);
          this.getWishListProducts();
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length) {
      let { Page, Length = this.filterObj.Length } = this.$route.query;

      this.filterObj = {
        ...this.filterObj,
        Page: parseInt(Page),
        Length: parseInt(Length),
      };
    }
    this.Fire.$on("pageChanged", (val) => {
      this.filterObj.Page = parseInt(val);
    });
  },
  watch: {
    clonedFilterObj: {
      deep: true,
      handler(newVal, oldVal) {
        if (!this._.isEqual(newVal, oldVal)) {
          this.handleFilterObjectChanged(newVal);
        }
      },
    },
  },
  beforeDestroy() {
    this.Fire.$off("pageChanged");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/wish_list.scss";
</style>
