<template>
  <v-flex class="site-content-wrap site_bg">
    <v-container v-if="dataLoading">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-container>
    <v-container v-else class="py-0">
      <v-card tile>
        <v-flex class="card-list-wrap" v-if="items && items.length">
          <v-flex class="card-list-table pt-0">
            <v-row :init="(x = 0)">
              <v-col class="py-0" lg="8" cols="12">
                <v-skeleton-loader
                  v-if="cartLoading"
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>
                <v-row v-else>
                  <v-col
                    cols="12"
                    class="pb-10 py-0"
                    v-if="stockProducts && stockProducts.length"
                  >
                    <v-checkbox
                      v-if="cartType == 'Ecommerce'"
                      class="mt-0 bill-to"
                      :label="
                        $t('Select All') +
                        ' (' +
                        selectedIds.length +
                        ' ' +
                        $t('of') +
                        ' ' +
                        stockProducts.length +
                        ' ' +
                        $t('items') +
                        ')'
                      "
                      v-model="checkAll"
                      hide-details
                      @click="checkAllCartItems(checkAll)"
                    ></v-checkbox>
                    <span v-else class="d-block text-right bill-to"></span>
                    <template v-if="$root.windowSize > 959">
                      <table
                        v-for="(group, index) in groupItems"
                        :key="index"
                        :init="x++"
                      >
                        <thead>
                          <tr>
                            <th
                              v-if="cartType == 'Ecommerce'"
                              v-text="$t('Seller') + ': ' + x"
                              style="width: 12%"
                            ></th>
                            <th style="width: 40%" v-text="$t('Product')"></th>
                            <th v-text="$t('Unit Price')"></th>
                            <th v-text="$t('Quantity')"></th>
                            <th v-text="$t('Total Price')"></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in group" :key="item.ItemId">
                            <td v-if="cartType == 'Ecommerce'">
                              <v-checkbox
                                v-model="selectedIds"
                                label
                                :value="item.ItemId"
                                @click="updateCartSelectedSingleItem()"
                              ></v-checkbox>
                            </td>
                            <td data-label="Product Name">
                              <v-layout class="cart-product">
                                <router-link
                                  class="d-block fill-height primary--text"
                                  :to="{
                                    name: 'ProductDetails',
                                    params: {
                                      id: item.ItemId,
                                      slug: item.Slug,
                                    },
                                  }"
                                >
                                  <v-img
                                    contain
                                    max-width="80"
                                    :src="
                                      $root.generateMediaImageUrl(item.ImageUrl)
                                    "
                                    alt="Image"
                                  ></v-img>
                                </router-link>

                                <v-flex class="cart-product-title flex-grow-0">
                                  <h4>
                                    <router-link
                                      v-text="item.ItemName"
                                      class="d-block fill-height primary--text"
                                      :to="{
                                        name: 'ProductDetails',
                                        params: {
                                          id: item.ItemId,
                                          slug: item.Slug,
                                        },
                                      }"
                                    ></router-link>
                                  </h4>

                                  <template
                                    v-if="
                                      item.Attributes && item.Attributes.length
                                    "
                                  >
                                    <v-flex class="selected-attIn">
                                      <span
                                        class="selected-att"
                                        v-for="(val, index) in item.Attributes"
                                        :key="index"
                                      >
                                        <span
                                          v-text="val.CategoryName + ': '"
                                        ></span>
                                        <strong v-text="val.Name"></strong>
                                      </span>
                                    </v-flex>
                                  </template>
                                </v-flex>
                              </v-layout>
                            </td>
                            <td data-label="Price">
                              <span
                                class="unit-price"
                                v-text="$root.showAmount(item.SalesPrice)"
                              ></span>
                              <br />
                              <v-sheet
                                v-if="item.DiscountTag"
                                tag="span"
                                class="bidding-status text-decoration-line-through"
                                v-text="$root.showAmount(item.DiscountedPrice)"
                              ></v-sheet>
                              <span
                                v-if="item.DiscountTag"
                                class="discount-amount pl-2"
                                v-text="'(' + item.DiscountTag + ')'"
                              ></span>
                            </td>
                            <td data-label="Quantity">
                              <quantity-holder
                                :qty="item.StockQuantity"
                                :cart-quantity="item.CartQuantity"
                                :not-changeable="item.SaleType == 'Auction'"
                                :product-id="item.ItemId"
                                page-name="checkout"
                              ></quantity-holder>
                            </td>
                            <td data-label="Price">
                              <span
                                class="unit-price"
                                v-text="
                                  getItemTotal(
                                    item.SalesPrice,
                                    item.CartQuantity
                                  )
                                "
                              ></span>
                            </td>
                            <td>
                              <a
                                @click="removeProduct(item.ItemId)"
                                href="javascript:void(0)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <v-alert
                        dense
                        text
                        type="success"
                        v-if="
                          cartType == 'BuyNow' &&
                          items[0].IsEmi &&
                          items[0].AvailEmi
                        "
                      >
                        This item is selected for EMI (<span
                          class="danger--text"
                          >Service charge might be applicable</span
                        >)
                      </v-alert>
                    </template>

                    <template v-else>
                      <v-flex
                        v-for="(group, index) in groupItems"
                        :key="index"
                        :init="x++"
                      >
                        <v-flex
                          v-if="cartType == 'Ecommerce'"
                          v-text="'Seller: ' + x"
                        ></v-flex>
                        <v-layout
                          class="cart-product-wrap"
                          v-for="item in group"
                          :key="item.ItemId"
                        >
                          <v-flex class="product-img flex-grow-0">
                            <v-checkbox
                              :ripple="false"
                              class="pb-1 ma-0"
                              hide-details
                              v-if="cartType == 'Ecommerce'"
                              v-model="selectedIds"
                              label
                              :value="item.ItemId"
                              @click="updateCartSelectedSingleItem()"
                            ></v-checkbox>
                            <img
                              :src="$root.generateMediaImageUrl(item.ImageUrl)"
                              alt="Image"
                            />
                          </v-flex>

                          <v-flex class="product-info flex-grow-0 pl-2">
                            <h4 v-text="item.ItemName"></h4>

                            <template
                              v-if="item.Attributes && item.Attributes.length"
                            >
                              <v-flex class="selected-attIn">
                                <span
                                  class="selected-att"
                                  v-for="(val, index) in item.Attributes"
                                  :key="index"
                                >
                                  <span v-text="val.CategoryName + ': '"></span>
                                  <strong v-text="val.Name"></strong>
                                </span>
                              </v-flex>
                            </template>

                            <v-layout
                              wrap
                              class="justify-space-between align-center"
                            >
                              <v-flex class="flex-grow-0">
                                <span
                                  class="unit-price"
                                  v-text="
                                    getItemTotal(
                                      item.SalesPrice,
                                      item.CartQuantity
                                    )
                                  "
                                ></span>
                              </v-flex>

                              <v-flex class="qty-holder flex-grow-0">
                                <quantity-holder
                                  :qty="item.StockQuantity"
                                  :cart-quantity="item.CartQuantity"
                                  :not-changeable="item.SaleType == 'Auction'"
                                  :product-id="item.ItemId"
                                  page-name="checkout"
                                ></quantity-holder>
                              </v-flex>
                            </v-layout>
                            <a
                              @click="removeProduct(item.ItemId)"
                              href="javascript:void(0)"
                            >
                              <v-icon size="20">mdi-trash-can-outline</v-icon>
                            </a>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </template>

                    <!-- <v-flex class="pt-5 d-flex">
                      <v-btn
                        :to="{ name: 'ProductSearch' }"
                        tile
                        depressed
                        class="more-product text-none"
                        >{{ $t("Continue Shopping") }}</v-btn
                      >
                    </v-flex> -->
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="stockOutProducts && stockOutProducts.length"
                  >
                    <!--Stock out product list start-->
                    <v-flex class="stock-out-wrap">
                      <h3 v-text="$t('All stock-out items')"></h3>
                      <table class="stock-out">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th v-text="$t('Product')"></th>
                            <th
                              class="text-center"
                              v-text="$t('Unit Price')"
                            ></th>
                            <th
                              class="text-center"
                              v-text="$t('Cart Quantity')"
                            ></th>
                            <th
                              class="text-center"
                              v-text="$t('Stock Quantity')"
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in stockOutProducts"
                            :key="index"
                          >
                            <td>
                              <v-checkbox value disabled></v-checkbox>
                            </td>
                            <td data-label="Product Name">
                              <v-layout class="cart-product">
                                <router-link
                                  class="d-block fill-height primary--text"
                                  to="/"
                                >
                                  <v-img
                                    contain
                                    max-width="50"
                                    :src="
                                      $root.generateMediaImageUrl(item.ImageUrl)
                                    "
                                    alt="Image"
                                  ></v-img>
                                </router-link>

                                <v-flex class="cart-product-title flex-grow-0">
                                  <h4>
                                    <router-link
                                      v-text="item.ItemName"
                                      class="d-block fill-height primary--text"
                                      :to="{
                                        name: 'ProductDetails',
                                        params: {
                                          id: item.ItemId,
                                          slug: item.Slug,
                                        },
                                      }"
                                    ></router-link>
                                  </h4>
                                  <a
                                    @click="removeProduct(item.ItemId)"
                                    href="javascript:void(0)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </a>
                                </v-flex>
                              </v-layout>
                            </td>
                            <td class="text-center">
                              <span
                                class="unit-price"
                                v-text="$root.showAmount(item.SalesPrice)"
                              ></span>
                            </td>
                            <td class="text-center">
                              <span v-text="item.CartQuantity"></span>
                            </td>
                            <td class="text-center">
                              <span
                                class="unit-price"
                                v-text="item.StockQuantity"
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <small
                        class="warning--text"
                        v-text="
                          $t(
                            'NB:Those all product are not available in our stock, please wait until stock availability or you can remove from cart.'
                          )
                        "
                      ></small>
                    </v-flex>
                    <!--Stock out product list end-->
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="py-0" lg="4" cols="12">
                <!-- order-summay information-->
                <order-summay
                  :login="isLoggedIn"
                  page="checkout"
                ></order-summay>
              </v-col>
            </v-row>
          </v-flex>
        </v-flex>
        <data-not-found class="pt-10 pb-10" v-else>
          <div v-if="cartType == 'BuyNow'">
            <h1 v-text="$t('Item Not Found.')"></h1>
            <h4>
              <router-link
                :to="{ name: 'ProductSearch' }"
                v-text="$t('Click here')"
              ></router-link>
              {{ $t("to countinue your shopping") }}.
            </h4>
          </div>
          <div v-else>
            <h1 v-text="$t('Cart is empty.')"></h1>
            <h4>
              {{ $t("Countinue your shopping") }},
              <router-link
                :to="{ name: 'ProductSearch' }"
                v-text="$t('please add to cart')"
              ></router-link>
            </h4>
          </div>
        </data-not-found>
      </v-card>
    </v-container>
  </v-flex>
</template>

<script>
import QuantityHolder from "@/components/sites/common/QuantityHolder";
import OrderSummay from "@/components/sites/common/OrderSummay";
import DataNotFound from "@/components/sites/common/DataNotFound";

export default {
  name: "Checkout",
  components: {
    QuantityHolder,
    OrderSummay,
    DataNotFound,
  },
  data() {
    return {
      dataLoading: false,
      cartLoading: false,
      loading: false,
      cartType: "BuyNow",
      selectedIds: [],
      checkAll: false,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    carts() {
      return this.$store.state.carts;
    },
    items: {
      // getter
      get: function () {
        return this.carts[this.cartType];
      },
      // setter
      set: function (newValue) {
        this.setCardItemsToStorage(newValue);
      },
    },

    stockProducts() {
      return this._.filter(this.items, (o) => {
        return o.StockQuantity > 0;
      });
    },
    stockOutProducts() {
      return this._.filter(this.items, function (o) {
        return o.StockQuantity <= 0;
      });
    },
    groupItems() {
      return this._.groupBy(this.stockProducts, "SellerId");
    },
  },
  created() {
    this.getOrResetCardIemsForLocalStorage(this.isLoggedIn);
    this.cartType =
      this.$root.capitalizeFirstLetter(
        this._.camelCase(this.$route.params.cartType)
      ) || "BuyNow";
  },
  methods: {
    getOrResetCardIemsForLocalStorage(IsLoggedIn) {
      if (this.dataLoading) return;

      this.dataLoading = true;

      if (IsLoggedIn) {
        this.$store.dispatch("getEcommerceItems").then((res) => {
          this.selectedIds = this._.compact(
            this._.map(res.data.Items, (item) => {
              if (item.IsSelected) {
                return item.ItemId;
              } else {
                return;
              }
            })
          );
          this.checkAll = this.selectedIds.length == this.stockProducts.length;
          this.dataLoading = false;
        });
      } else {
        this.$store.state.carts.Ecommerce = [];
        this.$store.state.carts.BuyNow = [];
        this.selectedIds = [];
        this.dataLoading = false;
      }
    },
    storeInLocalStorage(newValue) {
      let index = this._.findIndex(this.carts[this.cartType], (o) => {
        return o.ItemId == newValue.id;
      });
      if (index !== -1) {
        this.carts[this.cartType][index].CartQuantity = newValue.qty;
      }
      if (this.CartType != "BuyNow") {
        this.$store.commit("cartQuantityCountUpdate", this.carts.Ecommerce);
      }
      localStorage.setItem("app-carts", JSON.stringify(this.carts));
    },
    setCardItemsToStorage(newValue) {
      if (this.loading && !newValue.isAuto) return;

      this.loading = true;

      if (this.cartType == "BuyNow") {
        var index = this._.findIndex(this.carts.Coupon, {
          Type: "BuyNow",
        });

        if (
          index != -1 &&
          this.carts.Coupon[index].MinPurchaseAmount >
            this.carts[this.cartType][0].SalesPrice * newValue.qty
        ) {
          this.$store
            .dispatch("removeCoupon", this.carts.Coupon[index])
            .then(() => {
              this.storeInLocalStorage(newValue);
              this.loading = false;
            });
        } else {
          this.storeInLocalStorage(newValue);
          this.loading = false;
        }
        return;
      } else {
        this.$root
          .syncHttp(this.$store.state.Configs.addToCart, {
            ItemId: newValue.id,
            Quantity: newValue.qty,
          })
          .then((res) => {
            this.$store.dispatch("updateCoupon", res.data.Coupon).then(() => {
              this.storeInLocalStorage(newValue);
              this.loading = false;
            });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getItemTotal(price, qty) {
      return this.$root.showAmount(qty * price);
    },
    async removeProduct(productId) {
      const yes = await this.$confirm("Are you sure?", {
        title: "Confirmation",
      });
      if (!yes) return;

      this.cartLoading = true;

      this.$store
        .dispatch("deleteFromCarts", {
          ItemId: productId,
          CartType: this.cartType,
        })
        .then(() => {
          if (this.cartType == "BuyNow") {
            var index = this._.findIndex(this.carts.Coupon, {
              Type: "BuyNow",
            });
            if (index != -1) {
              this.$store.dispatch("removeCoupon", this.carts.Coupon[index]);
            }
          } else {
            this.selectedIds = this._.compact(
              this._.map(this.stockProducts, (item) => {
                if (item.IsSelected) {
                  return item.ItemId;
                } else {
                  return;
                }
              })
            );
          }
          this.cartLoading = false;
        });
    },
    updateCartSelectedSingleItem() {
      if (this.cartLoading) return;

      this.cartLoading = true;

      this.$store
        .dispatch("updateSelectedCartItems", this.selectedIds)
        .then(() => {
          this.checkAll = this.selectedIds.length == this.stockProducts.length;
          this.cartLoading = false;
        });
    },
    checkAllCartItems(checkAll) {
      if (this.cartLoading) return;

      this.cartLoading = true;

      var itemIds = [];

      if (checkAll) {
        itemIds = this._.reduce(
          this.stockProducts,
          function (results, items) {
            results.push(items.ItemId);
            return results;
          },
          []
        );
      }

      this.$store.dispatch("updateSelectedCartItems", itemIds).then((res) => {
        this.selectedIds = this._.compact(
          this._.map(res.data.Items, (item) => {
            if (item.IsSelected) {
              return item.ItemId;
            } else {
              return;
            }
          })
        );
        this.cartLoading = false;
      });
    },
  },
  mounted() {
    this.Fire.$on("qtyUpdate", (val) => {
      this.items = val;
    });
  },
  beforeDestroy() {
    this.Fire.$off("qtyUpdate");
  },
  watch: {
    $route(to) {
      this.cartType =
        this.$root.capitalizeFirstLetter(
          this._.camelCase(to.params.cartType)
        ) || "BuyNow";
    },
    cartType(newVal) {
      if (newVal != "BuyNow") {
        this.checkAll = this.stockProducts.length == this.selectedIds.length;
      }
    },
    stockOutProducts: {
      handler(newVal) {
        if (newVal.length) {
          let isSelectedItems = this._.filter(newVal, { IsSelected: true });
          if (isSelectedItems && isSelectedItems.length) {
            this.checkAllCartItems();
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/cart.scss";
</style>
