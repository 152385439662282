<template>
  <v-flex
    v-if="modal"
    class="proposal-modal"
    :class="{ 'show-mobile-chat': showMobileChat }"
  >
    <v-flex class="loader" v-if="loading">
      <v-flex class="fill-height d-flex align-center justify-center">
        <v-progress-circular
          indeterminate
          class="silver_tree--text"
          aria-progressbar-name="loading status"
        ></v-progress-circular>
      </v-flex>
    </v-flex>
    <template v-else>
      <v-flex class="title-bar">
        <span
          class="hideChat"
          v-if="showMobileChat && isOwner"
          @click="hideChat()"
        >
          <v-icon color="light">mdi-arrow-left</v-icon>
        </span>
        <h6>Bargain Information</h6>
        <span class="close" @click="closeModal()">
          <v-icon color="light">mdi-close</v-icon>
        </span>
      </v-flex>

      <v-layout class="proposal-layout">
        <v-flex class="proposal-sidebar" v-if="isOwner">
          <v-flex class="proposal-title d-flex align-center">
            <v-icon color="primary" size="22" class="mr-1"
              >mdi-script-text-outline</v-icon
            >Buyers
          </v-flex>

          <v-flex class="buyer-wrap" ref="buyerwrap">
            <ul v-if="buyersArr.length">
              <li
                v-for="(buyer, index) in buyersArr"
                :key="index"
                :class="{ active: buyer.BargainId == selectedInfo.BargainId }"
                @click="showBargainDetails(buyer.BargainId)"
                :id="buyer.BargainId"
              >
                <v-flex class="buyer-name d-flex align-center">
                  <v-avatar color="indigo" size="38">
                    <v-img
                      :src="$root.generateMediaImageUrl(buyer.ProfileImageUrl)"
                      :lazy-src="
                        require('@/assets/images/user-blank-photo.jpg')
                      "
                      :alt="buyer.Name"
                    />
                  </v-avatar>
                  <v-flex class="pl-1 buyer-name-in">
                    <h3 v-text="buyer.Name"></h3>
                    <span class="message" v-text="buyer.Message"></span>

                    <v-flex class="d-flex justify-space-between">
                      <span
                        class="date"
                        v-text="
                          $root.formateServerDateTime(buyer.LastBargainAt)
                        "
                      ></span>
                      <span
                        :class="buyer.Status.toLowerCase()"
                        v-text="buyer.Status"
                      ></span>
                    </v-flex>
                  </v-flex>
                </v-flex>
              </li>
            </ul>
          </v-flex>
        </v-flex>

        <v-flex class="proposal-content">
          <v-flex class="proposal-top">
            <v-flex class="buyer-info">
              <v-flex v-if="Object.keys(selectedInfo).length">
                <h3 v-text="selectedInfo.Name"></h3>
                <span v-if="isOwner">Buyer</span>
                <span v-else>Owner</span>
              </v-flex>
              <v-sheet class="cancel pointer" @click="cancelBargain">
                <v-icon size="18">mdi-broom</v-icon>Cancel
              </v-sheet>
            </v-flex>

            <v-flex class="proposal-product-wrap" v-if="Object.keys(itemInfo)">
              <v-layout class="proposal-product align-center">
                <v-flex class="product-image flex-grow-0">
                  <router-link
                    :to="{
                      name: 'ProductDetails',
                      params: { id: itemInfo.ItemId, slug: itemInfo.Slug },
                    }"
                  >
                    <img
                      :src="$root.generateMediaImageUrl(itemInfo.ImageUrl)"
                      :alt="itemInfo.ItemName"
                    />
                  </router-link>
                </v-flex>
                <v-layout class="product-desc justify-space-between">
                  <v-flex class="product-desc-in flex-grow-0">
                    <router-link
                      :to="{
                        name: 'ProductDetails',
                        params: { id: itemInfo.ItemId, slug: itemInfo.Slug },
                      }"
                    >
                      <h2 v-text="itemInfo.ItemName"></h2>
                    </router-link>
                    <span
                      class="price d-block"
                      v-text="
                        $root.showAmount(itemInfo.SalesPrice) +
                        '/' +
                        itemInfo.MeasurementName
                      "
                    ></span>

                    <v-sheet class="quantity d-flex align-center">
                      <span class="quantity-title">Quantity</span>

                      <!-- Quantity-Holder -->
                      <quantity-holder
                        :qty="itemInfo.StockQuantity"
                        :cart-quantity="quantity"
                        :productId="productId"
                      ></quantity-holder>
                    </v-sheet>
                  </v-flex>

                  <v-flex
                    class="total-holder d-flex align-center flex-grow-0"
                    v-if="Object.keys(bargainInfo).length"
                  >
                    <v-flex>
                      <span class="total-qty">
                        Total Qty:
                        <b v-text="quantity"></b>
                      </span>
                      <span class="total-price">
                        Total price:
                        <b
                          v-text="
                            $root.showAmount(
                              bargainInfo.LastBuyerAmount * quantity
                            )
                          "
                        ></b>
                      </span>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-flex>

          <!-- Proposal Product -->
          <v-flex class="proposal-content-in">
            <!-- Chat container -->
            <v-flex class="chat-wrap" ref="chatwrap">
              <template v-if="chatArr.length">
                <v-flex
                  class="chat-item"
                  v-for="(chatObj, index) in chatArr"
                  :class="{ left: $auth.user.UserId != chatObj.UserId }"
                  :key="index"
                >
                  <v-flex class="chat-info flex-grow-0">
                    <span class="time-wrap">
                      <span
                        class="time"
                        v-text="$root.formateServerDateTime(chatObj.BargainAt)"
                      ></span>
                      <b v-if="$auth.user.UserId == chatObj.UserId">You</b>
                      <b v-else-if="isOwner">Buyer</b>
                      <b v-else>Seller</b>
                    </span>
                    <v-avatar color="indigo" size="38">
                      <img
                        v-if="$auth.user.UserId == chatObj.UserId"
                        :src="
                          $root.generateMediaImageUrl(
                            profileObj.ProfileImageUrl
                          )
                        "
                        :alt="profileObj.FullName"
                      />
                      <img
                        v-else
                        :src="
                          $root.generateMediaImageUrl(
                            selectedInfo.ProfileImageUrl
                          )
                        "
                        :alt="selectedInfo.Name"
                      />
                    </v-avatar>
                  </v-flex>

                  <v-flex class="chat-text flex-grow-0">
                    <v-sheet class="chat-box">
                      <p v-text="chatObj.Message"></p>
                      <span
                        class="white--text px-1 rounded"
                        :class="{
                          primary: $auth.user.UserId != chatObj.UserId,
                          silver_tree: $auth.user.UserId == chatObj.UserId,
                        }"
                        v-text="$root.showAmount(chatObj.Amount)"
                      ></span>

                      <v-flex v-if="index == isAcceptOfferBtn()">
                        <v-btn
                          class="text-none primary"
                          :disabled="acceptOfferLoading"
                          :loading="acceptOfferLoading"
                          v-text="'I Agree'"
                          @click="acceptOffer(chatObj.Id)"
                        ></v-btn>
                      </v-flex>
                      <v-flex class="btn-wrap" v-if="index == isOrderBtn()">
                        <v-btn
                          class="text-none"
                          :class="{
                            accept: chatObj.IsAccepted,
                            primary: !chatObj.IsAccepted,
                          }"
                          :disabled="orderLoading"
                          :loading="orderLoading"
                          v-text="
                            chatObj.IsAccepted
                              ? 'Place an order'
                              : 'Accept & place an order'
                          "
                          @click="placeOrder"
                        ></v-btn>
                      </v-flex>
                    </v-sheet>
                  </v-flex>
                </v-flex>
              </template>
              <v-flex class="empty-chat" v-else-if="isOwner"
                >No bargain received yet.</v-flex
              >
              <v-flex class="empty-chat" v-else>
                <span v-text="'Please send your proposal.'"></span>
                <v-alert type="info" prominent text class="mt-3">
                  <span
                    v-if="bargainLimitMessage.length"
                    v-text="bargainLimitMessage"
                  ></span>
                </v-alert>
              </v-flex>

              <!-- thinking image -->
              <div v-show="proposalBroadcastLoading">
                <v-img
                  max-width="100"
                  :src="require('@/assets/images/tinking.gif')"
                ></v-img>
              </div>

              <!-- proposal form -->
              <v-flex class="proposal-form d-flex justify-end">
                <v-flex
                  v-if="!isOwner || chatArr.length"
                  class="proposal-form-in flex-grow-0"
                >
                  <h5 v-if="bargainRemain">
                    <span>Please enter your bargain price</span>
                    <span
                      class="danger--text"
                      v-if="bargainSequence && bargainLimitMessage.length"
                      v-text="' (' + bargainLimitMessage + ')'"
                    ></span>
                  </h5>
                  <h5 v-else class="text-center">
                    <span v-text="bargainLimitMessage"></span>
                  </h5>

                  <v-flex class="form-box flex-grow-0">
                    <ValidationObserver
                      tag="div"
                      ref="form"
                      v-slot="{ handleSubmit }"
                    >
                      <form @submit.prevent="handleSubmit(proposalSend)">
                        <v-layout
                          wrap
                          class="justify-space-between"
                          v-show="bargainRemain"
                        >
                          <ValidationProvider
                            ref="bargainPrice"
                            tag="div"
                            name="offer amount"
                            rules="required"
                            :bails="false"
                            v-slot="{ errors, classes }"
                            class="flex"
                          >
                            <div class="input-wrap" :class="classes">
                              <v-text-field
                                solo
                                type="number"
                                step="any"
                                flat
                                hide-details
                                :disabled="proposalLoading"
                                ref="bargainInput"
                                autocomplete="off"
                                outlined
                                label="Bargain Amount"
                                v-model="formData.bargain_price"
                              ></v-text-field>
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>

                          <!-- Product Price & Description -->

                          <v-btn
                            :disabled="proposalLoading"
                            tile
                            depressed
                            class="submit-proposal text-none"
                            type="submit"
                            >Bargain</v-btn
                          >
                        </v-layout>
                        <v-flex
                          class="d-flex justify-center"
                          v-if="!bargainRemain"
                        >
                          NB: If you are not satisfied with this bargain, please
                          cancel it and bargain again.

                          <v-btn
                            class="ml-2 darken-1"
                            rounded
                            elevation="0"
                            x-small
                            color="warning"
                            dark
                            @click="cancelBargain"
                          >
                            <v-icon size="15">mdi-broom</v-icon> try again
                          </v-btn>
                        </v-flex>
                      </form>
                    </ValidationObserver>
                  </v-flex>
                </v-flex>
              </v-flex>
            </v-flex>
          </v-flex>
        </v-flex>
      </v-layout>
    </template>
  </v-flex>
</template>

<script>
import QuantityHolder from "@/components/sites/common/QuantityHolder";
export default {
  name: "BargainModal",
  components: {
    QuantityHolder,
  },
  data() {
    return {
      price: "",
      quantity: 1,
      productId: 0,
      modal: false,
      showMobileChat: false,
      bargainId: 0,
      loading: true,
      acceptOfferLoading: false,
      orderLoading: false,
      proposalLoading: false,
      proposalBroadcastLoading: false,
      isOwner: false,
      selectedInfo: {},
      bargainInfo: {},
      buyersArr: [],
      itemInfo: {},
      chatArr: [],
      formData: {
        bargain_price: "",
      },
      bargainLimit: 3,
      bargainSequence: 0,
      bargainLimitMessage: "",
    };
  },
  props: {
    itemId: Number,
  },
  computed: {
    profileObj() {
      return this.$store.state.profileObj;
    },
    bargainRemain() {
      return parseInt((this.bargainLimit || 3) - (this.bargainSequence || 0));
    },
  },
  created() {
    this.productId = this.itemId;

    this.Fire.$on("qtyUpdate", (val) => {
      if (!this.modal) return; //though modal not open

      if (this.productId == val.id) {
        this.quantity = val.qty;
      }
    });

    this.$notificationHub.$on("bargain", ({ obj }) => {
      if (!this.modal) return; //though modal not open

      //restrict other bargain
      if (this.bargainId) {
        if (this.bargainId != obj.ResponseData.Id) return;
      }

      this.proposalBroadcastLoading = true;

      this.bargainInfo = obj.ResponseData;
      this.bargainId = obj.ResponseData.Id;
      let [firstObj, secondObj] = obj.ResponseData.BargainDetails;

      this.bargainLimit = firstObj.BargainStepLimit;
      this.bargainSequence = firstObj.Sequence;
      this.bargainLimitMessage = firstObj.BargainLimitMessage;

      // Update array
      this.chatArr = [...this.chatArr, ...[firstObj]];

      let time = this.randomInteger(1000, 2500);

      let setSecondObj = this._.debounce(async (obj) => {
        const res = await this.setSecondObjToChatArr(obj);
        if (res.length) {
          let container = this.$refs.chatwrap;
          container.scrollTop = container.scrollHeight;
        }
      }, time);

      setSecondObj(secondObj);
    });
  },
  beforeDestroy() {
    this.Fire.$off("qtyUpdate");
    this.Fire.$off("bargain");
  },
  methods: {
    isAcceptOfferBtn() {
      if (
        this.isOwner &&
        this.bargainInfo.Status == "Progress" &&
        !this.proposalLoading
      ) {
        return this._.findLastIndex(this.chatArr, (o) => {
          return o.UserId == this.bargainInfo.UserId;
        });
      }
      return -1;
    },
    isOrderBtn() {
      if (!this.isOwner && !this.proposalLoading) {
        return this._.findLastIndex(this.chatArr, (o) => {
          return o.UserId == this.bargainInfo.SellerId;
        });
      }
      return -1;
    },
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    closeModal() {
      this.bargainId = 0;
      this.modal = false;
      this.formData.bargain_price = null;
    },
    showModal(bargainId) {
      let check = this.$root.checkAuth();
      if (check) return;

      if (bargainId) this.bargainId = bargainId;
      this.getBargainInfo();
      this.modal = true;
      this.showMobileChat = true;
    },
    hideChat() {
      this.showMobileChat = false;
    },
    showBargainDetails(bargainId) {
      this.bargainId = bargainId;
      this.getBargainInfo();
      this.showMobileChat = true;
    },
    getBargainInfo() {
      this.loading = true;

      let url = "";

      if (this.bargainId) {
        url = this.$store.state.Configs.getBargainDetailsById.replace(
          ":id",
          this.bargainId
        );
      } else {
        url = this.$store.state.Configs.getBargainInfoByItemId.replace(
          ":itemId",
          this.productId
        );
      }

      this.$http
        .post(url)
        .then((result) => {
          let data = result.data;

          this.isOwner = data.IsOwner;

          if (data.Bargain) {
            this.bargainInfo = data.Bargain;
            this.productId = this.bargainInfo.ItemId;
            this.bargainId = this.bargainInfo.Id;
          } else {
            this.bargainInfo = {};
          }

          this.buyersArr = data.Buyers || [];
          this.itemInfo = data.Item || {};
          this.chatArr = data.Details || [];
          this.selectedInfo = data.Selected || {};

          if (this.selectedInfo.BargainLimitMessage) {
            this.bargainLimit = this.selectedInfo.BargainStepLimit;
            this.bargainSequence = this.selectedInfo.Sequence;
            this.bargainLimitMessage = this.selectedInfo.BargainLimitMessage;
          }
          this.loading = false;
        })
        .then(() => {
          let chatwrapcontainer = this.$refs.chatwrap;
          chatwrapcontainer.scrollTop = chatwrapcontainer.scrollHeight;
        })
        .then(() => {
          if (this.buyersArr.length) {
            let buyerwrapcontainer = this.$refs.buyerwrap;
            let yPosition = document.getElementById(this.bargainId);
            buyerwrapcontainer.scrollTop = yPosition.offsetTop - 39;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    proposalSend() {
      let check = this.$root.checkAuth();
      if (this.proposalLoading || check) return;

      this.proposalLoading = true;

      this.$refs.form.validate().then(() => {
        this.$http
          .post(this.$store.state.Configs.sendBargainPrice, {
            ItemId: this.productId,
            Amount: this.formData.bargain_price,
            Quantity: this.quantity,
            BargainId: this.bargainId,
          })
          .then((result) => {
            if (result.data.ResponseType == "Success") {
              this.formData.bargain_price = "";
              // Wait until the models are updated in the UI
              this.$nextTick(() => {
                this.$refs.bargainPrice.reset();
                this.$refs.form.reset();
              });
            } else {
              this.proposalLoading = false;
              this.$refs.bargainPrice.applyResult({
                errors: [result.data.ResponseMessage],
                valid: false,
                failedRules: {},
              });
            }
          })
          .then(() => {
            this.proposalLoading = false;
          })
          .then(() => {
            let container = this.$refs.chatwrap;
            container.scrollTop = container.scrollHeight;
            this.$refs.bargainInput.focus();
          })
          .catch((error) => {
            console.log(error);
            this.proposalLoading = false;
          });
      });
    },
    setSecondObjToChatArr(secondObj) {
      if (secondObj) {
        this.chatArr = [...this.chatArr, ...[secondObj]];
      }
      this.proposalBroadcastLoading = false;
      return this.chatArr;
    },
    async cancelBargain() {
      const yes = await this.$confirm("Do you really want to cancel this?", {
        title: "Warning",
      });
      if (!yes) return;

      this.$http
        .post(
          this.$store.state.Configs.cancelBargain.replace(":id", this.bargainId)
        )
        .then(() => {
          this.closeModal();
          this.showModal();
        });
    },
    async acceptOffer(id) {
      if (this.acceptOfferLoading) return;

      const yes = await this.$confirm("Are you sure?");
      if (!yes) return;

      this.acceptOfferLoading = true;

      this.$http
        .post(this.$store.state.Configs.acceptBargainAmount.replace(":id", id))
        .then((res) => {
          if (res.data.ResponseType == "Success") {
            this.acceptOfferLoading = false;

            this.$nextTick(() => {
              this.$refs.bargainPrice.reset();
              this.$refs.form.reset();
            });
          } else {
            this.acceptOfferLoading = false;
            this.$refs.bargainPrice.applyResult({
              errors: [res.data.ResponseMessage],
              valid: false,
              failedRules: {},
            });
          }
        })
        .then(() => {
          let container = this.$refs.chatwrap;
          container.scrollTop = container.scrollHeight;
        })
        .catch(() => {
          this.acceptOfferLoading = false;
        });
    },
    async placeOrder() {
      if (this.orderLoading) return;

      const yes = await this.$confirm("Are you sure?");
      if (!yes) return;

      this.orderLoading = true;

      this.$http
        .post(this.$store.state.Configs.getBargainItemForOrder, {
          BargainId: this.bargainId,
          Quantity: this.quantity,
        })
        .then((res) => {
          this.orderLoading = false;
          this.$store.dispatch("updateCards", res.data).then(() => {
            this.$router.push({ name: "Checkout" }).catch(() => {});
          });
        })
        .catch(() => {
          this.orderLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/proposal.scss";
</style>
