<template>
  <v-flex class="site-content-wrap site_bg">
    <v-flex class="mx-auto">
      <v-container class="py-0">
        <v-layout wrap>
          <!-- account-sidebar -->
          <account-sidebar></account-sidebar>

          <v-flex class="account-wrap">
            <v-card tile elevation="0">
              <v-flex class="account-panel">
                <v-layout
                  wrap
                  class="account-panel-top align-center justify-space-between"
                >
                  <h2>My Brand Details</h2>
                </v-layout>
                <!-- filters-bar -->
                <v-layout class="filter-bar flex-wrap">
                  <div class="input-wrap">
                    <v-text-field
                      solo
                      flat
                      hide-details
                      outlined
                      label="Keyword"
                      v-model="filterObj.Keyword"
                    ></v-text-field>
                  </div>

                  <div class="input-wrap">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="filterObj.date_range"
                      persistent
                      width="300px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterObj.date_range"
                          solo
                          flat
                          hide-details
                          outlined
                          label="Date range"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        range
                        v-model="filterObj.date_range"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(filterObj.date_range)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </div>

                  <div class="input-wrap">
                    <v-select
                      :items="status"
                      dense
                      solo
                      flat
                      hide-details
                      outlined
                      label="Order Status"
                      v-model="filterObj.Status"
                    ></v-select>
                  </div>

                  <v-btn
                    @click="getMyOrders()"
                    height="42"
                    type="submit"
                    tile
                    depressed
                    class="brand white--text text-none"
                    >Search</v-btn
                  >
                </v-layout>
                <!-- End filters-bar -->

                <v-flex class="brand-details">
                  <v-flex class="banner">
                    <img
                      class="d-block"
                      :src="require('@/assets/images/no-img.jpg')"
                      alt="Banner"
                    />
                  </v-flex>
                </v-flex>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-flex>
</template>

<script>
import AccountSidebar from "@/components/sites/pages/AccountSidebar";

export default {
  name: "MyBrands",
  components: {
    AccountSidebar,
  },

  data() {
    return {
      loading: false,
      filterObj: {
        date_range: [],
        DateFrom: "",
        DateTo: "",
        Keyword: "",
      },
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/my_brand_details.scss";
</style>
